import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { CheckboxesDropdown } from 'components/ui/dropdowns';

const BrandInput = (props) => {
  const { loading, all, onChange, value, isDirty, isValid, error } = props;
  const data = useMemo(() => (all || []).map((o) => ({ id: o.id, value: o.name })), [all]);

  return (
    <CheckboxesDropdown
      data={data}
      onChange={onChange}
      triggerProps={{
        label: 'Producător',
        placeholder: 'Selectează producătorul',
        required: true,
        autoFocus: true,
        valid: isDirty ? isValid : undefined,
        helperText: isDirty && !isValid ? error : null,
      }}
      searchable
      singleSelection
      selectedValues={!loading && value ? [value] : undefined}
    />
  );
};

BrandInput.propTypes = {
  loading: PropTypes.bool,
  all: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number, name: PropTypes.string }))
    .isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  isDirty: PropTypes.bool,
  isValid: PropTypes.bool,
  error: PropTypes.string,
};

BrandInput.defaultProps = {
  loading: false,
  value: '',
  isDirty: false,
  isValid: true,
  error: null,
};

BrandInput.displayName = 'BrandInput';

export default BrandInput;
