import React from 'react';
import PropTypes from 'prop-types';
import { CheckboxesDropdown } from 'components/ui/dropdowns';
import { Input } from 'components/ui/inputs';

const SpeciesInput = (props) => {
  const { loading, editable, item, onChange, data, onTriggerClick } = props;

  const triggerProps = {
    id: 'fishSpecies',
    name: 'fishSpecies',
    placeholder: 'Selectează specia',
    label: 'Specia de pește',
    type: 'text',
    required: true,
    valid: item.isDirty ? item.isValid : undefined,
    onClick: onTriggerClick,
  };

  if (editable) {
    return (
      <CheckboxesDropdown
        data={data}
        onChange={onChange}
        triggerProps={triggerProps}
        singleSelection
        selectedValues={!loading && item && item.value ? [item.value] : []}
        readOnly={!editable}
        searchable
      />
    );
  }

  const itemName = (data.find((obj) => obj.id === item.value) || {}).value;
  return <Input value={itemName} label="Specia de pește" readOnly />;
};

SpeciesInput.propTypes = {
  loading: PropTypes.bool.isRequired,
  editable: PropTypes.bool.isRequired,
  item: PropTypes.shape({
    isDirty: PropTypes.bool,
    isValid: PropTypes.bool,
    error: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })).isRequired,
  onTriggerClick: PropTypes.func.isRequired,
};

export default SpeciesInput;
