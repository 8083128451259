// import React from 'react';
import PropTypes from 'prop-types';
// import { IconButton } from 'components/ui/buttons';

const Social = () => {
  // const { onLike, onComment } = props;

  // TODO: Replace this once the social is implemented.
  return null;

  // return (
  //   <div className="flex gap-x-16 items-center">
  //     <IconButton onClick={onLike} icon="fire-small" variant="simple" size="small" weight="medium">
  //       Felicită (0)
  //     </IconButton>
  //     <IconButton
  //       onClick={onComment}
  //       icon="chat-small"
  //       variant="simple"
  //       size="small"
  //       weight="medium"
  //     >
  //       Socializează (0)
  //     </IconButton>
  //   </div>
  // );
};

Social.propTypes = {
  onLike: PropTypes.func.isRequired,
  onComment: PropTypes.func.isRequired,
};

Social.displayName = 'Social';

export default Social;
