import React from 'react';
import PropTypes from 'prop-types';
import Drawer from 'components/ui/Drawer';
import CreateGoOutButton from './controls/CreateGoOutButton';
import NavLinks from './controls/NavLinks';
import { getSidebarLinks } from './utils';

const Sidebar = (props) => {
  const { opened, toggleable, triggerRef, onClose } = props;

  const triggerClose = () => {
    if (triggerRef.current) {
      triggerRef.current.click();
    }
  };

  const sidebarLinks = getSidebarLinks(triggerClose);

  if (!opened) {
    return null;
  }

  return (
    <Drawer
      className="!z-30 w-104 border-r border-gray-input-stroke"
      triggerRef={triggerRef}
      withStartAnimation={toggleable}
      onClose={triggerRef.current ? onClose : null}
    >
      <div className="w-full h-full">
        <div className="flex flex-col gap-6.4 mt-29.2">
          <CreateGoOutButton onClickCallback={triggerClose} fullWidth />
          <NavLinks alignment="stretch" links={sidebarLinks} />
        </div>
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  opened: PropTypes.bool.isRequired,
  toggleable: PropTypes.bool,
  triggerRef: PropTypes.object,
  onClose: PropTypes.func,
};

Sidebar.defaultProps = {
  toggleable: true,
  triggerRef: { current: null },
  onClose: null,
};

Sidebar.displayName = 'Sidebar';

export default Sidebar;
