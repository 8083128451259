import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { NavLink as ReactNavLink } from 'react-router-dom';

const NavLink = (props) => {
  const { variant, active, children, to, className, ...otherProps } = props;
  const isPrimary = variant === 'primary';
  const isSecondary = variant === 'secondary';
  const isAccent = variant === 'accent';
  const isDropdownOption = variant === 'dropdown-option';
  const isDanger = variant === 'danger';

  return (
    <ReactNavLink
      data-testid="nav-link"
      tabIndex={0}
      to={to}
      className={cn('text-nowrap whitespace-nowrap cursor-pointer', className, {
        'text-link text-gray hover:text-green-dark px-6.4 py-4.8 rounded-md hover:bg-blueish':
          isPrimary,
        'bg-blueish text-green-dark': isPrimary && active,
        'text-base text-gray-darker hover:text-green-dark': isSecondary,
        'text-green-dark': isSecondary && active,
        'text-base text-blue hover:text-blue-darker font-semibold': isAccent,
        'text-blue-darker': isAccent && active,
        'text-red hover:text-red-darker': isDanger,
        'px-6.4 py-4 text-dark-gray font-medium leading-8 hover:bg-blueish first:rounded-3.2 last:rounded-3.2':
          isDropdownOption,
        'bg-blueish': isDropdownOption && active,
      })}
      {...otherProps}
    >
      {children}
    </ReactNavLink>
  );
};

NavLink.propTypes = {
  variant: PropTypes.oneOf(['primary', 'secondary', 'accent', 'dropdown-option', 'danger']),
  active: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  to: PropTypes.string,
  className: PropTypes.string,
};

NavLink.defaultProps = {
  variant: 'secondary',
  active: false,
  className: '',
  to: null,
};

export default NavLink;
