import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from 'components/ui/buttons';
import FiltersDrawer from '../FiltersDrawer';

const Filter = (props) => {
  const { onChange, count } = props;
  const [showContainer, setShowContainer] = useState(false);

  const onToggle = () => setShowContainer(!showContainer);

  return (
    <>
      <IconButton onClick={onToggle} icon="filter" variant="simple" scheme="blue">
        Filtrează ({count})
      </IconButton>
      {showContainer && <FiltersDrawer onClose={onToggle} onSubmit={onChange} onReset={() => {}} />}
    </>
  );
};

Filter.propTypes = {
  onChange: PropTypes.func.isRequired,
  count: PropTypes.number,
};

Filter.defaultProps = {
  count: 0,
};

export default Filter;
