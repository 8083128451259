import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import IconStats from 'components/ui/IconStats';
import Context from 'components/GoOut/state/contexts/addNew';
import { setStartTime } from 'components/GoOut/state/actions/addNew/others';
import { onFieldChange } from 'utility/utils';
import { isRequired } from 'utility/validation';
import { Input } from 'components/ui/inputs';
import { Button } from 'components/ui/buttons';
import { format } from 'date-fns';

const StartDateInput = (props) => {
  const { editable } = props;
  const [state, dispatch] = useContext(Context);
  const {
    event: {
      startTime: {
        value: startTimeValue,
        isValid: startTimeIsValid,
        isDirty: startTimeIsDirty,
        error: startTimeError,
      },
    },
  } = state;

  const onStartTimeChange = (eventOrValue) => {
    const setter = (newItem) => dispatch(setStartTime(newItem));
    onFieldChange(eventOrValue, setter, isRequired);
  };

  const onCurrentTimeSelection = () => onStartTimeChange(format(new Date(), 'HH:mm'));

  const inputProps = {
    placeholder: 'hh:mm',
    label: 'Oră de început',
  };

  if (editable) {
    return (
      <Input
        className="h-17.2 bg-white text-left"
        type="time"
        {...inputProps}
        value={startTimeValue}
        onChange={onStartTimeChange}
        required
        valid={startTimeIsDirty ? startTimeIsValid : undefined}
        error={startTimeError}
        actions={
          <Button key="current-time" variant="link" size="small" onClick={onCurrentTimeSelection}>
            Ora curentă
          </Button>
        }
      />
    );
  }

  return (
    <IconStats
      icon="clock"
      data={[{ label: 'Oră de început', value: startTimeValue }]}
      rounded="small"
    />
  );
};

StartDateInput.propTypes = {
  editable: PropTypes.bool.isRequired,
};

export default StartDateInput;
