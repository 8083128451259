import React, { useRef, useState } from 'react';
import SubmitModal from 'components/GoOut/SubmitModal';
import { useNavigate } from 'react-router-dom';

const useModalActions = () => {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const modalProps = useRef({
    onClose: () => {
      setShowModal(false);
    },
  });

  const modalElement = showModal ? <SubmitModal {...modalProps.current} /> : null;

  const cleanUpModalProps = () => {
    if (modalProps.current) {
      Object.keys(modalProps.current).forEach((key) => {
        if (key !== 'onClose') {
          delete modalProps.current[key];
        }
      });
    }
  };

  const openCreateModal = () => {
    modalProps.current.action = 'add';
    modalProps.current.successMessage = 'Partida a fost adăugată cu succes.';
    modalProps.current.onSuccess = () => {
      navigate('/');
      cleanUpModalProps();
      setShowModal(false);
    };
    setShowModal(true);
  };

  const openEditModal = () => {
    modalProps.current.action = 'edit';
    modalProps.current.successMessage = 'Partida a fost modificată cu succes.';
    modalProps.current.onSuccess = () => {
      navigate('/partidele-mele');
      cleanUpModalProps();
      setShowModal(false);
    };
    setShowModal(true);
  };

  const openDeleteModal = () => {
    modalProps.current.action = 'delete';
    modalProps.current.successMessage = 'Partida a fost ștearsă cu succes.';
    modalProps.current.onSuccess = () => {
      navigate('/partidele-mele');
      cleanUpModalProps();
      setShowModal(false);
    };
    setShowModal(true);
  };

  return {
    modalElement,
    showModal,
    setShowModal,
    openCreateModal,
    openEditModal,
    openDeleteModal,
  };
};

export default useModalActions;
