import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import AppContext from 'contexts/AppContext';
import GenericDropdown from 'components/ui/dropdowns/GenericDropdown';
import { clearAuthCookies } from 'utility/utils';
import Trigger from './Trigger';
import { getAccountLinks } from '../utils';
import NavLinks from '../controls/NavLinks';

const AccountDropdown = () => {
  const {
    user: { fullName, profile: { avatar } = {} } = {},
    setIsAuthenticated,
    env,
  } = useContext(AppContext);
  const navigate = useNavigate();
  const onLogout = () => {
    clearAuthCookies(env);
    setIsAuthenticated(false);
    navigate('/');
  };

  const accountLinks = getAccountLinks(onLogout);

  return (
    <GenericDropdown triggerProps={{ name: fullName, avatar }} triggerComponent={Trigger}>
      <NavLinks links={accountLinks} alignment="stretch" />
    </GenericDropdown>
  );
};

export default AccountDropdown;
