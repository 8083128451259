import {
  ADD_MAIN_ITEM,
  ADD_SPECIES,
  ADD_STRING_ITEM,
  REMOVE_MAIN_ITEM,
  REMOVE_MEDIA_FILE,
  REMOVE_SPECIES,
  REMOVE_STRING_ITEM,
  SET_ATMOSPHERIC_PRESSURE,
  SET_AVERAGE_CATCH_TIME,
  SET_DESCRIPTION,
  SET_END_DATE,
  SET_END_TIME,
  SET_FIRST_CATCH_TIME,
  SET_LOCATION_FIELD,
  SET_MAIN_ITEM,
  SET_MEDIA_FILE,
  SET_PERSONAL_NOTES,
  SET_PERSONAL_SCORE,
  SET_SKY_CONDITION,
  SET_SPECIES,
  SET_START_DATE,
  SET_START_TIME,
  SET_STRING_ITEM,
  SET_TEMPERATURE,
  SET_TOTAL_QUANTITY,
  SET_WATER_CLARITY,
  SET_WATER_LEVEL,
  SET_WATER_PERSONAL_NOTES,
  SET_WATER_SPEED,
  SET_WEATHER_PERSONAL_NOTES,
  SET_WIND_DIRECTION,
  SET_WIND_GUSTS,
  SET_WIND_SPEED,
  SET_CURRENT_COVER,
  SET_GO_OUT,
} from '../../types/addNew/others';

export const setStartTime = (newItem) => ({
  type: SET_START_TIME,
  payload: newItem,
});

export const setEndTime = (newItem) => ({
  type: SET_END_TIME,
  payload: newItem,
});
export const setStartDate = (newItem) => ({
  type: SET_START_DATE,
  payload: newItem,
});

export const setEndDate = (newItem) => ({
  type: SET_END_DATE,
  payload: newItem,
});

export const setLocationField = (newItem) => ({
  type: SET_LOCATION_FIELD,
  payload: newItem,
});

export const setWaterClarity = (newItem) => ({
  type: SET_WATER_CLARITY,
  payload: newItem,
});

export const setWaterSpeed = (newItem) => ({
  type: SET_WATER_SPEED,
  payload: newItem,
});

export const setWaterLevel = (newItem) => ({
  type: SET_WATER_LEVEL,
  payload: newItem,
});

export const setWaterPersonalNotes = (newItem) => ({
  type: SET_WATER_PERSONAL_NOTES,
  payload: newItem,
});

export const setSkyCondition = (newItem) => ({
  type: SET_SKY_CONDITION,
  payload: newItem,
});

export const setTemperature = (newItem) => ({
  type: SET_TEMPERATURE,
  payload: newItem,
});

export const setWindSpeed = (newItem) => ({
  type: SET_WIND_SPEED,
  payload: newItem,
});

export const setWindGuts = (newItem) => ({
  type: SET_WIND_GUSTS,
  payload: newItem,
});

export const setWindDirection = (newItem) => ({
  type: SET_WIND_DIRECTION,
  payload: newItem,
});

export const setAtmosphericPressure = (newItem) => ({
  type: SET_ATMOSPHERIC_PRESSURE,
  payload: newItem,
});

export const setWeatherPersonalNotes = (newItem) => ({
  type: SET_WEATHER_PERSONAL_NOTES,
  payload: newItem,
});

export const addMainItem = () => ({
  type: ADD_MAIN_ITEM,
});

export const setMainItem = (newItem, index, field) => ({
  type: SET_MAIN_ITEM,
  payload: {
    newItem,
    index,
    field,
  },
});

export const removeMainItem = (index) => ({
  type: REMOVE_MAIN_ITEM,
  payload: index,
});

export const addStringItem = () => ({
  type: ADD_STRING_ITEM,
});

export const setStringItem = (newItem, index, field) => ({
  type: SET_STRING_ITEM,
  payload: {
    newItem,
    index,
    field,
  },
});

export const removeStringItem = (index) => ({
  type: REMOVE_STRING_ITEM,
  payload: index,
});

export const setDescription = (newItem) => ({
  type: SET_DESCRIPTION,
  payload: newItem,
});

export const setPersonalScore = (newItem) => ({
  type: SET_PERSONAL_SCORE,
  payload: newItem,
});

export const setTotalQuantity = (newItem) => ({
  type: SET_TOTAL_QUANTITY,
  payload: newItem,
});

export const addSpecies = () => ({
  type: ADD_SPECIES,
});

export const setSpecies = (newItem, index, field) => ({
  type: SET_SPECIES,
  payload: {
    newItem,
    index,
    field,
  },
});

export const removeSpecies = (index) => ({
  type: REMOVE_SPECIES,
  payload: index,
});

export const setPersonalNotes = (newItem) => ({
  type: SET_PERSONAL_NOTES,
  payload: newItem,
});

export const setFirstCatchTime = (newItem) => ({
  type: SET_FIRST_CATCH_TIME,
  payload: newItem,
});

export const setAverageCatchTime = (newItem) => ({
  type: SET_AVERAGE_CATCH_TIME,
  payload: newItem,
});

export const setMediaFile = (newItem) => ({
  type: SET_MEDIA_FILE,
  payload: newItem,
});

export const removeMediaFile = (ids) => ({
  type: REMOVE_MEDIA_FILE,
  payload: ids,
});

export const setCoverImage = (index, newItem, meta) => ({
  type: SET_CURRENT_COVER,
  payload: {
    index,
    newItem,
    meta,
  },
});

export const setGoOut = (newItem) => ({
  type: SET_GO_OUT,
  payload: newItem,
});
