import useAPI from 'hooks/useAPI';

const useServerData = (props) => {
  const { isLoading, endpoint, dispatch, onInit, onAdd } = props;
  const { apiCall } = useAPI();

  const onAddNew = (item, callback) => {
    dispatch(onAdd(item));

    if (callback) {
      callback();
    }
  };

  const load = () => {
    if (isLoading) {
      (async () => {
        await apiCall(
          endpoint,
          'GET',
          {},
          {
            onSuccessCallback: (data) => {
              dispatch(onInit(data));
            },
          },
        );
      })();
    }
  };

  return [load, onAddNew];
};

export default useServerData;
