export const DAYS_OF_WEEK = [
  {
    identifier: 1,
    label: 'L',
  },
  {
    identifier: 2,
    label: 'Ma',
  },
  {
    identifier: 3,
    label: 'Mi',
  },
  {
    identifier: 4,
    label: 'J',
  },
  {
    identifier: 5,
    label: 'V',
  },
  {
    identifier: 6,
    label: 'S',
  },
  {
    identifier: 0,
    label: 'D',
  },
];

export const MONTHS = [
  {
    identifier: 0,
    label: 'IAN',
  },
  {
    identifier: 1,
    label: 'FEB',
  },
  {
    identifier: 2,
    label: 'MAR',
  },
  {
    identifier: 3,
    label: 'APR',
  },
  {
    identifier: 4,
    label: 'MAI',
  },
  {
    identifier: 5,
    label: 'IUN',
  },
  {
    identifier: 6,
    label: 'IUL',
  },
  {
    identifier: 7,
    label: 'AUG',
  },
  {
    identifier: 8,
    label: 'SEP',
  },
  {
    identifier: 9,
    label: 'OCT',
  },
  {
    identifier: 10,
    label: 'NOI',
  },
  {
    identifier: 11,
    label: 'DEC',
  },
];

export const VIEWS = {
  DAYS: 'days',
  MONTHS: 'months',
  YEARS: 'years',
};
