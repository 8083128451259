import React from 'react';
import PropTypes from 'prop-types';
import { getReelName } from 'components/Equipment/utility/utils';
import AddNewReel from 'components/Equipment/entities/ReelModal';
import Dropdown from './Dropdown';
import { init, add, select, deselect } from '../../state/actions/availableEntities/reels';

const ReelSelector = (props) => {
  const { index, editable } = props;

  return (
    <Dropdown
      inputProps={{
        id: `reel-${index}`,
        name: 'reel',
        placeholder: 'Selectează mulineta',
        label: 'Mulinetă',
        required: true,
      }}
      index={index}
      nameGetter={getReelName}
      resourceMeta={{
        apiResource: 'reels',
        entity: 'reels',
        formParentKey: 'gear',
        formKey: 'reel',
      }}
      actions={{
        onInit: init,
        onAdd: add,
        onSelect: select,
        onDeselect: deselect,
      }}
      addNewProps={{
        component: AddNewReel,
        text: 'Adaugă mulinetă',
      }}
      editable={editable}
    />
  );
};

ReelSelector.propTypes = {
  index: PropTypes.number.isRequired,
  editable: PropTypes.bool.isRequired,
};

ReelSelector.displayName = 'ReelSelector';

export default ReelSelector;
