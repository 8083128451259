import { SET_BRANDS } from './types';

export const defaultState = {
  isLoading: true,
  brands: null,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case SET_BRANDS:
      return {
        ...state,
        isLoading: false,
        brands: action.payload,
      };
    default:
      return state;
  }
};
