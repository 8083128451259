import { useNavigate } from 'react-router-dom';
import { REELS_HEADER, RODS_HEADER } from '../utility/constants';

const actions = {
  rods: 'Adaugă lansetă',
  reels: 'Adaugă mulinetă',
};

const useMeta = (activeTab, currentTabCount, otherTabCount) => {
  const navigate = useNavigate();
  const callToActionText = actions[activeTab];
  const header = activeTab === 'rods' ? RODS_HEADER : REELS_HEADER;
  const tabs = [
    {
      id: 'rods',
      label: 'Lansete',
      count: activeTab === 'rods' ? currentTabCount : otherTabCount,
      onClick: () => {
        navigate('/echipament/lansete');
      },
    },
    {
      id: 'reels',
      label: 'Mulinete',
      count: activeTab === 'reels' ? currentTabCount : otherTabCount,
      onClick: () => {
        navigate('/echipament/mulinete');
      },
    },
  ];

  return {
    callToActionText,
    header,
    tabs,
  };
};

export default useMeta;
