import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const WidgetCell = (props) => {
  const { description, value, unit, theme } = props;
  const isLightTheme = theme === 'light';

  return (
    <div className="flex flex-col gap-y-1.6 text-white">
      <div className={cn('text-sm', { 'text-gray': isLightTheme, 'text-white': !isLightTheme })}>
        {description}
      </div>
      <div
        className={cn('text-sm font-bold', {
          'text-green-dark': isLightTheme,
          'text-gray-300': !isLightTheme,
        })}
      >
        {value ? (
          <>
            {value}
            {unit && <span className="text-sm">&nbsp;{unit}</span>}
          </>
        ) : (
          '-'
        )}
      </div>
    </div>
  );
};

WidgetCell.propTypes = {
  description: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  unit: PropTypes.string,
  theme: PropTypes.oneOf(['light', 'dark']),
};

WidgetCell.defaultProps = {
  unit: '',
  theme: 'light',
};

WidgetCell.displayName = 'WidgetCell';

export default WidgetCell;
