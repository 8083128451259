import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import OrderNumber from 'components/ui/OrderNumber';
import { IconButton } from 'components/ui/buttons';
import Accordion from 'components/ui/Accordion';
import CanEditContext from '../../../contexts/CanEditContext';

const CombinationContainer = (props) => {
  const { number, children, onDelete, colsCount, title, opened, onToggle } = props;
  const editable = useContext(CanEditContext);

  const titleElement = (
    <div className="flex items-center gap-4">
      <div className="flex items-center gap-2">
        {onDelete && (
          <IconButton variant="simple" scheme="danger" onClick={onDelete} icon="bin-small" />
        )}
        <OrderNumber value={number} size="small" />
      </div>
      <div className="flex flex-col justify-center gap-2 text-sm">{title}</div>
    </div>
  );

  if (editable) {
    return (
      <Accordion opened={opened} onToggle={onToggle} title={titleElement}>
        <div className={`w-full grid grid-cols-1 lg:grid-cols-${colsCount} gap-x-4.8 gap-y-6.4`}>
          {children}
        </div>
      </Accordion>
    );
  }

  return titleElement;
};

CombinationContainer.propTypes = {
  number: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  onDelete: PropTypes.func,
  colsCount: PropTypes.number,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  opened: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
};

CombinationContainer.defaultProps = {
  onDelete: null,
  colsCount: 1,
  opened: false,
};

export default CombinationContainer;
