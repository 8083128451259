import React, { useState } from 'react';
import { getMonth, getYear } from 'date-fns';
import PropTypes from 'prop-types';
import Days from './views/Days';
import Months from './views/Months';
import Years from './views/Years';
import { VIEWS } from './utility/constants';

const Calendar = (props) => {
  const { date: initialDate, onChange, selectionType } = props;
  const [selectedDate, setSelectedDate] = useState(new Date(initialDate));
  const isDaySelection = selectionType === 'day';
  const [view, setView] = useState(isDaySelection ? VIEWS.DAYS : VIEWS.MONTHS);
  const onMonthsViewChange = () => setView(VIEWS.MONTHS);
  const onYearsViewChange = () => setView(VIEWS.YEARS);
  const onSelectedDateChange = (newDate) => {
    setSelectedDate(newDate);

    if (isDaySelection) {
      onChange(newDate);
    }
  };
  const onMonthChange = (year, month) => {
    if (isDaySelection) {
      if (month !== getMonth(selectedDate) || year !== getYear(selectedDate)) {
        onSelectedDateChange(new Date(year, month, 1));
      }
      setView(VIEWS.DAYS);
    } else {
      const newDate = new Date(year, month);
      setSelectedDate(newDate);
      onChange(newDate);
    }
  };

  const onYearChange = (year) => {
    if (year !== getYear(selectedDate)) {
      onSelectedDateChange(new Date(year, 0, 1));
    }

    setView(VIEWS.MONTHS);
  };

  return (
    <div className="bg-white flex flex-col gap-6.4 rounded-inherit">
      {view === VIEWS.DAYS && (
        <Days
          selectedDate={selectedDate}
          onViewChange={onMonthsViewChange}
          onChange={onSelectedDateChange}
        />
      )}
      {view === VIEWS.MONTHS && (
        <Months
          selectedDate={selectedDate}
          onViewChange={onYearsViewChange}
          onChange={onMonthChange}
        />
      )}
      {view === VIEWS.YEARS && <Years selectedDate={selectedDate} onChange={onYearChange} />}
    </div>
  );
};

Calendar.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  onChange: PropTypes.func.isRequired,
  selectionType: PropTypes.oneOf(['day', 'month']),
};

Calendar.defaultProps = {
  selectionType: 'day',
};

Calendar.displayName = 'Calendar';

export default Calendar;
