import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const Day = (props) => {
  const { month, referenceMonth, onClick, value, isToday, isSelected, disabled } = props;
  const isCurrentMonth = month === referenceMonth;
  const isDisabled = disabled || !isCurrentMonth;
  const isTodayAndNotSelected = isToday && !isSelected;
  const isAnyDayInCurrentMonth = isCurrentMonth && !isToday && !isSelected;

  const classes = cn('mx-auto w-12.8 h-12.8 flex items-center justify-center text-lg', {
    'text-gray': isDisabled,
    'text-blue bg-transparent': !isDisabled && isTodayAndNotSelected,
    'text-white rounded-full bg-blue': isSelected,
    'text-gray-dark bg-transparent hover:text-gray-darker': !isDisabled && isAnyDayInCurrentMonth,
    'hover:rounded-full hover:text-white hover:bg-blue': !isDisabled && isCurrentMonth,
  });

  if (disabled || !isCurrentMonth) {
    return <div className={classes}>{value}</div>;
  }

  return (
    <button type="button" className={classes} onClick={onClick} disabled={isSelected}>
      {value}
    </button>
  );
};

Day.propTypes = {
  month: PropTypes.number.isRequired,
  referenceMonth: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  isToday: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
};

Day.displayName = 'Day';

export default Day;
