import { useMemo } from 'react';
import useData from './useData';

const useTableRows = (endpoint, state, actions, columns) => {
  const { selectedIds } = state;
  const [isLoading, availableData, { load, onAdd: onAddNew }] = useData(
    endpoint,
    state,
    actions,
    columns,
  );
  const rows = useMemo(
    () =>
      availableData.map((obj) => ({
        id: obj.id,
        values: columns.map((column) =>
          column
            .split('.')
            .reduce((acc, key) => (acc && acc[key] !== undefined ? acc[key] : undefined), obj),
        ),
        selected: selectedIds.includes(obj.id),
      })),
    [availableData, selectedIds, columns],
  );
  const selectedRows = useMemo(() => rows.filter((row) => row.selected), [rows]);

  return [
    isLoading,
    rows,
    selectedRows,
    {
      load,
      onAdd: onAddNew,
    },
  ];
};

export default useTableRows;
