import React from 'react';
import PropTypes from 'prop-types';
import AddNewHook from 'components/Equipment/entities/HookModal';
import { getHookName } from '../../utility/utils';
import Dropdown from './Dropdown';
import { init, add, select, deselect } from '../../state/actions/availableEntities/hooks';

const HookSelector = (props) => {
  const { index, editable } = props;

  return (
    <Dropdown
      inputProps={{
        id: `hook-${index}`,
        name: 'hook',
        placeholder: 'Selectează cârligul',
        label: 'Cârlig',
        required: true,
      }}
      index={index}
      nameGetter={getHookName}
      resourceMeta={{
        apiResource: 'hooks',
        entity: 'hooks',
        formParentKey: 'rigs',
        formKey: 'hook',
      }}
      actions={{
        onInit: init,
        onAdd: add,
        onSelect: select,
        onDeselect: deselect,
      }}
      addNewProps={{
        component: AddNewHook,
        text: 'Adaugă cârlig',
      }}
      editable={editable}
    />
  );
};

HookSelector.propTypes = {
  index: PropTypes.number.isRequired,
  editable: PropTypes.bool.isRequired,
};

HookSelector.displayName = 'HookSelector';

export default HookSelector;
