import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { CheckboxesDropdown } from 'components/ui/dropdowns';
import { setWaterClarity } from 'components/GoOut/state/actions/addNew/others';
import { onFieldChange } from 'utility/utils';
import { isRequired } from 'utility/validation';
import IconStats from 'components/ui/IconStats';
import Context from 'components/GoOut/state/contexts/addNew';

const waterClearness = [
  'cristal',
  'limpede',
  'parțial limpede',
  'parțial tulbure',
  'tulbure',
  'foarte tulbure',
].map((o) => ({ id: o, value: o }));

const WaterClearnessInput = (props) => {
  const { editable } = props;
  const [state, dispatch] = useContext(Context);

  const {
    conditions: {
      water: {
        clearness: {
          value: clearness,
          isValid: clearnessIsValid,
          isDirty: clearnessIsDirty,
          error: clearnessError,
        },
      },
    },
  } = state;

  const onSelectClearness = (event) => {
    const setter = (newItem) => dispatch(setWaterClarity(newItem));
    onFieldChange(event, setter, isRequired);
  };

  if (editable) {
    const triggerProps = {
      id: 'waterClearness',
      name: 'waterClearness',
      placeholder: 'Selectare claritate',
      label: 'Claritate',
      type: 'text',
      required: true,
      valid: clearnessIsDirty ? clearnessIsValid : undefined,
      helperText: clearnessIsDirty && !clearnessIsValid ? clearnessError : null,
      value: clearness,
    };

    return (
      <CheckboxesDropdown
        data={waterClearness}
        id="waterClearness"
        onChange={onSelectClearness}
        triggerProps={triggerProps}
        singleSelection
        selectedValues={clearness ? [clearness] : []}
        readOnly={!editable}
      />
    );
  }

  return <IconStats icon="fog" data={[{ label: 'Claritate', value: clearness }]} rounded="small" />;
};

WaterClearnessInput.propTypes = {
  editable: PropTypes.bool.isRequired,
};

export default WaterClearnessInput;
