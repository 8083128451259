import { useContext, useMemo } from 'react';
import { AddNewContext, AvailableEntitiesContext } from 'components/GoOut/state/contexts';

const getFormData = (formKeysPath, formState, formObjectKey = null, index = null) => {
  let formData = formKeysPath.split('.').reduce((acc, key) => acc[key], formState);
  if (formObjectKey !== null) {
    formData = formData.map((values) => values[formObjectKey]);
  }
  if (index !== null) {
    if (formData.length > index) {
      if (formData[index] !== undefined) {
        formData = [formData[index]];
      } else {
        formData = [];
      }
    } else {
      formData = [];
    }
  }

  return formData;
};

const useItems = (formKeysPath, availableEntitiesKey, formObjectKey = null, index = null) => {
  const [formState, formStateDispatch] = useContext(AddNewContext);
  const [availableEntitiesState, availableEntitiesDispatch] = useContext(AvailableEntitiesContext);
  const { [availableEntitiesKey]: availableEntitiesData } = availableEntitiesState;
  const formData = useMemo(
    () => getFormData(formKeysPath, formState, formObjectKey, index),
    [formKeysPath, formState, formObjectKey, index],
  );
  return [formData, formStateDispatch, availableEntitiesData, availableEntitiesDispatch];
};

export default useItems;
