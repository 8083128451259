import { useMemo } from 'react';

const useTableData = (meta) =>
  useMemo(
    () =>
      Object.keys(meta).reduce(
        (acc, key) => {
          acc[0].push(meta[key]);
          acc[1].push(key);
          return acc;
        },
        [[], []],
      ),
    [meta],
  );

export default useTableData;
