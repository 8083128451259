import React from 'react';
import PropTypes from 'prop-types';
import AddNewMainLine from 'components/Equipment/entities/MainLineModal';
import { getLineName } from '../../utility/utils';
import Dropdown from './Dropdown';
import {
  initMainLines,
  addMainLine,
  selectMainLine,
  deselectMainLine,
} from '../../state/actions/availableEntities/lines';

const MainLineSelector = (props) => {
  const { index, editable } = props;

  return (
    <Dropdown
      inputProps={{
        id: `main-line-${index}`,
        name: 'mainLine',
        placeholder: 'Selectează linia principală',
        label: 'Linie principală',
        required: true,
      }}
      index={index}
      nameGetter={getLineName}
      resourceMeta={{
        apiResource: 'lines',
        entity: 'mainLines',
        formParentKey: 'gear',
        formKey: 'line',
        queryParams: {
          type: 'main',
        },
      }}
      actions={{
        onInit: initMainLines,
        onAdd: addMainLine,
        onSelect: selectMainLine,
        onDeselect: deselectMainLine,
      }}
      addNewProps={{
        component: AddNewMainLine,
        text: 'Adaugă linie principală',
      }}
      editable={editable}
    />
  );
};

MainLineSelector.propTypes = {
  index: PropTypes.number.isRequired,
  editable: PropTypes.bool.isRequired,
};

MainLineSelector.displayName = 'MainLineSelector';

export default MainLineSelector;
