import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

const Emphasize = (props) => {
  const { children, variant } = props;

  return (
    <span
      className={cn({
        'italic underline': variant === 'default',
        'font-semibold': variant === 'semibold',
        'italic underline text-blue-darker': variant === 'link',
      })}
    >
      {children}
    </span>
  );
};

Emphasize.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  variant: PropTypes.oneOf(['default', 'semibold', 'link']),
};

Emphasize.defaultProps = {
  variant: 'default',
};

Emphasize.displayName = 'Emphasize';

export default Emphasize;
