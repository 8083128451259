import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const Section = (props) => {
  const { children, title, className } = props;

  return (
    <div className="flex flex-col gap-y-9.6">
      {title && <span className="text-section-title text-gray-dark font-semibold">{title}</span>}
      <div className={cn(className, 'gap-y-9.6 gap-x-4.8')}>{children}</div>
    </div>
  );
};

Section.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  title: PropTypes.string,
  className: PropTypes.string,
};

Section.defaultProps = {
  className: '',
  title: '',
};

Section.displayName = 'Section';

export default Section;
