import { useEffect, useState } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import { toast } from 'react-toastify';
import useAPI from './useAPI';

const useGoogleAPI = () => {
  const [googleInstance, setGoogleInstance] = useState(null);
  const { apiCall } = useAPI();

  useEffect(() => {
    (async () => {
      await apiCall(
        '/maps/key',
        'get',
        {},
        {
          onSuccessCallback: (responseData) => {
            const API_KEY = responseData;
            (async () => {
              let google;
              try {
                const loader = new Loader({
                  apiKey: API_KEY,
                  version: 'weekly',
                });
                google = await loader.load();
              } catch (error) {
                toast.error('A apărut o eroare! Vă rugăm încercați mai târziu!');
              }
              if (google) {
                setGoogleInstance(google);
              }
            })();
          },
          onErrorCallback: () => {
            toast.error('A apărut o eroare! Vă rugăm încercați mai târziu!');
          },
        },
      );
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return googleInstance;
};

export default useGoogleAPI;
