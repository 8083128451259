import React from 'react';
import { Emphasize } from 'components/Policies/formatting';

const Mobile = () => {
  return (
    <Emphasize variant="link">
      <a href="tel:+40762568966">+40762568966</a>
    </Emphasize>
  );
};

export default Mobile;
