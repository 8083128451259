import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const SelectableInput = (props) => {
  const { children, selected, onSelect } = props;

  return (
    <button
      data-testid="selectable-input"
      className={cn(
        'flex border rounded-3.2 px-5.2 py-3.6 items-center justify-center font-medium',
        {
          'bg-blue-lighter border-blue text-blue': selected,
          // eslint-disable-next-line max-len
          'bg-white text-gray-darker border-gray-input-stroke hover:bg-blue-lighter hover:border-blue hover:text-blue':
            !selected,
        },
      )}
      onClick={onSelect}
      type="button"
      tabIndex={0}
    >
      {children}
    </button>
  );
};

SelectableInput.propTypes = {
  selected: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

SelectableInput.defaultProps = {
  selected: false,
};

SelectableInput.displayName = 'SelectableInput';

export default SelectableInput;
