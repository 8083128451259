import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from 'components/ui/buttons';

const Edit = (props) => {
  const { onEdit } = props;
  return <IconButton variant="simple" onClick={onEdit} icon="edit-small" scheme="blue" />;
};

Edit.propTypes = {
  onEdit: PropTypes.func.isRequired,
};

Edit.displayName = 'Edit';

export default Edit;
