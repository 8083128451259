import React, { useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const Drawer = (props) => {
  const {
    className,
    position,
    children,
    triggerRef,
    onOpen,
    onClose,
    bgColorClass,
    withStartAnimation,
    closedPositionClass,
    lockScreen,
  } = props;
  const parentContainerRef = useRef(null);
  const isOpened = useRef(!withStartAnimation);
  const isLeft = position === 'left';
  const marginClass = isLeft ? 'left-0' : 'right-0';
  const fullMarginClass = closedPositionClass || (isLeft ? '-left-full' : '-right-full');
  const isFirstOpening = useRef(withStartAnimation);

  const onTransitionEnd = () => {
    if (isOpened.current && onOpen) {
      onOpen();
    } else if (isFirstOpening.current) {
      isFirstOpening.current = false;
      isOpened.current = true;
      if (onOpen) {
        onOpen();
      }
    } else if (!isOpened.current && onClose) {
      onClose();
    }
  };

  const addStartTransitionClasses = useCallback(() => {
    const { current: container } = parentContainerRef;

    if (container) {
      container.classList.add(marginClass);
      container.classList.remove(fullMarginClass);

      setTimeout(() => {
        container.classList.add('transition-all', 'duration-300');
      }, 0);
    }
  }, [fullMarginClass, marginClass]);

  const removeEndTransitionClasses = useCallback(() => {
    const { current: container } = parentContainerRef;
    if (container) {
      container.classList.remove(marginClass);
      container.classList.add(fullMarginClass);
    }
  }, [fullMarginClass, marginClass]);

  useEffect(() => {
    if (lockScreen) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      if (lockScreen) {
        document.body.style.overflow = 'auto';
      }
    };
  }, [lockScreen]);

  useEffect(() => {
    setTimeout(() => {
      addStartTransitionClasses();
    }, 0);
  }, [addStartTransitionClasses]);

  useEffect(() => {
    const { current: trigger } = triggerRef;
    const onTriggerClick = (event) => {
      event.preventDefault();

      if (isOpened.current) {
        removeEndTransitionClasses();
      } else {
        addStartTransitionClasses();
      }

      isOpened.current = !isOpened.current;
    };

    if (trigger) {
      trigger.addEventListener('click', onTriggerClick);
    }

    return () => {
      if (trigger) {
        trigger.removeEventListener('click', onTriggerClick);
      }
    };
  }, [triggerRef, addStartTransitionClasses, removeEndTransitionClasses]);

  const classes = cn('z-40 fixed top-0 bottom-0 p-6.4', className, bgColorClass, fullMarginClass, {
    'transition-all duration-300': withStartAnimation,
  });

  return (
    <>
      {lockScreen && (
        <div className="fixed z-0 top-0 left-0 bottom-0 right-0 bg-black bg-opacity-25" />
      )}
      <div
        data-testid="drawer-container"
        ref={parentContainerRef}
        className={classes}
        onTransitionEnd={onTransitionEnd}
      >
        {children}
      </div>
    </>
  );
};

Drawer.propTypes = {
  position: PropTypes.oneOf(['left', 'right']),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  triggerRef: PropTypes.object.isRequired,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  className: PropTypes.string,
  bgColorClass: PropTypes.string,
  withStartAnimation: PropTypes.bool,
  closedPositionClass: PropTypes.string,
  lockScreen: PropTypes.bool,
};

Drawer.defaultProps = {
  position: 'left',
  onOpen: null,
  onClose: null,
  className: '',
  bgColorClass: 'bg-white',
  withStartAnimation: true,
  closedPositionClass: '',
  lockScreen: false,
};

Drawer.displayName = 'Drawer';

export default Drawer;
