import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation, NavLink } from 'react-router-dom';
import cn from 'classnames';
import Icon from 'components/ui/Icon';
import { IconButton } from 'components/ui/buttons';
import NavLinks from './controls/NavLinks';
import { getAccountLinks } from './utils';
import AccountDropdown from './AccountDropdown';

const Header = (props) => {
  const { onSidebarOpen, sidebarTriggerRef, isSidebarOpened } = props;
  const location = useLocation();
  const [isOpened, setIsOpened] = useState(false);
  const accountLinks = getAccountLinks(() => {});

  useEffect(() => {
    setIsOpened(false);
  }, [location]);

  return (
    <>
      <div
        className={cn('w-full fixed top-0 z-30 bg-white p-6.4', {
          'border-b border-gray-input-stroke': !isOpened,
        })}
      >
        <div className="flex flex-col w-full">
          <div className="flex items-center justify-end">
            <AccountDropdown />
          </div>
          {isOpened && (
            <div className="fixed z-10 top-27.2 left-0 right-0 bg-white border-b border-gray-input-stroke px-6.4 pb-6.4">
              <NavLinks variant="secondary" links={accountLinks} />
            </div>
          )}
        </div>
      </div>
      <div
        className={cn(
          "fixed top-0 left-0 z-30 xl:w-104 h-29.2 px-6.4 bg-white flex items-center gap-6.4 border-b xl:border-0 xl:after:content[''] xl:after:bg-gray-input-stroke xl:after:w-0.4 xl:after:h-0.4 xl:after:absolute xl:after:bottom-0 xl:after:right-0",
          {
            'border-b-white': isOpened,
            'border-b-gray-input-stroke': !isOpened,
          },
        )}
      >
        <div className="xl:hidden">
          <IconButton
            ref={sidebarTriggerRef}
            variant="simple"
            icon={isSidebarOpened ? 'indent-right' : 'indent-left'}
            onClick={onSidebarOpen}
          />
        </div>
        <NavLink to="/">
          <Icon identifier="logo-colored" />
        </NavLink>
      </div>
    </>
  );
};

Header.propTypes = {
  onSidebarOpen: PropTypes.func.isRequired,
  sidebarTriggerRef: PropTypes.object.isRequired,
  isSidebarOpened: PropTypes.bool.isRequired,
};

Header.displayName = 'Header';

export default Header;
