import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const Button = forwardRef((props, ref) => {
  const {
    children,
    variant,
    onClick,
    size,
    fullWidth,
    disabled,
    className,
    submit,
    weight,
    dataTestId,
    scheme,
    ...otherProps
  } = props;
  const isPrimary = variant === 'primary';
  const isSecondary = variant === 'secondary';
  const isLink = variant === 'link';
  const isBlueish = variant === 'blueish';
  const isSocial = variant === 'social';
  const isSimple = variant === 'simple';
  const isSimpleDefault = isSimple && scheme === 'default';
  const isSimpleDanger = isSimple && scheme === 'danger';
  const isSimpleBlue = isSimple && scheme === 'blue';
  const isDanger = variant === 'danger';
  const isMedium = size === 'medium';
  const isSmall = size === 'small';
  const isMediumWeight = weight === 'medium';
  const isSemiBoldWeight = weight === 'semibold';

  if (!submit && !onClick) {
    throw new Error('Button is not a submit button, therefore it must have an onClick handler.');
  }

  return (
    <button
      ref={ref}
      data-testid={dataTestId}
      tabIndex={0}
      type={submit ? 'submit' : 'button'}
      disabled={disabled}
      onClick={submit ? null : onClick}
      className={cn('text-nowrap whitespace-nowrap rounded-3.2', className, {
        'font-medium': isMediumWeight,
        'font-semibold': isSemiBoldWeight,
        'text-controls': isMedium,
        'leading-8': isSmall,
        'px-6.4 py-4': isPrimary || isBlueish || isDanger,
        'px-6 py-3.6': isSecondary || isSocial,
        'w-full': fullWidth,
        'bg-blue text-white hover:bg-blue-darker': isPrimary && !disabled,
        'bg-blue-light text-white': isPrimary && disabled,
        // eslint-disable-next-line max-len
        'bg-white text-gray-darker border border-gray-input-stroke hover:bg-blue-lighter hover:border-blue hover:text-gray-800':
          isSecondary && !disabled,
        'bg-white text-gray-input-stroke border border-gray-input-stroke':
          (isSecondary || isSocial) && disabled,
        'bg-white text-gray-input-stroke border border-gray-light-stroke':
          isSecondary && disabled && isSmall,
        'bg-blue-lighter text-blue hover:bg-blue-light hover:text-blue-darker':
          isBlueish && !disabled,
        'bg-gray-50 text-gray': isBlueish && disabled,
        'bg-transparent leading-8 text-blue hover:text-blue-darker': isLink,
        // eslint-disable-next-line max-len
        'bg-white text-gray-700 border border-gray-input-stroke hover:bg-blue-lighter hover:border-blue':
          isSocial && !disabled,
        'bg-transparent': isSimple,
        'text-gray-input-stroke': isSimple && disabled,
        'text-gray hover:text-gray-darker': isSimpleDefault && !disabled,
        'text-red hover:text-red-darker': isSimpleDanger && !disabled,
        'text-blue hover:text-blue-darker': isSimpleBlue && !disabled,
        'bg-red text-white hover:bg-red-darker': isDanger && !disabled,
      })}
      {...otherProps}
    >
      {children}
    </button>
  );
});

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'link',
    'blueish',
    'simple',
    'social',
    'danger',
  ]),
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['small', 'medium']),
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  submit: PropTypes.bool,
  weight: PropTypes.oneOf(['normal', 'medium', 'semibold']),
  dataTestId: PropTypes.string,
  scheme: PropTypes.oneOf(['default', 'danger', 'blue']),
};

Button.defaultProps = {
  variant: 'primary',
  size: 'medium',
  fullWidth: false,
  disabled: false,
  className: '',
  submit: false,
  onClick: null,
  weight: 'semibold',
  dataTestId: null,
  scheme: 'default',
};

Button.displayName = 'Button';

export default Button;
