import React, { useRef, useState } from 'react';
import Header from 'components/authentication/Header';
import { Input } from 'components/ui/inputs';
import { Button } from 'components/ui/buttons';
import PasswordInfo from 'components/authentication/Register/PasswordInfo';
import { EMPTY_DEFAULT_FIELD } from 'constants/forms';
import { onFieldChange } from 'utility/utils';
import { isRequired } from 'utility/validation';
import useTokenValidationResponse from 'components/authentication/hooks/useTokenValidationResponse';
import { getPasswordError } from 'components/authentication/utility/utils';

const ResetPassword = () => {
  const [passwordField, setPasswordField] = useState(EMPTY_DEFAULT_FIELD);
  const [passwordConfirmationField, setPasswordConfirmationField] = useState(EMPTY_DEFAULT_FIELD);
  const { onSubmit, token } = useTokenValidationResponse('reset');
  const passwordFieldRef = useRef(null);
  const canSubmit =
    passwordField.isValid &&
    passwordConfirmationField.isValid &&
    passwordField.value === passwordConfirmationField.value;

  const onPasswordChange = (e) => {
    onFieldChange(e.target.value, setPasswordField, isRequired);
  };

  const onPasswordConfirmationChange = (e) => {
    onFieldChange(e.target.value, setPasswordConfirmationField, isRequired);
  };

  const handledPasswordError = (errorData) => {
    const passwordError = getPasswordError(errorData);
    setPasswordField({
      ...passwordField,
      isValid: !passwordError,
      error: passwordError,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const body = {
      token,
      password: passwordField.value,
      passwordConfirmation: passwordConfirmationField.value,
    };

    await onSubmit('/authentication/password-reset/confirm', 'post', body, {
      getErrorMessage: (errorData, statusCode) => {
        switch (statusCode) {
          case 422:
            return getPasswordError(errorData.body.password);
          default:
            return null;
        }
      },
      onErrorCallback: (errorData, status) => {
        switch (status) {
          case 422:
            handledPasswordError(errorData.body.password);
            passwordFieldRef.current.focus();
            break;
          default:
            break;
        }
      },
    });
    return false;
  };

  return (
    <div className="flex flex-col h-full justify-start gap-8 xl:gap-12 relative">
      <Header> Resetează parola</Header>
      <form className="flex flex-col gap-y-8" onSubmit={handleSubmit}>
        <Input
          ref={passwordFieldRef}
          id="password"
          name="password"
          onChange={onPasswordChange}
          placeholder="Introdu parola"
          label="Parolă"
          value={passwordField.value}
          valid={passwordField.isDirty ? passwordField.isValid : true}
          helperText={passwordField.error}
          type="password"
          required
          actions={<PasswordInfo />}
        />
        <Input
          id="confirmedPassword"
          name="confirmedPassword"
          onChange={onPasswordConfirmationChange}
          placeholder="Introdu confirmarea parolei"
          label="Confirmare parolă"
          value={passwordConfirmationField.value}
          type="password"
          required
        />
        <div className="flex items-center justify-center">
          <Button variant="primary" submit disabled={!canSubmit}>
            Resetează parola
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ResetPassword;
