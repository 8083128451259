import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from 'components/ui/buttons';
import GenericDropdown from './GenericDropdown';

const InformationalDropdown = (props) => {
  const { children } = props;

  return (
    <GenericDropdown
      triggerProps={{ icon: 'circle-info-small', variant: 'simple', scheme: 'blue' }}
      triggerComponent={IconButton}
    >
      <div className="rounded-[inherit] flex flex-col gap-3.2">{children}</div>
    </GenericDropdown>
  );
};

InformationalDropdown.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default InformationalDropdown;
