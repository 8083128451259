import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components/ui/buttons';

const Controls = (props) => {
  const { onReset, onSubmit } = props;
  return (
    <div className="flex mt-auto gap-x-3.2">
      <Button variant="secondary" onClick={onReset} fullWidth>
        Resetează
      </Button>
      <Button variant="primary" onClick={() => onSubmit} fullWidth>
        Aplică
      </Button>
    </div>
  );
};

Controls.propTypes = {
  onReset: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

Controls.displayName = 'Controls';

export default Controls;
