import React from 'react';
import { NavLink } from 'react-router-dom';
import { Section, Emphasize, Title, List, SectionSubtitle } from './formatting';
import { WhoWeAre, Email, Mobile, AppName, CompanyName } from './common';

const PrivacyPolicy = () => {
  return (
    <div className="flex flex-col gap-9.6">
      <Title>
        Politica privind confidențialitatea aplicabilă{' '}
        <span className="text-blue-darker font-extrabold">pescuială.ro</span>
      </Title>
      <hr className="text-gray-input-stroke" />
      <Section>
        <p>
          Protecția și confidențialitatea datelor cu caracter personal este un subiect foarte
          important pentru noi și ne străduim să le stocăm în siguranță și să le prelucrăm cu
          atenție, iar în acest sens vă explicăm într-un mod clar și transparent care sunt
          practicile noastre privind confidențialitatea datelor dumneavoastră.
        </p>
        <p>
          În vederea asigurării funcționalităților disponibile în cadrul Aplicației <AppName />,{' '}
          <CompanyName /> prelucrează datele cu caracter personal aparținând utilizatorilor
          Aplicației (&quot;Utilizatorii&quot;), în conformitate cu prevederile legale aplicabile în
          domeniul protecției datelor cu caracter personal (respectiv, prevederile Regulamentului
          General privind Protecția Datelor nr. 679/2016 și ale legislației subsecvente).
        </p>
        <p>
          Prin achiziționarea produselor și/sau serviciilor noastre sau prin interacțiunea cu noi
          prin orice mijloc, declarați că sunteți de acord cu Politica de confidențialitate. Dacă nu
          sunteți de acord cu cele descrise în Prezentul Document, vă rugăm să nu utilizați
          serviciile noastre.
        </p>
        <p>
          Prezenta Politică privind confidențialitatea (denumită în continuare și
          &quot;Politică&quot;) se completează în mod corespunzător cu următoarele documente:
        </p>
        <List>
          <li>
            Termenii & Condițiile de utilizare pentru Aplicația <AppName /> (&quot;Termeni și
            Condiții&quot;), document disponibil aici:{' '}
            <Emphasize variant="link">
              <NavLink to="/termeni-si-conditii" target="_blank">
                pescuiala.ro/termeni-si-conditii
              </NavLink>
            </Emphasize>
            ;
          </li>
          <li>
            Politica privind utilizarea modulelor cookies, document disponibil aici:{' '}
            <Emphasize variant="link">
              <NavLink to="/politica-cookies" target="_blank">
                pescuiala.ro/politica-cookies
              </NavLink>
            </Emphasize>
          </li>
        </List>
      </Section>
      <Section title="1. Introducere. Definitii.">
        <SectionSubtitle>1.1. Scopul politicii de confidențialitate</SectionSubtitle>
        <p>
          Scopul acestei Politici de Confidențialitate este să vă explice ce informații prelucrăm
          (colectăm, utilizăm, partajăm), de ce le prelucrăm, în ce modalitate le prelucrăm,
          drepturile dvs. în temeiul GDPR și modalitatea în care puteți să le actualizați,
          gestionați, exportați și ștergeți, iar în acest scop noi acționăm în calitate de operator
          și, prin lege, suntem obligați să vă furnizăm aceste informații.
        </p>
        <SectionSubtitle>1.2. Cine suntem:</SectionSubtitle>
        <p>Mai jos găsiți datele noastre de identificare</p>
        <WhoWeAre />
        <p>
          În conformitate cu legislația în vigoare, compania noastră este{' '}
          <Emphasize>operator de date cu caracter personal</Emphasize>, iar pentru ca datele dvs. să
          fie prelucrate în siguranță, depunem toate eforturile să implementăm măsuri rezonabile și
          adecvate, tehnice și organizatorice pentru a proteja datele dvs. cu caracter personal.
        </p>
        <SectionSubtitle>1.3. Cine sunteți dvs.?</SectionSubtitle>
        <p>
          Potrivit legislației, dvs., persoana fizică beneficiară a serviciilor noastre,
          reprezentantul ori persoana de contact a unei companii care beneficiază de serviciile
          nostre, vizitatorul site-ului sau persoana aflată într-o relație de orice fel cu noi,
          sunteți o &quot;persoană vizată&quot; adică o persoană fizică identificată sau
          identificabilă. Pentru a fi complet transparenți în privința prelucrării de date și pentru
          a vă permite să vă exercitați cu ușurință, în orice moment, drepturile, am implementat
          măsuri pentru a facilita exercitarea drepturilor.
        </p>
        <SectionSubtitle>1.4. Definiții</SectionSubtitle>
        <p>
          <Emphasize>Date cu caracter personal</Emphasize> - înseamnă orice informații privind o
          persoană fizică identificată sau identificabilă (&quot;persoana vizată&quot;). O persoană
          fizică identificabilă este o persoană care poate fi identificată, direct sau indirect, în
          special prin referire la un element de identificare, cum ar fi un nume, un număr de
          identificare, date de localizare, un identificator online, sau la unul sau mai multe
          elemente specifice, proprii identității sale fizice, fiziologice, genetice, psihice,
          economice, culturale sau sociale;
        </p>
        <p>
          <Emphasize>Prelucrare</Emphasize> - înseamnă orice operațiune sau set de operațiuni
          efectuate asupra datelor cu caracter personal sau asupra seturilor de date cu caracter
          personal, cu sau fără utilizarea de mijloace automatizate, cum ar fi colectarea,
          înregistrarea, organizarea, structurarea, stocarea, adaptarea sau modificarea, extragerea,
          consultarea, utilizarea, divulgarea prin transmitere, diseminarea sau punerea la
          dispoziție în orice alt mod, alinierea sau combinarea, restricționarea, ștergerea sau
          distrugerea;
        </p>
        <p>
          <Emphasize>
            GDPR (General Data Protection Regulation) sau RGPD (Regulamentul General privind
            Protecția Datelor) sau Regulamentul
          </Emphasize>{' '}
          - înseamnă REGULAMENTUL PARLAMENTULUI EUROPEAN ȘI AL CONSILIULUI UNIUNII EUROPENE nr.
          2016/679 din 27 aprilie 2016 privind protecția persoanelor fizice în ceea ce privește
          prelucrarea datelor cu caracter personal și libera circulație a acestor date, împreună cu
          abrogarea Directivei 95/46/CE;
        </p>
        <p>
          <Emphasize>Operator</Emphasize> - înseamnă <CompanyName />, societate de naționalitate
          română cu sediul social în Aleea Nicolae Iorga, nr. 2, bl. A40, sc. 1, ap. 16, Craiova,
          Dolj, România, înmatriculată la Registrul Comerțului sub nr. de ordine J16/3036/2019,
          având cod de înregistrare fiscală RO41967980;
        </p>
        <p>
          <Emphasize>Persoană vizată</Emphasize> - reprezintă orice persoană fizică identificată sau
          identificabilă ale cărei date sunt prelucrate de către noi în calitate de operator, ca de
          exemplu beneficiari sau potențiali beneficiari ai serviciilor noastre, vizitatori ai
          site-ului afiliat aplicației;
        </p>
        <p>
          <Emphasize>Consimțământ</Emphasize> - înseamnă orice manifestare de voință liberă,
          specifică, informată și lipsită de ambiguitate a persoanei vizate prin care aceasta
          acceptă, printr-o declarație sau printr-o acțiune fără echivoc, ca datele cu caracter
          personal care o privesc să fie prelucrate;
        </p>
        <p>
          <Emphasize>Anonimizare</Emphasize> - înseamnă eliminarea ireversibilă a identificării
          datelor cu caracter personal, astfel încât să nu se poată identifica persoana prin
          folosirea unei perioade de timp, costuri și tehnologie rezonabile, fie de către Operator,
          fie de către orice altă persoană, pentru a identifica acea persoană fizică. Principiile de
          prelucrare a datelor cu caracter personal nu se aplică datelor anonimizate, întrucât nu
          mai sunt date cu caracter personal.
        </p>
        <SectionSubtitle>1.5. Cum ne puteți contacta?</SectionSubtitle>
        <p>
          Întrucât opinia dvs. este importantă pentru noi, vă rugăm să adresați orice întrebări cu
          privire la Politica privind confidențialitatea aplicației, în următoarele modalități:
        </p>
        <List>
          <li>
            <Emphasize variant="semibold">Prin email la adresa:</Emphasize> <Email />
          </li>
          <li>
            <Emphasize variant="semibold">
              Prin apel telefonic utilizând numărul de telefon:
            </Emphasize>{' '}
            <Mobile />
          </li>
          <li>
            <Emphasize variant="semibold">
              Prin cerere scrisă, transmisă cu servicii de curierat rapid, la adresa:
            </Emphasize>{' '}
            Aleea Nicolae Iorga, nr. 2, bl. A40, sc. 1, ap. 16, Craiova, Dolj, România
          </li>
        </List>
        <SectionSubtitle>1.6. Plângeri</SectionSubtitle>
        <p>
          Pentru orice fel de problemă sau nelămurire în legătură cu prelucrarea datelor personale,
          trebuie să știți că puteți depune o plângere la autoritatea de supraveghere a datelor cu
          caracter personal, însă vă rugăm să ne trimiteți mai întâi o cerere la adresa menționată
          în prezentul document, iar noi vom depune toate eforturile pentru a rezolva cererea dvs.
          în cel mai scurt timp, pe cale amiabilă.
        </p>
        <p>Pentru România, datele de contact sunt următoarele:</p>
        <List>
          <li>
            <Emphasize variant="semibold">Nume</Emphasize> Autoritatea Națională de Supraveghere a
            Prelucrării Datelor cu Caracter Personal
          </li>
          <li>
            <Emphasize variant="semibold">Adresă:</Emphasize> B-dul G-ral. Gheorghe Magheru nr.
            28-30, Sector 1, cod poștal 010336, București, România
          </li>
          <li>
            <Emphasize variant="semibold">Telefon:</Emphasize> +40.318.059.211 sau +40.318.059.212
          </li>
          <li>
            <Emphasize variant="semibold">Email:</Emphasize>{' '}
            <a href="mailto:anspdcp@dataprotection.ro">anspdcp@dataprotection.ro</a>
          </li>
        </List>
      </Section>
      <Section title="2. Datele pe care le colectăm despre dvs. și scopurile prelucrării">
        <p>
          Datele personale sau informațiile personale reprezintă toate informațiile despre un
          individ care pot ajuta la identificarea acelei persoane, iar aici nu sunt incluse și acele
          date unde identitatea a fost eliminată (<Emphasize>datele anonimizate</Emphasize>).
        </p>
        <p>
          Atunci când navigați în aplicația noastră sau atunci când ne contactați în orice scop și
          folosind orice canal de comunicare, ne puteți comunica datele dvs. personale.
        </p>
        <p>
          În conformitate cu <Emphasize variant="semibold">Art. 6 (1) a), b), c) GDPR</Emphasize> -
          Regulamentul (UE) 2016/679 al Parlamentului European și al Consiliului din 27 aprilie 2016
          privind protecția persoanelor fizice în ceea ce privește prelucrarea datelor cu caracter
          personal și privind libera circulație a acestor date și de abrogare a Directivei 95/46/CE
          (Regulamentul general privind protecția datelor), vă informăm că procedăm la colectarea,
          folosirea, stocarea sau transferul anumitor date personale, direct de la dvs. sau din alte
          surse, astfel:
        </p>
        <List>
          <li>
            pentru crearea unui cont în Aplicație, precum și în scopul gestionării acestuia și
            configurării serviciilor disponibile în cadrul Aplicației, prelucrăm următoarele date:
            numele și prenumele dvs., numărul de telefon (opțional) și adresa de e-mail.
          </li>
          <li>
            <p className="mb-4">
              pentru autentificarea dvs. în contul din Aplicație prelucrăm următoarele date:
            </p>
            <List variant="square">
              <li>denumirea de utilizator;</li>
              <li>parola.</li>
            </List>
          </li>
          <li>
            <p className="mb-4">
              pentru a vă putea furniza suport tehnic în legătură cu crearea/gestionarea contului
              din Aplicație și pentru a răspunde eventualelor solicitări pe care le puteți avea în
              acest sens, prelucrăm informații precum:
            </p>
            <List variant="square">
              <li>tipul abonamentului (și prețul aferent) în baza căruia folosiți Aplicația;</li>
              <li>datele administratorului contului din Aplicație;</li>
              <li>notificările;</li>
              <li>
                plângerile, feedback-ul și, în general, mesajele primit de la dvs. în legătură cu
                Aplicația;
              </li>
              <li>
                date automatizate/tehnice: Adresa de IP (internet protocol), datele de login, tipul
                de browser și versiunea, setările privind locația și fusul orar, plug-in-urile de
                browser și versiunile acestora, sistemul de operare, platforma de operare și alte
                tehnologii de pe dispozitivele pe care le folosiți pentru a accesa Aplicația.
              </li>
            </List>
          </li>
          <li>
            În cazul unei comenzi online, Compania <CompanyName /> va prelucra datele cu caracter
            personal ale utilizatorului declarate de către acesta în timpul creării contului și/sau
            comenzii sale în formularul de înregistrare a datelor, pentru a finaliza comanda
            specifică prin intermediul comerțului online. Fiecare utilizator al site-ului{' '}
            <Emphasize variant="semibold">pescuiala.ro</Emphasize> are posibilitatea de a cumpără
            anumite abonamente/beneficii în cadrul platformei online în calitate de utilizator
            înregistrat al aplicației. Utilizatorul înregistrat își poate modifica sau rectifica în
            orice moment datele prin conectarea la contul său la adresa{' '}
            <Emphasize variant="link">
              <NavLink to="/" target="_blank">
                pescuiala.ro
              </NavLink>
            </Emphasize>
            , cu numele de utilizator și parola sa. Pentru a accesa funcționalitate de
            autentificare, utilizatorul NU trebuie să fie deja autentificat. Dacă este deja
            autentificat, pagina de autentificare nu va fi disponibilă.
          </li>
        </List>
      </Section>
      <Section title="3. Tipul de date cu caracter personal și scopul prelucrării">
        <p>
          În special, datele cu caracter personal pe care fiecare utilizator trebuie să le furnizeze
          (fie în calitate de vizitator, fie în calitate de utilizator înregistrat) pentru a efectua
          orice tranzacție prin intermediul aplicației online al Companiei <CompanyName /> și pentru
          a plasa comenzi pentru beneficiile/abonamentele sale sunt următoarele:
        </p>
        <List>
          <li>numele și prenumele complet al persoanei;</li>
          <li>adresa de facturare a comenzii;</li>
          <li>
            detaliile complete de facturare, cum ar fi numele, profesia, administrația fiscală de
            care aparține și un număr de identificare fiscală (în cazul în care a fost aleasă
            eliberarea unei facturi fiscale comerciale);
          </li>
          <li>numărul de telefon;</li>
          <li>adresa sa de e-mail.</li>
        </List>
        <p>
          În plus față de cele de mai sus, <CompanyName /> mai colectează și stochează următoarele
          date cu caracter personal numai pentru utilizatorii înregistrați:
        </p>
        <List>
          <li>istoricul comenzilor;</li>
          <li>data ultimei accesări;</li>
          <li>produse care se află în coșul de cumpărături sau în lista de favorite;</li>
          <li>participarea la promoții.</li>
        </List>
        <p>
          Detaliile cardului de credit utilizate de utilizatorul înregistrat nu sunt stocate pe
          canalele de stocare ale <CompanyName /> în timpul tranzacției, ci sunt înregistrate direct
          într-un mediu securizat al companiei partenere care s-a ocupat de operațiunile cardurilor.
        </p>
        <p>
          Utilizatorul înregistrat este informat, de asemenea, că pentru comunicările Companiei{' '}
          <CompanyName /> cu acesta, în contextul Legii nr. 365 din 7 iunie 2002 privind comerțul
          electronic, prelucrează datele de contact furnizate de utilizator.
        </p>
        <p>
          De asemenea, atunci când utilizatorului înregistrat i se trimit notificări prin SMS/email
          etc. pentru modificări ale condițiilor de utilizare a portalului{' '}
          <Emphasize variant="link">
            <NavLink to="/" target="_blank">
              pescuiala.ro
            </NavLink>
          </Emphasize>{' '}
          cu un hyperlink, este posibil să se înregistreze adresa IP a dispozitivului electronic al
          acestuia (telefon mobil, tabletă, computer etc.) pentru a dovedi că utilizatorul
          înregistrat a primit notificarea.
        </p>
        <p>
          Scopul prelucrării de bază a datelor este executarea contractului și finalizarea comenzii
          specifice, comunicarea cu utilizatorul înregistrat și transmiterea de mesaje de informare
          cu privire la etapele de procesare a comenzii, furnizarea de clarificări legate de comandă
          și, în general, de informații privind achizițiile efectuate, confirmarea și identificarea
          utilizatorului înregistrat în orice caz necesar și informarea cu privire la stadiul
          comenzii.
        </p>
        <p>
          Utilizatorul înregistrat este informat că furnizarea datelor cu caracter personal și a
          datelor de procesare obligatorii de mai sus este necesară și reprezintă o condiție
          prealabilă pentru executarea corectă a comenzii și livrarea produselor și serviciilor. Din
          acest motiv, consimțământul acestuia nu este necesar pentru această prelucrare specifică.
        </p>
        <p>
          Fiecare utilizator înregistrat care folosește{' '}
          <Emphasize variant="link">
            <NavLink to="/" target="_blank">
              pescuiala.ro
            </NavLink>{' '}
          </Emphasize>{' '}
          este informat că datele sale cu caracter personal privind primirea, executarea și livrarea
          unei comenzi, precum și întrebările privind cumpărăturile, produsele și serviciile
          Companiei <CompanyName /> și comenzile plasate pe{' '}
          <Emphasize variant="link">
            <NavLink to="/" target="_blank">
              pescuiala.ro
            </NavLink>
          </Emphasize>{' '}
          vor fi prelucrate în scopul deservirii utilizatorilor - clienți atât de către angajații
          competenți ai Companiei <CompanyName />, cât și de către terți destinatari și/sau
          procesatori în numele <CompanyName />, în contextul îndeplinirii unei comenzi. Acești
          destinatari terți reprezintă companii de transport, companii de expediere individuale sau
          de grup, furnizori de servicii de internet și de comerț electronic și centre de apel
          pentru serviciul clienți, cu care <CompanyName /> cooperează și care sunt supuși
          condițiilor specifice stricte de prelucrare a datelor cu caracter personal convenite cu{' '}
          <CompanyName />, în calitate de operator de date. Compania <CompanyName /> solicită
          angajaților săi, operatorilor care întrețin site-ul său, precum și partenerilor săi terți
          să ia toate măsurile tehnice și organizatorice necesare (inclusiv politici și proceduri de
          prevenire a dezvăluirii datelor cu caracter personal ale vizitatorilor/utilizatorilor
          înregistrați - clienți pe care le prelucrează și pe care le dețin și să implementeze
          proceduri de gestionare și prelucrare a datelor cu caracter personal, într-un mod legal și
          care să le protejeze în conformitate cu GDPR.
        </p>
        <p>
          Nu se va efectua nicio altă prelucrare sau transmitere a informațiilor privind
          vizitatorii/utilizatorii înregistrați de către Compania <CompanyName /> și aplicația sa
          online{' '}
          <Emphasize variant="link">
            <NavLink to="/" target="_blank">
              pescuiala.ro
            </NavLink>
          </Emphasize>
          , decât dacă există o prealabilă informare și consimțământ deplin, acolo unde este
          necesar.
        </p>
        <p>
          Vă aducem la cunoștință faptul că nu colectăm niciun fel de Categorii Speciale de Date
          Personale despre dvs. (asta include date despre originea rasială sau etnică, opinii
          politice, confesiunea religioasă sau convingerile filozofice sau apartenența la sindicate
          și prelucrarea de date genetice, date biometrice pentru identificarea ta unică, date
          privind sănătatea sau date privind viața sexuală sau orientarea ta sexuală sau informații
          despre condamnări penale și infracțiuni).
        </p>
      </Section>
      <Section title="4. Ce se întâmplă dacă nu ne furnizați datele">
        <p>
          Atunci când vă rugăm să completați datele personale pentru a vă oferi acces la anumite
          funcționalități sau servicii ale site-ului, vom marca unele câmpuri ca fiind obligatorii,
          deoarece acestea sunt informații de care avem nevoie pentru a vă putea furniza serviciul
          sau pentru a vă oferi acces la funcționalitatea respectivă.
        </p>
        <p>
          Vă rugăm să rețineți că, dacă decideți să nu ne furnizați aceste informații, este posibil
          să nu puteți finaliza înregistrarea dvs. ca utilizator sau să nu puteți beneficia de
          aceste servicii sau funcționalități.
        </p>
      </Section>
      <Section title="5. Temeiurile legale pentru prelucrarea datelor cu caracter personal">
        <p>
          Temeiurile noastre legale pentru prelucrarea datelor dvs. personale vor fi în general
          următoarele:
        </p>
        <List>
          <li>
            <div className="mb-4">Interese legitime – exemple:</div>
            <List variant="square">
              <li>furnizarea serviciilor disponibile în cadrul Aplicației;</li>
              <li>
                gestionarea relației cu clienții Aplicației și, în general, executarea contractului
                privind accesarea și utilizarea Aplicației;
              </li>
              <li>
                apărarea drepturilor și intereselor Operatorului <CompanyName /> în cazul unui
                litigiu/plângeri.
              </li>
            </List>
          </li>
          <li>
            <div className="mb-4">
              Încheierea și executarea contractului cu dvs., în cazul în care:
            </div>
            <List variant="square">
              <li>acționați și utilizați Aplicația în calitate de client persoană fizică;</li>
              <li>
                faceți parte din personalul companiei care deține un cont în Aplicație în scopul
                asigurării serviciilor furnizate de noi în legătură cu Aplicația.
              </li>
            </List>
          </li>
        </List>
      </Section>
      <Section title="6. Divulgarea datelor personale și transferuri de date">
        <p>
          Vă informăm că putem dezvălui datele dvs., respectând legea aplicabilă, partenerilor de
          afaceri sau altor terți. Depunem în permanență eforturi rezonabile pentru a ne asigura că
          acești terți au implementate măsuri adecvate de protecție și securitate. Cu acești terți
          avem clauze contractuale astfel încât datele dvs. să fie protejate. În aceste situații, ne
          vom asigura că orice transfer este legitim conform legislației.
        </p>
        <p>Dacă este cazul, transmitem datele dvs. către:</p>
        <List>
          <li>Societățile din Grupul Companiei;</li>
          <li>
            Partenerii, furnizorii implicați în livrarea serviciilor disponibile în cadrul
            Aplicației;
          </li>
          <li>
            Agențiile de aplicare a legii, organisme guvernamentale, organizații de reglementare,
            instanțe sau alte autorități publice, dacă trebuie să facem acest lucru sau suntem
            autorizați prin lege;
          </li>
          <li>
            O terță parte sau un organism în cazul în care o astfel de divulgare este necesară
            pentru a respecta orice lege aplicabilă sau alte cerințe legale sau de reglementare.
          </li>
        </List>
        <p>
          De asemenea, putem transmite datele și altor părți având consimțământul sau potrivit
          instrucțiunilor dvs., ca de exemplu, în situația în care exercitați o cerere de
          portabilitate sau către organe abilitate ale statului, în baza și în limitele prevederilor
          legale și ca urmare a unor cereri expres formulate.
        </p>
        <p>
          Transferul de date cu caracter personal către un stat terț poate avea loc numai dacă
          statul către care se intenționează transferul asigură un nivel de protecție adecvat.
        </p>
        <p>
          Transferul de date către un stat a cărui legislație nu prevede un nivel de protecție cel
          puțin egal cu cel oferit de Regulamentul General de Protecție a Datelor este posibil numai
          dacă există garanții suficiente cu privire la protecția drepturilor fundamentale ale
          persoanelor vizate. Aceste garanții vor fi stabilite de noi prin contracte încheiate cu
          furnizorii/prestatori de servicii către care se va realiza transferul datelor tale
          personale.
        </p>
        <p>
          De fiecare dată când vom transfera datele dvs. personale în afara SEE ne vom asigura că
          există un nivel de protecție similar prin unul din următoarele mecanisme de salvgardare:
        </p>
        <List>
          <li>
            vom transfera datele tale personale către țări unde s-a demonstrat de către Comisia
            Europeană că furnizează un nivel adecvat de siguranță pentru datele personale.
          </li>
          <li>
            când apelăm la anumiți furnizori de servicii, vom putea folosi anumite modele de
            contracte furnizate și aprobate de Comisia Europeană care oferă datelor personale
            aceeași protecție.
          </li>
        </List>
      </Section>
      <Section title="7. Stocarea datelor">
        <p>
          Trebuie să rețineți că stocăm datele dvs. cu caracter personal doar pe perioada necesară
          îndeplinirii scopurilor, dar nu mai mult de 5 ani de la încetarea contractului sau ultima
          interacțiune cu noi.
        </p>
        <p>
          După încetarea perioadei, datele cu caracter personal vor fi distruse sau șterse din
          sistemele informatice sau transformate în date anonime pentru a fi utilizate în scopuri de
          cercetare științifică, istorică sau statistică.
        </p>
        <p>
          În anumite circumstanțe, putem anonimiza datele personale (astfel încât acestea nu mai pot
          fi asociate cu dvs.) în scopuri de cercetare științifică, istorică sau statistică, caz în
          care putem folosi aceste informații pe o perioadă nelimitată fără a vă anunța în
          prealabil. Rețineți faptul că în anumite situații expres reglementate, stocăm datele pe
          perioada care ne este impusă de lege.
        </p>
        <p>
          <Emphasize>Categoriile de date personale și perioada de stocare</Emphasize> sunt
          următoarele:
        </p>
        <List>
          <li>
            <Emphasize variant="semibold">Adresa de e-mail, Conținutul mesajelor:</Emphasize> 5 ani
            de la ultima interacțiune cu noi;
          </li>
          <li>
            <Emphasize variant="semibold">Datele necesare facturării</Emphasize> (i.e. adresa,
            numele clientului, numele delegatului): 10 ani conform legislației;
          </li>
          <li>
            <Emphasize variant="semibold">Alte date cu caracter personal:</Emphasize> 5 ani.
          </li>
        </List>
      </Section>
      <Section title="8. Securitatea datelor">
        <p>
          Înțelegem cât de importantă este securitatea datelor personale și luăm măsurile necesare
          pentru a proteja clienții noștri și alte persoane ale căror date le prelucrăm, de accesul
          neautorizat al datelor personale, precum și de modificarea, divulgarea sau distrugerea
          neautorizata ale datelor pe care le prelucrăm în activitatea curentă.
        </p>
        <p>
          Am implementat următoarele măsuri tehnice și organizatorice de securitate a datelor cu
          caracter personal:
        </p>
        <List>
          <li>
            <Emphasize variant="semibold">Politici dedicate:</Emphasize> Adoptăm și revizuim
            constant practicile și politicile interne de prelucrare a datelor personale (inclusiv
            măsurile fizice și electronice de securitate/firewalls), pentru a ne proteja sistemele
            de un eventual acces neautorizat sau alte posibile amenințări la securitatea acestora.
            Aceste politici sunt supuse unor verificări constante, pentru a ne asigura că respectăm
            cerințele legale și că sistemele funcționează în mod adecvat;
          </li>
          <li>
            <Emphasize variant="semibold">Minimizarea datelor:</Emphasize> Ne asigurăm că datele
            dvs. cu caracter personal pe care le prelucrăm sunt limitate doar la cele necesare,
            adecvate și relevante pentru scopurile declarate în această Politică.
          </li>
          <li>
            <Emphasize variant="semibold">Restrângerea accesului la date:</Emphasize> Încercăm să
            restrângem cât mai mult accesul la datele cu caracter personal pe care le prelucrăm la
            minimul necesar: angajați, colaboratori și alte persoane care au nevoie să acceseze
            aceste date pentru a le prelucra și a duce la îndeplinire un serviciu. Partenerii și
            colaboratorii noștri sunt supuși unor obligații stricte de confidențialitate (fie prin
            contract, fie legale).
          </li>
          <li>
            <Emphasize variant="semibold">Măsuri tehnice specifice:</Emphasize> Utilizăm tehnologii
            care să asigure securitatea clienților noștri, încercând întotdeauna să implementăm cele
            mai optime soluții pentru protecția datelor. De asemenea, facem back-up-uri periodice de
            date pentru a le putea recupera în cazul unui eventual incident și avem implementate
            proceduri de audit periodice în privința securității echipamentelor folosite. Totuși,
            niciun site web, nicio aplicație și nicio conexiune la internet nu este complet sigură
            și de neatins;
          </li>
          <li>
            <Emphasize variant="semibold">Asigurarea exactității datelor dvs:</Emphasize> Câteodată
            este posibil să vă cerem să confirmați exactitatea sau actualitatea datelor dvs. pentru
            a fi siguri că ele reflectă realitatea;
          </li>
          <li>
            <Emphasize variant="semibold">Instruirea personalului:</Emphasize> Ne instruim și testăm
            constant angajații și colaboratorii cu privire la legislația și cele mai bune practici
            în domeniul prelucrării datelor cu caracter personal. Exemple: obligativitatea
            utilizării unor parole de acces complexe, educarea angajaților spre a identifica
            phishing-ul, de a nu introduce pe stațiile de lucru software neautorizat de organizație
            ș.a.;
          </li>
          <li>
            <Emphasize variant="semibold">Anonimizarea datelor:</Emphasize> Acolo unde putem,
            încercăm pe cât posibil să anonimizăm /pseudoanonimizăm datele cu caracter personal pe
            care le prelucrăm, astfel încât să nu mai putem identifica persoanele la care acestea se
            referă.
          </li>
        </List>
        <p>
          Cu toate acestea, deși depunem eforturi constante pentru a asigura securitatea datelor pe
          care ni le încredințați, este posibil să avem parte și de evenimente mai puțin fericite și
          să avem incidente/breșe de securitate. În aceste cazuri, vom urma cu strictețe procedura
          de raportare și notificare a incidentelor de securitate și vom lua toate măsurile necesare
          pentru a readuce situația la normal în cel mai scurt timp posibil.
        </p>
      </Section>
      <Section title="9. Drepturile dvs. conform Regulamentului GDPR sunt următoarele:">
        <List>
          <li>
            <Emphasize variant="semibold">
              Dreptul de a fi informat cu privire la prelucrarea datelor dvs.
            </Emphasize>{' '}
          </li>
          <li>
            <Emphasize variant="semibold">
              Dreptul de acces la datele cu caracter personal.
            </Emphasize>{' '}
            Puteți obține de la noi confirmarea că prelucrăm datele dvs. cu caracter personal,
            precum și informații privind specificul prelucrării cum ar fi: scopul, categoriile de
            date cu caracter personal prelucrate, destinatarii datelor, perioada pentru care datele
            sunt păstrate, existența dreptului de rectificare, ștergere sau restricționare a
            prelucrării (informațiile prevăzute de art. 15 alin. (1) din GDPR). Aveți dreptul de a
            solicita o copie a datelor cu caracter personal pe care le deține Compania în legătură
            cu dvs.
          </li>
          <li>
            <Emphasize variant="semibold">
              Dreptul de rectificare a datelor cu caracter personal inexacte sau incomplete.
            </Emphasize>{' '}
            Aveți dreptul de a rectifica datele deținute în legătură cu dvs., dacă acestea nu sunt
            corecte sau trebuie actualizate.
          </li>
          <li>
            <Emphasize variant="semibold">
              Dreptul de opoziție la utilizarea datelor cu caracter personal.
            </Emphasize>{' '}
            În anumite circumstanțe, aveți dreptul de a vă opune prelucrării de către Companie a
            datelor dvs. cu caracter personal. În funcție de situația concretă, dacă avem interese
            legitime pentru prelucrare care prevalează intereselor, drepturilor și libertăților dvs.
            ori avem nevoie de date pentru stabilirea, exercitarea ori apărarea în cadrul unor
            proceduri oficiale, putem prelucra în continuare datele dvs. pentru scopuri corelative.
          </li>
          <li>
            <Emphasize variant="semibold">
              Dreptul de a restricționa utilizarea datelor dvs. cu caracter personal.
            </Emphasize>{' '}
            În cazurile prevăzute la art. 18 din GDPR, aveți dreptul de a solicita și obține
            restricționarea prelucrării, in cazul în care considerați că datele pe care le deținem
            cu privire la dvs. sunt incorecte sau considerați că nu ar trebui să prelucrăm datele
            dvs. - și nu doriți ștergerea acestor date - puteți solicita restricționarea prelucrării
            datelor dumneavoastră.
          </li>
          <li>
            <Emphasize variant="semibold">
              Dreptul de a transmite datele pe care le avem despre dvs. către alt operator
              (&quot;dreptul la portabilitate&quot;).
            </Emphasize>
          </li>
          <li>
            <Emphasize variant="semibold">
              Dreptul de a nu face obiectul unei decizii bazate exclusiv pe prelucrarea automată,
              inclusiv crearea de profiluri cu efecte juridice sau cu efecte semnificative similare
              asupra dvs.
            </Emphasize>
          </li>
          <li>
            <Emphasize variant="semibold">
              Dreptul de a vă adresa justiției pentru apărarea drepturilor și intereselor dvs.
            </Emphasize>
          </li>
          <li>
            <Emphasize variant="semibold">Dreptul de ștergere.</Emphasize>
          </li>
        </List>
        <p>
          În situațiile prevăzute la art. 17 din GDPR, aveți dreptul de a solicita și obține
          ștergerea datelor cu caracter personal.
        </p>
        <p>
          Pentru orice alte informații cu privire la datele sale, precum și la prelucrarea și
          protecția acestora, fiecare persoană în cauză poate contacta responsabilul cu protecția
          datelor din cadrul <CompanyName /> (responsabilul cu protecția datelor) la adresa de
          e-mail <Email /> și la numărul de telefon <Mobile />.
        </p>
        <p>
          De asemenea, vom încerca să răspundem solicitării dvs. în termen de 30 (treizeci) de zile.
          Cu toate acestea, termenul poate fi prelungit în funcție de diferite aspecte, precum
          complexitatea cererii, numărul mare de cereri primite sau imposibilitatea de a vă
          identifica într-un termen util.
        </p>
        <p>
          Vom face toate eforturile pentru a vă ajuta, dar dacă aveți în continuare nemulțumiri,
          puteți contacta Autoritatea Națională de Supraveghere a Prelucrării Datelor cu Caracter
          Personal, datele de contact se găsesc la adresa{' '}
          <Emphasize variant="link">
            <a href="dataprotection.ro">www.dataprotection.ro</a>
          </Emphasize>{' '}
          .
        </p>
      </Section>
      <Section title="10. Schimbări/Modificări/Actualizări ale politicii de confidențialitate">
        <p>
          Este posibil să actualizăm ocazional Politica de confidențialitate și vă încurajăm să
          accesați și să citiți periodic Politica pentru a fi la curent cu ultimele versiuni.
        </p>
        <p>
          Politică de confidențialitate a fost actualizată la data de{' '}
          <Emphasize variant="semibold">01 Mai 2024</Emphasize> .
        </p>
      </Section>
    </div>
  );
};

export default PrivacyPolicy;
