import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import Icon from 'components/ui/Icon';

const TextField = (props) => {
  const {
    id,
    label,
    placeholder,
    element,
    type,
    value,
    name,
    onChange,
    disabled,
    valid,
    autoComplete,
    autoFocus,
    dropDown,
    dropDownOpened,
    required,
    helperText,
    className,
    onClick,
    actions,
    forceHelperText,
    readOnly,
    ...otherProps
  } = props;

  return (
    <div className="flex flex-col gap-2.4 w-full">
      {(label || actions) && (
        <div className="flex justify-between items-center">
          {label && (
            <label htmlFor={id} className="text-gray-dark leading-8 font-medium">
              {label} {required && <span className="text-error-500 leading-8">*</span>}
            </label>
          )}
          {actions}
        </div>
      )}
      <div className="relative">
        {React.cloneElement(element, {
          id,
          className: cn(
            'px-6 py-3.6 rounded-3.2 placeholder:text-gray-darker border text-controls w-full',
            className,
            {
              // eslint-disable-next-line max-len
              'text-gray-900 focus:outline-none focus:border-blue focus:outline-offset-0 focus:outline-blue-lighter focus:outline-4':
                !disabled && !readOnly,
              'bg-gray-50 text-gray-darker': disabled,
              'border-gray-input-stroke': valid,
              'pr-15.6 border-error-300': !valid,
              'pr-20.4': dropDown && valid,
              'pr-29.8': dropDown && !valid,
            },
          ),
          ...otherProps,
          name,
          type,
          value,
          onChange,
          placeholder,
          disabled,
          autoComplete,
          autoFocus,
          readOnly: dropDown || readOnly,
          onClick,
        })}
        {!valid && (
          <div
            data-testid="error-icon"
            className={cn('absolute flex items-center top-0.4 bottom-0.4', {
              'right-20.4': dropDown,
              'right-6': !dropDown,
            })}
          >
            <Icon identifier="warning" />
          </div>
        )}
        {dropDown && (
          <div
            data-testid="drop-down-icon"
            className={cn('absolute flex items-center top-0.4 bottom-0.4 right-6', {
              'psc-rotate-180': dropDownOpened,
            })}
          >
            <Icon identifier="down-chevron-small" />
          </div>
        )}
      </div>
      {(helperText || forceHelperText) && (
        <span
          className={cn('leading-8', {
            'text-error-500': !valid,
            'text-gray-darker': valid,
            invisible: !helperText && forceHelperText,
          })}
        >
          {helperText || <span>&nbsp;</span> /* To keep the height of the helper text container */}
        </span>
      )}
    </div>
  );
};

TextField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  element: PropTypes.element.isRequired,
  type: PropTypes.oneOf(['text', 'number', 'password', 'email']),
  value: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  valid: PropTypes.bool,
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  dropDown: PropTypes.bool,
  dropDownOpened: PropTypes.bool,
  required: PropTypes.bool,
  helperText: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  actions: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  forceHelperText: PropTypes.bool,
  readOnly: PropTypes.bool,
};

TextField.defaultProps = {
  id: '',
  name: '',
  label: '',
  placeholder: '',
  type: 'text',
  value: '',
  onChange: null,
  disabled: false,
  valid: true,
  autoComplete: 'off',
  autoFocus: false,
  dropDown: false,
  dropDownOpened: false,
  required: false,
  helperText: '',
  className: '',
  onClick: null,
  actions: null,
  forceHelperText: false,
  readOnly: false,
};

TextField.displayName = 'TextField';

export default TextField;
