import { getFieldsValues, setFieldsErrors } from 'utility/utils';
import useAPI from 'hooks/useAPI';
import { successMessage } from '../utility/utils';

const useSubmit = (resourceName, item, fields, setFields, onSuccess) => {
  const { apiCall, isSubmitting } = useAPI();

  const canSubmit =
    !isSubmitting &&
    Object.values(fields).every((field) => field.isValid) &&
    Object.values(fields).some((field) => field.isDirty);

  const onSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    let method;
    let endpoint = `/gear/${resourceName}`;
    const requestData = getFieldsValues(fields);

    if (item) {
      method = 'put';
      endpoint += `/${item.id}`;
    } else {
      method = 'post';
    }

    await apiCall(endpoint, method, requestData, {
      getSuccessMessage: (serverItem) => successMessage(resourceName, serverItem, item),
      onSuccessCallback: (serverItem) => onSuccess(serverItem),
      onErrorCallback: (data, statusCode) => {
        if (statusCode === 422) {
          const { body } = data;
          setFieldsErrors(body, setFields);
        }
      },
    });

    return false;
  };

  return [canSubmit, onSubmit];
};

export default useSubmit;
