import React, { forwardRef } from 'react';
import TextField from 'components/ui/inputs/TextField';
import PropTypes from 'prop-types';

const Input = forwardRef((props, ref) => <TextField element={<input ref={ref} />} {...props} />);

Input.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.oneOf(['text', 'number', 'password', 'email']),
  value: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  valid: PropTypes.bool,
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  dropDown: PropTypes.bool,
  dropDownOpened: PropTypes.bool,
  required: PropTypes.bool,
  helperText: PropTypes.string,
  actions: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  readOnly: PropTypes.bool,
};

Input.defaultProps = {
  id: null,
  name: null,
  label: '',
  placeholder: '',
  type: 'text',
  value: '',
  onChange: null,
  disabled: false,
  valid: true,
  autoComplete: 'off',
  autoFocus: false,
  dropDown: false,
  dropDownOpened: false,
  required: false,
  helperText: '',
  actions: null,
  readOnly: false,
};

Input.displayName = 'Input';

export default Input;
