import React, { useMemo, useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { Outlet, useLocation, useLoaderData, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import AppContext from 'contexts/AppContext';
import axios from 'utility/axios';
import { clearAuthCookies } from 'utility/utils';
import useSystemColorScheme from '../hooks/useSystemColorScheme';
import { defaultState, reducer } from '../state/app/reducer';

import 'react-toastify/dist/ReactToastify.css';
import './app.scss';

const { ENV } = process.env;

export const userLoader = async () => {
  let response;

  try {
    response = await axios.get('/authentication/me');
  } catch (error) {
    if (!error.response || error.response.status === 401) {
      clearAuthCookies(ENV);
    }

    return { error: true };
  }

  return response?.data;
};

const App = (props) => {
  const { authenticated } = props;
  const [state, dispatch] = useReducer(reducer, defaultState);
  const userData = useLoaderData();
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(authenticated);
  const contextValue = useMemo(
    () => ({
      env: ENV,
      isAuthenticated,
      setIsAuthenticated,
      user: userData,
      state,
      dispatch,
    }),
    [isAuthenticated, setIsAuthenticated, userData, state, dispatch],
  );
  const navigate = useNavigate();

  useSystemColorScheme();

  useEffect(() => {
    const onBeforeUnload = () => {
      localStorage.removeItem('is-user-loaded');
    };

    window.addEventListener('beforeunload', onBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    const isUserLoaded = JSON.parse(localStorage.getItem('is-user-loaded'));

    if (userData.error && !isUserLoaded) {
      setIsAuthenticated(false);
      navigate('/');
    }

    localStorage.setItem('is-user-loaded', JSON.stringify(true));
  }, [userData, navigate]);

  return (
    <AppContext.Provider value={contextValue}>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        rtl={false}
        closeOnClick
        pauseOnFocusLoss
        pauseOnHover
        bodyClassName="text-base leading-8"
      />
      <Outlet />
    </AppContext.Provider>
  );
};

App.propTypes = {
  authenticated: PropTypes.bool,
};

App.defaultProps = {
  authenticated: undefined,
};

export default App;
