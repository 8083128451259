import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Input from 'components/ui/inputs/Input';
import Icon from 'components/ui/Icon';

const IconInput = (props) => {
  const { icon, valid, helperText, label, actions, ...otherProps } = props;

  return (
    <div className="relative w-full">
      <div
        data-testid="input-icon"
        className={cn('absolute flex items-center left-6 z-10', {
          'top-10.8': label || actions,
          'top-0.4': !label && !actions,
          'bottom-0.4': valid && !helperText,
          'bottom-10.8': helperText,
        })}
      >
        <Icon identifier={icon} />
      </div>
      <Input
        valid={valid}
        helperText={helperText}
        className="pl-17.2"
        label={label}
        actions={actions}
        {...otherProps}
      />
    </div>
  );
};

IconInput.propTypes = {
  icon: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.oneOf(['text', 'number', 'password', 'email']),
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  valid: PropTypes.bool,
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  dropDown: PropTypes.bool,
  dropDownOpened: PropTypes.bool,
  required: PropTypes.bool,
  helperText: PropTypes.string,
  actions: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  readOnly: PropTypes.bool,
};

IconInput.defaultProps = {
  icon: '',
  label: '',
  placeholder: '',
  type: 'text',
  value: '',
  onChange: null,
  disabled: false,
  valid: true,
  autoComplete: 'off',
  autoFocus: false,
  dropDown: false,
  dropDownOpened: false,
  required: false,
  helperText: '',
  actions: null,
  readOnly: false,
};

IconInput.displayName = 'IconInput';

export default IconInput;
