import React from 'react';
import PropTypes from 'prop-types';
import CombinationContainer from 'components/GoOut/steps/CombinationContainer';
import SpeciesInput from 'components/GoOut/steps/inputs/SpeciesInput';
import { Input } from 'components/ui/inputs';
import IconStats from 'components/ui/IconStats';
import { isDecimalNumberMaxThreeDecimals, isIntegerNumber } from 'utility/validation';

const getAverageWeight = (quantity, number) => {
  if (!quantity || !number) {
    return '0.000';
  }

  const quantityInt = Number.parseInt(quantity, 10);
  const numberInt = Number.parseInt(number, 10);

  if (Number.isNaN(quantityInt) || Number.isNaN(numberInt) || numberInt === 0) {
    return '0.000';
  }

  return (quantityInt / numberInt).toFixed(3);
};

const CatchData = (props) => {
  const {
    formData,
    speciesDetails,
    index,
    opened,
    onAccordionToggle,
    editable,
    removeRow,
    onDropdownTriggerClick,
    changeField,
    onSelect,
    dropdownData,
  } = props;
  const { species: formItem, totalWeight, maxWeight, totalNumber } = formData;
  const averageWeight = getAverageWeight(totalWeight.value, totalNumber.value);

  if (editable) {
    const titleElement = speciesDetails ? (
      speciesDetails.name
    ) : (
      <p className="text-gray-input-stroke">Alege specia prinsă</p>
    );

    return (
      <CombinationContainer
        key={index}
        className="flex gap-x-4.8"
        number={index + 1}
        onDelete={removeRow ? () => removeRow(index) : null}
        onToggle={onAccordionToggle}
        opened={opened}
        title={titleElement}
      >
        <SpeciesInput
          loading={dropdownData.isLoading}
          editable={editable}
          item={formItem}
          onChange={(event) => onSelect(event, index, 'species')}
          data={dropdownData.data}
          onTriggerClick={onDropdownTriggerClick}
        />
        <Input
          id="totalQuantity"
          name="totalQuantity"
          onChange={(event) =>
            changeField(event.target.value, index, 'totalWeight', isDecimalNumberMaxThreeDecimals)
          }
          placeholder="Introdu cantitatea"
          label="Cantitate (kg)"
          required={editable}
          valid={totalWeight.isDirty ? totalWeight.isValid : undefined}
          value={totalWeight.value}
          readOnly={!editable}
          helperText={
            totalWeight.isDirty && !totalWeight.isValid
              ? totalWeight.error || 'Introdu un număr cu maxim 3 zecimale'
              : null
          }
        />
        <Input
          id="totalCount"
          name="totalCount"
          onChange={(event) =>
            changeField(event.target.value, index, 'totalNumber', isIntegerNumber)
          }
          placeholder="Introdu număr de pești"
          label="Număr de pești"
          required={editable}
          valid={totalNumber.isDirty ? totalNumber.isValid : undefined}
          value={totalNumber.value}
          readOnly={!editable}
          helperText={
            totalNumber.isDirty && !totalNumber.isValid
              ? totalNumber.error || 'Introdu un număr întreg'
              : null
          }
        />
        <IconStats
          icon="fish"
          data={[{ label: 'Greutate medie', value: `${averageWeight} kg` }]}
          size="small"
          rounded="small"
        />
        <Input
          id="maxWeight"
          name="maxWeight"
          onChange={(event) =>
            changeField(
              event.target.value,
              index,
              'maxWeight',
              event.target.value !== '' ? isDecimalNumberMaxThreeDecimals : undefined,
            )
          }
          placeholder="Introdu greutatea maximă"
          label="Greutate maximă (kg)"
          valid={maxWeight.isDirty ? maxWeight.isValid : undefined}
          value={maxWeight.value}
          readOnly={!editable}
          helperText={
            maxWeight.isDirty && !maxWeight.isValid
              ? maxWeight.error || 'Introdu un număr cu maxim 3 zecimale'
              : null
          }
        />
      </CombinationContainer>
    );
  }

  return (
    <IconStats
      icon="fish"
      data={[
        { label: 'Specie', value: speciesDetails.name },
        { label: 'Total', value: `${totalWeight.value.toFixed(3)} kg` },
        { label: 'Număr', value: `${totalNumber.value} pești` },
        { label: 'Talie medie', value: `${averageWeight} kg` },
        { label: 'CMMC', value: maxWeight.value ? `${maxWeight.value.toFixed(3)} kg` : '' },
      ]}
    />
  );
};

CatchData.propTypes = {
  formData: PropTypes.object.isRequired,
  speciesDetails: PropTypes.object,
  index: PropTypes.number.isRequired,
  opened: PropTypes.bool.isRequired,
  onAccordionToggle: PropTypes.func.isRequired,
  editable: PropTypes.bool.isRequired,
  removeRow: PropTypes.func,
  onDropdownTriggerClick: PropTypes.func.isRequired,
  changeField: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  dropdownData: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    data: PropTypes.array.isRequired,
  }).isRequired,
};

CatchData.defaultProps = {
  removeRow: null,
  speciesDetails: null,
};

CatchData.displayName = 'CatchData';

export default CatchData;
