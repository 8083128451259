import { getTextWithoutDiacritics } from 'utility/utils';

export const getFilteredData = (data, searchTerm) =>
  data.filter((item) => {
    const itemValueWithoutDiacritics = getTextWithoutDiacritics(item.value);
    const searchTermWithoutDiacritics = getTextWithoutDiacritics(searchTerm);
    return itemValueWithoutDiacritics
      .toLowerCase()
      .includes(searchTermWithoutDiacritics.toLowerCase());
  });

export const getChipValues = (data, selectedValues, onSelectionChange) =>
  selectedValues.map((id) => {
    const { value } = data.find((item) => item.id === id);
    return {
      value,
      onClick: () => onSelectionChange(id),
    };
  });

export const getOnSelectionChangeMethod =
  (selectedValues, isSingleSelection, isRadioVariant, onChange) => (itemId) => {
    let newValues = [];

    if (selectedValues.includes(itemId)) {
      newValues = selectedValues.filter((item) => item !== itemId);
    } else if (isSingleSelection) {
      newValues = [itemId];
    } else {
      newValues = [...selectedValues, itemId];
    }

    const isValidValue = isRadioVariant ? newValues.length > 0 : true;
    if (!isValidValue) {
      return;
    }

    if (isSingleSelection) {
      onChange(newValues.length ? newValues[0] : null);
    } else {
      onChange(newValues);
    }
  };
