import React, { useContext, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import AppContext from 'contexts/AppContext';

const ProtectedRoute = () => {
  const { isAuthenticated } = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/', { state: { from: '' } });
    }
  }, [isAuthenticated, navigate]);

  return <Outlet />;
};

ProtectedRoute.displayName = 'ProtectedRoute';

export default ProtectedRoute;
