import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Header from 'components/ui/Accordion/Header';

const Accordion = (props) => {
  const { children, title, opened, onToggle, extras } = props;
  const contentRef = useRef(null);

  return (
    <div
      data-testid="accordion-container"
      className={cn(
        // eslint-disable-next-line max-len
        'flex flex-col pt-6.4 px-6.4 transition-all duration-3000 bg-white border border-gray-light-stroke rounded-3.2',
        {
          'pb-6.4': opened,
          'pb-0': !opened,
        },
      )}
    >
      <Header title={title} opened={opened} onToggle={onToggle} extras={extras} />
      <div
        ref={contentRef}
        data-testid="accordion-content"
        className={cn('overflow-hidden flex flex-col transition-all duration-3000 gap-y-6.4', {
          'max-h-screen': opened,
          'max-h-0': !opened,
        })}
      >
        {children}
      </div>
    </div>
  );
};

Accordion.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  opened: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
  extras: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

Accordion.defaultProps = {
  opened: false,
  extras: null,
};

Accordion.displayName = 'Accordion';

export default Accordion;
