import React from 'react';
import { Emphasize, List } from 'components/Policies/formatting';

const businessData = [
  {
    key: 'Denumire',
    label: 'Dacian Technologies SRL',
  },
  {
    key: 'Sediul social',
    label:
      'Aleea Nicolae Iorga, nr. 2, bl. A40, sc. 1, ap. 16, Municipiul Craiova, Județul Dolj, România',
  },
  {
    key: 'Număr Registrul Comerțului',
    label: 'J16/3036/2019',
  },
  {
    key: 'Cod de identificare fiscală',
    label: 'RO41967980',
  },
  {
    key: 'Email',
    label: 'contact@pescuiala.ro',
  },
  {
    key: 'Telefon',
    label: '+40762568966',
  },
];

const WhoWeAre = () => {
  return (
    <List>
      {businessData.map((item) => (
        <li key={item.key}>
          <Emphasize variant="semibold">{item.key}:</Emphasize> {item.label}
        </li>
      ))}
    </List>
  );
};

export default WhoWeAre;
