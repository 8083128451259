import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input, Textarea } from 'components/ui/inputs';
import Section from 'components/GoOut/steps/Section';
import Context from 'components/GoOut/state/contexts/addNew';
import { isIntegerNumber } from 'utility/validation';
import {
  setAtmosphericPressure,
  setTemperature,
  setWeatherPersonalNotes,
  setWindGuts,
  setWindSpeed,
} from 'components/GoOut/state/actions/addNew/others';
import { onFieldChange } from 'utility/utils';
import SkyConditionsInput from 'components/GoOut/steps/inputs/SkyConditionsInput';
import WindDirectionInput from 'components/GoOut/steps/inputs/WindDirectionInput';
import CanEditContext from 'contexts/CanEditContext';
import WeatherWidget from 'components/ui/WeatherWidget';

const Weather = (props) => {
  const { onChange } = props;
  const [state, dispatch] = useContext(Context);
  const editable = useContext(CanEditContext);
  const {
    conditions: {
      weather: {
        skyCondition: { value: skyCondition },
        temperature: {
          value: temperature,
          isValid: temperatureIsValid,
          isDirty: temperatureIsDirty,
          error: temperatureError,
        },
        windSpeed: {
          value: windSpeed,
          isValid: windSpeedIsValid,
          isDirty: windSpeedIsDirty,
          error: windSpeedError,
        },
        windGusts: {
          value: windGusts,
          isValid: windGustsIsValid,
          isDirty: windGustsIsDirty,
          error: windGustsError,
        },
        windDirection: { value: windDirection },
        atmPressure: {
          value: atmPressure,
          isValid: atmPressureIsValid,
          isDirty: atmPressureIsDirty,
          error: atmPressureError,
        },
        personalNotes: {
          value: personalNotes,
          isValid: personalNotesIsValid,
          isDirty: personalNotesIsDirty,
          error: personalNotesError,
        },
      },
    },
  } = state;

  useEffect(() => {
    onChange(
      Object.values(state.conditions.weather).every((field) => field.isValid) &&
        Object.values(state.conditions.weather).some((field) => field.isDirty),
    );
  }, [onChange, state.conditions.weather]);

  const onInputTemperature = (event) => {
    const setter = (newItem) => dispatch(setTemperature(newItem));
    onFieldChange(event.target.value, setter, isIntegerNumber);
  };

  const onInputWindSpeed = (event) => {
    const setter = (newItem) => dispatch(setWindSpeed(newItem));
    onFieldChange(event.target.value, setter, isIntegerNumber);
  };

  const onInputWindGust = (event) => {
    const setter = (newItem) => dispatch(setWindGuts(newItem));
    onFieldChange(event.target.value, setter, event.target.value ? isIntegerNumber : null);
  };

  const onInputAtmosphericPressure = (event) => {
    const setter = (newItem) => dispatch(setAtmosphericPressure(newItem));
    onFieldChange(event.target.value, setter, isIntegerNumber);
  };

  const onInputPersonalNotes = (event) => {
    const setter = (newItem) => dispatch(setWeatherPersonalNotes(newItem));
    onFieldChange(event, setter);
  };

  if (editable) {
    return (
      <Section title="Condiții meteo" className="grid grid-cols-1 lg:grid-cols-3">
        <SkyConditionsInput editable={editable} />
        <Input
          id="temperature"
          name="temperature"
          onChange={onInputTemperature}
          placeholder="Introdu temperatură"
          label="Temperatură (°C)"
          type="text"
          value={temperature}
          valid={temperatureIsDirty ? temperatureIsValid : undefined}
          helperText={
            temperatureIsDirty && !temperatureIsValid
              ? temperatureError || 'Introdu un număr întreg'
              : null
          }
          required={editable}
          readOnly={!editable}
        />
        <Input
          id="windSpeed"
          name="windSpeed"
          onChange={onInputWindSpeed}
          placeholder="Introdu viteza vântului"
          label="Viteza vântului (km/h)"
          type="text"
          value={windSpeed}
          valid={windSpeedIsDirty ? windSpeedIsValid : undefined}
          helperText={
            windSpeedIsDirty && !windSpeedIsValid
              ? windSpeedError || 'Introdu un număr întreg'
              : null
          }
          required={editable}
          readOnly={!editable}
        />
        <Input
          id="windGusts"
          name="windGusts"
          onChange={onInputWindGust}
          placeholder="Introdu viteza rafalelor de vânt"
          label="Viteza rafalelor de vânt (km/h)"
          type="text"
          value={windGusts}
          valid={windGustsIsDirty ? windGustsIsValid : undefined}
          helperText={
            windGustsIsDirty && !windGustsIsValid
              ? windGustsError || 'Introdu un număr întreg'
              : null
          }
          readOnly={!editable}
        />
        <WindDirectionInput canEdit={editable} />
        <Input
          id="atmPressure"
          name="atmPressure"
          onChange={onInputAtmosphericPressure}
          placeholder="Introdu presiune atmosferică"
          label="Presiune atmosferică (hPa)"
          type="text"
          value={atmPressure}
          valid={atmPressureIsDirty ? atmPressureIsValid : undefined}
          helperText={
            atmPressureIsDirty && !atmPressureIsValid
              ? atmPressureError || 'Introdu un număr întreg'
              : null
          }
          required={editable}
          readOnly={!editable}
        />
        {(editable || !!personalNotes) && (
          <div className="lg:col-span-3">
            <Textarea
              onChange={onInputPersonalNotes}
              name="personalNotes"
              id="personalNotes"
              placeholder="Introdu note personale"
              label="Note personale"
              type="text"
              rows="4"
              cols="50"
              value={personalNotes}
              valid={personalNotesIsDirty ? personalNotesIsValid : undefined}
              helperText={personalNotesIsDirty && !personalNotesIsValid ? personalNotesError : null}
              readOnly={!editable}
            />
          </div>
        )}
      </Section>
    );
  }

  return (
    <Section title="Condiții meteo" className="flex flex-col gap-y-4.8">
      <WeatherWidget
        windGusts={windGusts}
        atmPressure={atmPressure}
        temperature={temperature}
        windDirection={windDirection}
        skyCondition={skyCondition}
        windSpeed={windSpeed}
        theme="dark"
        rounded="small"
      />
      <Textarea
        onChange={onInputPersonalNotes}
        name="personalNotes"
        id="personalNotes"
        label="Note personale"
        type="text"
        rows="4"
        cols="50"
        value={personalNotes}
        disabled
      />
    </Section>
  );
};

Weather.propTypes = {
  onChange: PropTypes.func,
};

Weather.defaultProps = {
  onChange: null,
};

Weather.displayName = 'Weather';

export default Weather;
