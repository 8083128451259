import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/ui/Modal';
import Input from 'components/ui/inputs/Input';
import { CheckboxesDropdown } from 'components/ui/dropdowns';
import { ACTIONS } from 'components/Equipment/utility/constants';
import useBrandsLoader from 'hooks/useBrandsLoader';
import { isDecimalNumberMaxTwoDecimals, isRequired } from 'utility/validation';
import { BrandInput, ModelInput } from '../inputs';
import useFormFields from '../hooks/useFormFields';
import useSubmit from '../hooks/useSubmit';

const units = ['m', 'ft'].map((o) => ({ id: o, value: o }));
const powers = ['gr', 'lbs'].map((o) => ({ id: o, value: o }));
const actions = ['parabolică', 'moderată', 'rapidă', 'foarte rapidă'].map((o) => ({
  id: o,
  value: o,
}));

const RodModal = (props) => {
  const { onClose, action, item, onSuccess } = props;
  const { length, lengthUnit, power, powerUnit, action: actionType } = item || {};
  const [fields, setFields, individualSetters] = useFormFields(item, {
    length: {
      field: {
        value: length ? length.toString() : '',
        isValid: !!length,
      },
      required: true,
      validator: isDecimalNumberMaxTwoDecimals,
    },
    lengthUnit: {
      field: {
        value: lengthUnit || 'm',
        isValid: true,
      },
      required: true,
      validator: isRequired,
    },
    power: {
      field: {
        value: power || '',
        isValid: !!power,
      },
      required: true,
      validator: isRequired,
    },
    powerUnit: {
      field: {
        value: powerUnit || 'gr',
        isValid: true,
      },
      required: true,
      validator: isRequired,
    },
    action: {
      field: {
        value: actionType,
        isValid: actionType ? isRequired(actionType) : true,
      },
      required: false,
      validator: isRequired,
    },
  });
  const [isLoadingBrands, allBrands] = useBrandsLoader();
  const [canSubmit, onSubmit] = useSubmit('rods', item, fields, setFields, onSuccess);
  const actionText = ACTIONS[action];

  return (
    <Modal
      title={`${actionText} lansetă`}
      onClose={onClose}
      onCancel={onClose}
      onSubmit={onSubmit}
      submitText={actionText}
      canSubmit={canSubmit}
      form
    >
      <BrandInput
        loading={isLoadingBrands}
        all={allBrands}
        onChange={individualSetters.brand}
        {...fields.brand}
      />
      <ModelInput onChange={individualSetters.model} {...fields.model} />
      <div className="flex gap-6.4 items-center justify-between">
        <div className="w-8/12">
          <Input
            id="length"
            name="length"
            onChange={individualSetters.length}
            placeholder="Introdu lungimea"
            label="Lungime"
            value={fields.length.value}
            required
            inputMode="decimal"
            pattern="[0-9]*"
            valid={fields.length.isDirty ? fields.length.isValid : undefined}
            helperText={
              fields.length.isDirty && !fields.length.isValid
                ? fields.length.error || 'Introdu un număr cu maxim 2 zecimale'
                : null
            }
          />
        </div>
        <div className="w-4/12">
          <CheckboxesDropdown
            data={units}
            onChange={individualSetters.lengthUnit}
            singleSelection
            selectedValues={fields.lengthUnit.value ? [fields.lengthUnit.value] : undefined}
            triggerProps={{
              label: 'Unitate',
              forceHelperText: fields.length.isDirty && !fields.length.isValid,
            }}
            variant="radio"
          />
        </div>
      </div>
      <div className="flex gap-6.4 items-center justify-between">
        <div className="w-8/12">
          <Input
            id="power"
            name="power"
            onChange={individualSetters.power}
            placeholder="Introdu puterea de aruncare"
            label="Putere de aruncare"
            value={fields.power.value}
            required
            inputMode="decimal"
            pattern="[0-9]*"
            valid={fields.power.isDirty ? fields.power.isValid : undefined}
          />
        </div>
        <div className="w-4/12">
          <CheckboxesDropdown
            data={powers}
            onChange={individualSetters.powerUnit}
            singleSelection
            selectedValues={fields.powerUnit.value ? [fields.powerUnit.value] : undefined}
            triggerProps={{
              label: 'Unitate',
              valid: fields.powerUnit.isDirty ? fields.powerUnit.isValid : undefined,
            }}
            variant="radio"
          />
        </div>
      </div>
      <CheckboxesDropdown
        data={actions}
        onChange={individualSetters.action}
        singleSelection
        triggerProps={{
          label: 'Acțiune',
          placeholder: 'Selectează acțiunea',
        }}
        position="top-right"
        selectedValues={fields.action.value ? [fields.action.value] : undefined}
      />
    </Modal>
  );
};

RodModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  action: PropTypes.oneOf(['add', 'edit']),
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    brand: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    model: PropTypes.string.isRequired,
    length: PropTypes.string.isRequired,
    lengthUnit: PropTypes.string.isRequired,
    power: PropTypes.number.isRequired,
    powerUnit: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired,
  }),
};

RodModal.defaultProps = {
  action: 'add',
  item: null,
};

RodModal.displayName = 'RodModal';

export default RodModal;
