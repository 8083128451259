import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useLocation } from 'react-router-dom';
import { IconNavLink } from 'components/ui/links';

const NavLinks = (props) => {
  const { alignment, links } = props;
  const { pathname } = useLocation();

  return (
    <div
      data-testid="app-links"
      className={cn('flex flex-col gap-6.4', {
        'items-center': alignment === 'center',
        'items-stretch': alignment === 'stretch',
      })}
    >
      {links.map(({ to, icon, label, activeRegex, variant = 'primary', onClick }, index) => {
        return (
          <IconNavLink
            key={index}
            to={to}
            icon={icon}
            label={label}
            variant={variant}
            active={!!pathname.match(activeRegex)}
            onClick={onClick}
          >
            {label}
          </IconNavLink>
        );
      })}
    </div>
  );
};

NavLinks.propTypes = {
  variant: PropTypes.oneOf(['primary', 'secondary', 'accent']),
  alignment: PropTypes.oneOf(['center', 'stretch']),
  links: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string,
      icon: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      activeRegex: PropTypes.instanceOf(RegExp).isRequired,
      onClick: PropTypes.func,
    }),
  ).isRequired,
};

NavLinks.defaultProps = {
  variant: 'primary',
  alignment: 'center',
};

export default NavLinks;
