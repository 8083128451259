import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Textarea } from 'components/ui/inputs';
import CanEditContext from 'contexts/CanEditContext';
import { AddNewContext } from 'components/GoOut/state/contexts';

const FeedingStrategyInput = (props) => {
  const { label, placeholder, onChange: onChangeAction, contextKey } = props;
  const editable = useContext(CanEditContext);
  const [
    {
      feeding: { [contextKey]: { strategy: value } = {} },
    },
    dispatch,
  ] = useContext(AddNewContext);

  const onChange = (event) => dispatch(onChangeAction(event.target.value));

  return (
    <Textarea
      onChange={onChange}
      id="strategy"
      name="strategy"
      placeholder={placeholder}
      label={label}
      value={value}
      disabled={!editable}
    />
  );
};

FeedingStrategyInput.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  contextKey: PropTypes.string.isRequired,
};

FeedingStrategyInput.displayName = 'FeedingStrategyInput';

export default FeedingStrategyInput;
