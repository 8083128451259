import { useEffect, useCallback } from 'react';

const usePositioning = (containerRef, contentRef, isOpened, position) => {
  const handlePosition = useCallback(() => {
    const scrollPosition = window.scrollY;

    if (containerRef.current && contentRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect();
      const containerBottom = containerRect.bottom + scrollPosition;
      const contentHeight = contentRef.current.offsetHeight;

      if (position.startsWith('top')) {
        contentRef.current.style.top = `${containerRect.top - contentHeight - 8}px`;
      } else {
        contentRef.current.style.top = `${containerBottom - scrollPosition + 8}px`;
      }

      if (position.endsWith('right')) {
        contentRef.current.style.right = `${window.innerWidth - containerRect.right}px`;
      } else {
        contentRef.current.style.left = `${containerRect.left}px`;
      }

      contentRef.current.style.minWidth = `${containerRect.width}px`;
    }
  }, [containerRef, contentRef, position]);

  useEffect(() => {
    if (isOpened) {
      handlePosition();
      window.addEventListener('scroll', handlePosition);
      window.addEventListener('resize', handlePosition);
    } else {
      window.removeEventListener('scroll', handlePosition);
      window.removeEventListener('resize', handlePosition);
    }
  }, [isOpened, handlePosition]);
};

export default usePositioning;
