import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Icon from 'components/ui/Icon';
import { Button } from 'components/ui/buttons';
import useAPI from '../../../hooks/useAPI';

const RefreshCode = (props) => {
  const { userId, token } = props;
  const [isCodRenewed, setIsCodRenewed] = useState(false);
  const navigate = useNavigate();
  const { apiCall } = useAPI();

  const message = {
    title: isCodRenewed
      ? '  Un e-mail a fost trimis pe adresa cu care te-ai înregistrat.'
      : 'Link-ul de activare a expirat.',

    body: isCodRenewed
      ? 'Te rugăm să urmărești indicațiile primite pe mail pentru a activa contul.'
      : 'Puteți solicita unul nou folosind butonul de mai jos.',
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    await apiCall(
      `/authentication/${userId}/activate/${token}/refresh`,
      'post',
      {},
      {
        onSuccessCallback: () => {
          setIsCodRenewed(true);
        },
        onErrorCallback: () => {
          navigate('/');
        },
        getErrorMessage: () => 'Link-ul de activare este invalid!',
      },
    );

    return false;
  };

  return (
    <>
      <div className="flex justify-center lg:justify-start">
        <Icon identifier={isCodRenewed ? 'success' : 'warning'} />
        <p className="ml-4 lg:ml-4.8 flex-1">
          <span className="font-bold text-green-dark">{message.title}</span> {message.body}
        </p>
      </div>
      {!isCodRenewed && (
        <form className="grid gap-8 xl:gap-9.6 w-full" onSubmit={onSubmit}>
          <Button variant="primary" submit>
            Retrimite cod de activare
          </Button>
        </form>
      )}
    </>
  );
};

RefreshCode.propTypes = {
  userId: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
};

RefreshCode.displayName = 'RefreshCode';

export default RefreshCode;
