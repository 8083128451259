import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Drawer from 'components/ui/Drawer';
import Header from 'components/GoOuts/FiltersDrawer/Header';
import Controls from 'components/GoOuts/FiltersDrawer/Controls';
import SelectableGroup from 'components/ui/SelectableGroup';
import { CalendarDropdown, CheckboxesDropdown } from 'components/ui/dropdowns';

const locationInputProps = {
  label: 'Locație',
  placeholder: 'Selectează locație',
};

const weatherInputProps = {
  label: 'Vreme',
  placeholder: 'Selectează vreme',
};

const fishSpeciesInputProps = {
  label: 'Specie',
  placeholder: 'Selectează specie',
};

const scoreInputProps = {
  label: 'Scor',
  placeholder: 'Selectează scor',
};

const fromMonthInputProps = {
  label: 'De la luna',
  placeholder: 'Selectează luna',
};

const toMonthInputProps = {
  label: 'Până la luna',
  placeholder: 'Selectează luna',
};

const items = [
  {
    id: 1,
    label: 'Salbatică',
    onSelect: () => {},
    selected: false,
  },
  {
    id: 2,
    label: 'Comercial',
    onSelect: () => {},
    selected: false,
  },
];

const values = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];

const Filters = (props) => {
  const { onClose, onReset, onSubmit } = props;
  const trigger = useRef(null);

  return (
    <Drawer className="p-8 shadow-drawer" triggerRef={trigger} position="right" lockScreen>
      <div className="flex flex-col w-full h-full gap-y-12.8">
        <Header onClose={onClose} triggerRef={trigger} />
        <div className="flex-1 flex flex-col gap-y-9.6 overflow-auto">
          <SelectableGroup items={items} label="Tip apă" />
          <CheckboxesDropdown data={values} searchable triggerProps={locationInputProps} />
          <CheckboxesDropdown data={values} triggerProps={weatherInputProps} />
          <CheckboxesDropdown data={values} searchable triggerProps={fishSpeciesInputProps} />
          <CalendarDropdown
            position="top-right"
            onChange={() => {}}
            triggerProps={fromMonthInputProps}
            date={null}
          />
          <CalendarDropdown
            position="top-right"
            onChange={() => {}}
            triggerProps={toMonthInputProps}
            date={null}
          />
          <CheckboxesDropdown position="top-right" data={values} triggerProps={scoreInputProps} />
        </div>
        <Controls onReset={onReset} onSubmit={onSubmit} />
      </div>
    </Drawer>
  );
};

Filters.propTypes = {
  onClose: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

Filters.displayName = 'Filters';

export default Filters;
