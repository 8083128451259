import React, { useContext } from 'react';
import AppContext from 'contexts/AppContext';
import Hero from 'components/authentication/Hero';
import Layout from 'components/Layout';

const LandingPageWrapper = () => {
  const { isAuthenticated } = useContext(AppContext);

  if (isAuthenticated) {
    return <Layout />;
  }

  return <Hero />;
};

LandingPageWrapper.propTypes = {};

LandingPageWrapper.displayName = 'LandpageWrapper';

export default LandingPageWrapper;
