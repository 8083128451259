import { useServerData } from './_prototypes';

const useData = (endpoint, state, actions) => {
  const { isLoading, all: availableData } = state;
  const { availableEntitiesDispatch, onInit, onAdd } = actions;
  const [load, onAddNew] = useServerData({
    dispatch: availableEntitiesDispatch,
    onAdd,
    onInit,
    isLoading,
    endpoint,
  });

  return [
    isLoading,
    availableData,
    {
      load,
      onAdd: onAddNew,
    },
  ];
};

export default useData;
