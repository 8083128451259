import { getReelName, getRodName } from 'components/Equipment/utility/utils';
import { getFieldsValues } from 'utility/utils';

export const getLineName = (data) => {
  if (!data) {
    return '';
  }

  const { brand, model, diameter, length, usage } = data;
  const brandName = brand?.name || brand;

  let value = `${brandName} ${model} ${diameter}`;

  if (usage === 'rig') {
    value += ` ${length}cm`;
  } else if (usage === 'leader') {
    value += ` ${length / 1000}m`;
  }

  return value;
};

export const getPowerGumName = (data) => {
  const { brand, model, power, powerUnit, length } = data;
  const brandName = brand?.name || brand;
  return `${brandName} ${model} ${power}${powerUnit} ${length}cm`;
};

export const getHookName = (data) => {
  if (!data) {
    return '';
  }

  const { brand, model, size } = data;
  const brandName = brand?.name || brand;
  return `${brandName} ${model} ${size}`;
};

export const getHookLineName = (data) => {
  const {
    brand,
    model,
    diameter,
    details: { length },
  } = data;
  const brandName = brand?.name || brand;
  return `${brandName} ${model} ${diameter} ${length || ''} `;
};

export const getFeederName = (data) => {
  const { brand, model, weight, type, subType } = data;
  const brandName = brand?.name || brand;
  return `${brandName} ${model} ${weight} ${type} ${subType}`;
};

export const getGroundBaitName = (data) => {
  const { brand, model } = data;
  const brandName = brand?.name || brand;
  return `${brandName} ${model}`;
};

export const getBaitName = (data) => {
  return data.name;
};

export const getItemsName = (data, type) => {
  switch (type) {
    case 'rod':
      return data.map((item) => ({
        id: item.id,
        name: getRodName(item),
      }));
    case 'reel':
      return data.map((item) => ({
        id: item.id,
        name: getReelName(item),
      }));
    case 'line':
      return data.map((item) => ({
        id: item.id,
        name: getLineName(item),
      }));
    case 'hook':
      return data.map((item) => ({
        id: item.id,
        name: getHookName(item),
      }));
    case 'hookLine':
      return data.map((item) => ({
        id: item.id,
        name: getHookLineName(item),
      }));
    default:
      return [];
  }
};

export const getFeedersTableData = (data, selectedFeeders) => {
  return data.map((item) => ({
    id: item.id,
    values: [item.brand.name, item.model, item.weight, item.type, item.subType],
    selected: selectedFeeders.includes(item.id),
  }));
};

export const getGroundBaitsTableData = (data, selectedGroundBaits) => {
  return data.map((item) => ({
    id: item.id,
    values: [item.brand.name, item.model],
    selected: selectedGroundBaits.includes(item.id),
  }));
};

export const getBaitsTableData = (data, selectedBaits) => {
  return data.map((item) => ({
    id: item.id,
    values: [item.name],
    selected: selectedBaits.includes(item.id),
  }));
};

export const getValue = (id, data) => {
  const items = data.find((item) => item.id.toString() === id);
  return items?.name || '';
};

export const getReducerValues = (state) => {
  return Object.keys(state).reduce((acc, key) => {
    acc[key] = {};

    switch (key) {
      case 'media':
        acc[key] = state[key];
        break;

      case 'feeding':
        acc[key] = Object.keys(state[key]).reduce((feedingAcc, field) => {
          feedingAcc[field] = {
            ...state[key][field],
            strategy: state[key][field].strategy || undefined,
          };
          return feedingAcc;
        }, {});
        break;

      default:
        Object.keys(state[key]).forEach((field) => {
          switch (field) {
            case 'species':
              acc[key][field] = state[key][field].map((item) =>
                getFieldsValues(item, (originalKey) =>
                  originalKey === 'species' ? 'id' : originalKey,
                ),
              );
              break;

            case 'location':
            case 'weather':
            case 'water':
              acc[key][field] = getFieldsValues(state[key][field]);
              break;

            case 'gear':
            case 'rigs':
              acc[key][field] = state[key][field];
              break;

            default:
              acc[key][field] = state[key][field]?.value || undefined;
              break;
          }
        });
    }
    return acc;
  }, {});
};

export const getAddressData = (addressComponent) => {
  const name =
    addressComponent.find((item) => item.types.includes('point_of_interest'))?.long_name || '';
  const street = addressComponent.find((item) => item.types.includes('route'))?.short_name || '';
  const streetNo =
    addressComponent.find((item) => item.types.includes('street_number'))?.long_name || '';
  const city = addressComponent.find((item) => item.types.includes('locality'))?.long_name || '';
  const county =
    addressComponent.find((item) => item.types.includes('administrative_area_level_1'))
      ?.long_name || '';
  const country = addressComponent.find((item) => item.types.includes('country'))?.long_name || '';

  return {
    name,
    street,
    streetNo,
    city,
    county,
    country,
  };
};

export const formatLocation = (data) => {
  if (Object.values(data).some((item) => item !== '')) {
    const { name, street, streetNo, city, county } = data;
    return `${name ? ` ${name},` : ''}${street ? ` ${street},` : ''}${
      streetNo ? ` ${streetNo},` : ''
    }${city ? ` ${city},` : ''}${county ? ` ${county.split(' ')[0]}` : ''}`;
  }
  return '';
};

export const getTimeValue = (hour, minutes) => {
  if (hour === null && minutes === null) {
    return '';
  }

  const paddedHour = hour ? hour.toString().padStart(2, '0') : '00';
  const paddedMinutes = minutes ? minutes.toString().padStart(2, '0') : '00';

  return `${paddedHour}:${paddedMinutes}`;
};

export const getHour = (time) => {
  if (!time) {
    return null;
  }
  return parseInt(time?.split(':')[0]);
};

export const getMinutes = (time) => {
  if (!time) {
    return null;
  }
  return parseInt(time.split(':')[1]);
};

export const getOnSelectionChangeMethod =
  (selectedIds, dispatch, onSelect, onDeselect) =>
  (id, index = null) => {
    if (selectedIds.includes(id) || id === null) {
      dispatch(onDeselect(id || index));
    } else {
      const params = [id];
      if (index !== null) {
        params.push(index);
      }

      dispatch(onSelect(...params));
    }
  };
