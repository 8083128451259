import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/ui/Icon';
import { Button } from 'components/ui/buttons';

const Upload = (props) => {
  const { onUpload } = props;
  const fileInput = useRef(null);

  const onClickUpload = () => {
    fileInput.current.click();
  };

  const handleFiles = (files) => {
    if (files && files.length > 0) {
      const newFiles = Array.from(files);
      onUpload(newFiles);
    }
  };

  const onChange = (e) => {
    handleFiles(e.target.files);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    handleFiles(e.dataTransfer.files);
  };

  return (
    <div
      onDrop={handleDrop}
      onDragOver={(e) => e.preventDefault()}
      /* eslint-disable-next-line max-len */
      className=" flex items-center justify-center w-full h-full px-9.6 py-8 bg-blue/2  border border-dashed border-blue rounded-3.2"
    >
      <div className="flex flex-col gap-y-4.8 items-center">
        <div className="p-4 rounded-full bg-blue-lighter">
          <Icon identifier="cloud-upload" fillClassName="fill-blue" />
        </div>
        <div className="flex flex-col items-center">
          <div className="flex">
            <Button variant="link" size="small" weight="medium" onClick={onClickUpload}>
              Apasă aici pentru a încărca fișiere
            </Button>
            <input
              type="file"
              ref={fileInput}
              className="sr-only"
              onChange={onChange}
              multiple
              accept="image/*, video/*"
            />
            <span className="text-gray-darker leading-8">&nbsp;sau fă drag & drop</span>
          </div>
          <span className="text-gray-darker leading-8">
            JPG/JPEG, PNG, WebP, BMP, HEIC, MP4, AVI, MKV, MOV, 3GP, FLV, WebM
          </span>
        </div>
      </div>
    </div>
  );
};

Upload.propTypes = {
  onUpload: PropTypes.func.isRequired,
};

Upload.displayName = 'Upload';

export default Upload;
