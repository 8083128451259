import {
  INIT_BAITS,
  ADD_BAIT,
  SELECT_BAIT,
  DESELECT_BAIT,
  SET_BAIT_STRATEGY,
} from 'components/GoOut/state/types/availableEntities/baits';

export const init = (payload) => ({
  type: INIT_BAITS,
  payload,
});

export const add = (payload) => ({
  type: ADD_BAIT,
  payload,
});

export const select = (payload) => ({
  type: SELECT_BAIT,
  payload,
});

export const deselect = (payload) => ({
  type: DESELECT_BAIT,
  payload,
});

export const setStrategy = (payload) => ({
  type: SET_BAIT_STRATEGY,
  payload,
});
