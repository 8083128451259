import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'components/ui/inputs';
import TimePicker from 'components/ui/TimePicker';
import { Button } from 'components/ui/buttons';
import GenericDropdown from './GenericDropdown';

const getInputActions = (defaults, onCurrentTimeClick) => {
  let actions = defaults || [];

  if (onCurrentTimeClick) {
    actions = [
      ...actions,
      <Button key="current-time" variant="link" size="small" onClick={onCurrentTimeClick}>
        Ora curentă
      </Button>,
    ];
  }

  return actions;
};

const getInputValue = (hour, minutes) => {
  if (hour === null || minutes === null) {
    return '';
  }

  const paddedHour = hour.toString().padStart(2, '0');
  const paddedMinutes = minutes.toString().padStart(2, '0');

  return `${paddedHour}:${paddedMinutes}`;
};

const TimePickerDropdown = (props) => {
  const {
    label,
    placeholder,
    onChange,
    required,
    withCurrentTime,
    actions,
    hour,
    minutes,
    ...otherProps
  } = props;
  const onCurrentTimeSelection = () => onChange(new Date().getHours(), new Date().getMinutes());
  const inputActions = getInputActions(actions, withCurrentTime ? onCurrentTimeSelection : null);
  const inputValue = getInputValue(hour, minutes);
  const triggerProps = {
    onClick: () => {},
    dropDown: true,
    label,
    placeholder,
    value: inputValue,
    actions: inputActions,
    required,
  };

  return (
    <GenericDropdown triggerComponent={Input} triggerProps={triggerProps} {...otherProps}>
      <TimePicker hour={hour} minutes={minutes} onChange={onChange} />
    </GenericDropdown>
  );
};

TimePickerDropdown.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  withCurrentTime: PropTypes.bool,
  actions: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  hour: PropTypes.number,
  minutes: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
};

TimePickerDropdown.defaultProps = {
  label: '',
  placeholder: '',
  withCurrentTime: false,
  actions: null,
  hour: null,
  minutes: null,
  required: false,
};

export default TimePickerDropdown;
