import React, { useEffect, useMemo, useCallback } from 'react';
import PropTypes, { string } from 'prop-types';
import { useParams } from 'react-router-dom';
import { CheckboxesDropdown } from 'components/ui/dropdowns';
import { Button } from 'components/ui/buttons';
import { Input } from 'components/ui/inputs';
import { getOnSelectionChangeMethod } from '../../utility/utils';
import { useData, useItems, useAddNew } from '../../hooks';

const getTriggerProps = (inputProps, isLoading = null, load = null) => ({
  ...inputProps,
  type: 'text',
  onClick: load
    ? (isOpened) => {
        if (isOpened && isLoading && load) {
          load();
        }
      }
    : undefined,
});

const getOnChangeMethod = (dispatch, onSelect, onDeselect, index) => (itemId) => {
  const onSelectionChange = getOnSelectionChangeMethod([], dispatch, onSelect, onDeselect);
  onSelectionChange(itemId, index);
};

const Dropdown = (props) => {
  const {
    inputProps,
    index,
    editable,
    resourceMeta: { apiResource, entity, formParentKey, formKey, queryParams },
    nameGetter,
    actions,
    addNewProps: { component: AddNewComponent, text: addNewText },
  } = props;
  const { id: goOutId } = useParams();
  const endpoint = useMemo(() => {
    const query = new URLSearchParams(queryParams).toString();
    const value = `/gear/${apiResource}`;
    return query ? `${value}?${query}` : value;
  }, [apiResource, apiResource, queryParams]);
  const [formData, formDataDispatch, availableEntitiesData, availableEntitiesDispatch] = useItems(
    `equipment.${formParentKey}`,
    entity,
    formKey,
    index,
  );
  const state = { ...formData, ...availableEntitiesData };
  const [isLoading, availableData, { load: loadData, onAdd }] = useData(endpoint, state, {
    formDataDispatch,
    availableEntitiesDispatch,
    ...actions,
  });
  const data = useMemo(
    () => availableData.map((item) => ({ id: item.id, value: nameGetter(item) })),
    [availableData, nameGetter],
  );
  const triggerProps = goOutId
    ? getTriggerProps(inputProps)
    : getTriggerProps(inputProps, isLoading, loadData);
  const { onSelect, onDeselect } = actions;
  const onChange = useCallback(getOnChangeMethod(formDataDispatch, onSelect, onDeselect, index), [
    formDataDispatch,
    onSelect,
    onDeselect,
    index,
  ]);
  const [showAddNewModal, setShowAddNewModal, addNewModalContent] = useAddNew(
    AddNewComponent,
    onAdd,
    (item) => {
      formDataDispatch(onSelect(item.id, index));
    },
  );

  useEffect(() => {
    if (goOutId && isLoading) {
      loadData();
    }
  }, [goOutId, isLoading, loadData]);

  if (editable) {
    return (
      <>
        <CheckboxesDropdown
          data={data}
          onChange={onChange}
          singleSelection
          triggerProps={triggerProps}
          action={
            <Button
              variant="blueish"
              onClick={() => setShowAddNewModal(true)}
              size="small"
              weight="medium"
            >
              {addNewText}
            </Button>
          }
          selectedValues={isLoading ? [] : formData}
        />
        {showAddNewModal && addNewModalContent}
      </>
    );
  }

  const item = availableData.find((obj) => obj.id === formData[0]);
  const itemName = item ? nameGetter(item) : '';

  return <Input value={itemName} label={inputProps.label} readOnly />;
};

Dropdown.propTypes = {
  inputProps: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  editable: PropTypes.bool,
  resourceMeta: PropTypes.shape({
    apiResource: PropTypes.string.isRequired,
    entity: PropTypes.string.isRequired,
    formParentKey: PropTypes.string.isRequired,
    formKey: string.isRequired,
    queryParams: PropTypes.object,
  }).isRequired,
  actions: PropTypes.shape({
    onInit: PropTypes.func.isRequired,
    onAdd: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    onDeselect: PropTypes.func.isRequired,
  }).isRequired,
  nameGetter: PropTypes.func.isRequired,
  addNewProps: PropTypes.shape({
    component: PropTypes.elementType.isRequired,
    text: PropTypes.string.isRequired,
  }).isRequired,
};

Dropdown.defaultProps = {
  editable: false,
};

Dropdown.displayName = 'Dropdown';

export default Dropdown;
