import React, { useContext } from 'react';
import GoOuts from 'components/GoOuts';
import Login from 'components/authentication/Login';
import AppContext from 'contexts/AppContext';

const LandingPage = () => {
  const { isAuthenticated } = useContext(AppContext);

  if (isAuthenticated) {
    return <GoOuts title="Noutăți" />;
  }

  return <Login />;
};

export default LandingPage;
