import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from 'components/ui/Icon';

const Checkbox = (props) => {
  const { id, label, variant, singleSelection, checked, disabled, size, name, onChange } = props;
  const [isInputFocused, setIsInputFocused] = useState(false);
  const checkbox = variant === 'checkbox';
  const radio = variant === 'radio';
  const iconIdentifier = checkbox ? 'check' : 'radio-check';
  const medium = size === 'medium';
  const small = size === 'small';

  return (
    <label
      data-testid="checkbox-label"
      htmlFor={id}
      className={cn('text-nowrap whitespace-nowrap group flex items-center', {
        'gap-x-4.8': medium,
        'gap-x-3.2': small,
        'cursor-pointer': !disabled,
      })}
    >
      <input
        tabIndex={0}
        onChange={onChange}
        id={id}
        type={singleSelection ? 'radio' : 'checkbox'}
        checked={checked}
        className="sr-only"
        disabled={disabled}
        name={name}
        onFocus={() => setIsInputFocused(true)}
        onBlur={() => setIsInputFocused(false)}
      />
      <div
        data-testid="checkbox-div"
        className={cn('flex items-center justify-center border', {
          'h-8 w-8': medium,
          'h-6.4 w-6.4': small,
          'rounded-1.6': checkbox,
          'rounded-full': radio,
          'border-gray-input-stroke group-hover:bg-blue-lighter group-hover:border-blue':
            !checked && !disabled,
          'bg-blue-lighter border-blue group-hover:border-blue-darker': checked && !disabled,
          'bg-gray-100 border-gray-light-stroke': disabled,
          'outline-none !border-blue outline-offset-0 outline-blue-lighter outline-4':
            isInputFocused,
        })}
        aria-checked={checked}
      >
        {checked && !disabled ? (
          <Icon
            identifier={iconIdentifier}
            fillClassName={cn({
              'fill-blue group-hover:fill-blue-darker': checked,
            })}
          />
        ) : null}
      </div>
      {label && (
        <span
          data-testid="checkbox-text"
          className={cn('font-medium', {
            'text-controls': medium,
            'leading-8': small,
            'text-gray-dark': !disabled,
            'text-gray-input-stroke': disabled,
            'text-green-dark': checked && !disabled,
          })}
        >
          {label}
        </span>
      )}
    </label>
  );
};

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  variant: PropTypes.oneOf(['checkbox', 'radio']),
  singleSelection: PropTypes.bool,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium']),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

Checkbox.defaultProps = {
  label: '',
  variant: 'checkbox',
  checked: false,
  singleSelection: false,
  disabled: false,
  size: 'medium',
};

Checkbox.displayName = 'Checkbox';

export default Checkbox;
