import React from 'react';
import Icon from 'components/ui/Icon';
import Footer from 'components/Footer';

const Error404 = () => {
  return (
    <div className="flex flex-col h-screen">
      <div
        className="bg-green-dark relative lg:flex-1 px-8 sm:px-32 md:px-64 lg:px-32 2xl:px-120 xl:pb-18 py-24 flex flex-col h-full"
        style={{
          backgroundImage: "url('./images/hero-waves-o5.webp')",
          backgroundSize: '100% 100%',
        }}
      >
        <a className="flex justify-center lg:justify-start" href="/">
          <Icon identifier="logo-hero" />
        </a>
        <div className="flex flex-col items-center flex-1 justify-center gap-32">
          <h1 className="w-fulltext-center mt-8 text-2xl md:text-4xl lg:text-7xl leading-10.4 text-error-300">
            Această pagină nu există.
          </h1>
        </div>
      </div>
      <Footer />
    </div>
  );
};

Error404.displayName = 'Error404';

export default Error404;
