import {
  INIT_MAIN_LINES,
  ADD_MAIN_LINE,
  INIT_RIG_LINES,
  ADD_RIG_LINE,
  INIT_LEADER_LINES,
  ADD_LEADER_LINE,
  SELECT_MAIN_LINE,
  DESELECT_MAIN_LINE,
  SELECT_RIG_LINE,
  DESELECT_RIG_LINE,
  SELECT_LEADER_LINE,
  DESELECT_LEADER_LINE,
} from '../../types/availableEntities/lines';

export const initMainLines = (payload) => ({
  type: INIT_MAIN_LINES,
  payload,
});

export const addMainLine = (payload) => ({
  type: ADD_MAIN_LINE,
  payload,
});

export const initRigLines = (payload) => ({
  type: INIT_RIG_LINES,
  payload,
});

export const addRigLine = (payload) => ({
  type: ADD_RIG_LINE,
  payload,
});

export const initLeaderLines = (payload) => ({
  type: INIT_LEADER_LINES,
  payload,
});

export const addLeaderLine = (payload) => ({
  type: ADD_LEADER_LINE,
  payload,
});

export const selectMainLine = (id, index) => ({
  type: SELECT_MAIN_LINE,
  payload: {
    id,
    index,
  },
});

export const deselectMainLine = (payload) => ({
  type: DESELECT_MAIN_LINE,
  payload,
});

export const selectRigLine = (id, index) => ({
  type: SELECT_RIG_LINE,
  payload: {
    id,
    index,
  },
});

export const deselectRigLine = (payload) => ({
  type: DESELECT_RIG_LINE,
  payload,
});

export const selectLeaderLine = (id, index) => ({
  type: SELECT_LEADER_LINE,
  payload: {
    id,
    index,
  },
});

export const deselectLeaderLine = (payload) => ({
  type: DESELECT_LEADER_LINE,
  payload,
});
