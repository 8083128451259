import React, { useState, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'utility/axios';
import Icon from 'components/ui/Icon';
import { DEFAULT_API_ERROR } from 'constants/forms';
import AppContext from 'contexts/AppContext';

const useAPI = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { setIsAuthenticated } = useContext(AppContext);

  const apiCall = async (endpoint, method, data = {}, options = {}) => {
    const { getSuccessMessage, onSuccessCallback, getErrorMessage, onErrorCallback } = options;

    setIsSubmitting(true);

    const isToastNotification = !!getSuccessMessage || !!getErrorMessage;
    const toastId = isToastNotification
      ? toast.loading('Procesare în curs...', {
          isLoading: true,
        })
      : null;

    let response;

    try {
      response = await axios({
        method,
        url: endpoint,
        data,
      });
    } catch (error) {
      const { response: errorResponse } = error;

      if (errorResponse) {
        const { status: statusCode, data: errorData } = errorResponse;

        if (statusCode === 401 && errorData.detail === 'Not authenticated') {
          setIsAuthenticated(false);
          navigate('/', { state: { from: location.pathname } });
          if (toastId) {
            toast.update(toastId, {
              render: 'Nu aveți permisiunea necesară pentru a accesa această pagină.',
              type: 'error',
              isLoading: false,
              autoClose: 5000,
              icon: <Icon fillClassName="fill-error-500" identifier="circle-alert" />,
            });
          } else {
            toast.error('Nu aveți permisiunea necesară pentru a accesa această pagină.');
          }

          return;
        }

        if (getErrorMessage) {
          const errorMessage = getErrorMessage(errorData, statusCode);
          toast.update(toastId, {
            render: errorMessage || DEFAULT_API_ERROR,
            type: 'error',
            isLoading: false,
            autoClose: 5000,
            icon: <Icon fillClassName="fill-error-500" identifier="circle-alert" />,
          });
        } else {
          toast.dismiss(toastId);
        }

        if (onErrorCallback) {
          onErrorCallback(errorData, statusCode);
        }
      } else {
        toast.update(toastId, {
          render: DEFAULT_API_ERROR,
          type: 'error',
          isLoading: false,
          autoClose: 5000,
          icon: <Icon fillClassName="fill-error-500" identifier="circle-alert" />,
        });
      }
    }

    if (response) {
      const { status: statusCode, data: responseData } = response;

      if (getSuccessMessage) {
        const successMessage = getSuccessMessage(responseData, statusCode);
        toast.update(toastId, {
          render: successMessage || 'Procesarea a fost finalizată cu succes!',
          type: 'success',
          isLoading: false,
          autoClose: 5000,
          icon: <Icon fillClassName="fill-green" identifier="check" />,
        });
      } else {
        toast.dismiss(toastId);
      }

      if (onSuccessCallback) {
        onSuccessCallback(responseData, statusCode);
      }
    }

    setIsSubmitting(false);
  };

  return {
    apiCall,
    isSubmitting,
  };
};

export default useAPI;
