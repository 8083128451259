import React from 'react';
import NavLink from 'components/ui/links/NavLink';

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <footer
      data-testid="footer"
      className="bg-white psc-sa-p-r psc-sa-p-l psc-sa-p-b md:px-8 lg:px-32 xl:px-120 pt-8 md:pb-8 xl:py-12 w-full flex flex-col md:flex-row md:justify-between gap-8"
    >
      <div className="flex flex-col md:flex-row items-center">
        <NavLink to="/termeni-si-conditii">Termeni și Condiții</NavLink>
        <NavLink className="mt-4 md:mt-0 md:ml-16" to="/politica-de-confidentialitate">
          Politică de confidențialitate
        </NavLink>
        <NavLink className="mt-4 md:mt-0 md:ml-16" to="/politica-cookies">
          Politică de cookies
        </NavLink>
      </div>
      <div className="text-base text-gray-darker text-center">
        <span data-testid="copyright-details" className="text-gray-darker">
          @ {year}{' '}
        </span>
        <a
          className="text-gray-darker hover:text-green-dark cursor-pointer"
          href="https://dacian.tech"
          target="_blank"
          rel="noreferrer"
        >
          DacianTech
        </a>
      </div>
    </footer>
  );
};

export default Footer;
