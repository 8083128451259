import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate, createSearchParams } from 'react-router-dom';
import PageHeader from 'components/Layout/PageHeader';
import axios from 'utility/axios';
import AppContext from 'contexts/AppContext';
import { setGoOuts } from 'state/app/actions';
import useData from 'components/GoOuts/hooks/useData';
import CreateGoOutButton from 'components/Layout/controls/CreateGoOutButton';
import { Sort } from './controls'; // Filter,
import GoOut from './GoOut';

export const goOutsLoader = async ({ request }) => {
  let response;
  const sortParams = new URL(request.url).searchParams.get('sort');

  let url = '/go-outs/feed';
  if (request.url.includes('partidele-mele')) {
    url = '/go-outs/user/feed';
  }

  try {
    response = await axios.get(url, { params: { sort: sortParams } });
  } catch (error) {
    return { error };
  }

  return {
    data: response.data,
  };
};

const GoOuts = (props) => {
  const { title, ownOnly } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const serverData = useData();
  const { serverGoOuts } = serverData || {};
  const {
    user,
    state: {
      goOuts: { isLoaded: isDataLoaded, data: goOuts },
    },
    dispatch,
  } = useContext(AppContext);

  const onSortChange = (option) => {
    navigate({
      pathname: location.pathname,
      search: createSearchParams({ sort: encodeURIComponent(option) })
        .toString()
        .toLowerCase(),
    });
  };

  const controls = [
    // <Filter key={1} onChange={() => {}} />,
    <Sort key={2} onChange={onSortChange} />,
  ];

  useEffect(() => {
    const useDataFromServer = serverGoOuts || !isDataLoaded;

    if (useDataFromServer) {
      if (!serverGoOuts) {
        navigate('/');
      }

      dispatch(setGoOuts(serverGoOuts));
    }
  }, [serverGoOuts, dispatch, isDataLoaded, navigate]);

  const onViewGoOut = (id) => {
    navigate(`/partida/${id}`, { state: { from: location.pathname } });
  };

  const onEditGoOut = (id) => {
    navigate(`/partida/${id}/modifica`, { state: { from: location.pathname } });
  };

  return (
    <div>
      <PageHeader title={title} actions={controls} />
      <div className="flex flex-col gap-6.4">
        {(goOuts || []).map(
          ({ id, stats, author, cover, weather, location: goOutLocation, personalNotes }) => {
            return (
              <GoOut
                key={id}
                author={author}
                image={cover}
                weather={weather}
                stats={stats}
                location={goOutLocation}
                description={personalNotes}
                onView={() => onViewGoOut(id)}
                onEdit={user?.id === author?.id ? () => onEditGoOut(id) : undefined}
              />
            );
          },
        )}
        {!(goOuts || []).length && (
          <div className="flex flex-col gap-12.8 justify-center">
            <p className="mt-32 text-center text-2xl text-gray-darker leading-8">
              Nu sunt partide disponibile.
            </p>
            {ownOnly ? (
              <div className="self-center">
                <CreateGoOutButton onClickCallback={() => {}} fullWidth />
              </div>
            ) : (
              <div>
                <p className="text-center text-gray-darker leading-8">
                  Momentan funcția de echipe nu este disponibilă.
                </p>
                <p className="text-center text-gray-darker leading-8">
                  Vă rugăm să ne contactați la adresa de e-mail{' '}
                  <a className="text-blue" href="mailto:contact@pescuiala.ro">
                    contact@pescuiala.ro
                  </a>{' '}
                  pentru a vă configura echipa înainte ca funcția să fie disponibilă.
                </p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

GoOuts.propTypes = {
  title: PropTypes.string.isRequired,
  ownOnly: PropTypes.bool,
};

GoOuts.defaultProps = {
  ownOnly: false,
};

GoOuts.displayName = 'GoOuts';

export default GoOuts;
