import React from 'react';
import { Emphasize } from '../formatting';

const CompanyName = () => {
  return <Emphasize variant="semibold">Dacian Technologies SRL</Emphasize>;
};

CompanyName.displayName = 'CompanyName';

export default CompanyName;
