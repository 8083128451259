import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from 'components/ui/Icon';
import NavLink from './NavLink';

const IconNavLink = (props) => {
  const { icon, variant, active, children, className, ...navLinkProps } = props;
  const isPrimary = variant === 'primary';
  const isSecondary = variant === 'secondary';
  const isAccent = variant === 'accent';
  const isDanger = variant === 'danger';

  return (
    <NavLink
      className={cn('group flex items-center', className)}
      variant={variant}
      active={active}
      {...navLinkProps}
    >
      <Icon
        identifier={icon}
        fillClassName={cn({
          'fill-gray group-hover:fill-green-dark': (isPrimary || isSecondary) && !active,
          'fill-green-dark': (isPrimary || isSecondary) && active,
          'fill-blue group-hover:fill-blue-darker': isAccent && !active,
          'fill-blue-darker': isAccent && active,
          'fill-red group-hover:fill-red-darker': isDanger && !active,
          'fill-red-darker': isDanger && active,
        })}
      />
      <span className="ml-3.2 md:ml-6.4">{children}</span>
    </NavLink>
  );
};

IconNavLink.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
  variant: PropTypes.oneOf(['primary', 'secondary', 'accent', 'danger']),
  active: PropTypes.bool,
};

IconNavLink.defaultProps = {
  className: '',
  variant: 'secondary',
  active: false,
};

export default IconNavLink;
