import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/ui/Modal';

const DeleteModal = (props) => {
  const { onClose, onSubmit } = props;
  return (
    <Modal title="Delete files" onClose={onClose} onSubmit={onSubmit} danger>
      Are you sure you want to delete this files?
    </Modal>
  );
};

DeleteModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

DeleteModal.displayName = 'DeleteModal';

export default DeleteModal;
