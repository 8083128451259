import { useState, useEffect, useCallback } from 'react';
import { isClickWithinElementBounds } from 'utility/utils';

const useOpenedState = (containerRef, contentRef, triggerProps, closeOnClick) => {
  const [isOpened, setIsOpened] = useState(false);

  const onToggle = useCallback(() => {
    const newIsOpened = !isOpened;

    if (triggerProps.onClick) {
      triggerProps.onClick(newIsOpened);
    }

    setIsOpened(newIsOpened);
  }, [triggerProps, isOpened]);

  const onDocumentClick = useCallback(
    (event) => {
      let shouldToggle = !isClickWithinElementBounds(containerRef.current, event);

      if (!closeOnClick && contentRef.current) {
        shouldToggle = shouldToggle && !isClickWithinElementBounds(contentRef.current, event);
      }

      if (shouldToggle) {
        onToggle();
      }
    },
    [containerRef, contentRef, onToggle, closeOnClick],
  );

  useEffect(() => {
    return () => {
      document.removeEventListener('click', onDocumentClick);
    };
  }, [onDocumentClick]);

  useEffect(() => {
    if (isOpened) {
      document.addEventListener('click', onDocumentClick);
    }
  }, [isOpened, onDocumentClick]);

  return { isOpened, onToggle };
};

export default useOpenedState;
