import { useState, useMemo } from 'react';
import { getDefaultFields, onFieldChange } from 'utility/utils';
import { isRequired } from 'utility/validation';

const useFormFields = (item, specificFields) => {
  const { brand = {}, model } = item || {};
  const [fields, setFields] = useState(
    getDefaultFields({
      brand: {
        value: brand.id,
        isValid: !!brand.id,
      },
      model: {
        value: model,
        isValid: !!model,
      },
      ...Object.keys(specificFields).reduce(
        (acc, key) => ({
          ...acc,
          [key]: specificFields[key].field,
        }),
        {},
      ),
    }),
  );

  const individualSetters = useMemo(() => {
    return {
      brand: (value) => {
        return onFieldChange(
          value,
          (newValue) =>
            setFields({
              ...fields,
              brand: newValue,
            }),
          isRequired,
        );
      },
      model: (e) =>
        onFieldChange(
          e.target.value,
          (newValue) =>
            setFields({
              ...fields,
              model: newValue,
            }),
          isRequired,
        ),
      ...Object.keys(specificFields).reduce(
        (acc, key) => ({
          ...acc,
          [key]: (data) => {
            let value = data;
            if (data && data.target) {
              ({ value } = data.target);
            }

            let validator;
            if (specificFields[key].required) {
              ({ validator } = specificFields[key]);
            } else if (value) {
              ({ validator } = specificFields[key]);
            }

            return onFieldChange(
              value,
              (newValue) =>
                setFields({
                  ...fields,
                  [key]: newValue,
                }),
              validator,
            );
          },
        }),
        {},
      ),
    };
  }, [specificFields, fields]);

  return [fields, setFields, individualSetters];
};

export default useFormFields;
