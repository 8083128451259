import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const Tab = (props) => {
  const { id, label, count, onClick, active } = props;

  return (
    <button
      id={id}
      type="button"
      role="tab"
      tabIndex="0"
      onClick={onClick}
      className={cn(
        'group flex gap-x-3.2 px-6.4 py-4.8 items-center cursor-pointer rounded-2.4 font-medium',
        {
          'bg-blueish': active,
          'bg-transparent hover:bg-blueish': !active,
        },
      )}
    >
      <span
        className={cn('text-inner-header', {
          'text-green-dark': active,
          'text-gray-darker group-hover:text-green-dark': !active,
        })}
      >
        {label}
      </span>
      {count !== null && (
        <div
          data-testid="count"
          className={cn(
            // eslint-disable-next-line max-len
            'flex items-center px-3.2 py-0.8 rounded-1.6 text-sm leading-7.2 mix-blend-multiply',
            {
              // eslint-disable-next-line max-len
              'text-gray-darker bg-gray-darker bg-opacity-10 group-hover:text-green group-hover:bg-green-light':
                !active,
              'text-green bg-green-light': active,
            },
          )}
        >
          {count}
        </div>
      )}
    </button>
  );
};

Tab.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  count: PropTypes.number,
  active: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

Tab.defaultProps = {
  count: null,
  active: false,
};

Tab.displayName = 'Tab';

export default Tab;
