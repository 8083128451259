import { useEffect } from 'react';

const lightSchemeIcon = 'images/favicon-light-scheme-128x.png';
const darkSchemeIcon = 'images/favicon-dark-scheme-128x.png';

const useSystemColorScheme = () => {
  const setFavIcon = (isDarkMode) => {
    const favIcon = document.querySelector('link[rel="icon"]');
    const newFavIconHref = isDarkMode ? darkSchemeIcon : lightSchemeIcon;
    favIcon.setAttribute('href', newFavIconHref);
  };

  useEffect(() => {
    const onDarkModeChange = (event) => setFavIcon(event.matches);

    // Set on first render
    setFavIcon(window.matchMedia('(prefers-color-scheme: dark)'));

    // Add listener for set on every change.
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', onDarkModeChange);

    return () => {
      window
        .matchMedia('(prefers-color-scheme: dark)')
        .removeEventListener('change', onDarkModeChange);
    };
  }, []);
};

export default useSystemColorScheme;
