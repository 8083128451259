export const SET_START_TIME = 'set-start-time';
export const SET_END_TIME = 'set-end-time';
export const SET_START_DATE = 'set-start-date';
export const SET_END_DATE = 'set-end-date';
export const SET_LOCATION_FIELD = 'set-location';
export const SET_WATER_CLARITY = 'set-water-clarity';
export const SET_WATER_LEVEL = 'set-water-level';
export const SET_WATER_SPEED = 'set-water-speed';
export const SET_WATER_PERSONAL_NOTES = 'set-water-personal-notes';
export const SET_SKY_CONDITION = 'set-sky-condition';
export const SET_TEMPERATURE = 'set-temperature';
export const SET_WIND_SPEED = 'set-wind-speed';
export const SET_WIND_GUSTS = 'set-wind-gusts';
export const SET_WIND_DIRECTION = 'set-wind-direction';
export const SET_ATMOSPHERIC_PRESSURE = 'set-atmospheric-pressure';
export const SET_WEATHER_PERSONAL_NOTES = 'set-weather-personal-notes';
export const ADD_MAIN_ITEM = 'add-main-item';
export const SET_MAIN_ITEM = 'set-main-item';
export const REMOVE_MAIN_ITEM = 'remove-main-item';
export const ADD_STRING_ITEM = 'add-string-item';
export const SET_STRING_ITEM = 'set-string-item';
export const REMOVE_STRING_ITEM = 'remove-string-item';
export const SET_DESCRIPTION = 'set-description';
export const SET_PERSONAL_SCORE = 'set-personal-score';
export const SET_TOTAL_QUANTITY = 'set-total-quantity';
export const ADD_SPECIES = 'add-species';
export const SET_SPECIES = 'set-species';
export const REMOVE_SPECIES = 'remove-species';
export const SET_PERSONAL_NOTES = 'set-personal-notes';
export const SET_FIRST_CATCH_TIME = 'set-first-catch-time';
export const SET_AVERAGE_CATCH_TIME = 'set-average-catch-time';
export const SET_MEDIA_FILE = 'set-media-file';
export const REMOVE_MEDIA_FILE = 'remove-media-file';
export const SET_CURRENT_COVER = 'set-current-cover';
export const SET_GO_OUT = 'set-go-out';
