import {
  getBaitName,
  getFeederName,
  getGroundBaitName,
  getHookLineName,
  getHookName,
  getLineName,
  getPowerGumName,
} from 'components/GoOut/utility/utils';

export const getRodsTableData = (rawData) =>
  rawData.map((item) => ({
    id: item.id,
    values: [
      item.brand.name,
      item.model,
      `${item.length} ${item.lengthUnit}`,
      `${item.power} ${item.powerUnit}`,
      item.action,
    ],
  }));

export const getReelsTableData = (rawData) =>
  rawData.map((item) => ({
    id: item.id,
    values: [item.brand.name, item.model, item.size, item.ratio, item.recoverLength],
  }));

export const getRodName = (data) => {
  const { brand, model, length, lengthUnit, power, powerUnit } = data;
  const brandName = brand?.name || brand;
  return `${brandName} ${model} ${length}${lengthUnit} ${power}${powerUnit}`;
};

export const getReelName = (data) => {
  const { brand, model, size } = data;
  const brandName = brand?.name || brand;
  return `${brandName} ${model} ${size}`;
};

export const successMessage = (resourceName, data, item = null) => {
  let resourceType;
  let nameGetter;
  let genre;
  switch (resourceName) {
    case 'rods':
      resourceType = 'Lanseta';
      nameGetter = getRodName;
      genre = 'f';
      break;
    case 'reels':
      resourceType = 'Mulineta';
      nameGetter = getReelName;
      genre = 'f';
      break;
    case 'lines':
      resourceType = 'Firul';
      nameGetter = getLineName;
      genre = 'm';
      break;
    case 'hooks':
      resourceType = 'Carligul';
      nameGetter = getHookName;
      genre = 'm';
      break;
    case 'hookLines':
      resourceType = 'Firul';
      nameGetter = getHookLineName;
      genre = 'm';
      break;
    case 'feeders':
      resourceType = 'Momitorul';
      nameGetter = getFeederName;
      genre = 'm';
      break;
    case 'ground-baits':
      resourceType = 'Nada';
      nameGetter = getGroundBaitName;
      genre = 'f';
      break;
    case 'baits':
      resourceType = 'Momeala';
      nameGetter = getBaitName;
      genre = 'f';
      break;
    case 'power-gums':
      resourceType = 'Power Gum-ul';
      nameGetter = getPowerGumName;
      genre = 'm';
      break;
    default:
      break;
  }

  if (!resourceType || !nameGetter) {
    return 'Resursa a fost modificată cu succes.';
  }

  const editKeyword = genre === 'f' ? 'modificată' : 'modificat';
  const addKeyword = genre === 'f' ? 'adaugată' : 'adaugat';

  return `${resourceType} ${nameGetter(data)} a fost ${item ? editKeyword : addKeyword} cu succes.`;
};
