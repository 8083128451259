import React, { useContext, useEffect } from 'react';
import { Input, Textarea } from 'components/ui/inputs';
import Context from 'components/GoOut/state/contexts/addNew';
import { onFieldChange } from 'utility/utils';
import {
  setAverageCatchTime,
  setFirstCatchTime,
  setPersonalNotes,
} from 'components/GoOut/state/actions/addNew/others';
import { isDecimalNumberMaxTwoDecimals, isRequired } from 'utility/validation';
import CanSubmitContext from 'contexts/CanSubmitContext';
import CanEditContext from 'contexts/CanEditContext';
import IconStats from 'components/ui/IconStats';
import Section from './Section';
import Step from './Step';

const title = 'Strategie';
const getSectionDetails = (editable) =>
  editable
    ? 'Descrie strategia de pescuit abordată în această partidă.'
    : 'Strategia de pescuit abordată în această partidă.';

const Strategy = () => {
  const [state, dispatch] = useContext(Context);
  const [, setSubmit] = useContext(CanSubmitContext);
  const editable = useContext(CanEditContext);

  const {
    strategy: {
      description: { value: description },
      firstCatchTime: { value: firstCatch, isValid: firstCatchIsValid, isDirty: firstCatchIsDirty },
      averageCatchTime: {
        value: catchInterval,
        isValid: catchIntervalIsValid,
        isDirty: catchIntervalIsDirty,
      },
    },
  } = state;

  useEffect(() => {
    setSubmit(firstCatchIsValid && catchIntervalIsValid);
  }, [catchIntervalIsValid, firstCatchIsValid, setSubmit]);

  const onChangePersonalNotes = (event) => {
    const setter = (newItem) => dispatch(setPersonalNotes(newItem));
    onFieldChange(event.target.value, setter, isRequired);
  };

  const onChangeFirstCatch = (event) => {
    const { value } = event.target;
    const setter = (newItem) => dispatch(setFirstCatchTime(newItem));
    onFieldChange(value, setter, value ? isDecimalNumberMaxTwoDecimals : undefined);
  };

  const onChangeAverageCatchTime = (event) => {
    const { value } = event.target;
    const setter = (newItem) => dispatch(setAverageCatchTime(newItem));
    onFieldChange(value, setter, value ? isDecimalNumberMaxTwoDecimals : undefined);
  };

  return (
    <Step title={title} description={getSectionDetails(editable)}>
      <Section title="Strategie" className="flex flex-col">
        <Textarea
          onChange={onChangePersonalNotes}
          name="description"
          id="description"
          placeholder="Descrie strategia folosită"
          label="Descriere"
          type="text"
          rows="4"
          cols="50"
          value={description}
          disabled={!editable}
          autoFocus={editable}
        />
        <div className="flex flex-col lg:flex-row gap-4.8">
          {editable ? (
            <Input
              id="firstCatch"
              name="firstCatch"
              onChange={onChangeFirstCatch}
              placeholder="Introdu numărul de minute"
              label="Prima prezentare"
              type="text"
              valid={firstCatchIsDirty ? firstCatchIsValid : undefined}
              helperText={
                firstCatchIsDirty && !firstCatchIsValid
                  ? 'Introdu un număr cu maxim 2 zecimale'
                  : null
              }
              value={firstCatch}
            />
          ) : (
            <IconStats
              icon="clock"
              data={[{ label: 'Prima prezentare', value: `${firstCatch} minute` }]}
              rounded="small"
            />
          )}
          {editable ? (
            <Input
              id="catchInterval"
              name="catchInterval"
              onChange={onChangeAverageCatchTime}
              placeholder="Introdu numărul de minute"
              label="Timp de așteptare"
              type="text"
              valid={catchIntervalIsDirty ? catchIntervalIsValid : undefined}
              helperText={
                catchIntervalIsDirty && !catchIntervalIsValid
                  ? 'Introdu un număr cu maxim 2 zecimale'
                  : null
              }
              value={catchInterval}
            />
          ) : (
            <IconStats
              icon="clock"
              data={[{ label: 'Timp de așteptare', value: `${catchInterval} minute` }]}
              rounded="small"
            />
          )}
        </div>
      </Section>
    </Step>
  );
};

Strategy.displayName = 'Strategy';

export default Strategy;
