import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { OptionsDropdown } from 'components/ui/dropdowns';
import { useLocation } from 'react-router-dom';

const SORTING_OPTIONS = [
  {
    label: 'Cea mai nouă postare',
    selected: true,
    icon: 'check-small',
  },
  {
    label: 'Cea mai nouă partidă',
    selected: false,
    icon: null,
  },
  {
    label: 'Cel mai mare scor personal',
    selected: false,
    icon: null,
  },
  {
    label: 'Cel mai mic scor personal',
    selected: false,
    icon: null,
  },
  {
    label: 'Cea mai mare cantitate',
    selected: false,
    icon: null,
  },
  {
    label: 'Cea mai mică cantitate',
    selected: false,
    icon: null,
  },
  {
    label: 'Cele mai multe specii prinse',
    selected: false,
    icon: null,
  },
  {
    label: 'Cele mai puține specii prinse',
    selected: false,
    icon: null,
  },
];

const AVAILABLE_SORTING_OPTIONS = SORTING_OPTIONS.map((option) => option.label);

const Sort = (props) => {
  const { onChange } = props;
  const [sortingOption, setSortingOption] = useState('');
  const buttonProps = {
    children: sortingOption,
    variant: 'simple',
    icon: 'sort',
    scheme: 'blue',
  };
  const location = useLocation();

  const selectSortOption = (index) => {
    const currentItem = SORTING_OPTIONS[index];
    onChange(currentItem.label);
  };

  const changeSelection = (sortBy = null) => {
    const prevOption = SORTING_OPTIONS.find((sortOption) => sortOption.selected);
    if (prevOption) {
      prevOption.selected = false;
      prevOption.icon = null;
    }

    let selectedOption;
    if (AVAILABLE_SORTING_OPTIONS.includes(sortBy)) {
      selectedOption = SORTING_OPTIONS.find(
        (sortOption) => sortOption.label.toLowerCase() === sortBy,
      );
    } else {
      [selectedOption] = SORTING_OPTIONS;
    }
    selectedOption.selected = true;
    selectedOption.icon = 'check-small';
    setSortingOption(selectedOption.label);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const param = params.size > 0 ? decodeURIComponent(params.get('sort')) : null;
    changeSelection(param);
  }, [location]);

  return (
    <OptionsDropdown
      items={SORTING_OPTIONS}
      buttonProps={buttonProps}
      onChange={selectSortOption}
    />
  );
};

Sort.propTypes = {
  onChange: PropTypes.func.isRequired,
};

Sort.displayName = 'Sort';

export default Sort;
