import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { IconButton } from 'components/ui/buttons';

const Header = (props) => {
  const { title, onToggle, extras, opened } = props;

  return (
    <div data-testid="accordion-header" className="flex justify-between pb-6.4">
      <div
        data-testid="accordion-title"
        className="flex items-center text-controls font-semibold text-green-dark"
      >
        {title}
      </div>
      <div className="flex items-center">
        <div data-testid="accordion-select" className="hidden lg:flex items-center gap-x-3.2">
          {extras}
        </div>
        <div
          data-testid="accordion-toggle"
          className={cn('flex items-center', {
            'psc-rotate-180': opened,
          })}
        >
          <IconButton icon="down-chevron-small" onClick={onToggle} variant="simple" />
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  onToggle: PropTypes.func.isRequired,
  opened: PropTypes.bool,
  extras: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

Header.defaultProps = {
  opened: false,
  extras: null,
};

Header.displayName = 'Header';

export default Header;
