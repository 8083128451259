import React from 'react';
import PropTypes from 'prop-types';

const Header = (props) => {
  const { children } = props;

  return (
    <h2 className="text-green-dark text-3xl leading-16 font-bold text-center lg:text-left">
      {children}
    </h2>
  );
};

Header.propTypes = {
  children: PropTypes.string.isRequired,
};

Header.displayName = 'Header';

export default Header;
