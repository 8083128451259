import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input, Textarea } from 'components/ui/inputs';
import Section from 'components/GoOut/steps/Section';
import Context from 'components/GoOut/state/contexts/addNew';
import { setWaterLevel, setWaterPersonalNotes } from 'components/GoOut/state/actions/addNew/others';
import { onFieldChange } from 'utility/utils';
import { isNegativeOrPositiveIntegerNumber } from 'utility/validation';
import WaterClearnessInput from 'components/GoOut/steps/inputs/WaterClearnessInput';
import WaterSpeedInput from 'components/GoOut/steps/inputs/WaterSpeedInput';
import CanEditContext from 'contexts/CanEditContext';
import IconStats from 'components/ui/IconStats';

const Water = (props) => {
  const { onChange } = props;
  const [state, dispatch] = useContext(Context);
  const editable = useContext(CanEditContext);

  const {
    conditions: {
      water: {
        level: { value: level, isValid: levelIsValid, isDirty: levelIsDirty, error: levelError },
        personalNotes: {
          value: personalNotes,
          isValid: personalNotesIsValid,
          isDirty: personalNotesIsDirty,
          error: personalNotesError,
        },
      },
    },
  } = state;

  useEffect(() => {
    onChange(
      Object.values(state.conditions.water).every((field) => field.isValid) &&
        Object.values(state.conditions.water).some((field) => field.isDirty),
    );
  }, [state.conditions.water, onChange]);

  const onLevelChange = (event) => {
    const { value } = event.target;
    const setter = (newItem) => dispatch(setWaterLevel(newItem));
    onFieldChange(value, setter, value ? isNegativeOrPositiveIntegerNumber : undefined);
  };

  const onPersonalNotesInput = (event) => {
    const setter = (newItem) => dispatch(setWaterPersonalNotes(newItem));
    onFieldChange(event, setter);
  };

  return (
    <Section title="Detalii apă" className="grid grid-cols-1 lg:grid-cols-3">
      <WaterClearnessInput editable={editable} />
      {editable ? (
        <Input
          id="waterLevel"
          name="waterLevel"
          onChange={onLevelChange}
          placeholder="Introdu cotă"
          label="Cotă"
          type="text"
          value={level}
          valid={levelIsDirty ? levelIsValid : undefined}
          helperText={
            levelIsDirty && !levelIsValid ? levelError || 'Introdu un număr întreg' : null
          }
        />
      ) : (
        <IconStats icon="drop" data={[{ label: 'Cotă', value: level }]} rounded="small" />
      )}
      <WaterSpeedInput editable={editable} />
      <div className="lg:col-span-3">
        <Textarea
          onChange={onPersonalNotesInput}
          name="personalNotes"
          id="personalNotes"
          placeholder="Introdu note personale"
          label="Note personale"
          type="text"
          rows="4"
          cols="50"
          value={personalNotes}
          valid={personalNotesIsDirty ? personalNotesIsValid : undefined}
          helperText={personalNotesIsDirty && !personalNotesIsValid ? personalNotesError : null}
          disabled={!editable}
        />
      </div>
    </Section>
  );
};

Water.propTypes = {
  onChange: PropTypes.func,
};

Water.defaultProps = {
  onChange: null,
};

Water.displayName = 'Water';

export default Water;
