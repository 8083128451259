import {
  INIT_POWER_GUMS,
  ADD_POWER_GUM,
  SELECT_POWER_GUM,
  DESELECT_POWER_GUM,
} from '../../types/availableEntities/powerGums';

export const init = (payload) => ({
  type: INIT_POWER_GUMS,
  payload,
});

export const add = (payload) => ({
  type: ADD_POWER_GUM,
  payload,
});

export const select = (id, index) => ({
  type: SELECT_POWER_GUM,
  payload: {
    id,
    index,
  },
});

export const deselect = (payload) => ({
  type: DESELECT_POWER_GUM,
  payload,
});
