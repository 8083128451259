import { useContext, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import AppContext from '../../contexts/AppContext';
import useAPI from '../../hooks/useAPI';

const regexState = /state=([^&]+)/;
const regexCode = /code=([^&]+)/;

const IdentityProvider = () => {
  const { setIsAuthenticated } = useContext(AppContext);
  const location = useLocation();
  const { provider } = useParams();
  const navigate = useNavigate();
  const { apiCall } = useAPI();

  useEffect(() => {
    (async () => {
      const { search } = location;

      await apiCall(
        `authentication/${provider}/callback`,
        'post',
        {
          access_code: search.match(regexCode)[1],
          state_token: search.match(regexState)[1].split('#')[0],
        },
        {
          getErrorMessage: (statusCode) => {
            switch (statusCode) {
              case 422:
                // eslint-disable-next-line max-len
                return 'Eroare de autentificare. Te rugăm să furnizezi un token de acces valid pentru a continua.';
              case 400:
                return 'State token-ul este invalid.';
              default:
                return null;
            }
          },
          onErrorCallback: () => {
            navigate('/');
          },
          onSuccessCallback: () => {
            setIsAuthenticated(true);
            navigate('/');
          },
        },
      );
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

IdentityProvider.displayName = 'IdentityProvider';

export default IdentityProvider;
