import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { addYears, subYears, getYear, isFuture } from 'date-fns';
import { IconButton } from 'components/ui/buttons';

const getYears = (year) => {
  const years = [];
  for (let i = year - 10; i <= year + 1; i++) {
    years.push(i);
  }

  return years;
};

const Years = (props) => {
  const { selectedDate, onChange } = props;
  const [dateInView, setDateInView] = useState(new Date(selectedDate));
  const selectedYear = getYear(selectedDate);
  const year = getYear(dateInView);
  const yearsInView = getYears(year);
  const onNextPage = () => setDateInView(addYears(dateInView, 12));
  const onPrevPage = () => setDateInView(subYears(dateInView, 12));

  return (
    <>
      <div data-testid="years-view-header" className="flex justify-between items-center gap-6.4">
        <IconButton variant="link" icon="left-chevron" onClick={onPrevPage} />
        <p className="text-gray-dark font-semibold text-controls leading-8">
          {yearsInView[0]} - {yearsInView[yearsInView.length - 1]}
        </p>
        <IconButton variant="link" icon="right-chevron" onClick={onNextPage} />
      </div>
      <div
        data-testid="years-container"
        className="w-full grid grid-cols-4 justify-between gap-8 h-131.2"
      >
        {yearsInView.map((value) => {
          const isSelected = value === selectedYear;
          const isDisabled = isFuture(new Date(value, 0, 1));

          return (
            <div key={value} className="flex justify-center items-center mx-auto">
              {isDisabled ? (
                <div className="p-4 text-gray text-lg">{value}</div>
              ) : (
                <button
                  type="button"
                  onClick={() => onChange(value)}
                  className={cn('text-gray-dark text-lg p-4 rounded-full', {
                    'bg-blue text-white': isSelected,
                    'bg-transparent hover:bg-blue hover:text-white': !isSelected,
                  })}
                >
                  {value}
                </button>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};

Years.propTypes = {
  selectedDate: PropTypes.instanceOf(Date).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Years;
