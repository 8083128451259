import { useEffect, useState } from 'react';

const useShowMoreNumber = (
  values,
  chipsContainerRef,
  auxChipsContainerRef,
  paddingLeft,
  paddingRight,
) => {
  const [showMoreNumber, setShowMoreNumber] = useState(0);
  const showMoreNumberChipWidth = 2.4;

  useEffect(() => {
    const { current: chipsContainer } = chipsContainerRef;
    const { current: auxChipsContainer } = auxChipsContainerRef;

    if (chipsContainer) {
      chipsContainerRef.current.classList.add('invisible');

      const availableWidth =
        chipsContainer.getBoundingClientRect().width - (paddingLeft + paddingRight) * 10;

      let showChipsNumber = 0;
      let chipsWidth = 0;
      const chips = Array.from(auxChipsContainer.children);
      for (let i = 0; i < chips.length; i += 1) {
        const chip = chips[i];
        const gapSizeInFront = i === 0 ? 0 : 4;
        const widthIncrease = chip.getBoundingClientRect().width + gapSizeInFront;

        if (chipsWidth + widthIncrease > availableWidth) {
          break;
        }

        chipsWidth += widthIncrease;
        showChipsNumber += 1;
      }

      let newShowMoreNumber = chips.length - showChipsNumber;

      if (showChipsNumber !== chips.length) {
        const showMoreNumberLetters = newShowMoreNumber.toString().length;
        chipsWidth += (showMoreNumberChipWidth + 0.8 * showMoreNumberLetters) * 10;
        if (chipsWidth > availableWidth) {
          newShowMoreNumber += 1;
        }
      }

      setShowMoreNumber(newShowMoreNumber);
    }
  }, [values, chipsContainerRef, paddingLeft, paddingRight, auxChipsContainerRef]);

  return showMoreNumber;
};

export default useShowMoreNumber;
