import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/ui/Modal';
import BrandInput from 'components/Equipment/inputs/BrandInput';
import { Input } from 'components/ui/inputs';
import useFormFields from 'components/Equipment/hooks/useFormFields';
import { CheckboxesDropdown } from 'components/ui/dropdowns';
import { ModelInput } from 'components/Equipment/inputs';
import { isIntegerNumber, isRequired } from 'utility/validation';
import useBrandsLoader from 'hooks/useBrandsLoader';
import useSubmit from '../hooks/useSubmit';

const feederTypes = ['method', 'coșuleț', 'arc momitor'].map((o) => ({ id: o, value: o }));
const feederSubTypes = ['flat', 'banjo', 'pellet', 'cage', 'window', 'open-end'].map((o) => ({
  id: o,
  value: o,
}));

const FeederModal = (props) => {
  const { onClose, item, onSuccess } = props;
  const { type, subtype, weight } = item || {};
  const [fields, setFields, individualSetters] = useFormFields(item, {
    type: {
      field: {
        value: type || '',
        isValid: !!type,
      },
      validator: isRequired,
    },
    subType: {
      field: {
        value: subtype || '',
        isValid: !!subtype,
      },
      validator: isRequired,
    },
    weight: {
      field: {
        value: weight ? weight.toString() : '',
        isValid: !!weight,
      },
      validator: isIntegerNumber,
    },
  });

  const [isLoadingBrands, allBrands] = useBrandsLoader();
  const [canSubmit, onSubmit] = useSubmit('feeders', item, fields, setFields, onSuccess);

  return (
    <Modal title="Add new feeder" onSubmit={onSubmit} onClose={onClose} canSubmit={canSubmit} form>
      <BrandInput
        loading={isLoadingBrands}
        all={allBrands}
        onChange={individualSetters.brand}
        {...fields.brand}
      />
      <ModelInput onChange={individualSetters.model} {...fields.model} />
      <CheckboxesDropdown
        data={feederTypes}
        onChange={individualSetters.type}
        singleSelection
        selectedValues={fields.type.value ? [fields.type.value] : undefined}
        triggerProps={{
          label: 'Tip',
          placeholder: 'Selectează tipul',
          required: true,
        }}
      />
      <CheckboxesDropdown
        data={feederSubTypes}
        onChange={individualSetters.subType}
        singleSelection
        selectedValues={fields.subType.value ? [fields.subType.value] : undefined}
        triggerProps={{
          label: 'Sub-tip',
          placeholder: 'Selectează sub-tip',
          required: true,
        }}
      />
      <Input
        id="weight"
        name="weight"
        onChange={individualSetters.weight}
        placeholder="Introdu greutate"
        label="Greutate"
        value={fields.weight.value}
        inputMode="numeric"
        pattern="[0-9]*"
        required
        valid={fields.weight.isDirty ? fields.weight.isValid : undefined}
        helperText={
          fields.weight.isDirty && !fields.weight.isValid
            ? fields.weight.error || 'Introdu un număr întreg'
            : null
        }
      />
    </Modal>
  );
};

FeederModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    brand: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
    model: PropTypes.string.isRequired,
    type: PropTypes.string,
    subtype: PropTypes.string,
    weight: PropTypes.number,
  }),
};

FeederModal.defaultProps = {
  item: null,
};

FeederModal.displayName = 'FeederModal';

export default FeederModal;
