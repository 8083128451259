import React from 'react';
import { NavLink } from 'react-router-dom';
import { Title, Subtitle, Section, Emphasize, SectionSubtitle, List } from './formatting';
import { WhoWeAre, Email, AppName, CompanyName, AppLink } from './common';

const TermsAndConditions = () => {
  return (
    <div className="flex flex-col gap-9.6">
      <Title>Termeni și condiții.</Title>
      <Subtitle>Condiții generale pentru utilizarea aplicației pescuială.ro</Subtitle>
      <Section>
        <p>
          Vă mulțumim pentru interesul față de compania, aplicația, site-ul, serviciile și/sau
          produsele noastre.
        </p>
        <p>
          Vă rugăm să citiți cu atenție acest document, denumit în continuare{' '}
          <Emphasize>&quot;Prezentul document&quot;</Emphasize>,{' '}
          <Emphasize>&quot;Contractul&quot;</Emphasize>, <Emphasize>&quot;Termenii&quot;</Emphasize>
          , <Emphasize>&quot;Condiții generale&quot;</Emphasize>,{' '}
          <Emphasize>&quot;Termeni de utilizare&quot;</Emphasize>, împreună cu documentele
          menționate în cuprinsul acestuia. Acest document reprezintă o convenție legală – un
          contract între dumneavoastră și noi.
        </p>
        <p>
          Vă rugăm să citiți și <Emphasize>Politica de confidențialitate</Emphasize> pe care o poți
          regăsi aici{' '}
          <Emphasize variant="link">
            <NavLink to="/politica-de-confidentialitate" target="_blank">
              pescuiala.ro/politica-de-confidentialitate
            </NavLink>
          </Emphasize>{' '}
          și <Emphasize>Politica privind utilizarea modulelor cookies</Emphasize> (pe care o puteți
          regăsi aici{' '}
          <Emphasize variant="link">
            <NavLink to="/politica-cookies" target="_blank">
              pescuiala.ro/politica-cookies
            </NavLink>
          </Emphasize>
          . Dacă nu sunteți de acord cu acești Termeni sau cu Politicile indicate mai sus, vă rugăm
          să nu utilizați aplicația.
        </p>
      </Section>
      <Section title="1. Introducere. Definirea noțiunilor utilizate">
        <SectionSubtitle>1.1. Dispoziții introductive</SectionSubtitle>
        <p>
          Acești termeni de utilizare (&quot;Termenii de utilizare&quot;) înglobează termenii și
          condițiile de utilizare a aplicației web <AppName /> (&quot;Aplicația <AppName />
          &quot; sau &quot;Aplicația&quot;) și a funcționalităților puse la dispoziția dumneavoastră
          în calitate de utilizator și sunt obligatorii pentru toți utilizatorii aplicației{' '}
          <AppName />.
        </p>
        <p>
          Aplicația este pusă la dispoziția dumneavoastră de către <CompanyName />.
        </p>
        <p>
          <Emphasize variant="semibold">
            Vă rugăm să citiți cu atenție aceste Condiții Generale înainte de a utiliza serviciile
            furnizate de <CompanyName />.
          </Emphasize>{' '}
          Prezentul Contract conține termenii și condițiile obligatorii pentru utilizarea Aplicației{' '}
          <AppName quoted /> (în continuare: &quot;Aplicația&quot;) și a serviciilor disponibile
          prin intermediul Aplicației (în continuare, &quot;Serviciul&quot; sau
          &quot;Serviciile&quot;).
        </p>
        <p>
          <Emphasize variant="semibold">
            Dacă nu intenționați să respectați aceste Condiții Generale, vă rugăm să nu utilizați
            Aplicația și/sau Serviciul.
          </Emphasize>
        </p>
        <p>
          Prin acceptarea acestor Termeni de utilizare, înțelegeți și confirmați acordul
          dumneavoastră cu privire la utilizarea Aplicației si a Serviciilor în termenii și
          condițiile stipulate în prezentul document și pentru orice neclaritate cu privire la
          acești Termeni de utilizare, vă rugăm să ne contactați la adresa de e-mail: <Email />.
        </p>
        <p>
          Furnizorul Serviciului își rezervă dreptul de a modifica sau de a înlocui aceste Condiții
          Generale la propria alegere, cu condiția ca acesta să publice dispozițiile curente pe
          Website și în aplicația mobilă.
        </p>
        <SectionSubtitle>1.2. Cine suntem noi?</SectionSubtitle>
        <p>Mai jos găsiți datele noastre de identificare:</p>
        <WhoWeAre />
        <p>
          În cuprinsul prezentului document vom face referire la noi folosind denumirea oficială
          menționată mai sus sau sub numele de brand &quot;pescuială.ro&quot;, sub denumirea de
          &quot;Furnizorul Serviciului&quot;, &quot;Furnizorul&quot;, &quot;Compania&quot;,
          &quot;Vânzătorul&quot;.
        </p>
        <p>Suntem o companie care oferă produse și servicii prin intermediul aplicației .</p>
        <SectionSubtitle>1.3. Definirea noțiunilor utilizate</SectionSubtitle>
        <p>
          <Emphasize variant="semibold">Vânzătorul sau Furnizorul</Emphasize> - reprezintă
          societatea <CompanyName />, astfel cum ne-am prezentat anterior.
        </p>
        <p>
          <Emphasize variant="semibold">Utilizator/Client/Cumpărător</Emphasize> – reprezintă orice
          persoană fizică, în vârstă de cel puțin 18 ani, cu capacitate deplină de exercițiu sau
          orice persoană/entitate juridică, care își creează sau nu un Cont prin intermediul
          Aplicației Web şi efectuează o Comandă online. Prin Client/Cumpărător se înțelege și o
          persoană juridică care plasează o Comandă printr-o persoană fizică în numele acesteia.
          Persoanele majore care autorizează minori să folosească Site-ul, sunt răspunzători de
          întregul comportament al acestora pe Site și pentru toate acțiunile pe care minorii le pot
          întreprinde.
        </p>
        <p>
          <Emphasize variant="semibold">Produse ori servicii</Emphasize> - reprezintă orice
          bunuri/produse sau servicii care sunt oferite Clientului/Cumpărătorului în vederea
          achiziționării, contra cost, prin intermediul aplicației <AppName />.
        </p>
        <p>
          <Emphasize variant="semibold">Contract</Emphasize> – reprezintă înțelegerea la distanță
          (fără prezența fizică simultană) intervenită între Vânzător şi Client, cu privire la
          vânzarea/achiziționarea unuia sau mai multor Produse ori Servicii din aplicație, prin
          plasarea unei Comenzi de către Client şi acceptarea ei de către Vânzător, cu respectarea
          prevederilor legale şi a termenilor şi condițiilor pentru vânzarea online/comerțul
          electronic. Contractul se încheie, de regulă, în limba română.
        </p>
        <p>
          <Emphasize variant="semibold">Comanda</Emphasize> - reprezintă angajamentul exprimat prin
          intermediul site-ului de către Client de a achiziționa unul sau mai multe Produse, în
          condițiile prevăzute în prezentul document și/sau agreate cu Vânzătorul printr-un mijloc
          de comunicare la distanță.
        </p>
        <p>
          <Emphasize variant="semibold">Cont de Utilizator</Emphasize> - tehnologia prin care
          Utilizatorul se poate conecta la Aplicație, respectiv serviciul de informații. Contul de
          Utilizator poate fi accesat prin intermediul unui Nume de Utilizator și al unei Parole.
        </p>
        <p>
          <Emphasize variant="semibold">Nume de Utilizator și Parolă</Emphasize> - Elementele de
          identificare specificate de Utilizatorul persoană fizică prin intermediul Aplicației care
          permit utilizarea funcțiilor Aplicației Mobile.
        </p>
        <p>
          <Emphasize variant="semibold">
            Aplicația Mobilă sau Aplicația <AppName />
          </Emphasize>{' '}
          - o aplicație (soluție IT) dezvoltată pentru dispozitive mobile, prin intermediul căreia
          Utilizatorul poate efectua înregistrarea necesară utilizării Aplicației, poate accesa
          Contul său de Utilizator și utiliza Serviciul.
        </p>
        <p>
          <Emphasize variant="semibold">Dispozitiv mobil</Emphasize> - dispozitiv tehnic capabil de
          trafic de date mobile și comunicare mobilă, incluzând, în mod special, dar fără a se
          limita, smartphone-uri, tablete etc.
        </p>
        <p>
          <Emphasize variant="semibold">Site-ul</Emphasize> – reprezintă site-ul nostru <AppLink />,
          precum și orice secțiune sau subpagină a acestuia.
        </p>
        <p>
          Ceilalți termeni folosiți în prezentul document au înțelesul conferit de prevederile
          legale aplicabile.
        </p>
      </Section>
      <Section title="2. Obiectul Condițiilor Generale și Politica de vânzare online">
        <p>
          2.1.{' '}
          <Emphasize variant="semibold">
            Serviciul este disponibil numai pentru persoane fizice cu vârsta de cel puțin
            optsprezece (18) ani
          </Emphasize>
          . Utilizarea sau accesarea Aplicației de orice manieră de către persoane cu vârsta sub 18
          ani va fi neautorizată și va fi considerată a fi o încălcare a acestor Condiții Generale.{' '}
          <Emphasize variant="semibold">
            Prin utilizarea Serviciului și a Aplicației, se consideră că Utilizatorul a declarat și
            garantat că are cel puțin 18 ani și are dreptul de a utiliza Aplicația.
          </Emphasize>
        </p>
        <p>
          <Emphasize variant="semibold">
            În situația în care Utilizatorul are sub 18 ani, acesta garantează că are acordul
            Părinților/Tutorelui/Reprezentantului legal, pentru descărcarea și utilizarea
            aplicației.
          </Emphasize>
        </p>
        <p>
          2.2. Dacă oricare dintre dispozițiile Condițiilor Generale este sau devine nevalabilă sau
          neaplicabilă, aceasta nu va afecta valabilitatea celorlalte dispoziții ale acestor
          Condiții Generale. În acest caz, Părțile vor înlocui această dispoziție nevalabilă sau
          neaplicabilă cu o dispoziție care corespunde cel mai bine obiectivelor inițiale ale
          acesteia.
        </p>
        <p>
          2.3. În situația modificării Condițiilor Generale, Furnizorul Serviciului va publica un
          anunț privind efectuarea modificării, un sumar al acesteia, data intrării în vigoare a
          modificării și noul text complet al Condițiilor Generale ce urmează a fi aplicabil cu cel
          puțin 3 (trei) zile calendaristice înaintea datei intrării în vigoare a modificării și îl
          va face direct accesibil și lizibil pentru Utilizatori în cadrul Aplicației.
        </p>
        <p>
          2.4. Dacă Utilizatorul continuă să utilizeze Serviciul după data intrării în vigoare a
          modificării aduse Condițiilor Generale, iar la prima utilizare după data intrării în
          vigoare a modificării acesta acceptă Condițiile Generale, se va considera că acesta
          acceptă noile Condiții Generale cu modificările aduse acestora. În cazul neacceptării
          termenilor și condițiilor generale modificate, Aplicația nu va putea fi accesată.
        </p>
        <p>
          2.5. Oricărui potenţial Client care acţionează cu un scop legitim şi care are intenţia de
          a achiziţiona de la noi unul sau mai multe Produse, cu respectarea acestor termeni și
          condiţii, îi este permis accesul la site-ul <AppLink /> în vederea efectuării unei
          Comenzi.
        </p>
        <p>
          2.6. Clientul declară că acceptă și este de acord cu forma de comunicare la distanţă (e.g.
          telefonic sau e-mail) prin care ne derulăm operațiunile în cazul utilizării site-ului şi,
          după caz, înregistrarea unei Comenzi.
        </p>
        <p>
          2.7. În cazul în care există erori în legătură cu prețul sau oricare alte detalii ale
          Produselor și ați plasat o comandă, vă vom informa prin e-mail sau printr-o altă
          modalitate de comunicare agreată, în cel mai scurt timp în legătură cu o astfel de eroare.
        </p>
        <p>
          2.8. Atunci când există o promoție sau campanie de orice tip în desfășurare, Produsele
          care fac obiectul vânzării în cadrul acesteia vor fi supuse termenilor şi condiţiilor
          aplicabile respectivei campanii sau promoţii, pe care vi le vom aduce la cunoștință.
        </p>
        <p>
          2.9. Toate promoțiile sau campaniile prezentate pe site sunt valabile pe durata
          menționată, iar în cazul în care nu este indicată o durată pentru desfășurarea
          promoțiilor, acestea vor fi valabile în limitele stocurilor/locurilor disponibile sau
          pentru durata pe care noi o considerăm oportună pentru a ne atinge obiectivele.
        </p>
        <p>
          2.10. Nu garantăm disponibilitatea în orice moment a tuturor Produselor și ne rezervăm
          dreptul de a înceta în orice moment comercializarea unui produs.
        </p>
        <p>
          2.11. Orice întrebări sau nelămuriri pot fi adresate cu încredere la adresa de e-mail:
          <Email /> sau prin completarea formularului de contact, iar noi vă garantăm un răspuns în
          cel mai scurt timp.
        </p>
        <SectionSubtitle>3. Deschiderea contului și utilizarea aplicației</SectionSubtitle>
        <p>
          3.1. Pașii necesari pentru deschiderea unui cont de utilizator al Aplicației, precum și de
          înrolare a dispozitivului sunt următorii:
        </p>
        <List variant="ordered">
          <li>
            Dacă este vorba de aplicația mobilă <AppName />, aceasta se descarcă din magazinele de
            aplicații mobile, cum ar fi Google Play, App Store etc.; Dacă este vorba de aplicația
            web <AppName />, aceasta se accesează la adresa <AppLink />
          </li>
          <li>
            Este necesar să vă înregistrați cu adresa dumneavoastră de e-mail care va fi validată
            ulterior printr-un e-mail de confirmare, trimis pe adresa de e-mail declarată;
          </li>
          <li>
            Pentru înregistrare, este necesar să introduceți următoarele date: numele, prenumele și
            adresa de e-mail.
          </li>
        </List>
        <p>
          3.2. Utilizatorul își asumă obligația să informeze <CompanyName /> în cel mai scurt timp
          posibil, despre modificarea/actualizarea datelor cu caracter personal (numele, adresa de
          e-mail sau numărul de telefon). Puteți astfel să modificați oricând datele cu caracter
          personal obligatorii și/sau să înserați/ștergeți date cu caracter personal opționale.
        </p>
        <p>
          3.3. Vă aducem la cunoștință că nu trebuie să încercați să creați cont cu număr de telefon
          sau adresă de e-mail care nu vă aparțin și/sau sunt de unică folosință. Nu trebuie să
          aplicați pentru mai mult de un cont de utilizator. Vă asumați răspunderea și nu veți
          furniza în niciun moment informații false, inexacte, incomplete sau derutante.
        </p>
        <p>
          3.4. Vă aducem la cunoștință că nu veți putea folosi Aplicația sau orice parte a
          funcționalității sale, inclusiv Serviciile, până când nu veți furniza informațiile
          solicitate conform cu acești Termeni de utilizare și/sau cu termenii și cu politicile
          interne ale Companiei.
        </p>
        <p>
          3.5. Vă rugăm să aveți în vedere că vom revizui procesul dumneavoastră de înregistrare cât
          mai curând posibil. Este alegerea exclusivă a Furnizorului dacă vă va înregistra și
          accepta ca unul dintre clienții acesteia în vederea utilizării serviciilor furnizate de
          către Companie. Dacă înregistrarea dumneavoastră este aprobată, veți fi notificat cât mai
          curând posibil.
        </p>
        <p>
          3.6. Vă aducem la cunoștință că veți putea utiliza Aplicația <AppName /> și Serviciile
          doar în scopuri personale. Acestea nu vor fi folosite pentru activitățile legate de
          afaceri și activități comerciale ilegale, tranzacții financiare ilegale sau pentru crearea
          unei aparențe de legalitate cu privire la venituri obținute ilegal și/sau pentru orice
          alte tranzacții efectuate cu încălcarea legilor aplicabile în țară dumneavoastră și a
          oricărei alte legi internaționale. În măsura în care conform legilor aplicabile sumele de
          bani ce fac obiectul Serviciilor utilizate de dumneavoastră devin subiectul unor obligații
          fiscale, aceste obligații fiscale și orice proceduri în legătură acestea cad întotdeauna
          în sarcina dumneavoastră.
        </p>
      </Section>
      <Section title="4. Comanda produselor și serviciilor">
        <p>
          4.1. Clientul își exprimă acordul de a intra într-o relație contractuală bilaterală cu noi
          - echipa <CompanyName />, prin plasarea unei comenzi online, care poate conține unul sau
          mai multe dintre produsele sau serviciile oferite în aplicația noastră.
        </p>
        <p>
          4.2. Orice persoană fizică (care a împlinit vârsta de 18 ani) sau juridică poate plasa o
          comandă din meniul aferent Contului de client, care va facilita interacțiunea cu noi și
          poate aduce beneficii (promoții exclusive, reduceri, bonusuri etc.).
        </p>
        <p>
          4.3. Adăugarea unui produs în coșul de cumpărături nu reprezintă rezervarea acestuia, iar
          o comandă va fi considerată validă și finalizată după parcurgerea tuturor etapelor din
          meniul Finalizare comandă și doar după confirmarea plății integrale a prețului produselor
          ori serviciilor pentru care ați optat. Prin finalizarea Comenzii, ne confirmați că toate
          datele pe care ni le-ați furnizat sunt corecte, complete și adevărate la data plasării
          Comenzii și recunoașteți în mod explicit că respectiva Comandă implică obligaţia
          dumneavoastră fermă de a achita &quot;suma totală de plată&quot;. Orice altă modalitate a
          procedurii de achiziție nu va conduce la încheierea unui contract valabil, corelativ, noi
          nu vom avea obligația să livrăm respectivul produs ori serviciu.
        </p>
        <p>
          4.4. Vă aducem la cunoștință faptul că nu putem estima timpul necesar finalizării și
          furnizării produselor și/sau serviciilor care necesită informații suplimentare din partea
          dumneavoastră sau care presupun o personalizare a produselor/serviciilor deja disponibile.
        </p>
        <p>
          4.5. Comanda va fi considerată acceptată de către noi cel mai târziu odată cu livrarea în
          format fizic sau furnizarea în format electronic a Produselor comandate, iar în cazul unei
          comenzi neacceptate dar plătite, îți vom returna sumele transferate. Notificarea primită
          prin e-mail după efectuarea Comenzii, cu privire la preluarea respectivei Comenzi, are rol
          de informare și nu reprezintă acceptarea Comenzii de către noi.
        </p>
        <p>
          4.6. Clientul se obligă să nu comercializeze/revândă sau să distribuie produsele cumpărate
          prin intermediul aplicației, Produsele fiind destinate uzului personal, iar pentru
          situația unei utilizări profesionale există posibilitatea de a prezenta Clientului o
          ofertă personalizată.
        </p>
        <p>
          4.7. Clientul este direct și complet răspunzător pentru încălcarea dispozițiilor prevăzute
          mai sus, iar în acest caz Vânzătorul își rezervă dreptul de a solicita Clientului
          diferența de sumă pentru fiecare Produs pe care l-a transmis mai departe indiferent că l-a
          modificat parțial sau nu, iar acest drept nu exclude posibilitatea Vânzătorului de a-și
          recupera integral prejudiciul suferit, prin orice modalitate recunoscută de lege, inclusiv
          prin contactarea respectivelor persoane unde regăsim documentele pentru a le solicita
          informații privind achiziția Produselor.
        </p>
        <p>
          4.8. Vânzătorul/Prestatorul va putea anula o Comandă plasată cu o notificare concomitentă
          sau ulterioară (o astfel de anulare nu atrage nicio răspundere din partea noastră) în
          următoarele cazuri:
        </p>
        <List>
          <li>
            tranzacția nu este acceptată de banca emitentă a cardului (banca, care a emis cardul nu
            acceptă Tranzacția, în cazul plății online);
          </li>
          <li>
            procesatorul de carduri cu care colaborează vânzătorul nu validează sau invalidează
            Tranzacția (de exemplu, fie pentru că nu sunt fonduri suficiente, fie din alte motive,
            conform politicii procesatorului);
          </li>
          <li>
            finalizarea Plății nu se realizează în termenul indicat prin factura proformă, în cazul
            plății prin transfer bancar/internet banking;
          </li>
          <li>
            datele pe care le furnizează Utilizatorul/Clientul cu ocazia accesării Platformei sunt
            incomplete sau incorecte;
          </li>
          <li>
            utilizatorul/clientul nu confirmă Comanda atunci când este contactat de către vânzător
            în acest scop;
          </li>
          <li>
            vânzătorul consideră în mod rezonabil că prin accesarea Platformei şi plasarea Comenzii
            utilizatorul/clientul urmărește un scop ilicit sau care poate cauza orice fel de
            prejudicii Vânzătorului/Companiei, Afiliaților sau Partenerilor acestuia;
          </li>
          <li>
            oricare dintre termenii şi condiţiile din prezentul document nu au fost respectate
            întocmai.
          </li>
        </List>
      </Section>
      <Section title="5. Încheierea contractului prin mijloace electronice și înregistrarea utilizatorului">
        <p>
          5.1. Contractul pentru utilizarea Aplicației <AppName quoted /> va fi încheiat între
          Utilizator și Furnizorul Serviciului, în cazul în care Utilizatorul a acceptat Condițiile
          Generale și s-a înregistrat în mod corespunzător în Aplicație, iar Furnizorul Serviciului
          a confirmat acest lucru într-un e-mail trimis la adresa de e-mail comunicată de
          Utilizator. Contractul este încheiat pe perioadă nedeterminată.
        </p>
        <p>
          5.2. Un singur Cont de Utilizator poate fi asociat cu o singură adresă de e-mail.
          Utilizatorii sunt răspunzători pentru introducerea unei adrese de e-mail personale,
          funcționale și valabile și pentru menținerea adresei de e-mail furnizate în timpul
          înregistrării pe întreaga perioadă de utilizare a Serviciului.
        </p>
        <p>
          5.3. Utilizatorii sunt obligați să păstreze întotdeauna confidențialitatea Numelui de
          Utilizator și a Parolei și să nu le pună la dispoziția unor terțe părți. Utilizatorii vor
          lua toate măsurile necesare pentru a asigura siguranța Numelui de Utilizator și a Parolei
          lor și pentru a preveni utilizarea frauduloasă a Conturilor lor de Utilizatori.
        </p>
        <p>
          5.4. Utilizatorul va asigura condițiile tehnice necesare funcționării aplicației.
          Furnizorul Serviciului nu va fi răspunzător pentru neîndeplinirea acestor condiții
          tehnice.
        </p>
        <p>
          5.5. Părțile se vor notifica reciproc în cazul apariției oricăror aspecte, date,
          circumstanțe sau informații ce sunt relevante pentru utilizarea Aplicației.
        </p>
        <p>
          5.6. Utilizatorul nu poate folosi Contul de Utilizator al altei persoane. În cazul în care
          Utilizatorul ia la cunoștință de orice utilizare neautorizată a Contului său de Utilizator
          sau încălcare a oricărei alte reguli de securitate aplicabile Contului de Utilizator,
          Utilizatorul va notifica în mod prompt în scris Furnizorul Serviciului.
        </p>
        <p>
          5.7. Utilizatorul își poate schimba oricând Parola prin intermediul Aplicației. Furnizorul
          Serviciului recomandă în mod expres ca Utilizatorul să își schimbe parola în mod regulat,
          să nu o comunice altei persoane și să nu o păstreze într-un loc accesibil.
        </p>
        <p>
          5.8. În cazul în care Utilizatorul uită Parola, acesta poate solicita resetarea parolei
          făcând click pe butonul &quot;Ai uitat parola?&quot; din interfața de autentificare în
          Contul de Utilizator prin introducerea adresei de e-mail înregistrată de acesta.
          Furnizorul Serviciului va trimite un e-mail unde Utilizatorul își poate seta o nouă Parolă
          la adresa de e-mail introdusă de Utilizator. În urma autentificării cu aceasta,
          Utilizatorul poate schimba parola generată cu o parolă personală unică.
        </p>
      </Section>
      <Section title="6. Prețul serviciilor și produselor, facturare și plată">
        <p>
          6.1. Prețurile Produselor afișate în Aplicație sau comunicate Clientului sunt exprimate în
          Lei și pot include sau nu T.V.A. conform legislației în vigoare, așa cum vor fi afișate pe
          site și sunt considerate prețuri valabile pentru Produsele afișate pe site sau comunicate
          Clientului în momentul finalizării unei Comenzi.
        </p>
        <p>
          6.2. Vânzătorul poate actualiza prețurile Produselor, iar o astfel de actualizare va
          înlocui orice prețuri afișate anterior pentru respectivele Produse.
        </p>
        <p>
          6.3. Prețurile nu includ cheltuielile de livrare decât dacă este specificat în mod expres
          acest aspect sau se oferă ca și bonus pentru comenzile mari.
        </p>
        <p>
          6.4. În cazul în care prețul nu este afișat, Clientul va avea posibilitatea de a cere o
          ofertă iar un operator va comunica prețul în cel mai scurt timp posibil.
        </p>
        <p>6.5. Prețul produsului comandat este cel afișat la data plasării comenzii.</p>
        <p>
          6.6. Prețul imprimat pe factură este același cu cel transmis de operatori în momentul
          confirmării comenzii.
        </p>
        <p>6.7. Modalitățile de plată sunt disponibile pe pagina de lansare a comenzii.</p>
        <p>
          6.8. Pe baza informațiilor deținute de Vânzător și a celor furnizate de Cumpărător va fi
          emisă o factură pentru bunurile livrate. Totodată, coletul va fi însoțit de documentația
          prevăzută de lege pentru produsul/produsele comandat(e), după caz: certificat/declarație
          de conformitate, certificat de garanție, instrucțiuni de utilizare etc.
        </p>
        <p>
          6.9. Vânzătorul va emite factura aferentă produselor ori serviciilor achiziționate pe baza
          datelor de identificare pe care Clientul le va comunica, iar factura va fi livrată odată
          cu produsele ori serviciile comandate, pe e-mail-ul Clientului sau în Contul de client,
          aceasta fiind valabilă în acest format și chiar fără o ștampilă sau semnătură din partea
          emitentului.
        </p>
        <p>
          6.10. Pentru plata cu cardul nu vor fi aplicabile taxe suplimentare, iar Vânzătorul nu
          este responsabil pentru niciun alt cost suportat de Client în plus față de preţul
          Produsului achiziţionat incluzând, dar fără a se limita la, comisioane de transfer bancar
          ori de conversie valutară aplicate de către banca emitentă a cardului Clientului, în cazul
          în care moneda de emitere a cardului diferă de moneda în care se efectuează vânzarea.
        </p>
        <p>
          6.11. Clientul va fi pe deplin răspunzător pentru orice fel de plată făcută din eroare,
          iar în aceste situații Vânzătorul va analiza situația și va decide punctual dacă se pot
          sau nu returna sumele de bani deja plătite, Clientul fiind singurul răspunzător pentru
          aceste plăți.
        </p>
      </Section>
      <Section title="7. Stocuri">
        <p>
          7.1. Produsele sunt disponibile în stoc, însă vânzătorul nu poate garanta disponibilitatea
          produselor postate.
        </p>
        <p>
          7.2. Clienții sunt informați corect, conform termenilor și condițiilor, de
          disponibilitatea produselor comandate înainte de confirmarea livrării și rareori pot
          apărea erori de comunicare în acest sens.
        </p>
      </Section>
      <Section title="8. Dreptul de retragere al Clientului în cazul comercializării produselor digitale/electronice">
        <p>
          8.1. În cazul comercializării de produse digitale/electronice, Clientul înțelege și
          acceptă faptul că Produsele și Serviciile comercializate în aplicația <AppName /> sunt
          exceptate de la dreptul de retragere din contract, în conformitate cu legislația în
          vigoare, fiind vorba despre furnizarea de conținut digital care nu este livrat pe un
          suport material, iar prestarea a început odată cu acordul prealabil expres al Clientului,
          iar sumele achitate pentru un produs, bun ori serviciu, de pe site-ul nostru nu vor putea
          fi restituite.
        </p>
        <p>
          8.2. Vânzătorul va putea decide, în cazuri excepționale și doar prin libera apreciere a
          acestuia rambursarea anumitor sume (totale sau parțiale) în funcție de anumite cazuri
          particulare, conform politicii noastre comerciale.
        </p>
      </Section>
      <Section title="9. Politica de retur">
        <p>
          <Emphasize variant="semibold">
            9.1. Conform O.U.G. nr. 34/2014 Clientul/Consumatorul are dreptul de a se retrage din
            prezentul contract în termen de 14 (paisprezece) zile, fără a preciza motivele și fără a
            suporta penalități.
          </Emphasize>
        </p>
        <p>
          <Emphasize variant="semibold">
            9.2. Perioada de retragere expiră după (paisprezece) 14 zile și se calculează începând
            cu ziua în care Clientul/Consumatorul sau o parte terță, alta decât transportatorul,
            indicată de către Client/Consumator, intră în posesia fizică a produselor.
          </Emphasize>
        </p>
        <p>
          9.3. Pentru a-și exercita dreptul de retragere, Clientul/Consumatorul trebuie să informeze
          Vânzătorul cu privire la decizia acestuia de a se retrage din prezentul contract,
          utilizând o declarație neechivocă, de exemplu, o scrisoare trimisă prin poștă sau e-mail.
          Datele de contact ale Vânzătorului sunt următoarele:
        </p>
        <pre className="px-8 whitespace-break-spaces">
          <span>
            Denumire socitate: <CompanyName />
          </span>
          <br />
          <span>
            Adresa: Localitatea <Emphasize variant="semibold">Craiova</Emphasize>, str.{' '}
            <Emphasize variant="semibold">Aleea Nicolae Iorga</Emphasize>, nr.{' '}
            <Emphasize variant="semibold">2</Emphasize>, bl.{' '}
            <Emphasize variant="semibold">A40</Emphasize>, sc.{' '}
            <Emphasize variant="semibold">1</Emphasize>, ap.{' '}
            <Emphasize variant="semibold">16</Emphasize>
          </span>
          <br />
          <span>
            Email: <Emphasize variant="semibold">contact@pescuiala.ro</Emphasize>
          </span>
          <br />
          <span>
            Telefon: <Emphasize variant="semibold">+40762568966</Emphasize>
          </span>
        </pre>
        <p>
          9.4. În acest scop, Clientul/Consumatorul poate folosi modelul de retragere atașat în
          Anexa nr. 1, însă folosirea lui nu este obligatorie.
        </p>
        <p>
          9.5. Pentru a respecta termenul limită de retragere este suficient să fie transmisă de
          către Client/Consumator comunicarea privind exercitarea dreptului de retragere înainte de
          expirarea perioadei de retragere.
        </p>
        <p>
          9.6. În cazul retragerii Clientului/Consumatorului, Vânzătorul va rambursa orice sumă pe
          care a primit-o de la Client/Consumator, cu excepția costurilor suplimentare determinate
          de alegerea de către Client/Consumator a unei alte modalități de livrare decât cel mai
          ieftin tip de livrare standard oferit de Vânzător, fără întârzieri nejustificate și, în
          orice caz, nu mai târziu de 14 (paisprezece) zile de la data la care Vânzătorul este
          informat cu privire la decizia Clientului/Consumatorului de a se retrage din prezentul
          contract.
        </p>
        <p>
          9.7. Costurile suplimentare reprezintă orice costuri (transport, livrare, diminuarea
          valorii produselor care rezultă din manipulări, altele decât cele necesare pentru
          determinarea naturii calităților și funcționării produselor, taxe poștale sau de orice
          altă natură) care urmează a fi suportate de Client/Consumator cu ocazia exercitării
          dreptului de retragere.
        </p>
        <p>
          9.8. Costurile aferente serviciului de curierat, dacă respectiva comandă nu a beneficiat
          de transport gratuit, nu sunt returnabile.
        </p>
        <p>
          9.9. Livrarea standard se consideră livrarea oferită gratuit de flota{' '}
          <Emphasize variant="semibold">FAN Courier</Emphasize> pentru comenzi mai mari de 200 Lei.
        </p>
        <p>
          9.10. Rambursarea va fi efectuată folosind aceeași modalitate de plată ca și cea folosită
          pentru tranzacția inițială, cu excepția cazului în care Clientul/Consumatorul și-a
          exprimat acordul expres pentru o altă modalitate de rambursare și nu vor fi percepute
          comisioane ca urmare a unei astfel de rambursări.
        </p>
        <p>
          9.11. Rambursarea poate fi amânată până la data la care Vânzătorul va primi înapoi
          produsele sau până la momentul la care Clientul/Consumatorul furnizează dovada că a trimis
          înapoi produsele, fiind valabilă data cea mai apropiată.
        </p>
        <p>
          9.12. Condițiile dreptului de retragere se aplică doar clienților/consumatorilor persoane
          fizice, definite de dispozițiile O.U.G. nr. 34/2014 (art. 2 pct.1).
        </p>
        <p>
          9.13. Clientul/Consumatorul va suporta costul direct al returnării produselor și este
          responsabil de integritatea produsului până la intrarea în posesia magazinului.
        </p>
        <p>
          9.14. Clientul/Consumatorul este direct responsabil pentru diminuarea valorii produselor
          care rezultă din manipulări, altele decât cele necesare pentru determinarea naturii,
          calităților și funcționării produselor.
        </p>
        <p>
          9.15. La solicitarea și cu acordul Clientului/Consumatorului, Vânzătorul poate recomanda
          Cumpărătorului achiziționarea altui produs de o calitate și la un preț echivalente cu cele
          solicitate în prima comandă.
        </p>
        <p>
          9.16. Comanda inițială va fi anulată la data confirmării comenzii produsului/produselor ce
          înlocuiesc produsul inițial.
        </p>
        <p>9.17. Nu pot fi returnate produsele care:</p>
        <List>
          <li>au făcut obiectul unei comenzi speciale;</li>
          <li>au fost distinct personalizate la cererea consumatorului;</li>
          <li>
            au fost confecționate după specificațiile Clientului/Consumatorului (de exemplu:
            nuanțarea produsului în culoarea dorită de Client/Consumator);
          </li>
          <li>prezintă urme de lovire, deteriorare, uzură;</li>
          <li>
            au fost desigilate și nu mai au ambalajul original intact, cu toate accesoriile și
            eticheta integre;
          </li>
          <li>sunt puse prin orice mijloc într-o stare necorespunzătoare repunerii în vânzare.</li>
          <li>produsele care se pot deteriora sau expiră rapid.</li>
          <li>
            produsele sigilate care nu pot fi returnate din motive de protecție a sănătății sau din
            motive de igienă și care au fost desigilate de cumpărător (exemplu: lenjerie intimă)
          </li>
          <li>produse care sunt inseparabil amestecate cu alte elemente </li>
        </List>
        <p>
          9.18. Vânzătorul va suporta cheltuielile de retur dacă acesta îi este imputabil (de
          exemplu, produsele primite nu corespund comenzii, sunt altele decât cele solicitate prin
          transmiterea acesteia etc.).
        </p>
        <p>
          9.19. Pentru alte informații legate de returul produselor vă rugăm sa ne contactați prin
          intermediul formularului de contact sau la adresa de e-mail: <Email />.
        </p>
        <p>
          9.20. Aceste prevederi se completează cu Legea nr. 365/2002 privind comerțul electronic
          republicată și modificată și cu O.U.G. nr. 34/2014 privind drepturile consumatorilor în
          cadrul contractelor încheiate cu profesioniștii.
        </p>
      </Section>
      <Section title="10. Program de lucru, livrări, termene">
        <p>
          10.1. O comandă poate fi plasată în orice moment, dar perioada de procesare este de Luni
          până Vineri în intervalul orar 10.00 - 20.00, exceptând sărbătorile legale.
        </p>
        <p>
          10.2. Produsele disponibile în aplicație/site/magazinul online pot fi livrate prin curier,
          în aria specificată.
        </p>
        <p>
          10.3. Termenul de livrare pentru produse este de 48 - 72 ore din momentul confirmării
          livrării.
        </p>
        <p>
          10.4. Livrările fizice se efectuează <Emphasize>door to door</Emphasize>, prin curierat
          rapid - serviciu externalizat deci independent de vânzător.
        </p>
        <p>
          10.5. Pot exista întârzieri față de termenul de livrare stabilit în momentul confirmării
          comenzii, iar în acest caz vânzătorul nu își asumă nicio răspundere în numele firmelor de
          curierat.
        </p>
        <p>
          10.6. Vânzătorul își asumă responsabilitatea în privința livrării produselor comandate de
          cumpărător. Riscul pieirii bunului se transferă la cumpărător în momentul predării
          efective a bunului.
        </p>
        <p>
          10.7. Livrarea Produselor sau Serviciilor digitale/electronice se va face exclusiv în
          format electronic prin furnizarea unui cod, link de descărcare sau altă metodă pe care o
          punem la dispoziție și pe care ți-o vom comunica pe e-mail/în Contul tău de Client.
          Livrarea se va face după efectuarea plății integrale a Produselor.
        </p>
      </Section>
      <Section title="11. Obligațiile Cumpărătorului">
        <List>
          <li>
            să furnizeze informații reale, corecte și actuale despre entitatea sa atunci când
            acestea i se cer, în caz contrar existând posibilitatea refuzării comenzilor;
          </li>
          <li>
            să accepte soluționarea eventualelor probleme în termen de 30 (treizeci) de zile de la
            data sesizării acestora printr-un e-mail adresat vânzătorului;
          </li>
          <li>
            să accepte Termenii și Condițiile vânzătorului odată cu lansarea comenzilor și să achite
            contravaloarea produselor și/sau serviciilor prin metodele puse la dispoziție, la
            momentul transmiterii comenzii.
          </li>
        </List>
      </Section>
      <Section title="12. Obligațiile Vânzătorului">
        <List>
          <li>
            <div className="mb-4">
              să pună la dispoziția utilizatorilor gratuit informații profesionale cu privire la
              produsele și serviciile sale.
            </div>
            <List variant="square">
              <li>
                Conținutul publicat în magazinul virtual are caracter orientativ. Caracteristicile
                produselor și documentația tehnică aferentă acestora și afișate pe site sunt puse la
                dispoziție de către producători și furnizori.
              </li>
              <li>
                Imaginile produselor sunt afișate cu titlu de prezentare, însă în funcție de lotul
                în care au fost fabricate, aspectul real al produsului poate diferi.
              </li>
            </List>
          </li>
          <li>să contacteze clienții pentru confirmarea comenzilor, prin e-mail sau telefonic;</li>
          <li>
            să livreze produsele în cel mult 30 (treizeci) de zile de la confirmarea livrării, dacă
            acestea sunt în stoc;
          </li>
          <li>
            să utilizeze datele personale colectate de la clienți/utilizatori în concordanță cu
            secțiunea „Politica de Confidențialitate” și „Politica cookies”, care se regăsesc de
            asemenea pe site-ul companiei vânzătorului.
          </li>
        </List>
      </Section>
      <Section title="13. Garanții">
        <p>
          13.1. În conformitate cu dispozițiile legale în vigoare, Clientul declară că înțelege și
          este de acord cu faptul că în cazul produselor electronice/digitale care presupun acces
          imediat nu va beneficia de garanție legală.
        </p>
        <p>
          13.2. Vânzătorul garantează că produsele livrate sunt originale, sigilate (în forma în
          care provin de la parteneri) și însoțite de factură fiscală, chitanță (eliberată de
          administrator/vânzător sau firma de curierat) și, după caz, certificat de garanție (pentru
          produsele care necesită garanție).
        </p>
        <p>
          13.3. Vânzătorul declară pe proprie răspundere conform prevederilor art. 5 din HG
          nr.1022/2002 privind regimul produselor care pot pune în pericol viața și sănătatea,
          securitatea muncii și protecția mediului că produsele livrate nu pun în pericol viața,
          sănătatea, securitatea muncii, nu produc impact negativ asupra mediului și sunt în
          conformitate cu: Directivele CE 67/549/CEE si 1999/45/CE (în condițiile respectării
          manipulării, transportului și depozitării conform Fișelor Tehnice și a Fișelor de
          Securitate emise de furnizor și aflate în original la sediul societății si pe site-ul{' '}
          <AppLink /> în secțiunea specifică produselor în cauză.
        </p>
        <p>
          13.4. Calitatea produselor comercializate este garantată pe perioada înscrisă pe ambalaj,
          în condițiile respectării prescripțiilor de depozitare și transport.
        </p>
        <p>
          13.5. Pentru orice reclamație sau sesizare vă rugăm să ne contactați la adresa de e-mail:{' '}
          <Email />.
        </p>
      </Section>
      <Section title="14. Răspunderea vânzătorului, limitări și excluderi">
        <p>
          14.1. Vânzătorul nu este răspunzător în situația în care Clientul furnizează informații
          sau date greșite ori incomplete, Clientul fiind singurul responsabil de acuratețea acestor
          informații, iar în acest sens Clientul declară că este de acord și înțelege faptul că o
          mare parte dintre funcțiile aplicației noastre sunt automatizate iar rezultatul final
          depinde exclusiv de informațiile pe care acesta le furnizează Vânzătorului sau le
          introduce în sistem.
        </p>
        <p>
          14.2. Limita răspunderii maxime a <CompanyName />, precum și a directorilor,
          administratorilor, angajaților, subcontractanților și afiliaților săi față de Clienți
          pentru daune de orice natură va fi suma maximă efectiv plătită de Client către{' '}
          <CompanyName />.
        </p>
        <p>14.3. Vânzătorul nu își asumă răspunderea pentru:</p>
        <List>
          <li>
            pierderile de date sau conținut, profiturile pierdute, întreruperea afacerii sau pentru
            orice daune indirecte, accidentale, speciale, consecvente, excepționale sau punitive
            care rezultă din sau sunt legate de materialele sau serviciile furnizate de{' '}
            <CompanyName />, chiar dacă Clientul a fost informat despre posibilitatea unor astfel de
            daune și fără a aduce atingere scopului esențial al oricărui remediu limitat;
          </li>
          <li>
            deciziile Clientului ca urmare a mesajelor pe care le promovează un partener sau un
            afiliat sau deciziile pe care Clientul le ia ca urmare a informațiilor pe care le
            regăsește în site, indiferent că sunt scrise sau nu de experți recunoscuți în domeniul
            lor;
          </li>
          <li>
            orice modificări pe care le va face cu privire la furnizarea serviciilor, inclusiv o
            întrerupere totală sau parțială a furnizării serviciilor (sau a anumitor funcții ale
            serviciilor);
          </li>
          <li>
            inexistența furnizării sau furnizarea incompletă de către Client a unor informații care
            să poată fi verificate;
          </li>
          <li>
            imposibilitatea Clientului de a menține în siguranță și confidențiale datele sale de
            logare (username, parolă sau alte credențiale).
          </li>
          <li>
            afectarea vreunui dispozitiv sau conținut digital care aparține Clientului cauzată de
            lipsa unei implementări corespunzătoare a măsurilor de siguranță necesare, prin
            accesarea materialelor și informațiilor pe care Vânzătorul le livrează sub forma unui
            conținut digital sau de orice alt tip.
          </li>
          <li>
            orice alte costuri suplimentare se vor aplica Clientului, inclusiv costuri de telefonie
            mobilă (cum ar fi costuri cu internetul mobil) sau costuri cu internetul din locația de
            unde accesează site-ul sau alte costuri conexe.
          </li>
          <li>
            pentru orice daune directe sau indirecte, pierderi de profit sau consecințe rezultând
            din utilizarea Serviciului, cu excepția daunelor aduse vieții, integrității corporale și
            sănătății și daunelor cauzate din vina Furnizorului Serviciului;
          </li>
          <li>
            pentru daunele care pot fi atribuite unor circumstanțe aflate în afara controlului său,
            dacă apariția acestora daune nu a putut fi prevăzută la momentul încheierii
            contractului;
          </li>
          <li>
            pentru dificultăți tehnice, precum, dar fără a se limita la, orice indisponibilitate a
            conexiunii la internet sau a rețelei GPS;
          </li>
          <li>
            pentru situațiile în care Aplicația și/sau serverul care rulează Aplicația este vizat(ă)
            de un atac extern sau în cazul în care Utilizatorul primește un mesaj de sistem fals ca
            urmare a unui astfel de atac;
          </li>
          <li>
            în situația în care utilizatorul dezvăluie elementele de identificare / acces în
            Aplicație că urmare a unor atacuri de tip ”phishing” sau viruși informatici pe
            echipamentele/dispozitivele acestuia;
          </li>
          <li>
            pentru orice daune rezultând din utilizarea Aplicației, operarea necorespunzătoare a
            acesteia sau ștergerea întregii Aplicații sau a oricărei părți din Aplicație;
          </li>
          <li>
            pentru orice pierdere de date rezultând din utilizarea sau operarea necorespunzătoare a
            Aplicației, defecte, eventuale defecțiuni, neînțelegeri, eventuale defecțiuni ale
            rețelei de internet, indisponibilitatea căii de acces sau din orice alte defecțiuni
            tehnice sau pentru daune și/sau pierderi rezultând din infectarea cu viruși sau alte
            programe dăunătoare;
          </li>
          <li>
            pentru orice daune rezultând din utilizarea Aplicației, operarea necorespunzătoare a
            acesteia sau ștergerea întregii Aplicații sau a oricărei părți din Aplicație.
          </li>
        </List>
        <p>
          14.4. În cazul conduitei ilicite a Utilizatorului, Furnizorul Serviciului va colabora pe
          deplin cu autoritățile relevante pentru investigarea încălcării legii.
        </p>
      </Section>
      <Section title="15. Acceptarea condițiilor și răspunderea utilizatorului sau clientului">
        <p>
          <Emphasize variant="semibold">
            15.1 Utilizatorul sau Clientul declară că înțelege și este de acord că este interzisă
            utilizarea aplicației <AppName /> în următoarele modalități sau pentru următoarele
            scopuri:
          </Emphasize>
        </p>
        <List>
          <li>
            dacă folosește Aplicația de o manieră care nu respectă pe deplin cerințele privind
            utilizarea corespunzătoare, precum și standardele și cerințele legale, Condițiile
            Generale și notificările publicate de Furnizorul Serviciului aplicabile în orice moment.
            Utilizatorul va fi răspunzător pentru orice daune care rezultă din utilizarea
            necorespunzătoare.
          </li>
          <li>
            cu încălcarea, în orice modalitate, a prevederilor legale aplicabile sau prin modalități
            care pot conduce la încălcarea, în orice modalitate, a prevederilor legale aplicabile;
          </li>
          <li>
            pentru a disemina informații false sau greșite ori pentru a propaga dezinformare,
            incitare la ură sau alte asemenea;
          </li>
          <li>
            în orice modalitate prin care se acționează în contul și pe seama altei persoane, în
            special prin utilizarea de nume false, adrese de email false, numere de telefon false
            etc.
          </li>
          <li>
            pentru defăimarea altor persoane, pentru a aduce injurii, acuze sau afirmații jignitoare
            cu privire la oricare altă persoană;
          </li>
          <li>pentru promovarea sau ascunderea activităților cu caracter ilegal sau imoral;</li>
          <li>
            pentru a reproduce, în orice fel, interfața aplicației, în vederea inducerii în eroare a
            utilizatorilor, clienților sau potențialilor clienți pe care noi îi avem;
          </li>
          <li>
            pentru a obține acces neautorizat la datele pe care vizitatorii sau clienții noștri ni
            le-au furnizat în mod voluntar;
          </li>
          <li>pentru a introduce în sistem programe sau linii de cod malițioase;</li>
          <li>
            pentru a solicita informații, produse ori servicii ilegale ori pentru a solicita
            informații care să acopere o activitate ilegală;
          </li>
          <li>
            pentru a obține acces la diferite secțiuni sau subsecțiuni ale site-ului sau la
            produsele ori serviciile oferite de către noi utilizând proceduri ilegale;
          </li>
          <li>
            pentru a obține produse ori servicii în scopul revânzării (cu excepția Politicilor
            privind prelucrarea datelor cu caracter personal generate prin intermediul TOOL-ului).
          </li>
          <li>
            pentru nerespectarea confidențialității Numelui său de Utilizator și Parolei sale, a
            prevederilor privind notificările și a veridicității datelor furnizate de acesta;
          </li>
          <li>
            pentru orice eveniment, activitate care a avut loc în urma utilizării parolei sale;
          </li>
          <li>
            pentru orice defecțiune, prejudiciu cauzat de infectarea cu un virus sau orice pierdere
            de date pe telefonul mobil al Utilizatorului și de asemenea pentru orice accesare
            neautorizată a telefonului mobil al Utilizatorului sau pentru orice alte prejudicii
            atribuibile unei terțe părți sau în legătură cu un eveniment de forță majoră.
          </li>
        </List>
        <p>
          15.2 Utilizatorul nu poate închiria sau transfera Aplicația sau orice parte din aceasta și
          nu o poate distribui ulterior în orice alt mod. Utilizatorul nu va realiza și nu va ajuta
          alte persoane să modifice, transforme sau traducă Aplicația, incluzând orice modificare
          adusă unui software, aplicațiilor și bazelor de date din cadrul Aplicației. Utilizatorul
          nu va realiza și nu va ajuta alte persoane să îndepărteze sau să modifice orice comunicare
          a dreptului de autor, mărcii comerciale sau brevetului care apare în Aplicație sau care
          face ca sursa sa să fie necunoscută. Utilizatorul nu va realiza și nu va ajuta alte
          persoane să utilizeze Aplicația în mod ilegal sau în alte scopuri ilicite incompatibile cu
          prezentele Condiții Generale. Utilizatorul acceptă faptul că nu va modifica, prelucra sau
          duplica software-ul sau codul său sursă.
        </p>
        <p>
          15.3. Furnizorul Serviciului exclude răspunderea sa privind cererile de despăgubire
          formulate de utilizatori referitoare la utilizarea unui software sau a unei aplicații
          operate de părți externe (ex.: IOS, Android, Apple App Store, Google Play, etc.), precum
          și funcționarea defectuoasă a aplicației.
        </p>
      </Section>
      <Section title="16. Sesizări și reclamații ">
        <p>
          16.1. Utilizatorii și/sau Clienții aplicației <AppName /> ne pot contacta în general la
          adresa de e-mail sau prin formularul din secțiunea &quot;Contact&quot; pentru orice
          nelămuriri, probleme, sesizări sau reclamații în legătură cu Produsele sau Serviciile,
          inclusiv pentru a identifica și corecta eventuale erori care au loc cu ocazia introducerii
          datelor.
        </p>
        <p>
          16.2. Sesizările astfel recepţionate vor fi soluționate de noi în maxim 30 (treizeci) de
          zile calendaristice de la primirea lor.
        </p>
      </Section>
      <Section title="17. Alte mențiuni privind utilizarea Serviciului">
        <p>
          17.1. Furnizorul Serviciului poate refuza să furnizeze Serviciul și poate întrerupe
          furnizarea Serviciului către Utilizator cu efect imediat în cazul în care acest lucru este
          impus de lege sau de o hotărâre judecătorească sau în cazul în care Serviciul este folosit
          cu nerespectarea Condițiilor Generale, fără autorizație, sau în mod ilegal sau
          necorespunzător în ceea ce privește scopul Aplicației.
        </p>
        <p>
          17.2. Furnizorul Serviciului va informa în timpul util în avans Utilizatorul pe cale
          electronică sau de o manieră considerată a fi adecvată de către Furnizorul Serviciului cu
          privire la motivul și durata oricărei întreruperi a funcționării Aplicației.
        </p>
      </Section>
      <Section title="18. Încetarea contractului, reziliere și suspendare">
        <p>
          18.1. Utilizatorul poate solicita ștergerea Contului său de Utilizator și astfel încetarea
          contractului, în scris, fără a fi necesară furnizarea unor motive. Contractul va înceta
          odată cu ștergerea Contului de Utilizator. Dezinstalarea aplicației de pe dispozitivul
          mobil al Utilizatorului nu presupune și ștergerea Contului de Utilizator.
        </p>
        <p>
          18.2. Acțiunea de ștergere a contului este ireversibilă și în cazul în care doriți să
          utilizați în continuare aplicația mobilă <AppName /> trebuie să va creați un cont nou. Va
          rugăm să aveți în vedere că datele cu caracter personal, ce au parvenit <CompanyName /> ca
          urmare a utilizării Aplicației și a Serviciilor de către dumneavoastră vor fi stocate de
          Companie și după închiderea contului de către dumneavoastră, în vederea respectării
          perioadelor de stocare impuse de legislația aplicabilă.
        </p>
        <p>
          18.3. Cealaltă parte va fi notificată electronic cu privire la încetare prin intermediul
          Aplicației prin utilizarea butonului &quot;Ștergere cont&quot; sau printr-o sesizare
          făcută printr-un e-mail sau prin scrisoare recomandată cu confirmare de primire.
        </p>
        <p>
          18.4. Furnizorul Serviciului poate înceta Contractul în scris în urma comunicării unei
          notificări cu 30 de zile în prealabil, fără a fi necesară prezentarea unor motive.
          Contractul va înceta la data expirării perioadei de preaviz.
        </p>
        <p>
          18.5. În cazul în care Utilizatorul solicită ca Furnizorul Serviciului să îi șteargă
          datele, contractul va înceta la data primirii cererii de ștergere a datelor.
        </p>
        <p>
          18.6. Notificările privind încetarea transmise prin e-mail conform prezentei secțiuni vor
          fi supuse prevederilor secțiunii &quot;Notificări prin mijloace electronice&quot;.
        </p>
        <p>
          18.7. Ne rezervăm dreptul că, în orice moment, în mod unilateral să suspendăm sau să
          restricționăm accesul dumneavoastră la Aplicație și Servicii precum și să reziliem
          Termenii de utilizare, cu efect imediat sau să refuzăm să vă reactivăm accesul, fără
          obligația de a plăti daune (în continuare: reziliere imediată), dacă:
        </p>
        <List>
          <li>
            ați încălcat acești Termeni de Utilizare într-un mod grav sau repetat și dacă nu ați
            remediat situația într-un interval rezonabil de timp din momentul în care v-am notificat
            să faceți acest lucru (atunci când remedierea este posibilă);
          </li>
          <li>
            avem motive rezonabile să suspectăm că aveți sau ați putea avea un comportament
            fraudulos, inclusiv dacă suspectăm că mai multe conturi aparțin aceleiași persoane;
          </li>
          <li>
            nu ne-ați furnizat orice informații de care avem nevoie pentru funcționarea Serviciilor,
            sau dacă avem motive rezonabile să credem că informațiile pe care le-ați oferit sunt
            incorecte sau neadevărate;
          </li>
          <li>
            acționați într-o manieră care este amenințătoare sau abuzivă față de personalul nostru
            sau față de oricare dintre reprezentanții noștri, iar acest fapt poate fi considerat
            orice afirmație sau conduită/faptă a Utilizatorului care aduce prejudicii bunei
            reputații sau integrității profesionale a Furnizorului Serviciului sau pentru
            software-ul, sistemele sau hardware-ul nostru;
          </li>
          <li>
            dacă trebuie să facem acest lucru în temeiul oricărei legi, reglementări, hotărâri
            judecătorești sau decizii primite de la o autoritate competență.
          </li>
        </List>
        <p>
          18.8. În cazul rezilierii imediate, Furnizorul Serviciului își rezervă dreptul de a
          exercita drepturile care îi revin din nerespectarea contractului, inclusiv dreptul la
          despăgubiri.
        </p>
        <p>
          18.9. Furnizorul Serviciului va notifica Utilizatorul vizat cu privire la rezilierea
          imediată în scris, prin e-mail sau scrisoare recomandată cu confirmare de primire, dacă
          informarea dumneavoastră nu va compromite măsurile rezonabile de securitate sau ar fi în
          alt fel ilegală. Notificarea și comunicarea menționată în prezenta secțiune va include
          numele Utilizatorului vizat, motivul rezilierii, prevederile Condițiilor Generale
          nerespectate de către Utilizator, o descriere scurtă a neconformității și data încetării
          contractului.
        </p>
      </Section>
      <Section title="19. Dreptul de proprietate intelectuală">
        <p>
          19.1. Toate drepturile asupra informațiilor furnizate prin intermediul Aplicației sunt
          proprietatea Companiei <AppName />. Acestea includ, fără limitare: date, texte, surse
          tehnice de pagini de internet, știri, grafică, desene, fotografii, ilustrații, imagini,
          sigle, servicii, API-uri și orice alte mențiuni. Conținutul Aplicației nu poate fi
          reprodus, copiat, tradus sau modificat în niciun fel, parțial sau în întregime, fără
          permisiunea noastră scrisă prealabilă.
        </p>
        <p>
          19.2. Vă aducem la cunoștință că manipularea tehnologiei informației sau reproducerea
          Aplicației prin inginerie inversă sau modelare inversă sau în orice alt mod este
          interzisă. Orice nerespectare a acestei interdicții va fi considerată a fi o încălcare a
          contractului, iar Utilizatorul în cauză va fi obligat să plătească toate daunele rezultând
          din această nerespectare.
        </p>
        <p>
          19.3. Aplicația și orice software care stă la baza acesteia sunt protejate prin Legea nr.
          8/1996 privind Dreptul de Autor și drepturile conexe și prin alte dispoziții legale
          privind utilizarea proprietății intelectuale. Utilizatorul acceptă faptul că, prin
          descărcarea aplicației, acestuia i se acordă un drept limitat, neexclusiv și
          netransferabil de utilizare fără niciun drept de proprietate. Furnizorul Serviciului
          deține orice drept privind Aplicația și software-ul – în special, dar nelimitându-se la
          permiterea realizării oricărei schimbări, actualizarea, distribuirea, reproducerea,
          documentarea, modificarea și utilizarea ulterioară, precum și drepturile de publicare și
          orice alte eventuale drepturi. Structura, designul și codul Aplicației sunt considerate a
          fi secret profesional și comercial, precum și informații confidențiale aparținând
          Furnizorului Serviciului. Aplicația poate fi folosită numai în conformitate cu Condițiile
          Generale.
        </p>
        <p>
          19.4. Prezentele dispoziții nu conferă niciun drept de utilizare a mărcilor comerciale
          folosite în cadrul Aplicației.
        </p>
        <p>
          19.5. Furnizorul Serviciului este de acord cu descărcarea de către Utilizator a Aplicației
          pe dispozitivul său mobil în mod gratuit și, astfel, cu acordarea dreptului unic, limitat
          și neexclusiv de utilizare a Aplicației conform dispozițiilor de mai sus. Pentru
          utilizarea în scop comercial, permisiunea prealabilă scrisă a Companiei este necesară.
          Utilizatorul este obligat să respecte fiecare drept de autor și celelalte drepturi privind
          Conținutul. Utilizatorul trebuie să aibă în vedere consecințele nerespectării dreptului de
          autor, astfel cum acestea sunt prevăzute de legislația în vigoare.
        </p>
        <p>
          19.6. În situația în care Utilizatorul acționează de o manieră care să rezulte în
          manipularea sau descărcarea în masă a Aplicației sau care este incompatibilă în orice alt
          mod cu destinația Aplicației, nu respectă sau există suspiciuni întemeiate că nu respectă
          această destinație, Furnizorul Serviciului își rezervă dreptul de a exclude Utilizatorul
          din utilizatorii Aplicației.
        </p>
        <p>
          19.7. Toate drepturile de proprietate intelectuală (care includ, fără limitare, drepturile
          de autor și drepturile asupra codului sursă și bazelor de date) privind marca, mărcile
          comerciale, siglele și toate materialele din cadrul Aplicației sunt proprietatea noastră
          sau utilizate de noi conform unei licențe sau cu permisiunea partenerilor noștri.
        </p>
        <p>
          19.8. Nimic din conținutul Aplicației nu trebuie interpretat că acordând implicit sau în
          alt mod nicio licență sau niciun drept de a utiliza nicio marca comercială afișată în
          cadrul Aplicației fără permisiunea noastră scrisă sau a partenerilor noștri, care ar putea
          deține marca comercială.
        </p>
        <p>
          19.9. În situația în care acești termeni vor fi încălcați, Compania va apela la oricare și
          toate mijloacele legale necesare și aplicabile pentru recuperarea prejudiciilor, iar
          pentru protecția noastră putem bloca accesul oricărui utilizator în cadrul Aplicației.
        </p>
      </Section>
      <Section title="20. Activitatea ilegală">
        <p>
          20.1. Vă aducem la cunoștință că aveți obligația să depuneți toate eforturile rezonabile
          pentru a preveni sau împiedică folosirea Aplicației și a Serviciilor pentru acte sau fapte
          de comerț, tranzacții financiare ilegale sau legalizarea unor venituri ilegale.
        </p>
        <p>
          20.2. Vă aducem la cunoștință că este interzisă utilizarea Aplicației și a Serviciilor în
          scopul legalizării veniturilor ilegale, a activităților ilegale, a comerțului ilegal, în
          scopuri de afaceri și pentru orice alte tranzacții efectuate cu încălcarea legilor
          aplicabile în țară dumneavoastră și în Europa.
        </p>
      </Section>
      <Section title="21. Securitate">
        <p>
          21.1. Vă informăm că este obligația și responsabilitatea dumneavoastră să mențineți
          confidențiale și în siguranța datele de identificare ale dumneavoastră în cadrul
          Aplicației, precum și informațiile privind securitatea și orice date legate de cardul
          dumneavoastră și să nu le dezvăluiți niciodată nimănui.
        </p>
        <p>
          21.2. Dacă o altă persoană obține acces la informațiile dumneavoastră, la aplicație sau la
          dispozitivul dvs. prin trecerea de toate verificările de identificare și validare de
          securitate, noi vom fi îndreptățiți să tratăm orice tranzacție și alte tranzacții
          efectuate de acea persoană drept valabile și autorizate de dumneavoastră și nu vom putea
          fi ținuți răspunzători pentru nici o pierdere sau daună care ar putea fi calculată că
          rezultat al acestora.
        </p>
      </Section>
      <Section title="22. Datele cu caracter personal/Politica de confidențialitate">
        <p>
          22.1. Prin utilizarea Platformei, Utilizatorul înțelege şi acceptă să transmită
          Furnizorului date cu caracter personal, aceste date urmând să facă obiectul prelucrării în
          conformitate cu şi pentru scopurile prevăzute în cuprinsul Politicii de Confidențialitate,
          care completează aceşti Termeni și Condiții.
        </p>
        <p>
          22.2. Informarea privind Confidențialitatea cuprinde reguli detaliate privind prelucrarea
          datelor cu caracter personal ale Utilizatorului. Informarea privind Confidențialitatea
          este disponibilă aici:{' '}
          <Emphasize variant="link">
            <NavLink to="/politica-de-confidentialitate" target="_blank">
              pescuiala.ro/politica-de-confidentialitate
            </NavLink>
          </Emphasize>
          .
        </p>
        <p>
          22.3. Furnizorul Serviciului va prelucra datele puse la dispoziție de Utilizatori în
          conformitate cu dispozițiile legale aplicabile ale Uniunii Europene, mai exact conform
          Regulamentului (UE) 2016/679 al Parlamentului European și al Consiliului din 27 aprilie
          2016, privind protecția persoanelor fizice în ceea ce privește prelucrarea datelor cu
          caracter personal și privind libera circulație a acestor date și de abrogare a Directivei
          95/46/CE (Regulamentul general privind protecția datelor – GDPR), cu Legea 190/2018
          privind măsuri de punere în aplicare a GDPR și/sau cu orice alte decizii pe care le poate
          emite Autoritatea Națională de Supraveghere a Protecției Datelor cu Caracter Personal
          (ANSPDCP) în calitate de autoritate de supraveghere privind protecția datelor cu caracter
          personal.
        </p>
        <p>
          22.4. Vă puteți șterge oricând contul dumneavoastră, ceea ce va termină acordul dintre
          dumneavoastră și noi și va duce la imposibilitatea utilizării Serviciilor.
        </p>
        <p>
          22.5. Pentru ștergerea datelor cu caracter personal aferente contului de utilizator,
          puteți accesa oricând butonul de ștergere a profilului, parcurgând următorii pași: Cont
          &gt; Detalii &gt; Editare &gt; Ștergere. Urmare a ștergerii contului de utilizator, datele
          cu caracter personal asociate contului vor fi șterse, iar pentru accesarea serviciilor{' '}
          <AppName /> va fi necesară crearea unui nou cont. Datele cu caracter personal cuprinse în
          documentele emise că urmare a prestării Serviciilor vor fi păstrate pentru perioadele
          impuse de dispozițiile legale aplicabile.
        </p>
      </Section>
      <Section title="23. Forța majoră">
        <p>
          23.1. Nici Furnizorul și nici Utilizatorul nu răspund de neexecutarea la termen sau/și de
          executarea în mod necorespunzător, total sau parțial, a oricărei obligații care îi revine
          în baza prezentului contract, dacă neexecutarea sau executarea necorespunzătoare a
          obligației respective a fost cauzată de forță majoră, așa cum este definită de lege.
        </p>
        <p>
          23.2. Partea care invocă forța majoră este obligată să notifice celeilalte părți, în
          termen de 15 (cincisprezece) zile calendaristice, producerea evenimentului și să ia toate
          măsurile posibile în vederea limitării consecințelor lui.
        </p>
        <p>
          23.3. Dacă în termen de 15 (cincisprezece) zile calendaristice de la producere,
          evenimentul respectiv nu încetează, părțile au dreptul să-și notifice încetarea de plin
          drept a prezentului contract, fără ca vreuna dintre ele să pretindă daune-interese.
        </p>
      </Section>
      <Section title="24. Notificări">
        <p>
          24.1. Utilizatorul este de acord ca toate comunicările efectuate în baza prezentului
          document să aibă loc prin intermediul poștei electronice la adresa comunicată de acesta în
          site, consimțind că o astfel de comunicare este validă prin simpla dovadă din partea
          Furnizorului privind trimiterea comunicării. Furnizorul are dreptul să folosească și alte
          metode de transmitere (prin poștă, curier sau prin intermediul executorilor judecătorești)
          a comunicărilor sale către Utilizator.
        </p>
        <p>
          24.2.{' '}
          <Emphasize variant="semibold">
            Notificare prin scrisoare recomandată cu confirmare de primire.
          </Emphasize>{' '}
          În cazul în care prezentul contract prevede comunicarea prin scrisoare recomandată cu
          confirmare de primire a oricărei declarații legale, iar Destinatarul nu primește
          scrisoarea recomandată trimisă la adresa de corespondență specificată în prezentul
          contract sau în absența acestuia, atunci când acesta nu se află la sediul său social, din
          orice motive, se va încerca livrarea scrisorii din nou. În cazul în care această încercare
          de livrare nu este realizată, se va considera că scrisoarea a fost livrată la 5 (cinci)
          zile de la data repetării livrării. Părțile convin că toate comunicările transmise prin
          scrisoare recomandată cu confirmare de primire se vor efectua la următoarele adrese:
        </p>
        <List>
          <li>
            Pentru <Emphasize>Utilizator</Emphasize> – la adresa cu care și-a creat contul în
            aplicație.
          </li>
          <li>
            Pentru <Emphasize>Furnizor</Emphasize> – la adresa:{' '}
            <Emphasize variant="semibold">
              Aleea Nicolae Iorga, nr. 2, bl. A40, sc. 1, ap. 16
            </Emphasize>
            .
          </li>
        </List>
        <p>24.3. Notificări prin mijloace electronice</p>
        <List>
          <li>
            Utilizatorii și Furnizorul Serviciului se vor notifica reciproc cu privire la informații
            referitoare la executarea contractului prin mijloace electronice, utilizând adresa de
            e-mail a Furnizorului Serviciului și adresa de e-mail comunicată de Utilizator.
          </li>
          <li>
            În vederea confirmării, notificările trimise prin e-mail la și de la adresele de e-mail
            de mai sus/online vor fi considerate a fi comunicate în termen de o zi de la data
            trimiterii.
          </li>
          <li>
            Notificările și confirmările trimise prin e-mail conform contractului vor ajunge la
            Utilizatori și Furnizorul Serviciului fără semnătură oficială sau legală și date de
            identificare care pot înlocui aceste semnături, Părțile confirmând acest lucru și
            considerându-l a fi acceptabil, cu excepția cazului în care se demonstrează contrariul.
          </li>
          <li>
            În ceea ce privește e-mail-urile generate conform dispozițiilor prevăzute în contract,
            Părțile nu pot pretinde în instanță sau în fața oricărei altei autorități că acestea nu
            respectă cerințele unui document în numele Furnizorului Serviciului sau Utilizatorului,
            cu excepția cazului în care se poate dovedi că e-mailul a fost utilizat în mod fraudulos
            sau în alt scop ilicit.
          </li>
          <li>
            Părțile sunt de acord că corespondența trimisă electronic (e-mail) este considerată a fi
            forma oficială de păstrarea legăturii numai în cazurile în care și în măsura în care
            acest lucru este permis în mod expres în prezentul contract.
          </li>
        </List>
        <p>
          Părțile convin că toate comunicările transmise în mod electronic se vor efectua la
          următoarele adrese:
        </p>
        <List>
          <li>
            Pentru <Emphasize>Utilizator</Emphasize>– la adresa de e-mail cu care și-a creat contul
            în aplicație.
          </li>
          <li>
            Pentru <Emphasize>Furnizor</Emphasize> – la adresa: <Email />.
          </li>
        </List>
      </Section>
      <Section title="25. Legea aplicabilă și soluționarea litigiilor">
        <p>
          25.1. Prezentul document reprezintă un contract legal încheiat la distanță, acceptat prin
          simpla lui bifare și este supus legii române.
        </p>
        <p>
          25.2. În cazul în care nu este posibilă rezolvarea litigiilor pe cale amiabilă, părțile se
          vor adresa instanțelor judecătorești competente conform legii.
        </p>
      </Section>
      <Section title="26. Clauze specifice">
        <p>
          26.1. Obligația noastră nu este o obligație de rezultat, ci una de diligență, ceea ce
          înseamnă că vom face tot ce putem ca să livrăm Serviciile, însă nu putem garanta
          executarea acestora, iar Clientul declară că este de acord în mod expres cu acest lucru.
        </p>
        <p>26.2. Limba în care se încheie contractul este limba română.</p>
      </Section>
      <Section title="27. Gestionarea reclamațiilor, observații și alte informații">
        <p>
          27.1. Utilizatorul poate contacta Furnizorul Serviciului pentru reclamații, în scris,
          utilizând adresa de e-mail <Email /> sau adresa poștală{' '}
          <Emphasize variant="semibold">
            Aleea Nicolae Iorga, nr. 2, bl. A40, sc. 1, ap. 16, Municipiul Craiova, Jud. Dolj,
            România
          </Emphasize>
          .
        </p>
        <p>
          27.2. După primirea cererii, Compania va reveni către Utilizator cu un răspuns în mod
          gratuit și fără întârzieri nejustificate, și în termen de cel mult 30 zile de la primirea
          cererii. Furnizorul Serviciului este obligat să prezinte motivele care au stat la baza
          deciziei de a respinge reclamația.
        </p>
        <p>
          27.3. Această perioadă poate fi prelungită cu două luni atunci când este necesar,
          ținându-se seama de complexitatea și numărul cererilor. Compania va informa Utilizatorul
          cu privire la orice astfel de prelungire, în termen de o lună de la primirea cererii,
          prezentând și motivele întârzierii.
        </p>
        <p>
          27.4. Orice comentarii, întrebări, feedback, idei, sugestii sau alte comunicări sau
          informații despre sau referitoare la aplicația <AppName />, funcționalitatea sau
          îmbunătățirea acesteia vor rămâne proprietatea <CompanyName />.
        </p>
      </Section>
      <Section title="28. Modificări/completări/actualizări">
        <p>
          28.1.Ne rezervăm dreptul de a modifica conținutul și/sau structura Aplicației în funcție
          de necesitățile tehnologice și pentru îmbunătățirea Serviciilor prestate utilizatorilor în
          orice moment. În funcție de tipul modificărilor realizate, este posibil să nu puteți
          accesa Serviciile disponibile în cadrul Aplicației până când nu descărcați ultima versiune
          a acesteia și acceptați termenii de utilizare actualizați dacă este cazul.
        </p>
        <p>
          28.2. După dată intrării în vigoare a oricăror modificări privind Termenii de utilizare,
          absența unui refuz formulat de către dumneavoastră și continuarea utilizării Aplicației și
          a Serviciilor vor constitui acceptarea dumneavoastră a unor astfel de amendamente.
        </p>
        <p>
          28.3. Dacă nu veți fi de acord cu modificările notificate, ne veți putea informa în acest
          sens în decursul perioadei de timp până la intrarea în vigoare a modificărilor anunțate.
          Vom trata comunicarea dumneavoastră că pe o notificare de încetare a acestor Termeni de
          utilizare. În aceste circumstanțe, vom restricționa accesul dumneavoastră la Aplicație și
          la Serviciile aferente.
        </p>
      </Section>
      <Section title="29. Alte prevederi">
        <p>
          29.1. Obligația noastră nu este o obligație de rezultat, ci una de diligență, ceea ce
          înseamnă că vom face tot ce putem ca să livrăm Produsele sau Serviciile, însă nu putem
          garanta executarea acestora, iar Clientul declară că este de acord în mod expres cu acest
          lucru.
        </p>
        <p>
          29.2. Vânzătorul/Prestatorul nu își asumă răspunderea pentru accesarea link-urilor pe care
          le găsește în site și nu garantează că platforma nu are bug-uri sau viruși și nu este
          răspunzător pentru eventualele întreruperi temporare, parțiale sau totale ale platformei,
          iar acest lucru nu dă dreptul la o compensație pentru Client/Consumator
        </p>
        <p>
          29.3 Acești Termeni de utilizare sunt personali pentru dumneavoastră și nu puteți cesiona
          sau transferă în alt fel drepturile și obligațiile dumneavoastră din cadrul acestora.
          Putem cesiona sau transferă avantajele sau obligațiile acestor Termeni de utilizare către
          o altă entitate în orice moment, cu o notificare rezonabilă în avans. Dacă vom proceda
          astfel, drepturile dumneavoastră nu vor fi afectate.
        </p>
        <p>
          29.4. Acești Termeni de utilizare sunt supuși amendării, modificării sau ștergerii dacă se
          solicită sau se constată că sunt în contradicție cu legea aplicabilă sau cu un regulament
          aplicabil, fără a afecta valabilitatea sau aplicabilitatea Termenilor de utilizare rămași
          valabili.
        </p>
        <p>
          29.5. Neexercitarea sau neaplicarea oricărui drept din cadrul acestor Termeni de utilizare
          nu va fi considerată o renunțare la vreun drept și nu va funcționa ca un obstacol în calea
          exercitării sau aplicării viitoare a respectivului drept în orice moment.
        </p>
        <p>
          29.6. Putem angaja serviciile unuia sau mai multor afiliați, subsidiare, agenți sau
          sub-contractanți pentru a ne îndeplini obligațiile.
        </p>
        <p>
          29.7. Părțile sunt de acord că toate aspectele reglementate în prezentul contract –
          incluzând valabilitatea contractului, precum și prevederile contractuale, declarațiile,
          promisiunile și obligațiile – vor fi stabilite prin aplicarea dispozițiilor legislației
          din România.
        </p>
        <p>
          29.8. Terțele părți cărora nu li s-au conferit în mod expres drepturi în prezentul
          contract nu pot solicita serviciile prevăzute în prezentul contract.
        </p>
      </Section>
    </div>
  );
};

export default TermsAndConditions;
