import {
  ADD_NEW_GEAR_ENTRY,
  ADD_NEW_RIG_ENTRY,
  REMOVE_GEAR_ENTRY,
  REMOVE_RIG_ENTRY,
} from '../../types/addNew/equipment';

export const addNewGearEntry = () => ({
  type: ADD_NEW_GEAR_ENTRY,
});

export const removeGearEntry = (index) => ({
  type: REMOVE_GEAR_ENTRY,
  payload: index,
});

export const addNewRigEntry = () => ({
  type: ADD_NEW_RIG_ENTRY,
});

export const removeRigEntry = (index) => ({
  type: REMOVE_RIG_ENTRY,
  payload: index,
});
