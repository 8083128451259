import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from 'components/authentication/Header';
import { Button } from 'components/ui/buttons';
import { NavLink } from 'components/ui/links';
import Input from 'components/ui/inputs/Input';
import { EMPTY_DEFAULT_FIELD } from 'constants/forms';
import { onFieldChange } from 'utility/utils';
import { isRequired, isValidEmail } from 'utility/validation';
import useAPI from 'hooks/useAPI';
import { getPasswordError } from 'components/authentication/utility/utils';
import PasswordInfo from './PasswordInfo';

const DEFAULT_FIELDS_STATE = {
  fullName: EMPTY_DEFAULT_FIELD,
  email: EMPTY_DEFAULT_FIELD,
  password: EMPTY_DEFAULT_FIELD,
  passwordConfirmation: EMPTY_DEFAULT_FIELD,
};

const Register = () => {
  const [fields, setFields] = useState(DEFAULT_FIELDS_STATE);
  const navigate = useNavigate();
  const { apiCall, isSubmitting } = useAPI();
  const fullNameField = useRef(null);
  const passwordField = useRef(null);
  const canSubmit = !Object.keys(fields).some((key) => !fields[key].isValid) && !isSubmitting;
  const isEmailValid = fields.email.isDirty ? fields.email.isValid : true;

  const onNameChange = (e) =>
    onFieldChange(
      e.target.value,
      (newValue) =>
        setFields({
          ...fields,
          fullName: newValue,
        }),
      isRequired,
    );
  const onEmailChange = (e) =>
    onFieldChange(
      e.target.value,
      (newValue) =>
        setFields({
          ...fields,
          email: newValue,
        }),
      isValidEmail,
    );
  const onPasswordChange = (e) =>
    onFieldChange(
      e.target.value,
      (newValue) =>
        setFields({
          ...fields,
          password: newValue,
        }),
      isRequired,
    );
  const onConfirmedPasswordChange = (e) =>
    onFieldChange(
      e.target.value,
      (newValue) =>
        setFields({
          ...fields,
          passwordConfirmation: newValue,
        }),
      isRequired,
    );

  const onSubmit = async (e) => {
    e.preventDefault();

    await apiCall(
      '/authentication/register',
      'post',
      {
        full_name: fields.fullName.value,
        email: fields.email.value,
        password: fields.password.value,
        password_confirmation: fields.passwordConfirmation.value,
      },
      {
        getErrorMessage: (errorData, statusCode) => {
          if (statusCode === 400) {
            return 'Această adresă de e-mail a fost deja înregistrată.';
          }

          return null;
        },
        onSuccessCallback: (data) => {
          navigate('/detalii-activare-cont', { state: { ...data.user } });
        },
        onErrorCallback: (data, statusCode) => {
          if (statusCode === 400) {
            setFields(DEFAULT_FIELDS_STATE);
            fullNameField.current.focus();
          }

          if (statusCode === 422) {
            const newFields = {
              ...fields,
              password: {
                ...fields.password,
                value: '',
              },
              passwordConfirmation: {
                ...fields.passwordConfirmation,
                value: '',
              },
            };

            const passwordError = getPasswordError(data.body.password);
            newFields.password = {
              ...newFields.password,
              isValid: !passwordError,
              error: passwordError,
            };

            setFields(newFields);

            passwordField.current.focus();
          }
        },
      },
    );

    return false;
  };

  return (
    <div className="flex flex-col justify-between h-full">
      <div className="grid gap-8 xl:gap-12">
        <Header>Crează cont</Header>
        <div className="grid gap-8 xl:gap-16">
          <form className="grid gap-8 xl:gap-9.6" onSubmit={onSubmit}>
            <Input
              ref={fullNameField}
              id="fullName"
              name="fullName"
              onChange={onNameChange}
              placeholder="Introdu nume și prenume"
              label="Nume și prenume"
              value={fields.fullName.value}
              type="text"
              required
              autoFocus
              autoComplete="name"
            />
            <Input
              id="email"
              name="email"
              onChange={onEmailChange}
              placeholder="Introdu adresa de e-mail"
              label="Adresă de e-mail"
              value={fields.email.value}
              valid={isEmailValid}
              type="email"
              required
              autoComplete="username"
              helperText={isEmailValid ? null : 'Adresa de e-mail nu este validă.'}
            />
            <Input
              ref={passwordField}
              id="password"
              name="password"
              onChange={onPasswordChange}
              placeholder="Introdu parola"
              label="Parolă"
              value={fields.password.value}
              type="password"
              required
              autoComplete="new-password"
              valid={fields.password.isDirty ? fields.password.isValid : true}
              helperText={fields.password.error}
              actions={<PasswordInfo />}
            />
            <Input
              id="confirmedPassword"
              name="confirmedPassword"
              onChange={onConfirmedPasswordChange}
              placeholder="Introdu confirmarea parolei"
              label="Confirmare parolă"
              value={fields.passwordConfirmation.value}
              type="password"
              required
              autoComplete="new-password"
            />
            <Button variant="primary" submit disabled={!canSubmit}>
              Înregistrare
            </Button>
            <div className="text-base leading-8 text-gray-darker">
              Prin folosirea butonului de &quot;Înregistrare&quot; ești de acord cu{' '}
              <NavLink variant="accent" to="/termeni-si-conditii" target="_blank">
                Termenii și Condițiile
              </NavLink>{' '}
              și{' '}
              <NavLink variant="accent" to="/politica-de-confidentialitate" target="_blank">
                Politica de Confidențialitate
              </NavLink>
              .
            </div>
          </form>
        </div>
      </div>
      <div className="mt-8">
        <span className="text-gray-darker">Ai un cont?</span>
        <NavLink className="ml-1" variant="accent" to="/">
          Autentifică-te
        </NavLink>
      </div>
    </div>
  );
};

export default Register;
