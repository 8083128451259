import React, { useContext, useEffect, useState } from 'react';
import CanSubmitContext from 'contexts/CanSubmitContext';
import CanEditContext from 'contexts/CanEditContext';
import Results from './Results';
import Description from './Description';
import Step from '../Step';

const title = 'Descriere';
const getDescription = (editable) =>
  editable
    ? 'Descrie partida în câteva cuvinte și expune rezultatele obținute.'
    : 'Descriere partidă și rezultatele obținute';

const Details = () => {
  const [descriptionIsValid, setDescriptionIsValid] = useState(false);
  const [resultsIsValid, setResultsIsValid] = useState(false);
  const [, setSubmit] = useContext(CanSubmitContext);
  const editable = useContext(CanEditContext);

  useEffect(() => {
    setSubmit(descriptionIsValid && resultsIsValid);
  }, [setSubmit, descriptionIsValid, resultsIsValid]);

  const onDescriptionChange = (value) => setDescriptionIsValid(value);
  const onResultsChange = (value) => setResultsIsValid(value);

  return (
    <Step title={title} description={getDescription(editable)}>
      <Description onChange={onDescriptionChange} />
      <Results onChange={onResultsChange} />
    </Step>
  );
};

Details.displayName = 'Details';

export default Details;
