import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

const ScrollableContainer = (props) => {
  const { data, selected, onChange } = props;
  const containerRef = useRef(null);
  const [scrollIntensityControl, setScrollIntensityControl] = useState(selected);

  useEffect(() => {
    if (scrollIntensityControl && containerRef.current) {
      const currentValue = Math.floor(scrollIntensityControl);
      containerRef.current.scrollTop = currentValue * 30;

      if (currentValue >= 0 && currentValue <= data[data.length - 1].value) {
        onChange(currentValue);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollIntensityControl, containerRef, data]);

  useEffect(() => {
    const { current: currentContainer } = containerRef;

    const onScroll = (event) => {
      event.preventDefault();
      event.stopPropagation();

      setScrollIntensityControl((prev) => {
        const newValue = prev + event.deltaY / 120;

        // Prevent getting too low in value after first value.
        if (newValue < 0) {
          return 0;
        }

        // Prevent getting too high in value after last value.
        if (newValue > currentContainer.scrollHeight / 30 - 3) {
          return currentContainer.scrollHeight / 30 - 3;
        }

        return newValue;
      });
    };

    currentContainer.addEventListener('scroll', onScroll);

    return () => {
      currentContainer.removeEventListener('scroll', onScroll);
    };
  }, [containerRef]);

  return (
    <div ref={containerRef} className="max-h-56 overflow-hidden grid grid-cols-1 gap-4">
      <div className="flex items-center text-center h-8" />
      <div className="flex items-center text-center h-8" />
      {data.map((item) => (
        <div
          key={item.label}
          className="mx-auto flex items-center text-center h-8 text-lg text-gray-dark"
        >
          {item.label}
        </div>
      ))}
      <div className="flex items-center text-center h-8" />
      <div className="flex items-center text-center h-8" />
    </div>
  );
};

ScrollableContainer.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.number,
};

ScrollableContainer.defaultProps = {
  selected: 0,
};

ScrollableContainer.displayName = 'ScrollableContainer';

export default ScrollableContainer;
