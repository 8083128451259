import React from 'react';
import PropTypes from 'prop-types';
import ScrollableContainer from './ScrollableContainer';

const allHours = Array.from({ length: 24 }, (_, i) => ({
  value: i,
  label: i.toString().length === 1 ? `0${i}` : i.toString(),
}));

const allMinutes = Array.from({ length: 60 }, (_, i) => ({
  value: i,
  label: i.toString().length === 1 ? `0${i}` : i.toString(),
}));

const TimePicker = (props) => {
  const { hour, minutes, onChange } = props;
  const onHourChange = (newHour) => onChange(newHour, minutes);
  const onMinutesChange = (newMinutes) => onChange(hour, newMinutes);

  return (
    <div className="flex flex-col gap-8">
      <div className="relative flex justify-center items-center gap-8 bg-white">
        <div className="pointer-events-none absolute h-12 border border-blue rounded-9.6 top-42 left-0 right-0 z-20" />
        <div className="grid grid-cols-1 gap-4 justify-center">
          <ScrollableContainer data={allHours} selected={hour} onChange={onHourChange} />
        </div>
        <div className="grid grid-cols-1 justify-center gap-4">
          <ScrollableContainer data={allMinutes} selected={minutes} onChange={onMinutesChange} />
        </div>
      </div>
    </div>
  );
};

TimePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  hour: PropTypes.number,
  minutes: PropTypes.number,
};

TimePicker.defaultProps = {
  hour: 0,
  minutes: 0,
};

export default TimePicker;
