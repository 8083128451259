import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from 'components/ui/buttons';

const Chip = (props) => {
  const { label, onClose } = props;

  return (
    <div
      data-testid="chip"
      className="flex flex-row gap-x-3.2 px-2.8 py-1.2 items-center bg-blue-lighter
       rounded-3.2 border border-blue"
    >
      <span className="cursor-default text-blue font-semibold leading-8">{label}</span>
      {onClose && (
        <IconButton onClick={onClose} icon="close" variant="link" iconClassName="w-6.4 h-6.4" />
      )}
    </div>
  );
};

Chip.propTypes = {
  label: PropTypes.string.isRequired,
  onClose: PropTypes.func,
};

Chip.defaultProps = {
  onClose: null,
};

Chip.displayName = 'Chip';

export default Chip;
