import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import omit from 'lodash/omit';
import { Button, IconButton } from 'components/ui/buttons';
import { useOpenedState, usePositioning } from './hooks';

const Dropdown = (props) => {
  const {
    className,
    triggerComponent: TriggerComponent,
    triggerProps: defaultTriggerProps,
    children,
    closeOnClick,
    position,
    removePaddings,
  } = props;
  const containerRef = useRef(null);
  const contentRef = useRef(null);
  const { isOpened, onToggle } = useOpenedState(
    containerRef,
    contentRef,
    defaultTriggerProps,
    closeOnClick,
  );
  usePositioning(containerRef, contentRef, isOpened, position);
  const isButtonTriggerComponent = TriggerComponent === Button || TriggerComponent === IconButton;
  const triggerProps = {
    ...omit(defaultTriggerProps, ['onClick']),
  };
  if (!isButtonTriggerComponent) {
    triggerProps.dropDownOpened = isOpened;
  }

  const triggerElement = <TriggerComponent onClick={onToggle} {...triggerProps} />;

  return (
    <div data-testid="dropdown-container" className={className}>
      <div data-testid="dropdown-trigger-container" ref={containerRef}>
        {triggerElement}
      </div>
      {isOpened && (
        <div
          data-testid="dropdown-content"
          ref={contentRef}
          className={cn(
            'fixed z-20 bg-white border border-gray-input-stroke rounded-3.2 flex flex-col gap-3.2',
            {
              'px-6.4 py-4.8': !removePaddings,
            },
          )}
        >
          {children}
        </div>
      )}
    </div>
  );
};

Dropdown.propTypes = {
  className: PropTypes.string,
  triggerComponent: PropTypes.elementType,
  triggerProps: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  closeOnClick: PropTypes.bool,
  position: PropTypes.oneOf(['top-right', 'top-left', 'bottom-right', 'bottom-left']),
  removePaddings: PropTypes.bool,
};

Dropdown.defaultProps = {
  className: '',
  triggerComponent: Button,
  closeOnClick: false,
  position: 'bottom-right',
  removePaddings: false,
};

Dropdown.displayName = 'GenericDropdown';

export default Dropdown;
