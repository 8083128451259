import React from 'react';
import PropTypes from 'prop-types';
import Author from './Author';
import Actions from './Actions';

const Meta = (props) => {
  const { author, onEdit, onView, location } = props;

  return (
    <div className="flex justify-between items-center">
      <Author location={location} author={author} />
      <Actions onEdit={onEdit} onView={onView} />
    </div>
  );
};

Meta.propTypes = {
  author: PropTypes.shape({
    fullName: PropTypes.string.isRequired,
    avatar: PropTypes.string,
    createdAt: PropTypes.string.isRequired,
  }),
  onView: PropTypes.func.isRequired,
  onEdit: PropTypes.func,
  location: PropTypes.node,
};

Meta.defaultProps = {
  author: undefined,
  location: null,
  onEdit: undefined,
};

Meta.displayName = 'Meta';

export default Meta;
