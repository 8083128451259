export const INIT_MAIN_LINES = 'init-main-lines';
export const ADD_MAIN_LINE = 'add-main-line';
export const INIT_RIG_LINES = 'init-rig-lines';
export const ADD_RIG_LINE = 'add-rig-line';
export const INIT_LEADER_LINES = 'init-leader-lines';
export const ADD_LEADER_LINE = 'add-leader-line';
export const SELECT_MAIN_LINE = 'select-main-line';
export const SELECT_RIG_LINE = 'select-rig-line';
export const SELECT_LEADER_LINE = 'select-leader-line';
export const DESELECT_MAIN_LINE = 'deselect-main-line';
export const DESELECT_RIG_LINE = 'deselect-rig-line';
export const DESELECT_LEADER_LINE = 'deselect-leader-line';
