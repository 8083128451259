import React from 'react';
import { Button } from 'components/ui/buttons';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Header from 'components/authentication/Header';
import useAPI from 'hooks/useAPI';

// export const activateAccountLoader = async ({ params }) => {
//   let response;
//
//   try {
//     response = await axios.get('/authentication/test-route');
//   } catch (e) {
//     // console.log(e);
//     return {};
//   }
//
//   return response.data;
// };

const ActivateAccount = () => {
  const { userId, token } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { apiCall } = useAPI();
  const activateAccount = async () => {
    await apiCall(
      `/authentication/${userId}/activate/${token}`,
      'post',
      {},
      {
        getSuccessMessage: () => 'Contul a fost activat cu succes!',
        onSuccessCallback: () => navigate('/'),
        getErrorMessage: (responseData, statusCode) => {
          if (statusCode === 400) {
            switch (responseData.detail) {
              case 'Token is invalid':
                return 'Link-ul de activare este invalid!';
              case 'Token has expired':
                return 'Link-ul de activare a expirat!';
              default:
                return null;
            }
          }
          return null;
        },
        onErrorCallback: (errorData, statusCode) => {
          if (statusCode === 400) {
            switch (errorData.detail) {
              case 'Token is invalid':
                navigate('/');
                break;
              case 'Token has expired':
                navigate('/detalii-activare-cont', {
                  state: {
                    from: location.pathname,
                    id: userId,
                    token,
                  },
                });
                break;
              default:
                return false;
            }
          }
          return null;
        },
      },
    );
    return false;
  };

  return (
    <div className="flex flex-col h-full justify-start gap-8 xl:gap-12 relative">
      <Header>Activează contul</Header>
      <p>
        <span className="font-bold text-green-dark">Contul tău a fost creat cu succes. </span>
        Apasă pe butonul de mai jos pentru a activa contul.
      </p>

      <Button variant="primary" onClick={activateAccount}>
        Activează cont
      </Button>
    </div>
  );
};

ActivateAccount.displayName = 'ActivateAccountRequest';

export default ActivateAccount;
