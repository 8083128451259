import { INIT_RODS, ADD_ROD, SELECT_ROD, DESELECT_ROD } from '../../types/availableEntities/rods';

export const init = (payload) => ({
  type: INIT_RODS,
  payload,
});

export const add = (payload) => ({
  type: ADD_ROD,
  payload,
});

export const select = (id, index) => ({
  type: SELECT_ROD,
  payload: {
    id,
    index,
  },
});

export const deselect = (payload) => ({
  type: DESELECT_ROD,
  payload,
});
