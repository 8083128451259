import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const List = (props) => {
  const { children, variant } = props;

  return (
    <ul
      className={cn('pl-16 flex flex-col gap-4', {
        'list-decimal': variant === 'ordered',
        'list-disc': variant === 'unordered',
        'list-[square]': variant === 'square',
      })}
    >
      {children}
    </ul>
  );
};

List.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  variant: PropTypes.oneOf(['ordered', 'unordered', 'square']),
};

List.defaultProps = {
  variant: 'unordered',
};

List.displayName = 'List';

export default List;
