import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'components/GoOut/steps/Gear/Dropdown';
import { getLineName } from 'components/GoOut/utility/utils';
import {
  initLeaderLines,
  addLeaderLine,
  selectLeaderLine,
  deselectLeaderLine,
} from 'components/GoOut/state/actions/availableEntities/lines';
import AddNewRigLeaderModal from 'components/Equipment/entities/RigLeaderModal';

const RigLeaderSelector = (props) => {
  const { index, editable } = props;

  return (
    <Dropdown
      inputProps={{
        id: `rig-line-${index}`,
        name: 'rigLine',
        placeholder: 'Selectează înaintașul',
        label: 'Înaintaș',
      }}
      index={index}
      nameGetter={getLineName}
      resourceMeta={{
        apiResource: 'lines',
        entity: 'leaderLines',
        formParentKey: 'rigs',
        formKey: 'leader',
        queryParams: {
          type: 'leader',
        },
      }}
      actions={{
        onInit: initLeaderLines,
        onAdd: addLeaderLine,
        onSelect: selectLeaderLine,
        onDeselect: deselectLeaderLine,
      }}
      addNewProps={{
        component: AddNewRigLeaderModal,
        text: 'Adaugă înaintaș',
      }}
      editable={editable}
    />
  );
};

RigLeaderSelector.propTypes = {
  index: PropTypes.number.isRequired,
  editable: PropTypes.bool.isRequired,
};

RigLeaderSelector.displayName = 'RigLeader';

export default RigLeaderSelector;
