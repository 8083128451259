export const EMPTY_DEFAULT_FIELD = {
  value: '',
  isDirty: false,
  isValid: false,
  error: '',
};

export const DEFAULT_SPECIES_FIELDS = {
  species: EMPTY_DEFAULT_FIELD,
  totalWeight: EMPTY_DEFAULT_FIELD,
  maxWeight: { ...EMPTY_DEFAULT_FIELD, isValid: true },
  totalNumber: EMPTY_DEFAULT_FIELD,
};

export const DEFAULT_MAIN_GEAR_FIELDS = {
  rod: EMPTY_DEFAULT_FIELD,
  reel: EMPTY_DEFAULT_FIELD,
  line: EMPTY_DEFAULT_FIELD,
};

export const DEFAULT_STRING_GEAR_FIELDS = {
  hook: EMPTY_DEFAULT_FIELD,
  line: EMPTY_DEFAULT_FIELD,
};

export const DEFAULT_API_ERROR =
  'Serverul nu este disponibil momentan. Vă rugăm încercați mai târziu.';
