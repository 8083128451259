import React from 'react';
import PropTypes from 'prop-types';
import TextField from './TextField';

const Textarea = (props) => <TextField element={<textarea />} {...props} />;

Textarea.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  valid: PropTypes.bool,
  required: PropTypes.bool,
  helperText: PropTypes.string,
  actions: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

Textarea.defaultProps = {
  label: '',
  placeholder: '',
  type: 'text',
  value: '',
  disabled: false,
  valid: true,
  required: false,
  helperText: '',
  actions: null,
};

Textarea.displayName = 'Textarea';

export default Textarea;
