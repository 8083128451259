import React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from 'components/Footer';
import Branding from './Branding';

const Hero = () => {
  return (
    <div className="flex flex-col lg:h-screen">
      <div
        className="bg-green-dark relative lg:flex-1 px-8 sm:px-32 md:px-64 lg:px-32 2xl:px-120 xl:pb-18 xl:py-24"
        style={{
          backgroundImage: "url('./images/hero-waves-o5.webp')",
          backgroundSize: '100% 100%',
        }}
      >
        <div className="flex flex-col lg:flex-row h-full">
          <Branding className="z-10 w-full lg:w-7/12 2xl:w-1/2 mb-4.8 xl:mb-0 xl:mr-20" />
          <div className="z-10 w-full lg:w-5/12 2xl:w-1/2 bg-white mt-16 mb-16 lg:my-24 xl:mt-0 xl:mb-0 xl:ml-20 rounded-9.6 p-16 flex flex-col justify-center">
            <Outlet />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

Hero.displayName = 'Hero';

export default Hero;
