import axios from 'utility/axios';

export const validateTokenLoader = async ({ request, params }) => {
  let response;
  let tokenType = 'activation';

  if (request && request.url.includes('resetare-parola')) {
    tokenType = 'password_reset';
  }

  try {
    response = await axios.get(`/authentication/validate/${tokenType}/${params.token}`);
  } catch (e) {
    response = e.response;
  }

  return {
    data: response.data,
    status: response.status,
  };
};
