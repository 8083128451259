import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Context from 'components/GoOut/state/contexts/addNew';
import { setPersonalScore } from 'components/GoOut/state/actions/addNew/others';
import { onFieldChange } from 'utility/utils';
import { isIntegerNumber } from 'utility/validation';
import { CheckboxesDropdown } from 'components/ui/dropdowns';
import IconStats from 'components/ui/IconStats';

const personalScores = [10, 9, 8, 7, 6, 5, 4, 3, 2, 1].map((o) => ({
  id: o.toString(),
  value: o.toString(),
}));

const PersonalScoreInput = (props) => {
  const { editable } = props;
  const [state, dispatch] = useContext(Context);
  const {
    results: {
      selfRate: { value: score, isValid: scoreIsValid, isDirty: scoreIsDirty, error: scoreError },
    },
  } = state;

  const onSelectScore = (event) => {
    const setter = (newItem) => dispatch(setPersonalScore(newItem));
    onFieldChange(event, setter, isIntegerNumber);
  };

  if (editable) {
    const triggerProps = {
      id: 'personalScore',
      name: 'personalScore',
      placeholder: 'Selecteaza scor',
      label: 'Scor personal',
      type: 'text',
      required: true,
      valid: scoreIsDirty ? scoreIsValid : undefined,
      helperText: scoreIsDirty && !scoreIsValid ? scoreError : null,
      value: score,
    };

    return (
      <CheckboxesDropdown
        data={personalScores}
        onChange={onSelectScore}
        triggerProps={triggerProps}
        singleSelection
        selectedValues={score ? [score.toString()] : []}
        readOnly={!editable}
      />
    );
  }

  return (
    <div className="flex">
      <IconStats
        icon="user-star"
        data={[{ label: 'Scor personal', value: score }]}
        rounded="small"
      />
    </div>
  );
};

PersonalScoreInput.propTypes = {
  editable: PropTypes.bool.isRequired,
};

export default PersonalScoreInput;
