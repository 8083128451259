import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from 'components/ui/Icon';
import { SKY_CONDITIONS } from 'constants/skyConditions';
import WidgetCell from './WidgetCell';

const WeatherWidget = (props) => {
  const {
    temperature,
    skyCondition,
    windSpeed,
    windDirection,
    windGusts,
    atmPressure,
    layout,
    theme,
    rounded,
  } = props;
  const item = SKY_CONDITIONS[skyCondition];
  const isHorizontal = layout === 'vertical';
  const isLightTheme = theme === 'light';

  if (!item) {
    throw new Error(`Sky condition "${skyCondition}" is not found.`);
  }

  const { identifier, title } = item;

  return (
    <div
      data-testid="weather-cotainer"
      className={cn('flex p-6 w-full h-full', {
        'bg-white border border-gray-input-stroke': isLightTheme,
        'bg-green-dark': !isLightTheme,
        'flex-row items-center justify-center gap-16': isHorizontal,
        'flex-col gap-6': !isHorizontal,
        'rounded-3.2': rounded === 'small',
        'rounded-6.4': rounded === 'medium',
      })}
    >
      <div className="flex gap-x-6.4">
        <div data-testid="weather-icon" className="w-24 h-24">
          <Icon
            fillClassName={isLightTheme ? 'fill-gray' : 'fill-white'}
            className="w-24 h-24"
            identifier={identifier}
          />
        </div>
        <div
          className={cn('flex flex-col', {
            'text-green-dark': isLightTheme,
            'text-gray-300': !isLightTheme,
          })}
        >
          <div data-testid="weather-temperature" className="text-4xl font-bold">
            {temperature}°C
          </div>
          <div
            data-testid="weather-sky-condition"
            className={cn('text-sm', { 'text-gray': isLightTheme, 'text-white': !isLightTheme })}
          >
            {title}
          </div>
        </div>
      </div>
      <div data-testid="weather-data" className="grid grid-cols-4 gap-6">
        <WidgetCell description="Viteză vânt" value={windSpeed} unit="km/h" theme={theme} />
        <WidgetCell description="Direcție vânt" value={windDirection} theme={theme} />
        <WidgetCell description="Rafale vânt" value={windGusts} unit="km/h" theme={theme} />
        <WidgetCell description="Presiune" value={atmPressure} unit="hPa" theme={theme} />
      </div>
    </div>
  );
};

WeatherWidget.propTypes = {
  temperature: PropTypes.number.isRequired,
  skyCondition: PropTypes.string.isRequired,
  windSpeed: PropTypes.number.isRequired,
  windDirection: PropTypes.string.isRequired,
  windGusts: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  atmPressure: PropTypes.number.isRequired,
  layout: PropTypes.oneOf(['horizontal', 'vertical']),
  theme: PropTypes.oneOf(['light', 'dark']),
  rounded: PropTypes.oneOf(['small', 'medium', 'large']),
};

WeatherWidget.defaultProps = {
  layout: 'horizontal',
  theme: 'light',
  rounded: 'medium',
};

WeatherWidget.displayName = 'WeatherWidget';

export default WeatherWidget;
