import React from 'react';
import { ICONS } from 'constants/icons';
import PropTypes from 'prop-types';

const Icon = (props) => {
  const { identifier, className, ...iconProps } = props;
  const icon = ICONS[identifier];

  if (!icon) {
    throw new Error(`SVG with identifier "${identifier}" is not found.`);
  }

  const { width, height, fill } = icon;
  const iconBody = icon.getBody(iconProps);

  return (
    <svg
      data-testid={identifier}
      className={className}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      {iconBody}
    </svg>
  );
};

Icon.propTypes = {
  identifier: PropTypes.string.isRequired,
  className: PropTypes.string,
};

Icon.defaultProps = {
  className: '',
};

Icon.displayName = 'Icon';

export default Icon;
