import {
  INIT_GROUND_BAITS,
  ADD_GROUND_BAIT,
  SELECT_GROUND_BAIT,
  DESELECT_GROUND_BAIT,
  SET_GROUND_BAIT_STRATEGY,
} from 'components/GoOut/state/types/availableEntities/groundBaits';

export const init = (payload) => ({
  type: INIT_GROUND_BAITS,
  payload,
});

export const add = (payload) => ({
  type: ADD_GROUND_BAIT,
  payload,
});

export const select = (payload) => ({
  type: SELECT_GROUND_BAIT,
  payload,
});

export const deselect = (payload) => ({
  type: DESELECT_GROUND_BAIT,
  payload,
});

export const setStrategy = (payload) => ({
  type: SET_GROUND_BAIT_STRATEGY,
  payload,
});
