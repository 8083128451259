import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components/ui/buttons';

const Text = (props) => {
  const { className, children, onViewMore } = props;
  const chunks = children.split('\n');

  return (
    <div className={className}>
      {chunks.map((textSplit, index) => {
        const isLast = index === chunks.length - 1;
        const showMoreButton = isLast && !!onViewMore;

        return (
          <div key={index} className="mt-3.2 font-medium text-lg text-green-dark">
            {textSplit} {!isLast && <br />}
            {showMoreButton && (
              <>
                <span>...</span>
                <span className="ml-2">
                  <Button variant="link" onClick={onViewMore}>
                    Mai mult
                  </Button>
                </span>
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};

Text.propTypes = {
  children: PropTypes.string.isRequired,
  onViewMore: PropTypes.func,
  className: PropTypes.string,
};

Text.defaultProps = {
  onViewMore: null,
  className: '',
};

Text.displayName = 'Text';

export default Text;
