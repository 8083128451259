import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Outlet } from 'react-router-dom';

const Content = (props) => {
  const { className } = props;

  return (
    <div className={cn('min-h-screen px-6.4 pb-6.4 flex', className)}>
      <div className="w-full flex flex-col psc-safe-area max-content-screen:p-0 max-w-400 max-content-screen:mx-auto">
        <Outlet />
      </div>
    </div>
  );
};

Content.propTypes = {
  className: PropTypes.string,
};

Content.defaultProps = {
  className: '',
};

export default Content;
