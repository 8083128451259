import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Context from 'components/GoOut/state/contexts/addNew';
import { CheckboxesDropdown } from 'components/ui/dropdowns';
import { setSkyCondition } from 'components/GoOut/state/actions/addNew/others';
import { onFieldChange } from 'utility/utils';
import { isRequired } from 'utility/validation';
import IconStats from 'components/ui/IconStats';

const skyConditions = [
  'înnorat',
  'predominant înnorat',
  'parțial senin',
  'predominant senin',
  'senin',
].map((o) => ({ id: o, value: o }));

const SkyConditionsInput = (props) => {
  const { editable } = props;
  const [state, dispatch] = useContext(Context);
  const {
    conditions: {
      weather: {
        skyCondition: {
          value: skyCondition,
          isValid: skyConditionIsValid,
          isDirty: skyConditionIsDirty,
          error: skyConditionError,
        },
      },
    },
  } = state;

  const onSelectSkyCondition = (event) => {
    const setter = (newItem) => dispatch(setSkyCondition(newItem));
    onFieldChange(event, setter, isRequired);
  };

  if (editable) {
    const triggerProps = {
      id: 'skyCondition',
      name: 'skyCondition',
      label: 'Starea vremii',
      placeholder: 'Selectare starea vremii',
      autoFocus: true,
      valid: skyConditionIsDirty ? skyConditionIsValid : undefined,
      helperText: skyConditionIsDirty && !skyConditionIsValid ? skyConditionError : null,
      value: skyCondition,
      required: true,
    };

    return (
      <CheckboxesDropdown
        data={skyConditions}
        onChange={onSelectSkyCondition}
        triggerProps={triggerProps}
        singleSelection
        selectedValues={skyCondition ? [skyCondition] : []}
        readOnly={!editable}
      />
    );
  }
  return <IconStats icon="fish" data={[{ label: 'Starea vremii', value: skyCondition }]} />;
};

SkyConditionsInput.propTypes = {
  editable: PropTypes.bool.isRequired,
};

export default SkyConditionsInput;
