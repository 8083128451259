import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'components/GoOut/steps/Gear/Dropdown';
import { getPowerGumName } from 'components/GoOut/utility/utils';
import {
  init,
  add,
  select,
  deselect,
} from 'components/GoOut/state/actions/availableEntities/powerGums';
import AddNewPowerGumModal from 'components/Equipment/entities/PowerGumModal';

const PowerGumSelector = (props) => {
  const { index, editable } = props;

  return (
    <Dropdown
      inputProps={{
        id: `rig-line-${index}`,
        name: 'rigLine',
        placeholder: 'Selectează power gum-ul',
        label: 'Power gum',
      }}
      index={index}
      nameGetter={getPowerGumName}
      resourceMeta={{
        apiResource: 'power-gums',
        entity: 'powerGums',
        formParentKey: 'rigs',
        formKey: 'powerGum',
      }}
      actions={{
        onInit: init,
        onAdd: add,
        onSelect: select,
        onDeselect: deselect,
      }}
      addNewProps={{
        component: AddNewPowerGumModal,
        text: 'Adaugă power gum',
      }}
      editable={editable}
    />
  );
};

PowerGumSelector.propTypes = {
  index: PropTypes.number.isRequired,
  editable: PropTypes.bool.isRequired,
};

PowerGumSelector.displayName = 'RigLeader';

export default PowerGumSelector;
