import axios from 'utility/axios';

export const fetchGear = async () => {
  let response;
  try {
    response = await axios.get('/gear/all');
  } catch (error) {
    return { error };
  }

  return { gear: response.data };
};

export const fetchSpecies = async () => {
  let response;
  try {
    response = await axios.get('/species');
  } catch (error) {
    return { error };
  }

  return { species: response.data };
};

export const fetchGoOut = async ({ id }) => {
  let response;
  try {
    response = await axios.get(`/go-outs/${id}`);
  } catch (error) {
    return { error };
  }
  return { goOut: response.data };
};

export const fetchGoOutData = async ({ params }) => {
  const [gearResult, goOutResult, speciesResult] = await Promise.all([
    fetchGear(),
    fetchGoOut(params),
    fetchSpecies(),
  ]);

  return {
    gear: gearResult.gear,
    goOut: goOutResult.goOut,
    species: speciesResult.species,
    errors: [gearResult.error, goOutResult.error, speciesResult.error].filter(Boolean),
  };
};
