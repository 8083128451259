import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const OrderNumber = (props) => {
  const { value, size, variant, dataTestId } = props;
  const isCurrent = variant === 'current';
  const isFilled = variant === 'filled';
  const isDisabled = variant === 'disabled';
  const isSmall = size === 'small';

  return (
    <div
      data-testid={dataTestId}
      className={cn('flex items-center justify-center text-stepper font-bold p-3.6 rounded-full', {
        'w-12 h-12': !isSmall,
        'w-10 h-10': isSmall,
        'text-blue border border-blue': isCurrent,
        'bg-blue text-white': isFilled,
        'text-gray-input-stroke border border-gray-input-stroke': isDisabled,
      })}
    >
      {value}
    </div>
  );
};

OrderNumber.propTypes = {
  value: PropTypes.number.isRequired,
  size: PropTypes.oneOf(['small', 'medium']),
  variant: PropTypes.oneOf(['current', 'filled', 'disabled']),
  dataTestId: PropTypes.string,
};

OrderNumber.defaultProps = {
  size: 'medium',
  variant: 'current',
  dataTestId: null,
};

export default OrderNumber;
