import { INIT_DATA } from '../types/availableEntities/common';
import { ADD_FEEDER, INIT_FEEDERS } from '../types/availableEntities/feeders';
import { ADD_GROUND_BAIT, INIT_GROUND_BAITS } from '../types/availableEntities/groundBaits';
import { ADD_BAIT, INIT_BAITS } from '../types/availableEntities/baits';
import { INIT_RODS, ADD_ROD } from '../types/availableEntities/rods';
import { INIT_REELS, ADD_REEL } from '../types/availableEntities/reels';
import {
  INIT_MAIN_LINES,
  ADD_MAIN_LINE,
  INIT_RIG_LINES,
  ADD_RIG_LINE,
  INIT_LEADER_LINES,
  ADD_LEADER_LINE,
} from '../types/availableEntities/lines';
import { INIT_POWER_GUMS, ADD_POWER_GUM } from '../types/availableEntities/powerGums';
import { INIT_HOOKS, ADD_HOOK } from '../types/availableEntities/hooks';
import { INIT_SPECIES } from '../types/availableEntities/species';

const defaultCollection = {
  isLoading: true,
  all: [],
};

const keys = [
  'feeders',
  'groundBaits',
  'baits',
  'rods',
  'reels',
  'mainLines',
  'rigLines',
  'leaderLines',
  'hooks',
  'powerGums',
  'species',
];
export const defaultState = keys.reduce((acc, key) => {
  acc[key] = { ...defaultCollection };
  return acc;
}, {});

export const reducer = (state, action) => {
  switch (action.type) {
    case INIT_DATA:
      if (!action.payload) {
        return defaultState;
      }

      return {
        ...state,
        rods: {
          isLoading: false,
          all: action.payload.rods,
        },
        reels: {
          isLoading: false,
          all: action.payload.reels,
        },
        mainLines: {
          isLoading: false,
          all: action.payload.mainLines,
        },
        rigLines: {
          isLoading: false,
          all: action.payload.rigLines,
        },
        leaderLines: {
          isLoading: false,
          all: action.payload.leaderLines,
        },
        powerGums: {
          isLoading: false,
          all: action.payload.powerGums,
        },
        hooks: {
          isLoading: false,
          all: action.payload.hooks,
        },
        feeders: {
          isLoading: false,
          all: action.payload.feeders,
        },
        groundBaits: {
          isLoading: false,
          all: action.payload.groundBaits,
        },
        baits: {
          isLoading: false,
          all: action.payload.baits,
        },
        species: {
          isLoading: false,
          all: action.payload.species,
        },
      };
    case INIT_FEEDERS:
      return {
        ...state,
        feeders: {
          all: action.payload,
          isLoading: false,
        },
      };
    case ADD_FEEDER:
      return {
        ...state,
        feeders: {
          ...state.feeders,
          all: [...state.feeders.all, action.payload],
        },
      };
    case INIT_GROUND_BAITS:
      return {
        ...state,
        groundBaits: {
          all: action.payload,
          isLoading: false,
        },
      };
    case ADD_GROUND_BAIT:
      return {
        ...state,
        groundBaits: {
          ...state.groundBaits,
          all: [...state.groundBaits.all, action.payload],
        },
      };
    case INIT_BAITS:
      return {
        ...state,
        baits: {
          all: action.payload,
          isLoading: false,
        },
      };
    case ADD_BAIT: {
      return {
        ...state,
        baits: {
          ...state.baits,
          all: [...state.baits.all, action.payload],
        },
      };
    }
    case INIT_RODS:
      return {
        ...state,
        rods: {
          all: action.payload,
          isLoading: false,
        },
      };
    case ADD_ROD:
      return {
        ...state,
        rods: {
          ...state.rods,
          all: [...state.rods.all, action.payload],
        },
      };
    case INIT_REELS:
      return {
        ...state,
        reels: {
          all: action.payload,
          isLoading: false,
        },
      };
    case ADD_REEL:
      return {
        ...state,
        reels: {
          ...state.reels,
          all: [...state.reels.all, action.payload],
        },
      };
    case INIT_MAIN_LINES:
      return {
        ...state,
        mainLines: {
          all: action.payload,
          isLoading: false,
        },
      };
    case ADD_MAIN_LINE:
      return {
        ...state,
        mainLines: {
          ...state.mainLines,
          all: [...state.mainLines.all, action.payload],
        },
      };
    case INIT_RIG_LINES:
      return {
        ...state,
        rigLines: {
          all: action.payload,
          isLoading: false,
        },
      };
    case ADD_RIG_LINE:
      return {
        ...state,
        rigLines: {
          ...state.rigLines,
          all: [...state.rigLines.all, action.payload],
        },
      };
    case INIT_LEADER_LINES:
      return {
        ...state,
        leaderLines: {
          all: action.payload,
          isLoading: false,
        },
      };
    case ADD_LEADER_LINE:
      return {
        ...state,
        leaderLines: {
          ...state.leaderLines,
          all: [...state.leaderLines.all, action.payload],
        },
      };
    case INIT_POWER_GUMS:
      return {
        ...state,
        powerGums: {
          all: action.payload,
          isLoading: false,
        },
      };
    case ADD_POWER_GUM:
      return {
        ...state,
        powerGums: {
          ...state.powerGums,
          all: [...state.powerGums.all, action.payload],
        },
      };
    case INIT_HOOKS:
      return {
        ...state,
        hooks: {
          all: action.payload,
          isLoading: false,
        },
      };
    case ADD_HOOK:
      return {
        ...state,
        hooks: {
          ...state.hooks,
          all: [...state.hooks.all, action.payload],
        },
      };
    case INIT_SPECIES:
      return {
        ...state,
        species: {
          all: action.payload,
          isLoading: false,
        },
      };
    default:
      return state;
  }
};
