import axios from 'utility/axios';

export const fetchRods = async () => {
  let response;

  try {
    response = await axios.get('/gear/rods/reels-count');
  } catch (error) {
    return { error };
  }

  return { data: response.data };
};

export const fetchReels = async () => {
  let response;

  try {
    response = await axios.get('/gear/reels/rods-count');
  } catch (error) {
    return { error };
  }

  return { data: response.data };
};
