export const getPasswordError = (errorData) => {
  switch (errorData) {
    case 'Confirmation mismatch':
      return 'Parola și confirmarea parolei nu se potrivesc.';

    case 'Must have at least 8 characters':
      return 'Parola trebuie să conțină cel puțin 8 caractere.';

    case 'Must have at least one lowercase letter':
      return 'Parola trebuie să conțină cel puțin o literă mică.';

    case 'Must have at least one uppercase letter':
      return 'Parola trebuie să conțină cel puțin o literă mare.';

    case 'Must have at least one digit':
      return 'Parola trebuie să conțină cel puțin o cifră.';

    case 'Must have at least one special character':
      return 'Parola trebuie să conțină cel puțin un caracter special.';

    case 'Too similar to first name.' || 'Too similar to last name.':
      return 'Parola este foarte asemănătoare cu numele.';

    default:
      return null;
  }
};
