import { SET_SPECIES } from './types';

export const defaultState = {
  species: null,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case SET_SPECIES:
      return {
        ...state,
        species: action.payload,
      };
    default:
      return state;
  }
};
