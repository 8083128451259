export const getSidebarLinks = (onClick) => [
  {
    to: '/',
    icon: 'announcement',
    label: 'Noutăți',
    activeRegex: /^\/$/,
    onClick,
  },
  {
    to: '/partidele-mele',
    icon: 'fishes-bait',
    label: 'Partidele mele',
    activeRegex: /^\/partidele-mele/,
    onClick,
  },
  {
    to: '/echipament/lansete',
    icon: 'equipment',
    label: 'Echipament',
    activeRegex: /^\/echipament/,
    onClick,
  },
];

export const getAccountLinks = (onLogout) => [
  // {
  //   to: '/contul-meu',
  //   icon: 'user',
  //   label: 'Profil',
  //   activeRegex: /^\/contul-meu$/,
  //   variant: 'secondary',
  // },
  // {
  //   to: '/setari',
  //   icon: 'cogwheels',
  //   label: 'Setări',
  //   activeRegex: /^\/setari/,
  //   variant: 'secondary',
  // },
  {
    icon: 'logout',
    label: 'Deconectare',
    onClick: onLogout,
    activeRegex: /^$/,
    variant: 'danger',
  },
];
