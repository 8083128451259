import {
  eachDayOfInterval,
  endOfWeek,
  getDaysInMonth,
  getMonth,
  getYear,
  startOfWeek,
} from 'date-fns';

export const getEachDayOfMonth = (referenceDate) => {
  const year = getYear(referenceDate);
  const month = getMonth(referenceDate);
  const daysInMonth = getDaysInMonth(referenceDate);

  return eachDayOfInterval({
    start: startOfWeek(new Date(year, month, 1), { weekStartsOn: 1 }),
    end: endOfWeek(new Date(year, month, daysInMonth), { weekStartsOn: 1 }),
  });
};
