import React from 'react';
import PropTypes from 'prop-types';
import Tab from 'components/ui/tabs/Tab';
import TabList from 'components/ui/tabs/TabList';

const Tabs = (props) => {
  const { children, tabs, selectedId, action } = props;
  const tabElements = tabs.map((o) => (
    <Tab
      key={o.id}
      id={o.id}
      label={o.label}
      onClick={o.onClick}
      count={o.count}
      active={selectedId === o.id}
    />
  ));

  return (
    <div
      data-testid="tabs-container"
      className="flex flex-col rounded-3.2 bg-white border border-gray-light-stroke overflow-hidden"
    >
      <div
        data-testid="tabs-container-header"
        className="flex justify-between px-9.6 pt-8 pb-7.6 border-b border-gray-light-stroke"
      >
        <div className="flex gap-x-9.6">
          <TabList tabs={tabElements} />
        </div>
        <div className="flex items-center justify-end">{action}</div>
      </div>
      {tabs.map((o) => (
        <div
          key={o.id}
          role="tabpanel"
          aria-labelledby={o.id}
          className={o.id === selectedId ? '' : 'hidden'}
        >
          {o.id === selectedId && children}
        </div>
      ))}
    </div>
  );
};

Tabs.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  tabs: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedId: PropTypes.string.isRequired,
  action: PropTypes.node.isRequired,
};

Tabs.displayName = 'Tabs';

export default Tabs;
