import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button } from 'components/ui/buttons';

const CreateGoOutButton = (props) => {
  const { onClickCallback, fullWidth } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const onClick = () => {
    navigate('/adauga-partida', { state: { from: location.pathname } });

    if (onClickCallback) {
      onClickCallback();
    }
  };

  return (
    <Button variant="primary" onClick={onClick} fullWidth={fullWidth}>
      Crează partidă
    </Button>
  );
};

CreateGoOutButton.propTypes = {
  onClickCallback: PropTypes.func,
  fullWidth: PropTypes.bool,
};

CreateGoOutButton.defaultProps = {
  onClickCallback: () => {},
  fullWidth: false,
};

export default CreateGoOutButton;
