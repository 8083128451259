import camelCase from 'lodash/camelCase';
import snakeCase from 'lodash/snakeCase';
import { format } from 'date-fns';
import { ro } from 'date-fns/locale/ro';

export const getTextWithoutDiacritics = (text) =>
  text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

export const isClickWithinElementBounds = (element, event) => {
  // Get the bounding client rectangle of the element
  const rect = element.getBoundingClientRect();

  // Check if click event coordinates are within the bounding client rectangle
  return (
    event.clientX >= rect.left &&
    event.clientX <= rect.right &&
    event.clientY >= rect.top &&
    event.clientY <= rect.bottom
  );
};

export const getCookie = (cookies, name) => {
  // Split cookie string and get all individual name=value pairs in an array
  const cookieArr = (cookies || '').split(';');
  // Loop through the array elements
  for (let i = 0; i < cookieArr.length; i++) {
    const cookiePair = cookieArr[i].split('=');
    /* Removing whitespace at the beginning of the cookie name
        and compare it with the given string */
    if (name === cookiePair[0].trim()) {
      // Decode the cookie value and return
      return decodeURIComponent(cookiePair[1]);
    }
  }
  // Return null if not found
  return null;
};

export const onFieldChange = (data, setter, validator = null) => {
  let value;
  if (data && data.target) {
    value = data.target.value;
  } else {
    value = data || '';
  }

  setter({
    isDirty: true,
    value,
    isValid: validator ? validator(value) : true,
    error: null,
  });
};

export const getDefaultFields = (fieldsMeta) => {
  return Object.keys(fieldsMeta).reduce((acc, key) => {
    acc[key] = {
      value: fieldsMeta[key].value || '',
      isDirty: false,
      isValid: fieldsMeta[key].isValid || false,
      error: null,
    };
    return acc;
  }, {});
};

export const toCamelCase = (data) => {
  if (Array.isArray(data)) {
    return data.map((item) => toCamelCase(item));
  }

  if (data !== null && typeof data === 'object') {
    return Object.keys(data).reduce((acc, key) => {
      const camelKey = camelCase(key);
      acc[camelKey] = toCamelCase(data[key]);
      return acc;
    }, {});
  }

  return data;
};

export const toSnakeCase = (data) => {
  if (Array.isArray(data)) {
    return data.map((item) => toSnakeCase(item));
  }

  if (data !== null && typeof data === 'object') {
    return Object.keys(data).reduce((acc, key) => {
      const camelKey = snakeCase(key);
      acc[camelKey] = toSnakeCase(data[key]);
      return acc;
    }, {});
  }

  return data;
};

export const setCookie = (name, value, days, env = 'development') => {
  const isProduction = env === 'production';
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }

  let cookieValue = `${value || ''}${expires}; path=/`;
  if (isProduction) {
    const currentDomain = window.location.hostname;
    cookieValue += `; SameSite=Lax; Secure; Domain=.${currentDomain}`;
  }

  document.cookie = `${name}=${cookieValue}`;
};

export const clearAuthCookies = (env) => {
  if (typeof document !== 'undefined') {
    setCookie('access_token', null, -1, env);
    setCookie('refresh_token', null, -1, env);
  }
};

export const getFieldsValues = (fields, customKeyGetter = null) =>
  Object.keys(fields).reduce((acc, key) => {
    const customKey = customKeyGetter ? customKeyGetter(key) : key;
    acc[customKey] = fields[key].value || undefined;
    return acc;
  }, {});

export const setFieldsErrors = (errors, setter) =>
  setter((currentFields) =>
    Object.keys(errors).reduce((acc, key) => {
      acc[key] = {
        ...currentFields[key],
        isValid: false,
        isDirty: true,
        error: 'Acest câmp nu este valid',
      };
      return acc;
    }, currentFields),
  );

export const getFormattedDate = (date, options) => {
  const { withTime = false, shortFormat = false, timeOnly = false } = options || {};

  if (timeOnly) {
    return date ? format(date, 'HH:mm', { locale: ro }) : '';
  }

  let formatString;

  if (shortFormat) {
    formatString = 'dd.MM.yy';
  } else {
    formatString = 'd MMMM yyyy';
  }

  if (withTime) {
    formatString += ' HH:mm';
  }

  return date ? format(date, formatString, { locale: ro }) : '';
};
