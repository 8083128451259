import React from 'react';
import PropTypes from 'prop-types';

const Subtitle = (props) => {
  const { children } = props;

  return <h1 className="text-lg lg:text-xl text-green-dark font-semibold italic">{children}</h1>;
};

Subtitle.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
};

Subtitle.displayName = 'Subtitle';

export default Subtitle;
