import {
  INIT_REELS,
  ADD_REEL,
  SELECT_REEL,
  DESELECT_REEL,
} from '../../types/availableEntities/reels';

export const init = (payload) => ({
  type: INIT_REELS,
  payload,
});

export const add = (payload) => ({
  type: ADD_REEL,
  payload,
});

export const select = (id, index) => ({
  type: SELECT_REEL,
  payload: {
    id,
    index,
  },
});

export const deselect = (payload) => ({
  type: DESELECT_REEL,
  payload,
});
