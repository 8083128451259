import React from 'react';
import Header from 'components/authentication/Header';
import { NavLink } from 'components/ui/links';
import { Button } from 'components/ui/buttons';

const PasswordResetRequest = () => {
  const onSubmit = (event) => {
    event.preventDefault();
    return false;
  };

  return (
    <div className="flex flex-col h-full justify-start gap-8 xl:gap-12 relative">
      <Header>Resetare parolă</Header>
      <form className="grid gap-8 xl:gap-9.6 w-full" onSubmit={onSubmit}>
        <p>
          Urmează să ceri o resetare a parolei pentru contul tău. Te rugăm să introduci adresa de
          e-mail pentru care dorești resetarea parolei în câmpul de mai jos.
        </p>
        <div
          className="w-full bg-gray-300 flex items-center justify-center"
          style={{ height: '7rem' }}
        >
          E-mail address input
        </div>
        <Button variant="primary" submit>
          Resetează parola
        </Button>
      </form>
      <div className="flex justify-end relative lg:absolute bottom-0 right-0 mt-24 lg:mt-0">
        <NavLink to="/" variant="accent">
          Mergi la pagina de autentificare
        </NavLink>
      </div>
    </div>
  );
};

export default PasswordResetRequest;
