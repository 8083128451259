import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Button } from 'components/ui/buttons';
import GenericDropdown from '../GenericDropdown';
import Option from './Option';

const OptionsDropdown = (props) => {
  const { buttonProps, items, onChange } = props;
  const TriggerComponent = buttonProps.icon ? IconButton : Button;

  return (
    <GenericDropdown
      triggerComponent={TriggerComponent}
      triggerProps={buttonProps}
      closeOnClick
      removePaddings
    >
      <div className="flex flex-col rounded-inherit">
        {items.map(({ label, selected, icon }, index) => (
          <Option
            first={index === 0}
            last={index === items.length - 1}
            key={label}
            value={label}
            onClick={() => onChange(index)}
            icon={icon}
            selected={selected}
          />
        ))}
      </div>
    </GenericDropdown>
  );
};

OptionsDropdown.propTypes = {
  buttonProps: PropTypes.object.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      selected: PropTypes.bool,
      icon: PropTypes.string,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
};

OptionsDropdown.displayName = 'OptionsDropdown';

export default OptionsDropdown;
