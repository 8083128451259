const { ENV, DOMAIN, GOOGLE_CLIENT_ID } = process.env;

const getRedirectUri = (provider) => {
  let schema;

  if (ENV === 'development') {
    schema = 'http';
  } else {
    schema = 'https';
  }

  return `${schema}://${DOMAIN}/autentificare/${provider}`;
};

export const GOOGLE = {
  identifier: 'google',
  authUrl: 'https://accounts.google.com/o/oauth2/v2/auth',
  authParams: {
    client_id: GOOGLE_CLIENT_ID,
    redirect_uri: getRedirectUri('google'),
    response_type: 'code',
    scope:
      'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
    include_granted_scopes: 'true',
    access_type: 'offline',
    prompt: 'select_account',
  },
};

export const FACEBOOK = {
  identifier: 'facebook',
  authUrl: 'https://www.facebook.com/v18.0/dialog/oauth',
  authParams: {
    client_id: '966859931042269',
    redirect_uri: getRedirectUri('facebook'),
    scope: 'email',
  },
};

export const IDENTITY_PROVIDERS = {
  ...Object.fromEntries([GOOGLE, FACEBOOK].map((provider) => [provider.identifier, provider])),
};
