import React, { useContext, useEffect, useState } from 'react';
import CanSubmitContext from 'contexts/CanSubmitContext';
import CanEditContext from 'contexts/CanEditContext';
import { IconButton } from 'components/ui/buttons';
import IconStats from 'components/ui/IconStats';
import { getReelName, getRodName } from 'components/Equipment/utility/utils';
import { getLineName, getHookName } from 'components/GoOut/utility/utils';
import Step from '../Step';
import CombinationContainer from '../CombinationContainer';
import Section from '../Section';
import RodSelector from './RodSelector';
import ReelSelector from './ReelSelector';
import MaineLineSelector from './MainLineSelector';
import RigLineSelector from './RigLineSelector';
import RigLeaderSelector from './RigLeaderSelector';
import PowerGumSelector from './PowerGumSelector';
import HookSelector from './HookSelector';
import { AddNewContext, AvailableEntitiesContext } from '../../state/contexts';
import {
  addNewGearEntry,
  removeGearEntry,
  addNewRigEntry,
  removeRigEntry,
} from '../../state/actions/addNew/equipment';
import { stepTitle, getStepDescription, getGearTitle, getRigTitle, getGearObject } from './utils';

const Gear = () => {
  const [, setCanSubmit] = useContext(CanSubmitContext);
  const editable = useContext(CanEditContext);
  const [isAccordionIdOpened, setIsAccordionIdOpened] = useState(editable ? 'gear-0' : null);
  const [
    {
      equipment: { gear, rigs },
    },
    dispatchFormData,
  ] = useContext(AddNewContext);
  const [
    {
      rods: { all: rods },
      reels: { all: reels },
      mainLines: { all: mainLines },
      rigLines: { all: rigLines },
      leaderLines: { all: leaderLines },
      powerGums: { all: powerGums },
      hooks: { all: hooks },
    },
  ] = useContext(AvailableEntitiesContext);

  useEffect(() => {
    const isGearValid = gear.every((obj) => {
      const ids = Object.keys(obj).map((key) => obj[key]);
      return ids.length === 3 && ids.every((id) => id !== null);
    });
    const isRigsValid = rigs.every((obj) => !!obj.hook);
    setCanSubmit(isGearValid && isRigsValid);
  }, [setCanSubmit, gear, rigs]);

  const canDeleteGear = gear.length > 1;
  const canDeleteRig = rigs.length > 1;
  const onAddNewGear = () => dispatchFormData(addNewGearEntry());
  const onGearDelete = (index) => dispatchFormData(removeGearEntry(index));
  const onAddNewRig = () => dispatchFormData(addNewRigEntry());
  const onRigDelete = (index) => dispatchFormData(removeRigEntry(index));
  const onToggleAccordionId = (id) => setIsAccordionIdOpened((prev) => (prev === id ? null : id));

  return (
    <Step title={stepTitle} description={getStepDescription(editable)}>
      <Section title="Echipament folosit" className="flex flex-col">
        {gear.map((ids, index) => {
          const rod = getGearObject(rods, ids, 'rod');
          const reel = getGearObject(reels, ids, 'reel');
          const line = getGearObject(mainLines, ids, 'line');
          const title = getGearTitle(rod, reel, line);

          if (editable) {
            return (
              <CombinationContainer
                key={index}
                number={index + 1}
                onDelete={canDeleteGear ? () => onGearDelete(index) : undefined}
                title={title}
                opened={isAccordionIdOpened === `gear-${index}`}
                onToggle={() => onToggleAccordionId(`gear-${index}`)}
              >
                <RodSelector index={index} editable />
                <ReelSelector index={index} editable />
                <MaineLineSelector index={index} editable />
              </CombinationContainer>
            );
          }

          return (
            <div key={index} className="flex">
              <IconStats
                icon="fish"
                data={[
                  { label: 'Lansetă', value: getRodName(rod) },
                  { label: 'Mulinetă', value: getReelName(reel) },
                  { label: 'Linie principală', value: getLineName(line) },
                ]}
                size="small"
                rounded="small"
              />
            </div>
          );
        })}
        {editable && (
          <div>
            <IconButton
              variant="link"
              onClick={onAddNewGear}
              size="small"
              weight="medium"
              icon="plus-small"
            >
              Adaugă un alt echipament
            </IconButton>
          </div>
        )}
      </Section>
      <Section title="Monturi și strune folosite" className="flex flex-col">
        {rigs.map((ids, index) => {
          const line = getGearObject(rigLines, ids, 'line');
          const hook = getGearObject(hooks, ids, 'hook');
          const leader = getGearObject(leaderLines, ids, 'leader');
          const powerGum = getGearObject(powerGums, ids, 'powerGum');
          const title = getRigTitle(line, hook, leader, powerGum);

          if (editable) {
            return (
              <CombinationContainer
                key={index}
                number={index + 1}
                onDelete={canDeleteRig ? () => onRigDelete(index) : undefined}
                title={title}
                opened={isAccordionIdOpened === `rig-${index}`}
                onToggle={() => onToggleAccordionId(`rig-${index}`)}
              >
                <RigLineSelector index={index} editable={editable} />
                <RigLeaderSelector index={index} editable={editable} />
                <PowerGumSelector index={index} editable={editable} />
                <HookSelector index={index} editable={editable} />
              </CombinationContainer>
            );
          }

          return (
            <div key={index} className="flex">
              <IconStats
                icon="fish"
                data={[
                  { label: 'Strună', value: getLineName(getGearObject(mainLines, ids)) },
                  { label: 'Cârlig', value: getHookName(getGearObject(hooks, ids)) },
                ]}
                size="small"
                rounded="small"
              />
            </div>
          );
        })}
        {editable && (
          <div>
            <IconButton
              variant="link"
              onClick={onAddNewRig}
              size="small"
              weight="medium"
              icon="plus-small"
            >
              Adaugă o altă montură și strună
            </IconButton>
          </div>
        )}
      </Section>
    </Step>
  );
};

Gear.displayName = 'Gear';

export default Gear;
