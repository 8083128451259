import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { CalendarDropdown } from 'components/ui/dropdowns';
import IconStats from 'components/ui/IconStats';
import Context from 'components/GoOut/state/contexts/addNew';
import { setStartDate } from 'components/GoOut/state/actions/addNew/others';
import { onFieldChange } from 'utility/utils';
import { isRequired } from 'utility/validation';
import { format } from 'date-fns';
import { ro } from 'date-fns/locale/ro';

const StartDateInput = (props) => {
  const { editable } = props;
  const [state, dispatch] = useContext(Context);
  const {
    event: {
      startDate: {
        value: startDateValue,
        isValid: startDateIsValid,
        isDirty: startDateIsDirty,
        error: startDateError,
      },
    },
  } = state;

  const onChange = (date) => {
    const setter = (newItem) => dispatch(setStartDate({ ...newItem, value: date }));
    onFieldChange(format(date, 'd MMMM yyyy'), setter, isRequired);
  };

  const defaultProps = {
    id: 'startDate',
    name: 'startDate',
    placeholder: 'zz/ll/aaaa',
    label: 'Dată de început',
    type: 'text',
    autoFocus: true,
    required: true,
    valid: startDateIsDirty ? startDateIsValid : undefined,
    error: startDateError,
  };

  if (editable) {
    return (
      <CalendarDropdown
        onChange={onChange}
        triggerProps={defaultProps}
        withCurrentDate
        date={startDateValue ? new Date(startDateValue) : null}
      />
    );
  }

  const displayValue = startDateValue ? format(startDateValue, 'd MMMM yyyy', { locale: ro }) : '-';
  return (
    <IconStats
      icon="calendar"
      data={[{ label: 'Dată de început', value: displayValue }]}
      rounded="small"
    />
  );
};

StartDateInput.propTypes = {
  editable: PropTypes.bool.isRequired,
};

export default StartDateInput;
