import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/ui/Modal';
import ZoomSlider from 'components/GoOut/steps/Media/controls/ZoomSlider';
import Cropper from 'react-easy-crop';

const PictureModal = (props) => {
  const { picture, onClose, onSubmit, meta } = props;
  const [zoom, setZoom] = useState(meta.zoom);
  const [crop, setCrop] = useState({
    x: meta.x,
    y: meta.y,
  });
  const [croppedArea, setCroppedArea] = useState({});
  const [orientation, setOrientation] = useState('landscape');

  const imageUrl = useMemo(() => URL.createObjectURL(picture), [picture]);
  const onCropComplete = (_, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };

  useEffect(() => {
    const img = new Image();
    img.src = URL.createObjectURL(picture);
    img.onload = () => {
      const imgOrientation = img.width > img.height ? 'landscape' : 'portrait';
      setOrientation(imgOrientation);
    };
  }, [picture]);

  return (
    <Modal
      title="Adjust cover image"
      onClose={onClose}
      onSubmit={() =>
        onSubmit({
          cropData: {
            ...crop,
            zoom,
          },
          croppedArea: { ...croppedArea },
        })
      }
      description="Crop image to fit the cover frame"
    >
      <div className="relative flex h-160 rounded-inherit">
        <Cropper
          image={imageUrl}
          crop={crop}
          zoom={zoom}
          aspect={orientation === 'landscape' ? 4 / 3 : 3 / 4}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          objectFit="vertical-cover"
        />
      </div>
      <ZoomSlider zoom={zoom} onChange={setZoom} />
    </Modal>
  );
};

PictureModal.propTypes = {
  picture: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  meta: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
    zoom: PropTypes.number,
  }),
};

PictureModal.defaultProps = {
  meta: {
    x: 0,
    y: 0,
    zoom: 1,
  },
};

PictureModal.displayName = 'PictureModal';

export default PictureModal;
