import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/ui/Modal';
import BrandInput from 'components/Equipment/inputs/BrandInput';
import ModelInput from 'components/Equipment/inputs/ModelInput';
import { Input } from 'components/ui/inputs';
import useFormFields from 'components/Equipment/hooks/useFormFields';
import useSubmit from 'components/Equipment/hooks/useSubmit';
import useBrandsLoader from 'hooks/useBrandsLoader';
import { isIntegerNumber } from 'utility/validation';

const HookModal = (props) => {
  const { onClose, item, onSuccess } = props;
  const { size } = item || {};
  const [fields, setFields, individualSetters] = useFormFields(item, {
    size: {
      field: {
        value: size ? size.toString() : '',
        isValid: !!size,
      },
      validator: isIntegerNumber,
    },
  });

  const [isLoadingBrands, allBrands] = useBrandsLoader();
  const [canSubmit, onSubmit] = useSubmit('hooks', item, fields, setFields, onSuccess);

  return (
    <Modal title="Adaugă cârlig" onClose={onClose} onSubmit={onSubmit} canSubmit={canSubmit} form>
      <BrandInput
        loading={isLoadingBrands}
        all={allBrands}
        onChange={individualSetters.brand}
        {...fields.brand}
      />
      <ModelInput onChange={individualSetters.model} {...fields.model} />
      <Input
        id="size"
        name="size"
        onChange={individualSetters.size}
        placeholder="Introdu număr"
        label="Număr"
        required
        inputMode="numeric"
        pattern="[0-9]*"
        value={fields.size.value}
        valid={fields.size.isDirty ? fields.size.isValid : undefined}
        helperText={
          fields.size.isDirty && !fields.size.isValid
            ? fields.size.error || 'Introdu un număr întreg'
            : null
        }
      />
    </Modal>
  );
};

HookModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    brand: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    model: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
  }),
};

HookModal.defaultProps = {
  item: null,
};

HookModal.displayName = 'HookModal';

export default HookModal;
