import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/ui/Modal';
import Context from 'components/GoOut/state/contexts/addNew';
import { getReducerValues } from 'components/GoOut/utility/utils';
import { useNavigate, useParams } from 'react-router-dom';
import { ACTIONS } from 'components/Equipment/utility/constants';
import useAPI from '../../hooks/useAPI';

const actions = {
  add: 'adaugi',
  edit: 'modifici',
  delete: 'sterge',
};

const SubmitModal = (props) => {
  const { onClose, action, onSuccess, successMessage } = props;
  const [state] = useContext(Context);
  const navigate = useNavigate();
  const { apiCall } = useAPI();
  const titleAction = ACTIONS[action];
  const { id } = useParams();

  const actionText = actions[action];

  const onSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    let method;
    let url = '/go-outs';
    switch (action) {
      case 'add':
        method = 'post';
        break;
      case 'edit':
        method = 'put';
        url += `/${id}`;
        break;
      case 'delete':
        method = 'delete';
        url += `/${id}`;
        break;
      default:
        break;
    }

    let formData;
    if (action !== 'delete') {
      formData = new FormData();
      formData.append('data', JSON.stringify(getReducerValues(state)));
      state.media.map((media) => formData.append('media', media.file));
    }

    await apiCall(url, method, formData, {
      getSuccessMessage: () => successMessage,
      onSuccessCallback: () => onSuccess(),
      onErrorCallback: (statusCode) => {
        if (statusCode === 404 || statusCode === 403) {
          navigate('/partidele-mele');
        }
      },
    });
  };
  return (
    <Modal
      title={`${titleAction} partida`}
      onSubmit={onSubmit}
      canSubmit
      onClose={onClose}
      danger={action === 'delete'}
    >
      Esti pe cale sa {actionText.toLowerCase()} o partida. Esti sigur ca vrei sa continui?
    </Modal>
  );
};

SubmitModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  action: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  successMessage: PropTypes.string.isRequired,
};

SubmitModal.displayName = 'SubmitModal';

export default SubmitModal;
