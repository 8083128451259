import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components/ui/buttons';
import ProgressBar from 'components/ui/Stepper/ProgressBar';
import ProgressBarMobile from 'components/ui/Stepper/ProgressBarMobile';
import cn from 'classnames';
import { useLocation } from 'react-router-dom';

const Stepper = (props) => {
  const { steps, onSubmit, canSubmit, onChange, freeSelection, editable } = props;
  const [currentStep, setCurrentStep] = useState(0);
  const location = useLocation();

  useEffect(() => {
    setCurrentStep(0);
  }, [location]);

  const onBackwardsClick = () => {
    setCurrentStep(currentStep - 1);
    if (onChange) {
      onChange(currentStep - 1);
    }
  };

  const onForwardClick = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
      if (onChange) {
        onChange(currentStep);
      }
    } else {
      setCurrentStep(steps.length);
      onSubmit();
    }
  };

  const onSelect = (index) => {
    setCurrentStep(index);
  };

  return (
    <div
      data-testid="stepper-container"
      /* eslint-disable-next-line max-len */
      className="flex flex-col md:flex-row border border-gray-light-stroke rounded-3.2 p-6 bg-white h-full"
    >
      <div
        data-testid="stepper-tracker"
        className="pb-6.4 md:pb-0 md:pr-6.4 md:border-r md:border-gray-light-stroke"
      >
        <ProgressBarMobile
          steps={steps}
          currentStep={currentStep}
          onSelect={freeSelection ? onSelect : null}
        />
        <ProgressBar
          steps={steps}
          currentStep={currentStep}
          onSelect={freeSelection ? onSelect : null}
        />
      </div>
      <div data-testid="stepper-content" className="flex-1 flex flex-col md:pl-6.4 gap-y-6.4">
        <div className="flex-1">
          {currentStep === steps.length
            ? steps[currentStep - 1].content
            : steps[currentStep].content}
        </div>
        <div className="md:grid md:grid-cols-2">
          <span className="hidden md:block">&nbsp;</span>
          <div data-testid="stepper-buttons" className="grid grid-cols-2 gap-x-3.2">
            <div
              className={cn('col-span-1', {
                hidden: currentStep === 0,
                'col-start-2': !editable && currentStep === steps.length - 1,
              })}
              data-testid="backwards-button"
            >
              <Button onClick={onBackwardsClick} variant="secondary" fullWidth>
                Înapoi
              </Button>
            </div>
            <div
              className={cn('col-span-1 col-start-2', {
                hidden: !editable && currentStep === steps.length - 1,
              })}
              data-testid="forward-button"
            >
              <Button onClick={onForwardClick} fullWidth disabled={!canSubmit}>
                Continuă
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Stepper.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.node,
    }),
  ).isRequired,
  onSubmit: PropTypes.func.isRequired,
  canSubmit: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  freeSelection: PropTypes.bool,
  editable: PropTypes.bool,
  currentStep: PropTypes.number,
};

Stepper.defaultProps = {
  onChange: null,
  freeSelection: false,
  editable: true,
  currentStep: 0,
};

Stepper.displayName = 'Stepper';

export default Stepper;
