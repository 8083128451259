import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/ui/Modal';
import useAPI from 'hooks/useAPI';
import { getRodName, getReelName } from '../utility/utils';

const getEntityMeta = (entity, item) => {
  switch (entity) {
    case 'rods':
      return {
        title: 'lansetă',
        articulatedTitle: 'lanseta',
        demonstrativePronoun: 'Această',
        name: getRodName(item),
        deleteActionKeyword: 'ștearsă',
      };
    case 'reels':
      return {
        title: 'mulinetă',
        articulatedTitle: 'mulineta',
        demonstrativePronoun: 'Această',
        name: getReelName(item),
        deleteActionKeyword: 'ștearsă',
      };
    default:
      throw new Error('Invalid entity');
  }
};

const DeleteModal = (props) => {
  const { onClose, onSuccess, item, entity } = props;
  const { id: itemId } = item;
  const { apiCall, isSubmitting } = useAPI();
  const {
    title: entityTitle,
    articulatedTitle: entityArticulatedTitle,
    demonstrativePronoun: entityDemonstrativePronoun,
    name: entityName,
    deleteActionKeyword: entityDeleteActionKeyword,
  } = useMemo(() => getEntityMeta(entity, item), [entity, item]);

  const onSubmit = async () => {
    const entityTitleTransformed = entityTitle.charAt(0).toUpperCase() + entityTitle.slice(1);
    const successMessage = `${entityTitleTransformed} ${entityName} a fost ${entityDeleteActionKeyword} cu succes.`;

    await apiCall(
      `/gear/${entity}/${itemId}`,
      'delete',
      {},
      {
        onSuccessCallback: () => onSuccess(),
        getErrorMessage: () => null,
        getSuccessMessage: () => successMessage,
      },
    );
  };

  return (
    <Modal
      title={`Ștergere ${entityTitle}`}
      onClose={onClose}
      onSubmit={onSubmit}
      cancelText="Nu"
      submitText="Da"
      danger
      canSubmit={!isSubmitting}
    >
      <p className="text-gray-dark leading-8">
        Ești sigur că vrei să ștergi {entityArticulatedTitle}{' '}
        <span className="font-semibold">{entityName}</span> din echipamentul tău?{' '}
        {entityDemonstrativePronoun} nu va mai putea fi folosită pentru partidele următoare.
      </p>
    </Modal>
  );
};

DeleteModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  item: PropTypes.shape({
    id: PropTypes.number,
    brand: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    model: PropTypes.string,
    length: PropTypes.string,
    lengthUnit: PropTypes.string,
    power: PropTypes.number,
    powerUnit: PropTypes.string,
    action: PropTypes.string,
  }).isRequired,
  entity: PropTypes.oneOf(['rods', 'reels']).isRequired,
};

DeleteModal.displayName = 'DeleteModal';

export default DeleteModal;
