import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Input, IconInput } from '../../inputs';
import Chip from '../../Chip';
import { useShowMoreNumber, useVisibleItems } from '../hooks';

const InputWrapper = (props) => {
  const { values, icon, onClick: onClickCallback, opened, singleSelection, ...otherProps } = props;
  const chipsContainerRef = useRef(null);
  const auxChipsContainerRef = useRef(null);
  const paddingLeft = icon ? 5.1 : 1.5;
  const paddingRight = 5.1;
  const showMoreNumber = useShowMoreNumber(
    values,
    chipsContainerRef,
    auxChipsContainerRef,
    paddingLeft,
    paddingRight,
  );
  const viewableChips = useVisibleItems(values, chipsContainerRef, showMoreNumber);
  const InputComponent = icon ? IconInput : Input;

  const onClick = (event) => {
    if (!event || event.target.closest('[data-testid="chip"]') === null) {
      onClickCallback(event);
    }
  };

  const onKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      onClick(event);
    }
  };

  const getInputValue = () => {
    if (singleSelection) {
      return viewableChips[0]?.value || '';
    }

    return viewableChips.length === 0 ? undefined : ' ';
  };

  return (
    <div
      className="relative cursor-pointer rounded-3.2"
      data-testid="dropdown-input-wrapper"
      role="button"
      tabIndex={0}
      onClick={onClick}
      onKeyDown={onKeyDown}
    >
      <InputComponent
        dropDown
        dropDownOpened={opened}
        icon={icon}
        value={getInputValue()}
        {...otherProps}
      />
      {!singleSelection && (
        <>
          <div
            ref={auxChipsContainerRef}
            data-testid="aux-dropdown-chips-container"
            className="fixed flex -top-full -left-full invisible"
          >
            {values.map(({ value, onClick: onChipClose }) => (
              <Chip key={value} label={value} onClose={onChipClose} />
            ))}
          </div>
          <div
            ref={chipsContainerRef}
            data-testid="dropdown-chips-container"
            className="absolute top-10.4 bottom-0 left-0 right-0 rounded-3.2 flex gap-1.6 items-center invisible"
            style={{
              paddingLeft: `${paddingLeft}rem`,
              paddingRight: `${paddingRight}rem`,
            }}
          >
            {viewableChips.map(
              ({ value, onClick: onChipClick, isVisible }) =>
                isVisible && <Chip key={value} label={value} onClose={onChipClick} />,
            )}
            {showMoreNumber > 0 && <Chip label={`+${showMoreNumber}`} />}
          </div>
        </>
      )}
    </div>
  );
};

InputWrapper.propTypes = {
  values: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      onClick: PropTypes.func,
    }),
  ),
  icon: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  opened: PropTypes.bool,
  singleSelection: PropTypes.bool,
};

InputWrapper.defaultProps = {
  values: [],
  icon: null,
  opened: false,
  singleSelection: false,
};

export default InputWrapper;
