import React, { useState, useRef, useEffect } from 'react';
import Sidebar from './Sidebar';
import Content from './Content';
import Header from './Header';

const Layout = () => {
  const [renderSidebar, setRenderSidebar] = useState(false);
  const [isMobileSidebarOpened, setIsMobileSidebarOpened] = useState(false);
  const mobileSidebarTriggerRef = useRef(null);
  const onMobileSidebarOpen = () => setIsMobileSidebarOpened(true);
  const onMobileSidebarClose = () => setIsMobileSidebarOpened(false);

  useEffect(() => {
    if (mobileSidebarTriggerRef.current) {
      setRenderSidebar(true);
    }
  }, [mobileSidebarTriggerRef]);

  return (
    <>
      {renderSidebar && (
        <>
          <div className="flex items-center justify-center xl:hidden">
            <Sidebar
              opened={isMobileSidebarOpened}
              triggerRef={mobileSidebarTriggerRef}
              onClose={onMobileSidebarClose}
            />
          </div>
          <div className="hidden xl:flex items-center justify-center">
            <Sidebar opened toggleable={false} />
          </div>
        </>
      )}
      <Header
        onSidebarOpen={onMobileSidebarOpen}
        sidebarTriggerRef={mobileSidebarTriggerRef}
        isSidebarOpened={isMobileSidebarOpened}
      />
      <Content className="mt-35.6 xl:ml-104 3xl:ml-0" />
    </>
  );
};

export default Layout;
