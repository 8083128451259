import React from 'react';
import PropTypes from 'prop-types';
import IconStats from 'components/ui/IconStats';

const Stats = (props) => {
  const { startDate, endDate, species, quantity, personalScore, waterCondition } = props;

  return (
    <>
      <IconStats
        icon="calendar"
        data={[
          { label: 'Dată start', value: startDate },
          { label: 'Dată final', value: endDate },
        ]}
        size="small"
      />
      <IconStats
        icon="drop"
        data={[
          { label: 'Claritate', value: waterCondition.clearness },
          { label: 'Cotă', value: waterCondition.level },
          { label: 'Curent', value: waterCondition.speed },
        ]}
        size="small"
      />
      <IconStats icon="fishes" data={[{ label: 'Specii prinse', value: species }]} size="small" />
      <div className="flex gap-4.8">
        <IconStats
          icon="scale"
          data={[{ label: 'Cantitate', value: `${quantity.toFixed(3)} kg` }]}
          size="small"
        />
        <IconStats
          icon="user-star"
          data={[{ label: 'Scor personal', value: personalScore }]}
          size="small"
        />
      </div>
    </>
  );
};

Stats.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  species: PropTypes.array.isRequired,
  quantity: PropTypes.number.isRequired,
  personalScore: PropTypes.number.isRequired,
  waterCondition: PropTypes.shape({
    clearness: PropTypes.string,
    level: PropTypes.number,
    speed: PropTypes.string,
  }).isRequired,
};

Stats.displayName = 'Stats';

export default Stats;
