import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import IconStats from 'components/ui/IconStats';
import Context from 'components/GoOut/state/contexts/addNew';
import { setEndTime } from 'components/GoOut/state/actions/addNew/others';
import { onFieldChange } from 'utility/utils';
import { isRequired } from 'utility/validation';
import { Input } from 'components/ui/inputs';

const StartDateInput = (props) => {
  const { editable } = props;
  const [state, dispatch] = useContext(Context);
  const {
    event: {
      endTime: {
        value: endTimeValue,
        isValid: endTimeIsValid,
        isDirty: endTimeIsDirty,
        error: endTimeError,
      },
    },
  } = state;

  const onEndTimeChange = (event) => {
    const setter = (newItem) => dispatch(setEndTime(newItem));
    onFieldChange(event.target.value, setter, isRequired);
  };

  const inputProps = {
    placeholder: 'hh:mm',
    label: 'Oră de final',
  };

  if (editable) {
    return (
      <Input
        className="h-17.2 bg-white text-left"
        type="time"
        {...inputProps}
        value={endTimeValue}
        onChange={onEndTimeChange}
        required
        valid={endTimeIsDirty ? endTimeIsValid : undefined}
        error={endTimeError}
      />
    );
  }
  return (
    <IconStats
      icon="clock"
      data={[{ label: 'Oră de final', value: endTimeValue }]}
      rounded="small"
    />
  );
};

StartDateInput.propTypes = {
  editable: PropTypes.bool.isRequired,
};

export default StartDateInput;
