import { useEffect, useState, useMemo } from 'react';
import { useLoaderData } from 'react-router-dom';
import { toast } from 'react-toastify';
import { DEFAULT_API_ERROR } from 'constants/forms';
import { getRodsTableData, getReelsTableData } from '../utility/utils';

const useData = (activeTab) => {
  const { data: { items: serverItems = [], otherCount = 0 } = {}, error } = useLoaderData();
  const [items, setItems] = useState([]);

  useEffect(() => {
    setItems(serverItems);
  }, [serverItems]);

  useEffect(() => {
    if (error) {
      const { response: errorResponse } = error;

      if (errorResponse) {
        const { status: statusCode, data: errorData } = errorResponse;

        if (statusCode === 401 && errorData.detail === 'Not authenticated') {
          toast.error('Nu aveți permisiunea necesară pentru a accesa această pagină.');
          return;
        }
      }

      toast.error(DEFAULT_API_ERROR);
    }
  }, [error]);

  const rowsData = useMemo(() => {
    if (activeTab === 'rods') {
      return getRodsTableData(items);
    }

    return getReelsTableData(items);
  }, [items, activeTab]);

  const addItem = (newItem) => setItems((prevItems) => [...prevItems, newItem]);
  const editItem = (updatedItem) =>
    setItems((prevItems) =>
      prevItems.map((item) => (item.id === updatedItem.id ? updatedItem : item)),
    );
  const deleteItem = (itemId) =>
    setItems((prevItems) => prevItems.filter((item) => item.id !== itemId));

  return [items, otherCount, rowsData, addItem, editItem, deleteItem];
};

export default useData;
