import { useContext, useEffect } from 'react';
import { setBrands } from 'state/equipment/actions';
import Context from '../contexts/BrandsContext';
import useAPI from './useAPI';

const useBrandsLoader = () => {
  const [{ isLoading, brands }, dispatch] = useContext(Context);
  const { apiCall } = useAPI();

  useEffect(() => {
    if (brands === null) {
      (async () => {
        await apiCall(
          '/gear/brands',
          'GET',
          {},
          {
            getErrorMessage: () => null,
            onErrorCallback: () => dispatch(setBrands([])),
            onSuccessCallback: (data) => dispatch(setBrands(data)),
          },
        );
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brands]);

  return [isLoading, brands || []];
};

export default useBrandsLoader;
