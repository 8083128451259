import React from 'react';
import PropTypes from 'prop-types';
import SelectableInput from './SelectableInput';

const SelectableGroup = (props) => {
  const { items, label: groupLabel } = props;

  return (
    <div className="flex flex-col gap-y-2.4">
      <div className="leading-8 text-gray-dark font-medium">{groupLabel}</div>
      <div data-testid="selectable-group" className="flex items-center gap-3.2 flex-wrap">
        {items.map(({ id, onSelect, label, selected }) => (
          <SelectableInput key={id} onSelect={() => onSelect(id)} selected={selected}>
            {label}
          </SelectableInput>
        ))}
      </div>
    </div>
  );
};

SelectableGroup.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      onSelect: PropTypes.func.isRequired,
      selected: PropTypes.bool,
    }),
  ).isRequired,
  label: PropTypes.string.isRequired,
};

SelectableGroup.displayName = 'SelectableGroup';

export default SelectableGroup;
