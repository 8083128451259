import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from 'components/ui/Icon';

const IconStats = (props) => {
  const { icon, data, size, rounded, className } = props;

  const iconElement = (
    <div
      data-testid="icon-container"
      className={cn('flex justify-center items-center bg-green-dark h-full', {
        'min-w-16.8': size === 'small',
        'min-w-22.4': size === 'medium',
        'rounded-tl-1.6 rounded-bl-1.6': rounded === 'small',
        'rounded-tl-4.8 rounded-bl-4.8': rounded === 'max',
      })}
    >
      <Icon
        className={cn({ 'w-10 h-10': size === 'small' })}
        fillClassName="fill-white"
        identifier={icon}
      />
    </div>
  );

  return (
    <div
      data-testid="icon-stats-container"
      className={cn(
        `flex items-center bg-white gap-x-6.4 w-full border border-green-dark ${className}`,
        {
          'rounded-3.2': rounded === 'small',
          'rounded-6.4': rounded === 'max',
        },
      )}
    >
      {iconElement}
      <div className={cn('flex items-center gap-6.4 w-full')}>
        {data.map(({ label, value }, itemIndex) => (
          <div
            key={itemIndex}
            data-testid="info-container"
            className={cn('grid grid-cols-1 gap-0.8', {
              'py-2 pr-2': size === 'small',
              'py-4 pr-4': size === 'medium',
            })}
          >
            <span
              data-testid="stats-title"
              className={cn('text-gray', {
                'text-xs': size === 'small',
                'text-sm': size === 'medium',
              })}
            >
              {label}
            </span>
            <div
              data-testid="stats-value"
              className={cn(
                'text-nowrap whitespace-nowrap overflow-hidden text-ellipsis items-baseline text-green-dark font-bold',
                { 'text-base': size === 'small', 'text-lg': size === 'medium' },
              )}
            >
              {Array.isArray(value)
                ? (value.length ? value : ['-'])
                    .map((v, index) => (index !== 0 ? v.toLowerCase() : v))
                    .join(', ')
                : value || '-'}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

IconStats.propTypes = {
  icon: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
      ]),
    }),
  ).isRequired,
  size: PropTypes.oneOf(['small', 'medium']),
  rounded: PropTypes.oneOf(['small', 'max']),
  className: PropTypes.string,
  justifyBetween: PropTypes.bool,
};

IconStats.defaultProps = {
  size: 'medium',
  rounded: 'max',
  className: '',
  justifyBetween: false,
};

IconStats.displayName = 'IconStats';

export default IconStats;
