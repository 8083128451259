import React from 'react';
import PropTypes from 'prop-types';
import Input from 'components/ui/inputs/Input';

const ModelInput = (props) => {
  const { value, onChange } = props;

  return (
    <Input
      id="model"
      name="model"
      onChange={onChange}
      placeholder="Introdu modelul"
      label="Model"
      value={value}
      required
    />
  );
};

ModelInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

ModelInput.defaultProps = {
  value: '',
};

ModelInput.displayName = 'ModelInput';

export default ModelInput;
