import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Table from 'components/ui/table/Table';
import Section from '../Section';
import { useTableData } from '../../hooks';

const TableWithData = (props) => {
  const { title, meta, all, selectedIds } = props;
  const [header, columns] = useTableData(meta);
  const data = useMemo(() => all.filter((obj) => selectedIds.includes(obj.id)), [all, selectedIds]);
  const rows = useMemo(
    () =>
      data.map((obj) => ({
        id: obj.id,
        values: columns.map((column) =>
          column
            .split('.')
            .reduce((acc, key) => (acc && acc[key] !== undefined ? acc[key] : undefined), obj),
        ),
        selected: selectedIds.includes(obj.id),
      })),
    [data, selectedIds, columns],
  );

  return (
    <Section className="flex flex-col gap-y-4.8" title={title}>
      <Table header={header} rows={rows} itemsCount={0} />
    </Section>
  );
};

TableWithData.propTypes = {
  title: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  all: PropTypes.array.isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string]))
    .isRequired,
};

TableWithData.displayName = 'TableWithData';

export default TableWithData;
