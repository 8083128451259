import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/ui/Icon';
import cn from 'classnames';

const ZoomSlider = (props) => {
  const { zoom, onChange } = props;
  const [isDragging, setIsDragging] = useState(false);
  const thumbRef = useRef(null);
  const progressRef = useRef(null);
  const filledRef = useRef(null);

  useEffect(() => {
    const updateThumbPosition = () => {
      if (thumbRef.current && progressRef.current) {
        const progressWidth = progressRef.current.offsetWidth;
        const thumbWidth = thumbRef.current.offsetWidth;
        const max = 3;
        const min = 1;
        const range = max - min;
        const relativeValue = (zoom - min) / range;
        const offset = relativeValue * (progressWidth - thumbWidth);
        thumbRef.current.style.left = `${offset}px`;
        filledRef.current.style.width = `${offset + thumbWidth / 2}px`; // Adjust filled width
      }
    };

    updateThumbPosition();
  }, [zoom]);

  const handleMouseMove = useCallback(
    (e) => {
      if (isDragging && progressRef.current) {
        const progressRect = progressRef.current.getBoundingClientRect();
        const progressWidth = progressRect.width;
        const thumbWidth = thumbRef.current.offsetWidth;
        const min = 1;
        const max = 3;
        const range = max - min;
        const newLeft = e.clientX - progressRect.left - thumbWidth / 2;
        const relativeValue = newLeft / (progressWidth - thumbWidth);
        const newValue = min + relativeValue * range;

        if (newValue >= min && newValue <= max) {
          onChange(newValue);
        }
      }
    },
    [isDragging, onChange],
  );

  const handleMouseDown = () => {
    setIsDragging(true);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    if (isDragging) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    } else {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [handleMouseMove, isDragging]);

  const handleChange = (value) => {
    onChange(parseFloat(value));
  };

  return (
    <div className="relative flex flex-col w-full">
      <div className="relative flex justify-between w-full">
        <div className="flex items-center justify-center">
          <Icon identifier="image-placeholder-small" />
        </div>
        <div className="absolute left-12.8 right-16 top-4.8">
          <div data-testid="slider-container" className="relative">
            <input
              type="range"
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              className="sr-only"
              onChange={(e) => handleChange(e.target.value)}
            />
            <div
              ref={progressRef}
              className="relative w-full h-1.6 bg-gray-light-stroke"
              id="progress"
            >
              <div ref={filledRef} className="absolute left-0 h-full bg-blue" />
            </div>
          </div>
          <button
            type="button"
            role={isDragging ? 'slider' : 'button'}
            aria-valuemin={1}
            ref={thumbRef}
            onMouseDown={handleMouseDown}
            className={cn('absolute -top-4 w-9.6 h-9.6 bg-white border border-blue rounded-full', {
              'cursor-grab': !isDragging,
              'cursor-grabbing': isDragging,
            })}
            id="thumb"
          />
        </div>
        <div className="flex items-center">
          <Icon identifier="image_placeholder_medium" />
        </div>
      </div>
    </div>
  );
};

ZoomSlider.propTypes = {
  zoom: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

ZoomSlider.displayName = 'ZoomSlider';

export default ZoomSlider;
