export const isValidEmail = (text) =>
  !!(text || '').trim().match(/^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/);

export const isRequired = (value) =>
  typeof value === 'number' ? !Number.isNaN(value) : (value || '').trim() !== '';

export const isIntegerNumber = (text) => !!(text || '').trim().match(/^\d+$/);

export const isDecimalNumberMaxTwoDecimals = (text) =>
  !!(text || '').trim().match(/^\d+(\.\d{1,2})?$/);

export const isDecimalNumberMaxThreeDecimals = (text) =>
  !!(text || '').trim().match(/^\d+(\.\d{1,3})?$/);

export const isNegativeOrPositiveIntegerNumber = (text) => !!(text || '').trim().match(/^-?\d+$/);
