import React from 'react';
import { hydrateRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { getCookie } from 'utility/utils';
import getRoutes from './routes';

const HTML = () => {
  const authenticated = !!getCookie(document.cookie, 'access_token');
  const router = createBrowserRouter(getRoutes({ authenticated }));

  return <RouterProvider router={router} />;
};

hydrateRoot(document.body, <HTML />);
