import React, { useState } from 'react';
import { Button } from 'components/ui/buttons';
import Header from 'components/authentication/Header';
import { EMPTY_DEFAULT_FIELD } from 'constants/forms';
import { onFieldChange } from 'utility/utils';
import { isValidEmail } from 'utility/validation';
import useTokenValidationResponse from 'components/authentication/hooks/useTokenValidationResponse';
import EmailInput from 'components/authentication/inputs/EmailInput';

const ActivateAccount = () => {
  const [emailField, setEmailField] = useState(EMPTY_DEFAULT_FIELD);
  const onChangeEmailField = (e) => {
    onFieldChange(e.target.value, setEmailField, isValidEmail);
  };
  const canSubmit = emailField.isValid && emailField.isDirty;
  const { onSubmit, token } = useTokenValidationResponse('activate');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const body = {
      token,
      email: emailField.value,
    };
    await onSubmit('/authentication/activate', 'post', body);
  };

  return (
    <div className="flex flex-col h-full justify-start gap-8 xl:gap-12 relative">
      <Header>Activare cont</Header>
      <form className="flex flex-col gap-y-8" onSubmit={handleSubmit}>
        <EmailInput item={emailField} onChange={onChangeEmailField} />
        <div className="flex items-center justify-center">
          <Button variant="primary" submit disabled={!canSubmit}>
            Activează cont
          </Button>
        </div>
      </form>
    </div>
  );
};

ActivateAccount.displayName = 'ActivateAccountRequest';

export default ActivateAccount;
