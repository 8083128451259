import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/ui/Modal';
import Map from 'components/ui/Map';
import { IconInput } from 'components/ui/inputs';
import useGoogleAPI from 'hooks/useGoogleAPI';
import { toast } from 'react-toastify';
import { formatLocation, getAddressData } from 'components/GoOut/utility/utils';

const MapModal = (props) => {
  const { onClose, onSubmit, coordinates, viewOnly, locationDetails, mapId } = props;
  const [locationData, setLocationData] = useState(null); // [1
  const [locationName, setLocationName] = useState(null);
  const [canSubmit, setCanSubmit] = useState(false);
  const [position, setPosition] = useState(null);
  const googleInstance = useGoogleAPI();

  const getLocationDetails = (location) => {
    if (!location) {
      return '';
    }
    return formatLocation(getAddressData(location));
  };

  const onSelectLocation = async (location) => {
    if (googleInstance) {
      const geocoder = new googleInstance.maps.Geocoder();
      try {
        geocoder.geocode(
          {
            location,
          },
          (results, status) => {
            if (status === 'OK') {
              const { address_components: addressComponent } = results[0];
              setLocationName(getLocationDetails(addressComponent));
              setPosition(location);
              setLocationData(results[0]);
              setCanSubmit(true);
            } else {
              toast.error('A apărut o eroare la selectarea locației');
            }
          },
        );
      } catch (error) {
        toast.error('A apărut o eroare la selectarea locației');
      }
    }
  };

  return (
    <Modal
      onSubmit={!viewOnly ? () => onSubmit(locationData) : null}
      title="Locație"
      submitText="Adaugă"
      cancelText="Închide"
      onClose={onClose}
      canSubmit={canSubmit}
    >
      <div className="relative w-full h-192 rounded-inherit flex items-end justify-center">
        <Map
          mapId={mapId}
          coordinates={coordinates || position}
          viewOnly={viewOnly}
          onClick={onSelectLocation}
        />
        {!viewOnly && (
          <div className="absolute mb-10">
            <IconInput
              name="location-details"
              id="location-details"
              onChange={() => {}}
              icon="map-marker-small"
              value={locationName || locationDetails}
              readOnly
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

MapModal.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  coordinates: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  viewOnly: PropTypes.bool,
  locationDetails: PropTypes.string,
  onClick: PropTypes.func,
  mapId: PropTypes.string.isRequired,
};

MapModal.defaultProps = {
  onClose: null,
  onSubmit: null,
  coordinates: null,
  viewOnly: false,
  locationDetails: '',
  onClick: null,
};

MapModal.displayName = 'MapModal';

export default MapModal;
