import { useEffect, useState } from 'react';

const useVisibleItems = (values, chipsContainerRef, showMoreNumber) => {
  const [items, setItems] = useState(values.map((value) => ({ ...value, isVisible: true })));

  useEffect(() => {
    const newItems = values.map((value, index) => ({
      ...value,
      isVisible: index < values.length - showMoreNumber,
    }));
    setItems(newItems);
  }, [showMoreNumber, values]);

  useEffect(() => {
    const { current: chipsContainer } = chipsContainerRef;
    if (chipsContainer) {
      chipsContainer.classList.remove('invisible');
    }
  }, [items, chipsContainerRef]);

  return items;
};

export default useVisibleItems;
