import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from 'components/ui/Icon';

const Branding = (props) => {
  const { className } = props;

  return (
    <div className={cn(className, 'pt-18 lg:pt-52 xl:pt-18')}>
      <a id="logo-link" className="flex justify-center lg:justify-start cursor-pointer" href="/">
        <Icon identifier="logo-hero" />
      </a>
      <h1 className="text-hero-mobile xl:text-hero text-center lg:text-left text-white font-bold mt-18 xl:mt-48 w-full lg:w-160 xl:w-240">
        Înregistrează-ți partidele de pescuit și progresează
      </h1>
      <p className="w-full lg:w-160 xl:w-280 text-center lg:text-left mt-8 text-base xl:text-xl leading-10.4 text-white/[.5]">
        Ține evidența partidelor de pescuit personale și ale echipelor din care faci parte și
        îmbunătățește-ți tactica. Experiențele trecute nu au fost niciodată mai accesibile!
      </p>
    </div>
  );
};

Branding.propTypes = {
  className: PropTypes.string,
};

Branding.defaultProps = {
  className: '',
};

export default Branding;
