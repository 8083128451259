import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from 'components/ui/buttons';

const Delete = (props) => {
  const { onDelete } = props;
  return <IconButton variant="simple" onClick={onDelete} icon="bin-small" scheme="danger" />;
};

Delete.propTypes = {
  onDelete: PropTypes.func.isRequired,
};

export default Delete;
