import omit from 'lodash/omit';
import { DEFAULT_SPECIES_FIELDS, EMPTY_DEFAULT_FIELD } from 'constants/forms';
import {
  ADD_SPECIES,
  REMOVE_MEDIA_FILE,
  REMOVE_SPECIES,
  SET_ATMOSPHERIC_PRESSURE,
  SET_AVERAGE_CATCH_TIME,
  SET_CURRENT_COVER,
  SET_DESCRIPTION,
  SET_END_DATE,
  SET_END_TIME,
  SET_FIRST_CATCH_TIME,
  SET_GO_OUT,
  SET_LOCATION_FIELD,
  SET_MEDIA_FILE,
  SET_PERSONAL_NOTES,
  SET_PERSONAL_SCORE,
  SET_SKY_CONDITION,
  SET_SPECIES,
  SET_START_DATE,
  SET_START_TIME,
  SET_TEMPERATURE,
  SET_TOTAL_QUANTITY,
  SET_WATER_CLARITY,
  SET_WATER_LEVEL,
  SET_WATER_PERSONAL_NOTES,
  SET_WATER_SPEED,
  SET_WEATHER_PERSONAL_NOTES,
  SET_WIND_DIRECTION,
  SET_WIND_GUSTS,
  SET_WIND_SPEED,
} from '../types/addNew/others';
import {
  SELECT_FEEDER,
  DESELECT_FEEDER,
  SET_FEEDING_STRATEGY,
} from '../types/availableEntities/feeders';
import {
  DESELECT_GROUND_BAIT,
  SELECT_GROUND_BAIT,
  SET_GROUND_BAIT_STRATEGY,
} from '../types/availableEntities/groundBaits';
import { SELECT_BAIT, DESELECT_BAIT, SET_BAIT_STRATEGY } from '../types/availableEntities/baits';
import { SELECT_ROD, DESELECT_ROD } from '../types/availableEntities/rods';
import { SELECT_REEL, DESELECT_REEL } from '../types/availableEntities/reels';
import {
  SELECT_MAIN_LINE,
  SELECT_RIG_LINE,
  DESELECT_MAIN_LINE,
  DESELECT_RIG_LINE,
  SELECT_LEADER_LINE,
  DESELECT_LEADER_LINE,
} from '../types/availableEntities/lines';
import { SELECT_POWER_GUM, DESELECT_POWER_GUM } from '../types/availableEntities/powerGums';
import { SELECT_HOOK, DESELECT_HOOK } from '../types/availableEntities/hooks';
import {
  ADD_NEW_GEAR_ENTRY,
  REMOVE_GEAR_ENTRY,
  ADD_NEW_RIG_ENTRY,
  REMOVE_RIG_ENTRY,
} from '../types/addNew/equipment';

export const defaultState = {
  event: {
    startDate: EMPTY_DEFAULT_FIELD,
    endDate: EMPTY_DEFAULT_FIELD,
    startTime: EMPTY_DEFAULT_FIELD,
    endTime: EMPTY_DEFAULT_FIELD,
    location: {
      latitude: {
        ...EMPTY_DEFAULT_FIELD,
        isValid: true,
      },
      longitude: {
        ...EMPTY_DEFAULT_FIELD,
        isValid: true,
      },
      name: {
        ...EMPTY_DEFAULT_FIELD,
        isValid: true,
      },
      formattedAddress: {
        ...EMPTY_DEFAULT_FIELD,
        isValid: true,
      },
      city: {
        ...EMPTY_DEFAULT_FIELD,
        isValid: true,
      },
      county: {
        ...EMPTY_DEFAULT_FIELD,
        isValid: true,
      },
      country: {
        ...EMPTY_DEFAULT_FIELD,
        isValid: true,
      },
      street: {
        ...EMPTY_DEFAULT_FIELD,
        isValid: true,
      },
      streetNo: {
        ...EMPTY_DEFAULT_FIELD,
        isValid: true,
      },
    },
  },
  conditions: {
    weather: {
      skyCondition: EMPTY_DEFAULT_FIELD,
      temperature: EMPTY_DEFAULT_FIELD,
      windSpeed: EMPTY_DEFAULT_FIELD,
      windGusts: { ...EMPTY_DEFAULT_FIELD, isValid: true },
      windDirection: { ...EMPTY_DEFAULT_FIELD, isValid: true },
      atmPressure: EMPTY_DEFAULT_FIELD,
      personalNotes: {
        ...EMPTY_DEFAULT_FIELD,
        isValid: true,
      },
    },
    water: {
      clearness: EMPTY_DEFAULT_FIELD,
      level: { ...EMPTY_DEFAULT_FIELD, isValid: true },
      speed: { ...EMPTY_DEFAULT_FIELD, isValid: true },
      personalNotes: {
        ...EMPTY_DEFAULT_FIELD,
        isValid: true,
      },
    },
  },
  equipment: {
    // Keep empty array representing the first gear combination to allow the user to add more.
    gear: [{}],
    rigs: [{}],
  },
  feeding: {
    feeders: {
      selectedIds: [],
      strategy: '',
    },
    groundBaits: {
      selectedIds: [],
      strategy: '',
    },
    baits: {
      selectedIds: [],
      strategy: '',
    },
  },
  results: {
    personalNotes: {
      ...EMPTY_DEFAULT_FIELD,
      isValid: true,
    },
    selfRate: EMPTY_DEFAULT_FIELD,
    totalQuantity: EMPTY_DEFAULT_FIELD,
    /* Example of a species object:
     *  {
     *    species: EMPTY_DEFAULT_FIELD,
     *    quantity: EMPTY_DEFAULT_FIELD,
     *    maxWeight: EMPTY_DEFAULT_FIELD,
     *    count: EMPTY_DEFAULT_FIELD,
     *  }
     * */
    species: [],
  },
  strategy: {
    description: EMPTY_DEFAULT_FIELD,
    firstCatchTime: {
      ...EMPTY_DEFAULT_FIELD,
      isValid: true,
    },
    averageCatchTime: {
      ...EMPTY_DEFAULT_FIELD,
      isValid: true,
    },
  },
  /* Example of a media object:
   *  {
   *    file: <uploaded-file>
   *    isCover: true/false
   *  }
   *  */
  media: [],
};

export const reducer = (state, action) => {
  switch (action.type) {
    case SET_GO_OUT:
      return {
        ...action.payload,
      };
    case SET_START_TIME:
      return {
        ...state,
        event: {
          ...state.event,
          startTime: action.payload,
        },
      };
    case SET_END_TIME:
      return {
        ...state,
        event: {
          ...state.event,
          endTime: action.payload,
        },
      };
    case SET_START_DATE:
      return {
        ...state,
        event: {
          ...state.event,
          startDate: action.payload,
        },
      };
    case SET_END_DATE:
      return {
        ...state,
        event: {
          ...state.event,
          endDate: action.payload,
        },
      };
    case SET_LOCATION_FIELD:
      return {
        ...state,
        event: {
          ...state.event,
          location: {
            ...state.event.location,
            ...Object.keys(action.payload).reduce((acc, key) => {
              acc[key] = {
                ...state.event.location[key],
                value: action.payload[key],
                isDirty: !!action.payload[key],
              };
              return acc;
            }, {}),
          },
        },
      };
    case SET_WATER_CLARITY:
      return {
        ...state,
        conditions: {
          ...state.conditions,
          water: {
            ...state.conditions.water,
            clearness: action.payload,
          },
        },
      };
    case SET_WATER_LEVEL:
      return {
        ...state,
        conditions: {
          ...state.conditions,
          water: {
            ...state.conditions.water,
            level: action.payload,
          },
        },
      };
    case SET_WATER_SPEED:
      return {
        ...state,
        conditions: {
          ...state.conditions,
          water: {
            ...state.conditions.water,
            speed: action.payload,
          },
        },
      };
    case SET_WATER_PERSONAL_NOTES:
      return {
        ...state,
        conditions: {
          ...state.conditions,
          water: {
            ...state.conditions.water,
            personalNotes: action.payload,
          },
        },
      };
    case SET_SKY_CONDITION:
      return {
        ...state,
        conditions: {
          ...state.conditions,
          weather: {
            ...state.conditions.weather,
            skyCondition: action.payload,
          },
        },
      };
    case SET_TEMPERATURE:
      return {
        ...state,
        conditions: {
          ...state.conditions,
          weather: {
            ...state.conditions.weather,
            temperature: action.payload,
          },
        },
      };
    case SET_WIND_SPEED:
      return {
        ...state,
        conditions: {
          ...state.conditions,
          weather: {
            ...state.conditions.weather,
            windSpeed: action.payload,
          },
        },
      };
    case SET_WIND_GUSTS:
      return {
        ...state,
        conditions: {
          ...state.conditions,
          weather: {
            ...state.conditions.weather,
            windGusts: action.payload,
          },
        },
      };
    case SET_WIND_DIRECTION:
      return {
        ...state,
        conditions: {
          ...state.conditions,
          weather: {
            ...state.conditions.weather,
            windDirection: action.payload,
          },
        },
      };
    case SET_ATMOSPHERIC_PRESSURE:
      return {
        ...state,
        conditions: {
          ...state.conditions,
          weather: {
            ...state.conditions.weather,
            atmPressure: action.payload,
          },
        },
      };
    case SET_WEATHER_PERSONAL_NOTES:
      return {
        ...state,
        conditions: {
          ...state.conditions,
          weather: {
            ...state.conditions.weather,
            personalNotes: action.payload,
          },
        },
      };
    case SELECT_ROD:
      return {
        ...state,
        equipment: {
          ...state.equipment,
          gear: [
            ...state.equipment.gear.slice(0, action.payload.index),
            {
              ...state.equipment.gear[action.payload.index],
              rod: action.payload.id,
            },
            ...state.equipment.gear.slice(action.payload.index + 1),
          ],
        },
      };
    case DESELECT_ROD:
      return {
        ...state,
        equipment: {
          ...state.equipment,
          gear: [
            ...state.equipment.gear.slice(0, action.payload),
            { ...omit(state.equipment.gear[action.payload], 'rod') },
            ...state.equipment.gear.slice(action.payload + 1),
          ],
        },
      };
    case SELECT_REEL:
      return {
        ...state,
        equipment: {
          ...state.equipment,
          gear: [
            ...state.equipment.gear.slice(0, action.payload.index),
            {
              ...state.equipment.gear[action.payload.index],
              reel: action.payload.id,
            },
            ...state.equipment.gear.slice(action.payload.index + 1),
          ],
        },
      };
    case DESELECT_REEL:
      return {
        ...state,
        equipment: {
          ...state.equipment,
          gear: [
            ...state.equipment.gear.slice(0, action.payload),
            { ...omit(state.equipment.gear[action.payload], 'reel') },
            ...state.equipment.gear.slice(action.payload + 1),
          ],
        },
      };
    case SELECT_MAIN_LINE:
      return {
        ...state,
        equipment: {
          ...state.equipment,
          gear: [
            ...state.equipment.gear.slice(0, action.payload.index),
            {
              ...state.equipment.gear[action.payload.index],
              line: action.payload.id,
            },
            ...state.equipment.gear.slice(action.payload.index + 1),
          ],
        },
      };
    case DESELECT_MAIN_LINE:
      return {
        ...state,
        equipment: {
          ...state.equipment,
          gear: [
            ...state.equipment.gear.slice(0, action.payload),
            { ...omit(state.equipment.gear[action.payload], 'line') },
            ...state.equipment.gear.slice(action.payload + 1),
          ],
        },
      };
    case SELECT_RIG_LINE:
      return {
        ...state,
        equipment: {
          ...state.equipment,
          rigs: [
            ...state.equipment.rigs.slice(0, action.payload.index),
            {
              ...state.equipment.rigs[action.payload.index],
              line: action.payload.id,
            },
            ...state.equipment.rigs.slice(action.payload.index + 1),
          ],
        },
      };
    case DESELECT_RIG_LINE:
      return {
        ...state,
        equipment: {
          ...state.equipment,
          rigs: [
            ...state.equipment.rigs.slice(0, action.payload),
            { ...omit(state.equipment.rigs[action.payload], 'line') },
            ...state.equipment.rigs.slice(action.payload + 1),
          ],
        },
      };
    case SELECT_LEADER_LINE:
      return {
        ...state,
        equipment: {
          ...state.equipment,
          rigs: [
            ...state.equipment.rigs.slice(0, action.payload.index),
            {
              ...state.equipment.rigs[action.payload.index],
              leader: action.payload.id,
            },
            ...state.equipment.rigs.slice(action.payload.index + 1),
          ],
        },
      };
    case DESELECT_LEADER_LINE:
      return {
        ...state,
        equipment: {
          ...state.equipment,
          rigs: [
            ...state.equipment.rigs.slice(0, action.payload),
            { ...omit(state.equipment.rigs[action.payload], 'leader') },
            ...state.equipment.rigs.slice(action.payload + 1),
          ],
        },
      };
    case SELECT_POWER_GUM:
      return {
        ...state,
        equipment: {
          ...state.equipment,
          rigs: [
            ...state.equipment.rigs.slice(0, action.payload.index),
            {
              ...state.equipment.rigs[action.payload.index],
              powerGum: action.payload.id,
            },
            ...state.equipment.rigs.slice(action.payload.index + 1),
          ],
        },
      };
    case DESELECT_POWER_GUM:
      return {
        ...state,
        equipment: {
          ...state.equipment,
          rigs: [
            ...state.equipment.rigs.slice(0, action.payload),
            { ...omit(state.equipment.rigs[action.payload], 'powerGum') },
            ...state.equipment.rigs.slice(action.payload + 1),
          ],
        },
      };
    case SELECT_HOOK:
      return {
        ...state,
        equipment: {
          ...state.equipment,
          rigs: [
            ...state.equipment.rigs.slice(0, action.payload.index),
            {
              ...state.equipment.rigs[action.payload.index],
              hook: action.payload.id,
            },
            ...state.equipment.rigs.slice(action.payload.index + 1),
          ],
        },
      };
    case DESELECT_HOOK:
      return {
        ...state,
        equipment: {
          ...state.equipment,
          rigs: [
            ...state.equipment.rigs.slice(0, action.payload),
            { ...omit(state.equipment.rigs[action.payload], 'hook') },
            ...state.equipment.rigs.slice(action.payload + 1),
          ],
        },
      };
    case ADD_NEW_GEAR_ENTRY:
      return {
        ...state,
        equipment: {
          ...state.equipment,
          gear: [...state.equipment.gear, {}],
        },
      };
    case REMOVE_GEAR_ENTRY:
      return {
        ...state,
        equipment: {
          ...state.equipment,
          gear: state.equipment.gear.filter((_, index) => index !== action.payload),
        },
      };
    case ADD_NEW_RIG_ENTRY:
      return {
        ...state,
        equipment: {
          ...state.equipment,
          rigs: [...state.equipment.rigs, {}],
        },
      };
    case REMOVE_RIG_ENTRY:
      return {
        ...state,
        equipment: {
          ...state.equipment,
          rigs: state.equipment.rigs.filter((_, index) => index !== action.payload),
        },
      };
    case SELECT_FEEDER:
      return {
        ...state,
        feeding: {
          ...state.feeding,
          feeders: {
            ...state.feeding.feeders,
            selectedIds: [...state.feeding.feeders.selectedIds, action.payload],
          },
        },
      };
    case DESELECT_FEEDER:
      return {
        ...state,
        feeding: {
          ...state.feeding,
          feeders: {
            ...state.feeding.feeders,
            selectedIds: state.feeding.feeders.selectedIds.filter((id) => id !== action.payload),
          },
        },
      };
    case SET_FEEDING_STRATEGY:
      return {
        ...state,
        feeding: {
          ...state.feeding,
          feeders: {
            ...state.feeding.feeders,
            strategy: action.payload,
          },
        },
      };
    case SELECT_GROUND_BAIT:
      return {
        ...state,
        feeding: {
          ...state.feeding,
          groundBaits: {
            ...state.feeding.groundBaits,
            selectedIds: [...state.feeding.groundBaits.selectedIds, action.payload],
          },
        },
      };
    case DESELECT_GROUND_BAIT:
      return {
        ...state,
        feeding: {
          ...state.feeding,
          groundBaits: {
            ...state.feeding.groundBaits,
            selectedIds: state.feeding.groundBaits.selectedIds.filter(
              (id) => id !== action.payload,
            ),
          },
        },
      };
    case SET_GROUND_BAIT_STRATEGY:
      return {
        ...state,
        feeding: {
          ...state.feeding,
          groundBaits: {
            ...state.feeding.groundBaits,
            strategy: action.payload,
          },
        },
      };
    case SELECT_BAIT: {
      return {
        ...state,
        feeding: {
          ...state.feeding,
          baits: {
            ...state.feeding.baits,
            selectedIds: [...state.feeding.baits.selectedIds, action.payload],
          },
        },
      };
    }
    case DESELECT_BAIT: {
      return {
        ...state,
        feeding: {
          ...state.feeding,
          baits: {
            ...state.feeding.baits,
            selectedIds: state.feeding.baits.selectedIds.filter((id) => id !== action.payload),
          },
        },
      };
    }
    case SET_BAIT_STRATEGY:
      return {
        ...state,
        feeding: {
          ...state.feeding,
          baits: {
            ...state.feeding.baits,
            strategy: action.payload,
          },
        },
      };
    case SET_DESCRIPTION:
      return {
        ...state,
        results: {
          ...state.results,
          personalNotes: action.payload,
        },
      };
    case SET_PERSONAL_SCORE:
      return {
        ...state,
        results: {
          ...state.results,
          selfRate: action.payload,
        },
      };
    case SET_TOTAL_QUANTITY:
      return {
        ...state,
        results: {
          ...state.results,
          totalQuantity: action.payload,
        },
      };
    case ADD_SPECIES:
      return {
        ...state,
        results: {
          ...state.results,
          species: [...state.results.species, { ...DEFAULT_SPECIES_FIELDS }],
        },
      };
    case SET_SPECIES: {
      const { newItem, index, field } = action.payload;
      const updatedSpecies = state.results.species.map((species, i) =>
        i === index
          ? {
              ...species,
              [field]: newItem,
            }
          : species,
      );

      return {
        ...state,
        results: {
          ...state.results,
          species: updatedSpecies,
        },
      };
    }
    case REMOVE_SPECIES:
      return {
        ...state,
        results: {
          ...state.results,
          species: state.results.species.filter((_, index) => index !== action.payload),
        },
      };
    case SET_PERSONAL_NOTES:
      return {
        ...state,
        strategy: {
          ...state.strategy,
          description: action.payload,
        },
      };
    case SET_FIRST_CATCH_TIME:
      return {
        ...state,
        strategy: {
          ...state.strategy,
          firstCatchTime: action.payload,
        },
      };
    case SET_AVERAGE_CATCH_TIME:
      return {
        ...state,
        strategy: {
          ...state.strategy,
          averageCatchTime: action.payload,
        },
      };
    case SET_MEDIA_FILE:
      return {
        ...state,
        media: [
          ...state.media,
          {
            ...action.payload,
          },
        ],
      };
    case REMOVE_MEDIA_FILE: {
      const indexesToRemove = new Set(action.payload);
      const updatedMedia = state.media.filter((item, index) => !indexesToRemove.has(index));

      return {
        ...state,
        media: updatedMedia,
      };
    }
    case SET_CURRENT_COVER: {
      const { index, newItem, meta } = action.payload;
      return {
        ...state,
        media: state.media.map((item, i) => {
          if (i === index) {
            return {
              ...item,
              file: newItem,
              isCover: true,
              metaData: { ...meta },
            };
          }

          return {
            ...item,
            isCover: false,
            metaData: null,
          };
        }),
      };
    }
    default:
      return state;
  }
};
