import React from 'react';
import { getReelName, getRodName } from 'components/Equipment/utility/utils';
import { getLineName, getHookName, getPowerGumName } from 'components/GoOut/utility/utils';

export const stepTitle = 'Echipament și strune';
export const getStepDescription = (editable) =>
  editable
    ? 'Adaugă detalii despre echipamentul, monturile și strunele folosite în această partidă de pescuit.'
    : 'Detalii despre echipamentul, monturile și strunele folosite în această partidă de pescuit.';

const getNotSelectedElement = (value) => <span className="text-gray-input-stroke">{value}</span>;

export const getGearObject = (arr, selectedIds, key) => {
  const { [key]: objId } = selectedIds;
  return (arr || []).find((o) => o.id === objId);
};
export const getGearTitle = (rod, reel, line) => {
  if (!rod && !reel && !line) {
    return getNotSelectedElement('Configurează echipamentul');
  }

  const rodName = rod ? getRodName(rod) : getNotSelectedElement('Lansetă');
  const reelName = reel ? getReelName(reel) : getNotSelectedElement('Mulinetă');
  const lineName = line ? getLineName(line) : getNotSelectedElement('Linie principală');

  return (
    <p className="text-sm flex items-center gap-2">
      {rodName} + {reelName} + {lineName}
    </p>
  );
};

export const getRigTitle = (line, hook, leader, powerGum) => {
  if (!line && !hook) {
    return getNotSelectedElement('Configurează montura și struna');
  }

  const lineName = line ? getLineName(line) : getNotSelectedElement('Strună');
  const hookName = hook ? getHookName(hook) : getNotSelectedElement('Cârlig');
  const leaderName = leader ? getLineName(leader) : null;
  const powerGumName = powerGum ? getPowerGumName(powerGum) : null;

  return (
    <p className="text-sm flex items-center gap-2">
      {line ? `${lineName} + ` : ''}
      {hookName} {leaderName ? `+ ${leaderName}` : ''} {powerGumName ? `+ ${powerGumName}` : ''}
    </p>
  );
};
