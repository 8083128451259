import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from 'components/ui/buttons';

const Actions = (props) => {
  const { onView } = props; // onEdit

  return (
    <>
      <div className="hidden md:flex items-center gap-6.4">
        {/* {onEdit && ( */}
        {/*   <IconButton variant="simple" icon="edit-small" onClick={onEdit} scheme="blue"> */}
        {/*     Modifică */}
        {/*   </IconButton> */}
        {/* )} */}
        <IconButton variant="simple" icon="view-small" onClick={onView} scheme="blue">
          Detalii
        </IconButton>
      </div>
      <div className="flex md:hidden items-center gap-6.4">
        {/* {onEdit && <IconButton variant="simple" icon="edit-small" onClick={onEdit} scheme="blue" />} */}
        <IconButton variant="simple" icon="view-small" onClick={onView} scheme="blue" />
      </div>
    </>
  );
};

Actions.propTypes = {
  onEdit: PropTypes.func,
  onView: PropTypes.func.isRequired,
};

Actions.defaultProps = {
  onEdit: null,
};

Actions.displayName = 'Actions';

export default Actions;
