import {
  INIT_FEEDERS,
  ADD_FEEDER,
  SELECT_FEEDER,
  DESELECT_FEEDER,
  SET_FEEDING_STRATEGY,
} from '../../types/availableEntities/feeders';

export const init = (payload) => ({
  type: INIT_FEEDERS,
  payload,
});

export const add = (payload) => ({
  type: ADD_FEEDER,
  payload,
});

export const select = (payload) => ({
  type: SELECT_FEEDER,
  payload,
});

export const deselect = (payload) => ({
  type: DESELECT_FEEDER,
  payload,
});

export const setStrategy = (payload) => ({
  type: SET_FEEDING_STRATEGY,
  payload,
});
