import React from 'react';
import PropTypes from 'prop-types';
import PaginationNumber from 'components/ui/table/PaginationNumber';

const Pagination = (props) => {
  const { pagesCount, currentPage, onChange } = props;
  const paginationNumbers = Array.from(Array(pagesCount).keys(), (index) => {
    const pageNumber = index + 1;
    return (
      <PaginationNumber
        key={`pagination-button-${pageNumber}`}
        active={currentPage === pageNumber}
        onSelect={() => onChange(pageNumber)}
      >
        {pageNumber}
      </PaginationNumber>
    );
  });

  return (
    <div className="flex gap-x-0.8" data-testid="pagination-buttons">
      {paginationNumbers}
    </div>
  );
};

Pagination.propTypes = {
  pagesCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

Pagination.displayName = 'Pagination';

export default Pagination;
