import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useGoogleAPI from 'hooks/useGoogleAPI';

const OPTIONS = {
  center: {
    lat: 46.937227763678386,
    lng: 26.39871664163224,
  },
  zoom: 13,
  mapId: 'psc-location',
};

const Map = (props) => {
  const { viewOnly, coordinates, onClick, mapId } = props;
  const [map, setMap] = useState(null);
  const [currentMarker, setCurrentMarker] = useState(null);
  const [location, setLocation] = useState(coordinates);
  const [userLocation, setUserLocation] = useState(null);
  const googleInstance = useGoogleAPI();

  useEffect(() => {
    if (coordinates) {
      setLocation(coordinates);
    }
  }, [coordinates]);

  useEffect(() => {
    // This hook is responsible for getting the user's location
    if (!viewOnly && navigator.geolocation && !coordinates) {
      navigator.geolocation.getCurrentPosition((position) => {
        const locationData = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        setUserLocation(locationData);
        onClick(locationData);
      });
    }
  }, [viewOnly, coordinates, onClick]);

  useEffect(() => {
    // This hook is responsible for initializing the map
    if (googleInstance) {
      const newMap = new googleInstance.maps.Map(document.getElementById(mapId), {
        ...OPTIONS,
        mapId,
      });

      const centerLocation = location || userLocation;
      if (centerLocation) {
        newMap.setCenter(centerLocation);
      }
      setMap(newMap);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [googleInstance, viewOnly, userLocation]);

  useEffect(() => {
    // This hook is responsible for adding a click event listener to the map
    if (map && !viewOnly) {
      map.addListener('click', (event) => {
        const { latLng } = event;
        setLocation(latLng.toJSON());
        if (onClick) {
          onClick(latLng.toJSON());
        }
      });
    }
    return () => {
      if (map) {
        googleInstance.maps.event.clearInstanceListeners(map, 'click');
      }
    };
  }, [googleInstance, map, onClick, viewOnly]);

  useEffect(() => {
    // This hook is responsible for adding a marker to the map
    if (!googleInstance) {
      return;
    }

    if (map) {
      (async () => {
        const { AdvancedMarkerElement } = await googleInstance.maps.importLibrary('marker');

        if (!location) {
          return;
        }
        if (currentMarker) {
          currentMarker.position = location;
        } else {
          const marker = new AdvancedMarkerElement({
            map,
            position: location,
          });
          setCurrentMarker(marker);
        }
      })();
    }
  }, [googleInstance, map, location, currentMarker]);

  return <div id={mapId} className="w-full h-full rounded-inherit" />;
};

Map.propTypes = {
  viewOnly: PropTypes.bool,
  coordinates: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  onClick: PropTypes.func,
  mapId: PropTypes.string.isRequired,
};

Map.defaultProps = {
  viewOnly: false,
  coordinates: null,
  onClick: null,
};

Map.displayName = 'Map';

export default Map;
