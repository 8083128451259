import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from 'components/ui/Icon';
import { OptionsDropdown } from 'components/ui/dropdowns';

const ProgressBarMobile = (props) => {
  const { steps, currentStep, onSelect } = props;
  const circ = 2 * Math.PI * 41.875;
  const progress = currentStep < steps.length ? (currentStep * 100) / steps.length - 1 : 100;
  const offset = circ * ((100 - progress) / 100);
  const currentStepTitle =
    currentStep < steps.length ? steps[currentStep].title : steps[steps.length - 1].title;
  const prevStep = currentStep < steps.length ? steps[currentStep - 1] : steps[steps.length - 2];
  const nextStep = currentStep < steps.length - 1 ? steps[currentStep + 1] : '';
  const buttonProps = {
    children: [],
    variant: 'simple',
    icon: 'more-small',
  };

  const options = useMemo(() => {
    return steps.map((step, index) => ({
      label: step.title,
      selected: index === currentStep,
      icon: index === currentStep ? 'check-small' : '',
    }));
  }, [steps, currentStep]);

  const onSelectOption = (index) => {
    const prevSelected = options.find((option) => option.selected);
    prevSelected.selected = false;
    prevSelected.icon = '';
    const currentSelected = options[index];
    currentSelected.selected = true;
    currentSelected.icon = 'check-small';
    onSelect(index);
  };

  return (
    <div data-testid="container-progress-bar-mobile" className="flex md:hidden gap-x-6.4">
      <div data-testid="progress-bar-mobile" className="flex items-center justify-center">
        <div
          data-testid="progress-bar-mobile-counter"
          className="text-stepper text-blue font-bold absolute"
        >
          {currentStep < steps.length ? currentStep + 1 : steps.length} din {steps.length}
        </div>
        <svg width="100" height="100" viewBox="0 0 100 100" className="psc-rotate-270">
          <circle
            r="41.875"
            cx="50.25"
            cy="50.25"
            fill="transparent"
            strokeWidth="0.5rem"
            strokeDasharray={circ}
            strokeDashoffset="0"
            className="stroke-gray-light-stroke"
          />
          <circle
            r="41.875"
            cx="50.25"
            cy="50.25"
            fill="transparent"
            strokeWidth="0.5rem"
            strokeDasharray={`${circ + 2} ${circ}`}
            strokeDashoffset={offset}
            className="stroke-blue transition-all duration-300"
            strokeLinecap="round"
          />
        </svg>
      </div>
      <div
        data-testid="mobile-progress-bar-steps"
        className="flex flex-col items-start justify-center gap-y-1.6"
      >
        <div
          className={cn('flex text-stepper items-center text-gray-input-stroke -m-2', {
            invisible: !prevStep,
          })}
        >
          {prevStep ? (
            <>
              <Icon identifier="left-chevron-small" />
              {prevStep.title}
            </>
          ) : (
            <>&nbsp;</>
          )}
        </div>
        <div className="text-2xl justify-center text-blue font-semibold">{currentStepTitle}</div>
        <div
          className={cn('flex text-stepper items-center text-gray-input-stroke -m-2', {
            invisible: !nextStep,
          })}
        >
          {nextStep ? (
            <>
              <Icon identifier="right-chevron-small" />
              {nextStep.title}
            </>
          ) : (
            <>&nbsp;</>
          )}
        </div>
      </div>
      {onSelect && (
        <div className="flex-1 flex items-start justify-end">
          <OptionsDropdown buttonProps={buttonProps} items={options} onChange={onSelectOption} />
        </div>
      )}
    </div>
  );
};

ProgressBarMobile.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.node,
    }),
  ).isRequired,
  currentStep: PropTypes.number.isRequired,
  onSelect: PropTypes.func,
};

ProgressBarMobile.defaultProps = {
  onSelect: null,
};

ProgressBarMobile.displayName = 'ProgressBarMobile';

export default ProgressBarMobile;
