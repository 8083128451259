import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { ro } from 'date-fns/locale/ro';
import GenericDropdown from 'components/ui/dropdowns/GenericDropdown';
import { Input } from 'components/ui/inputs';
import Calendar from 'components/ui/Calendar';
import { Button } from 'components/ui/buttons';

const getInputActions = (defaults, onCurrentDateClick) => {
  let actions = defaults || [];

  if (onCurrentDateClick) {
    actions = [
      ...actions,
      <Button key="current-date" variant="link" size="small" onClick={onCurrentDateClick}>
        Azi
      </Button>,
    ];
  }

  return actions;
};

const CalendarDropdown = (props) => {
  const { date, onChange, triggerProps, withCurrentDate, selectionType, ...otherProps } = props;
  const onCurrentDateSelection = () => onChange(new Date());
  const inputActions = getInputActions(
    triggerProps.actions,
    withCurrentDate ? onCurrentDateSelection : null,
  );
  const inputRef = useRef(null);
  const isDaySelection = selectionType === 'day';
  const dateFormat = isDaySelection ? 'd MMMM yyyy' : 'MMMM yyyy';

  return (
    <GenericDropdown
      triggerProps={{
        dropDown: true,
        ref: inputRef,
        value: date ? format(date, dateFormat, { locale: ro }) : '',
        actions: inputActions,
        ...triggerProps,
      }}
      triggerComponent={Input}
      {...otherProps}
    >
      <Calendar date={date || new Date()} onChange={onChange} selectionType={selectionType} />
    </GenericDropdown>
  );
};

CalendarDropdown.propTypes = {
  date: PropTypes.instanceOf(Date),
  onChange: PropTypes.func.isRequired,
  triggerProps: PropTypes.object,
  withCurrentDate: PropTypes.bool,
  selectionType: PropTypes.oneOf(['day', 'month']),
};

CalendarDropdown.defaultProps = {
  date: null,
  triggerProps: {},
  withCurrentDate: false,
  selectionType: 'day',
};

CalendarDropdown.displayName = 'CalendarDropdown';

export default CalendarDropdown;
