import React, { useContext, useEffect, useState } from 'react';
import CanSubmitContext from 'contexts/CanSubmitContext';
import CanEditContext from 'contexts/CanEditContext';
import Step from '../Step';
import Section from '../Section';
import { Feeders, GroundBaits, Baits } from './sections';

const title = 'Momitoare, nade și momeli';
const getDescription = (editable) =>
  editable
    ? 'Adaugă detalii despre momitoarele, nadele și momelile folosite în această partidă de pescuit.'
    : 'Detalii despre momitoarele, nadele și momelile folosite în această partidă de pescuit.';

const Feeding = () => {
  const [isValidFeeders, setIsValidFeeders] = useState(false);
  const [isValidGroundBaits, setIsValidGroundBaits] = useState(false);
  const [isValidBaits, setIsValidBaits] = useState(false);
  const [accordionsState, setAccordionsState] = useState({
    feeders: false,
    groundBaits: false,
    baits: false,
  });
  const [, setSubmit] = useContext(CanSubmitContext);
  const editable = useContext(CanEditContext);

  useEffect(() => {
    setSubmit(!editable ? true : isValidFeeders && isValidGroundBaits && isValidBaits);
  }, [editable, setSubmit, isValidFeeders, isValidGroundBaits, isValidBaits]);

  const onFeedersValidationChange = (value) => setIsValidFeeders(value);
  const onGroundBaitsValidationChange = (value) => setIsValidGroundBaits(value);
  const onBaitsValidationChange = (value) => setIsValidBaits(value);

  const onToggle = (key) => {
    setAccordionsState((prevState) => {
      const newState = { ...prevState };
      Object.keys(newState).forEach((accordionKey) => {
        newState[accordionKey] = accordionKey === key ? !newState[accordionKey] : false;
      });
      return newState;
    });
  };

  return (
    <Step title={title} description={getDescription(editable)}>
      <Section className="flex flex-col gap-y-4.8">
        <Feeders
          opened={accordionsState.feeders}
          onToggle={onToggle}
          onValidationChange={onFeedersValidationChange}
          editable={editable}
        />
        <GroundBaits
          opened={accordionsState.groundBaits}
          onToggle={onToggle}
          onValidationChange={onGroundBaitsValidationChange}
          editable={editable}
        />
        <Baits
          opened={accordionsState.baits}
          onToggle={onToggle}
          onValidationChange={onBaitsValidationChange}
          editable={editable}
        />
      </Section>
    </Step>
  );
};

export default Feeding;
