import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Context from 'components/GoOut/state/contexts/addNew';
import { setWindDirection } from 'components/GoOut/state/actions/addNew/others';
import { onFieldChange } from 'utility/utils';
// import { isRequired } from 'utility/validation';
import { CheckboxesDropdown } from 'components/ui/dropdowns';
import { Input } from 'components/ui/inputs';

const windDirections = ['N', 'NE', 'E', 'SE', 'S', 'SV', 'V', 'NV'].map((o) => ({
  id: o,
  value: o,
}));

const WindDirectionInput = (props) => {
  const { canEdit } = props;
  const [state, dispatch] = useContext(Context);
  const {
    conditions: {
      weather: {
        windDirection: {
          value: direction,
          isValid: directionIsValid,
          isDirty: directionIsDirty,
          error: directionError,
        },
      },
    },
  } = state;

  const defaultProps = {
    id: 'windDirection',
    name: 'windDirection',
    label: 'Direcția vântului',
    placeholder: 'Selectare direcția vântului',
    valid: directionIsDirty ? directionIsValid : undefined,
    helperText: directionIsDirty && !directionIsValid ? directionError : null,
    value: direction,
  };

  const onSelectWindDirection = (event) => {
    const setter = (newItem) => dispatch(setWindDirection(newItem));
    onFieldChange(event, setter);
  };

  if (canEdit) {
    return (
      <CheckboxesDropdown
        data={windDirections}
        onChange={onSelectWindDirection}
        triggerProps={defaultProps}
        singleSelection
        selectedValues={direction ? [direction] : []}
        readOnly={!canEdit}
      />
    );
  }
  return <Input readOnly={!canEdit} value={direction} {...defaultProps} required={false} />;
};

WindDirectionInput.propTypes = {
  canEdit: PropTypes.bool.isRequired,
};

export default WindDirectionInput;
