import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import OrderNumber from 'components/ui/OrderNumber';

const getVariant = (index, step) => {
  switch (true) {
    case index === step:
      return 'current';
    case index < step:
      return 'filled';
    default:
      return 'disabled';
  }
};

const ProgressBar = (props) => {
  const { steps, currentStep, onSelect } = props;

  return (
    <div data-testid="container-progress-bar" className="hidden md:flex md:flex-col gap-y-12.8">
      {steps.map((step, index) => {
        const variant = getVariant(index, currentStep);
        return (
          <button
            type="button"
            key={`${step.title} step`}
            className="flex items-center gap-x-3.2"
            onClick={() => {
              onSelect(index);
            }}
            disabled={!onSelect}
          >
            <div>
              <OrderNumber value={index + 1} variant={variant} dataTestId="progress-bar-number" />
              {index < steps.length - 1 && (
                <div
                  className={cn(
                    // eslint-disable-next-line max-len
                    'absolute flex items-center w-0.4 h-12.8 bg-blue ml-6',
                    {
                      'bg-gray-light-stroke': index >= currentStep,
                    },
                  )}
                />
              )}
            </div>
            <div
              data-testid="progress-bar-step"
              className={cn(
                // eslint-disable-next-line max-len
                'text-stepper text-blue whitespace-nowrap font-medium',
                {
                  'text-gray-input-stroke': index > currentStep,
                },
              )}
            >
              {step.title}
            </div>
          </button>
        );
      })}
    </div>
  );
};

ProgressBar.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.node,
    }),
  ).isRequired,
  currentStep: PropTypes.number.isRequired,
  onSelect: PropTypes.func,
};

ProgressBar.defaultProps = {
  onSelect: null,
};

ProgressBar.displayName = 'ProgressBar';

export default ProgressBar;
