import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/ui/Icon';

const Trigger = (props) => {
  const { name, avatar, onClick } = props;

  return (
    <button className="bg-transparent flex items-center gap-3.2" type="button" onClick={onClick}>
      <span className="bg-green-dark text-white rounded-full w-16 h-16 flex items-center justify-center">
        {avatar ? (
          <img
            className="rounded-inherit w-full h-full"
            src={avatar}
            alt="Imagine profil utilizator"
          />
        ) : (
          <Icon identifier="water-fishing" fillClassName="fill-white" className="w-10 h-10" />
        )}
      </span>
      <span className="font-medium text-gray-dark leading-8 text-lg">{name}</span>
      <Icon fillClassName="fill-gray-dark" identifier="down-chevron-small" />
    </button>
  );
};

Trigger.propTypes = {
  name: PropTypes.string,
  avatar: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

Trigger.defaultProps = {
  name: '',
  avatar: null,
};

Trigger.displayName = 'AccountDropdownTrigger';

export default Trigger;
