import React from 'react';
import PropTypes from 'prop-types';

const SectionSubtitle = (props) => {
  const { children } = props;

  return <h3 className="text-lg lg:text-xl text-gray-dark font-semibold">{children}</h3>;
};

SectionSubtitle.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
};

SectionSubtitle.displayName = 'SectionSubtitle';

export default SectionSubtitle;
