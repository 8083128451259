import React, { useContext, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from 'components/authentication/Header';
import { NavLink } from 'components/ui/links';
import { Button, IconButton } from 'components/ui/buttons';
import Input from 'components/ui/inputs/Input';
import Checkbox from 'components/ui/Checkbox';
import { IDENTITY_PROVIDERS } from 'constants/identityProviders';
import { EMPTY_DEFAULT_FIELD } from 'constants/forms';
import { onFieldChange } from 'utility/utils';
import { isRequired, isValidEmail } from 'utility/validation';
import AppContext from '../../contexts/AppContext';
import useAPI from '../../hooks/useAPI';

const Login = () => {
  const { setIsAuthenticated } = useContext(AppContext);
  const [emailField, setEmailField] = useState(EMPTY_DEFAULT_FIELD);
  const [passwordField, setPasswordField] = useState(EMPTY_DEFAULT_FIELD);
  const [rememberMe, setRememberMe] = useState(true);
  const location = useLocation();
  const navigation = useNavigate();
  const { apiCall, isSubmitting } = useAPI();
  const emailInputRef = useRef(null);
  const canSubmit = ![emailField, passwordField].some((o) => !o.isValid) && !isSubmitting;

  const onEmailChange = (e) => onFieldChange(e.target.value, setEmailField, isValidEmail);
  const onPasswordChange = (e) => onFieldChange(e.target.value, setPasswordField, isRequired);
  const onRememberMeChange = (e) => setRememberMe(e.target.checked);

  const clearFields = () => {
    setEmailField(EMPTY_DEFAULT_FIELD);
    setPasswordField(EMPTY_DEFAULT_FIELD);
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append('username', emailField.value);
    formData.append('password', passwordField.value);
    formData.append('rememberMe', rememberMe);

    await apiCall('/authentication/login', 'post', formData, {
      getErrorMessage: (_, statusCode) => {
        switch (statusCode) {
          case 404:
            // eslint-disable-next-line max-len
            return 'Contul nu există. Vă rugăm să vă asigurați că ați introdus corect adresa de e-mail și parola.';
          case 403:
            return 'Contul nu a fost activat.';
          case 401:
            return 'Datele de autentificare nu sunt corecte.';
          default:
            return null;
        }
      },
      onErrorCallback: (errorData, statusCode) => {
        clearFields();

        if (statusCode === 401) {
          emailInputRef.current.focus();
        }

        if (statusCode === 403 && errorData.detail === 'Account is inactive') {
          navigation('/detalii-activare-cont', {
            state: {
              detail: errorData.detail,
              email: emailField.value,
            },
          });
        }
      },
      onSuccessCallback: () => {
        setIsAuthenticated(true);
        localStorage.setItem('is-user-loaded', JSON.stringify(false));
        const returnUrl = location.state?.from?.pathname || '/';
        navigation(returnUrl);
      },
    });

    return false;
  };

  const onIdentityProviderClick = async (provider) => {
    const { authUrl, authParams } = IDENTITY_PROVIDERS[provider];

    await apiCall(
      '/authentication/state',
      'get',
      {},
      {
        onSuccessCallback: (responseData, statusCode) => {
          if (statusCode === 200) {
            authParams.state = responseData;
            const form = document.createElement('form');
            form.setAttribute('method', 'GET');
            form.setAttribute('action', authUrl);

            const paramsKeys = Object.keys(authParams);
            paramsKeys.forEach((p) => {
              const input = document.createElement('input');
              input.setAttribute('type', 'hidden');
              input.setAttribute('name', p);
              input.setAttribute('value', authParams[p]);
              form.appendChild(input);
            });
            document.body.appendChild(form);
            form.submit();
          }
        },
      },
    );
    return false;
  };

  return (
    <div className="flex flex-col justify-between h-full">
      <div className="grid gap-8 xl:gap-12">
        <Header>Autentificare</Header>
        <div className="grid gap-8 xl:gap-16">
          <form className="grid gap-8 xl:gap-9.6" onSubmit={onSubmit}>
            <Input
              ref={emailInputRef}
              id="email"
              name="email"
              onChange={onEmailChange}
              placeholder="Introdu adresa de e-mail"
              label="Adresă de e-mail"
              type="email"
              value={emailField.value}
              valid={emailField.value === '' ? true : emailField.isValid}
              autoFocus
              autoComplete="email"
            />
            <Input
              id="password"
              name="password"
              onChange={onPasswordChange}
              placeholder="Introdu parola"
              label="Parolă"
              type="password"
              value={passwordField.value}
              autoComplete="current-password"
            />
            <Button variant="primary" submit disabled={!canSubmit}>
              Autentificare
            </Button>
            <div className="w-full flex justify-between items-center">
              <Checkbox
                id="rememberMe"
                name="rememberMe"
                onChange={onRememberMeChange}
                value="rememberMe"
                label="Ține-mă minte"
                size="small"
                checked={rememberMe}
              />
              <NavLink variant="accent" to="/cerere-resetare-parola">
                Ai uitat parola?
              </NavLink>
            </div>
          </form>
          <div className="flex items-center justify-center relative">
            <div className="w-full absolute bg-gray-input-stroke" style={{ height: '1px' }} />
            <div className="px-3.2 bg-white z-10 text-gray-input-stroke">sau</div>
          </div>
          <div className="grid gap-6.4">
            {/* <IconButton */}
            {/*   variant="social" */}
            {/*   icon="facebook" */}
            {/*   onClick={() => onIdentityProviderClick('facebook')} */}
            {/*   disabled */}
            {/* > */}
            {/*   Autentificare cu Facebook */}
            {/* </IconButton> */}
            <IconButton
              variant="social"
              icon="google"
              onClick={() => onIdentityProviderClick('google')}
            >
              Autentificare cu Google
            </IconButton>
          </div>
        </div>
      </div>
      <div className="mt-8 xl:mt-0">
        <span className="text-gray-darker">Nu ai un cont încă?</span>
        <NavLink className="ml-1" variant="accent" to="/inregistrare">
          Crează cont
        </NavLink>
      </div>
    </div>
  );
};

export default Login;
