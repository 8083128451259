import React from 'react';
import PropTypes from 'prop-types';
import { Emphasize } from '../formatting';

const AppName = (props) => {
  const { quoted } = props;

  return (
    <span>
      {quoted && <span>&quot;</span>}
      <Emphasize variant="semibold">pescuială.ro</Emphasize>
      {quoted && <span>&quot;</span>}
    </span>
  );
};

AppName.propTypes = {
  quoted: PropTypes.bool,
};

AppName.defaultProps = {
  quoted: false,
};

AppName.displayName = 'AppName';

export default AppName;
