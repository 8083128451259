import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import WeatherWidget from 'components/ui/WeatherWidget';
import Map from 'components/ui/Map';
import Stats from './Stats';
import Description from './Description';

const Details = (props) => {
  const {
    image,
    weather,
    stats,
    description,
    location: { latitude, longitude },
  } = props;
  const isLandscapeImage = image && image.orientation === 'landscape';

  return (
    <div className="grid grid-cols-1 gap-4.8">
      {description && <Description imageSrc={image ? image.url : null} description={description} />}
      <div className="flex flex-col max-content-screen:flex-row gap-4.8 w-full">
        <div className="relative rounded-6.4 flex items-center max-content-screen:w-1/2 max-content-screen:h-250.8">
          {isLandscapeImage && (
            <div
              className="z-10 absolute top-3.2 left-3.2 right-3.2 bottom-3.2 rounded-inherit bg-cover bg-center blur"
              style={isLandscapeImage ? { backgroundImage: `url('${image.url}')` } : {}}
            />
          )}
          <div className="z-10 absolute w-full h-full bg-gray-dark bg-opacity-50 rounded-inherit" />
          {image ? (
            <img
              className={cn('z-10 w-full rounded-inherit', {
                'max-content-screen:rounded-none': isLandscapeImage,
              })}
              src={image.url}
              alt="Cover"
            />
          ) : null}
        </div>
        <div className="rounded-6.4 flex flex-col gap-4.8 max-content-screen:w-1/2">
          <div className="w-full flex-1 rounded-6.4 h-104 max-content-screen:h-auto">
            <Map
              mapId={`map-${latitude}-${longitude}`}
              coordinates={{
                lat: latitude,
                lng: longitude,
              }}
              viewOnly
            />
          </div>
          <div className="flex flex-col gap-4">
            <WeatherWidget {...weather} />
          </div>
          <div className="flex flex-col gap-4.8">
            <Stats {...stats} />
          </div>
        </div>
      </div>
    </div>
  );
};

Details.propTypes = {
  description: PropTypes.string.isRequired,
  image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    orientation: PropTypes.oneOf(['landscape', 'portrait']).isRequired,
  }),
  weather: PropTypes.shape({
    temp: PropTypes.number,
    skyCondition: PropTypes.string,
    windSpeed: PropTypes.number,
    windDirection: PropTypes.string,
    windGap: PropTypes.number,
    airPressure: PropTypes.number,
  }).isRequired,
  stats: PropTypes.shape({
    startDate: PropTypes.string.isRequired,
    startTime: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    endTime: PropTypes.string.isRequired,
    species: PropTypes.array.isRequired,
    quantity: PropTypes.number.isRequired,
    personalScore: PropTypes.number.isRequired,
    waterCondition: PropTypes.shape({
      clearness: PropTypes.string,
      level: PropTypes.number,
      speed: PropTypes.string,
    }).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
  }).isRequired,
};

Details.defaultProps = {
  image: null,
};

Details.displayName = 'Details';

export default Details;
