import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'components/ui/inputs';

const DiameterInput = (props) => {
  const { value, onChange, required, helperText, ...otherProps } = props;

  return (
    <Input
      id="diameter"
      name="diameter"
      onChange={onChange}
      placeholder="Introdu diametrul"
      label="Diametru"
      value={value}
      inputMode="decimal"
      pattern="[0-9]*"
      required={required}
      helperText={helperText}
      valid={otherProps.isDirty ? otherProps.isValid : undefined}
    />
  );
};

DiameterInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  required: PropTypes.bool,
  helperText: PropTypes.string,
};

DiameterInput.defaultProps = {
  value: '',
  required: false,
  helperText: '',
};

DiameterInput.displayName = 'DiameterInput';

export default DiameterInput;
