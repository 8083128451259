import React from 'react';
import PropTypes from 'prop-types';

const Section = (props) => {
  const { title, children } = props;

  return (
    <div className="flex flex-col gap-4">
      {title && (
        <h2 className="text-lg lg:text-xl text-gray-dark uppercase font-semibold">{title}</h2>
      )}
      <div className="text-lg: lg:text-xl text-gray-darker leading-8 text-justify flex flex-col gap-4">
        {children}
      </div>
    </div>
  );
};

Section.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};

Section.defaultProps = {
  title: null,
};

Section.displayName = 'Section';

export default Section;
