export const BRAND_NAME_KEY = 'brand.name';

export const FEEDERS_META = {
  [BRAND_NAME_KEY]: 'Producător',
  model: 'Model',
  weight: 'Greutate',
  type: 'Tip',
  subType: 'Model',
};
export const GROUND_BAITS_META = {
  [BRAND_NAME_KEY]: 'Producător',
  model: 'Model',
};

export const BAITS_META = {
  name: 'Nume',
};
