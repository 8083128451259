import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import Header from 'components/authentication/Header';
import { NavLink } from 'components/ui/links';
import RefreshCode from './RefreshCode';
import SuccessfulRegistration from './SuccessfulRegistration';

const getResponse = (location, componentProps) => {
  if (location.includes('/cont')) {
    return <RefreshCode {...componentProps} />;
  }
  return <SuccessfulRegistration {...componentProps} />;
};

const ActivationDetails = () => {
  const location = useLocation();
  const { id: userId, email, token } = location.state;
  const prevLocation = location.state.from || '/';

  const component = useMemo(
    () =>
      getResponse(prevLocation, {
        userId,
        email,
        token,
      }),
    [prevLocation, userId, email, token],
  );

  return (
    <div className="flex flex-col h-full justify-start gap-8 xl:gap-12 relative">
      <Header>Activare cont</Header>
      {component}
      <div className="flex justify-end relative lg:absolute bottom-0 right-0 mt-24 lg:mt-0">
        <NavLink to="/" variant="accent">
          Mergi la pagina de autentificare
        </NavLink>
      </div>
    </div>
  );
};

ActivationDetails.displayName = 'ActivationDetails';

export default ActivationDetails;
