import { format } from 'date-fns';
import { EMPTY_DEFAULT_FIELD } from 'constants/forms';

export const getGoOutState = async (data) => {
  const {
    startsAt,
    endsAt,
    location,
    weather,
    waterCondition,
    mediaFiles,
    selfRate,
    personalNotes,
    catch: catchData,
    baitsMeta,
    feedersMeta,
    feedingStrategy,
    gear,
    rigs,
    groundBaitsMeta,
    totalQuantity,
  } = data;
  const startDate = new Date(startsAt);
  const startTime = format(startsAt, 'HH:mm');
  const endDate = new Date(endsAt);
  const endTime = format(endsAt, 'HH:mm');

  const files = await Promise.all(
    mediaFiles.map(async (item) => {
      const blob = await fetch(item.mediaFile.url).then((response) => response.blob());
      const fileName = item.mediaFile.url.split('/').pop(); // Get file name from URL
      return {
        id: item.id,
        file: new File([blob], item.name || fileName, {
          type: blob.type,
          url: item.mediaFile.url,
        }),
        isCover: !!item.coverImage,
        metaData: item.coverImage
          ? {
              cropData: {
                x: item.coverImage.xCrop,
                y: item.coverImage.yCrop,
                zoom: item.coverImage.zoom,
              },
            }
          : null,
      };
    }),
  );

  return {
    event: {
      startDate: {
        ...EMPTY_DEFAULT_FIELD,
        isValid: true,
        value: startDate,
        isDirty: true,
      },
      startTime: {
        ...EMPTY_DEFAULT_FIELD,
        isValid: true,
        value: startTime,
        isDirty: true,
      },
      endDate: {
        ...EMPTY_DEFAULT_FIELD,
        isValid: true,
        value: endDate,
        isDirty: true,
      },
      endTime: {
        ...EMPTY_DEFAULT_FIELD,
        isValid: true,
        value: endTime,
        isDirty: true,
      },
      location: {
        latitude: {
          ...EMPTY_DEFAULT_FIELD,
          isValid: true,
          value: location.latitude,
          isDirty: true,
        },
        longitude: {
          ...EMPTY_DEFAULT_FIELD,
          isValid: true,
          value: location.longitude,
          isDirty: true,
        },
        name: {
          ...EMPTY_DEFAULT_FIELD,
          isValid: true,
          value: location.name || '',
        },
        formattedAddress: {
          ...EMPTY_DEFAULT_FIELD,
          isValid: true,
          value: location.formattedAddress || '',
        },
        city: {
          ...EMPTY_DEFAULT_FIELD,
          isValid: true,
          value: location.city || '',
        },
        county: {
          ...EMPTY_DEFAULT_FIELD,
          isValid: true,
          value: location.county || '',
        },
        country: {
          ...EMPTY_DEFAULT_FIELD,
          isValid: true,
          value: location.country || '',
        },
        street: {
          ...EMPTY_DEFAULT_FIELD,
          isValid: true,
          value: location.street || '',
        },
        streetNo: {
          ...EMPTY_DEFAULT_FIELD,
          isValid: true,
          value: location.streetNo || '',
        },
      },
    },
    conditions: {
      weather: {
        skyCondition: {
          ...EMPTY_DEFAULT_FIELD,
          isValid: true,
          value: weather.skyCondition || '',
          isDirty: true,
        },
        temperature: {
          ...EMPTY_DEFAULT_FIELD,
          isValid: true,
          value: weather.temperature.toString(),
          isDirty: true,
        },
        windSpeed: {
          ...EMPTY_DEFAULT_FIELD,
          isValid: true,
          value: weather.windSpeed.toString(),
          isDirty: true,
        },
        windGusts: {
          ...EMPTY_DEFAULT_FIELD,
          isValid: true,
          value: weather.windGusts ? weather.windGusts.toString() : '',
          isDirty: true,
        },
        windDirection: {
          ...EMPTY_DEFAULT_FIELD,
          isValid: true,
          value: weather.windDirection || '',
          isDirty: true,
        },
        atmPressure: {
          ...EMPTY_DEFAULT_FIELD,
          isValid: true,
          value: weather.atmPressure.toString(),
          isDirty: true,
        },
        personalNotes: {
          ...EMPTY_DEFAULT_FIELD,
          isValid: true,
          value: weather.personalNotes || '',
          isDirty: true,
        },
      },
      water: {
        clearness: {
          ...EMPTY_DEFAULT_FIELD,
          isValid: true,
          value: waterCondition.clearness || '',
          isDirty: true,
        },
        level: {
          ...EMPTY_DEFAULT_FIELD,
          isValid: true,
          value: waterCondition.level ? waterCondition.level.toString() : '',
          isDirty: true,
        },
        speed: {
          ...EMPTY_DEFAULT_FIELD,
          isValid: true,
          value: waterCondition.speed || '',
          isDirty: true,
        },
        personalNotes: {
          ...EMPTY_DEFAULT_FIELD,
          isValid: true,
          value: waterCondition.personalNotes || '',
          isDirty: true,
        },
      },
    },
    equipment: {
      gear: gear.map((item) => ({
        rod: item.rodId,
        reel: item.reelId,
        line: item.lineId,
      })),
      rigs: rigs.map((item) => ({
        hook: item.hookId,
        line: item.lineId,
        leader: item.leaderId,
        powerGum: item.powerGumId,
      })),
    },
    feeding: {
      feeders: {
        selectedIds: feedersMeta.feeders.map((item) => item.feeder.id),
        items: feedersMeta.feeders.map((item) => item.feeder),
        strategy: feedersMeta.description,
      },
      groundBaits: {
        selectedIds: groundBaitsMeta.groundBaits.map((item) => item.groundBait.id),
        items: groundBaitsMeta.groundBaits.map((item) => item.groundBait),
        strategy: groundBaitsMeta.description,
      },
      baits: {
        selectedIds: baitsMeta.baits.map((item) => item.bait.id),
        items: baitsMeta.baits.map((item) => item.bait),
        strategy: baitsMeta.description,
      },
    },
    results: {
      personalNotes: {
        ...EMPTY_DEFAULT_FIELD,
        isValid: true,
        value: personalNotes,
        isDirty: true,
      },
      selfRate: {
        ...EMPTY_DEFAULT_FIELD,
        isValid: true,
        value: selfRate,
        isDirty: true,
      },
      totalQuantity: {
        ...EMPTY_DEFAULT_FIELD,
        isValid: true,
        value: totalQuantity,
        isDirty: true,
      },
      species: catchData.map((item) => ({
        species: {
          ...EMPTY_DEFAULT_FIELD,
          isValid: true,
          value: item.fishSpecies.id,
          isDirty: true,
        },
        totalWeight: {
          ...EMPTY_DEFAULT_FIELD,
          isValid: true,
          value: item.totalWeight,
          isDirty: true,
        },
        maxWeight: {
          ...EMPTY_DEFAULT_FIELD,
          isValid: true,
          value: item.maxWeight,
          isDirty: true,
        },
        totalNumber: {
          ...EMPTY_DEFAULT_FIELD,
          isValid: true,
          value: item.totalNumber,
          isDirty: true,
        },
      })),
    },
    strategy: {
      description: {
        ...EMPTY_DEFAULT_FIELD,
        isValid: true,
        value: feedingStrategy.description,
        isDirty: true,
      },
      firstCatchTime: {
        ...EMPTY_DEFAULT_FIELD,
        isValid: true,
        value: feedingStrategy.firstCatchTime,
        isDirty: true,
      },
      averageCatchTime: {
        ...EMPTY_DEFAULT_FIELD,
        isValid: true,
        value: feedingStrategy.averageCatchTime,
        isDirty: true,
      },
    },
    media: files,
  };
};

export const getAvailableEntities = (data) => ({
  rods: data.gear.map((gear) => gear.rod),
  reels: data.gear.map((gear) => gear.reel),
  mainLines: data.gear.map((gear) => gear.line),
  rigLines: data.rigs.map((rig) => rig.line).filter((obj) => !!obj),
  leaders: data.rigs.map((rig) => rig.leader).filter((obj) => !!obj),
  powerGums: data.rigs.map((rig) => rig.powerGum).filter((obj) => !!obj),
  hooks: data.rigs.map((rig) => rig.hook),
  feeders: data.feedersMeta.feeders.map((item) => item.feeder),
  groundBaits: data.groundBaitsMeta.groundBaits.map((item) => item.groundBait),
  baits: data.baitsMeta.baits.map((item) => item.bait),
  species: data.catch.map((item) => item.fishSpecies),
});
