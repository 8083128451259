export const ACTIONS = {
  add: 'Adaugă',
  edit: 'Modifică ',
};

export const RODS_HEADER = ['Producător', 'Model', 'Lungime', 'Putere', 'Acțiune'];

export const REELS_HEADER = [
  'Producător',
  'Model',
  'Dimensiune',
  'Raport recuperare',
  'Lungime recuperare',
];
