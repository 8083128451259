import {
  INIT_HOOKS,
  ADD_HOOK,
  SELECT_HOOK,
  DESELECT_HOOK,
} from '../../types/availableEntities/hooks';

export const init = (payload) => ({
  type: INIT_HOOKS,
  payload,
});

export const add = (payload) => ({
  type: ADD_HOOK,
  payload,
});

export const select = (id, index) => ({
  type: SELECT_HOOK,
  payload: {
    id,
    index,
  },
});

export const deselect = (payload) => ({
  type: DESELECT_HOOK,
  payload,
});
