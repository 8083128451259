import React from 'react';
import { Outlet, NavLink } from 'react-router-dom';
import Icon from 'components/ui/Icon';
import Footer from '../Footer';

const PoliciesContainer = () => {
  return (
    <div className="flex flex-col h-screen">
      <div className="fixed top-0 right-0 left-0 bg-whitey-gray px-8 lg:px-32 xl:px-120 pb-8 pt-8 md:pb-8">
        <NavLink to="/">
          <Icon identifier="logo-colored" />
        </NavLink>
      </div>
      <div className="mt-29.2 flex-1 px-8 lg:px-32 xl:px-120 md:pb-8 xl:pb-12 flex flex-col gap-9.6">
        <div className="bg-white p-9.6 rounded-3.2 flex-1">
          <Outlet />
        </div>
      </div>
      <div className="sticky bottom-0">
        <Footer />
      </div>
    </div>
  );
};

export default PoliciesContainer;
