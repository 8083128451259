import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/ui/Modal';
import BrandInput from 'components/Equipment/inputs/BrandInput';
import ModelInput from 'components/Equipment/inputs/ModelInput';
import DiameterInput from 'components/Equipment/inputs/DiameterInput';
import useFormFields from 'components/Equipment/hooks/useFormFields';
import { isDecimalNumberMaxTwoDecimals, isRequired } from 'utility/validation';
import useSubmit from 'components/Equipment/hooks/useSubmit';
import useBrandsLoader from 'hooks/useBrandsLoader';
import { CheckboxesDropdown } from 'components/ui/dropdowns';

const lineTypes = ['monofilament', 'fluorocarbon', 'textil'].map((o) => ({ id: o, value: o }));

const MainLineModal = (props) => {
  const { onClose, item, onSuccess } = props;
  const { type, diameter } = item || {};
  const [fields, setFields, individualSetters] = useFormFields(item, {
    type: {
      field: {
        value: type || '',
        isValid: !!type,
      },
      validator: isRequired,
    },
    diameter: {
      field: {
        value: diameter || '',
        isValid: !!diameter,
      },
      validator: isDecimalNumberMaxTwoDecimals,
    },
  });

  const [isLoadingBrands, allBrands] = useBrandsLoader();
  const [canSubmit, onSubmit] = useSubmit('lines', item, fields, setFields, onSuccess);

  return (
    <Modal
      title="Adaugă fir principal"
      onClose={onClose}
      onSubmit={onSubmit}
      canSubmit={canSubmit}
      form
    >
      <BrandInput
        loading={isLoadingBrands}
        all={allBrands}
        onChange={individualSetters.brand}
        {...fields.brand}
      />
      <ModelInput onChange={individualSetters.model} {...fields.model} />
      <CheckboxesDropdown
        data={lineTypes}
        onChange={individualSetters.type}
        singleSelection
        triggerProps={{
          label: 'Tip',
          placeholder: 'Selectează tipul',
          required: true,
        }}
        selectedValues={fields.type.value ? [fields.type.value] : []}
      />
      <DiameterInput
        required
        onChange={individualSetters.diameter}
        helperText={
          fields.diameter.isDirty && !fields.diameter.isValid
            ? fields.diameter.error || 'Introdu un număr cu maxim 2 zecimale'
            : null
        }
        {...fields.diameter}
      />
    </Modal>
  );
};

MainLineModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    brand: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    model: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    diameter: PropTypes.number.isRequired,
  }),
};

MainLineModal.defaultProps = {
  item: null,
};

MainLineModal.displayName = 'MainLineModal';

export default MainLineModal;
