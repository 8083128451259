import React, { useContext, useEffect } from 'react';
import { Textarea } from 'components/ui/inputs';
import Context from 'components/GoOut/state/contexts/addNew';
import { onFieldChange } from 'utility/utils';
import { setDescription } from 'components/GoOut/state/actions/addNew/others';
import PropTypes from 'prop-types';
import PersonalScoreInput from 'components/GoOut/steps/inputs/PersonalScoreInput';
import CanEditContext from 'contexts/CanEditContext';
import Section from '../Section';

const Description = (props) => {
  const { onChange } = props;
  const [state, dispatch] = useContext(Context);
  const editable = useContext(CanEditContext);
  const {
    results: {
      personalNotes: { value: personalNotes },
      selfRate: { isValid: scoreIsValid },
    },
  } = state;

  useEffect(() => {
    onChange(scoreIsValid);
  }, [scoreIsValid, onChange]);

  const onChangeDescription = (event) => {
    const setter = (newItem) => dispatch(setDescription(newItem));
    onFieldChange(event.target.value, setter);
  };

  return (
    <Section title="Descriere" className="flex flex-col">
      <PersonalScoreInput editable={editable} />
      <Textarea
        onChange={onChangeDescription}
        name="description"
        id="description"
        placeholder="Introdu descriere"
        label="Descriere"
        type="text"
        rows="4"
        cols="50"
        value={personalNotes}
        helperText={editable ? 'Această descriere va apărea pe pagina principală.' : null}
        disabled={!editable}
      />
    </Section>
  );
};

Description.propTypes = {
  onChange: PropTypes.func.isRequired,
};

Description.displayName = 'Description';

export default Description;
