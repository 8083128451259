import React from 'react';
import { InformationalDropdown } from 'components/ui/dropdowns';

const PasswordInfo = () => (
  <InformationalDropdown>
    <p className="font-semibold">Reguli setare parolă</p>
    <ul className="list-disc pl-8 grid gap-3.2">
      <li>Trebuie să conțină cel puțin 8 caractere.</li>
      <li>Trebuie să conțină cel puțin o literă mică.</li>
      <li>Trebuie să conțină cel puțin o literă mare.</li>
      <li>Trebuie să conțină cel puțin o cifră.</li>
      <li>Trebuie să conțină cel puțin un caracter special.</li>
      <li>Nu poate fi foarte asemănătoare cu numele.</li>
    </ul>
  </InformationalDropdown>
);

PasswordInfo.displayName = 'PasswordInfo';

export default PasswordInfo;
