import axios from 'axios';
import { getCookie, toCamelCase, toSnakeCase } from './utils';

const { API_URL } = process.env;

const axiosInstance = axios.create({
  baseURL: API_URL,
  withCredentials: true,
});

const getAccessToken = () => {
  if (global.isSSR) {
    return global.accessToken;
  }

  return getCookie(document.cookie, 'access_token');
};

axiosInstance.interceptors.request.use((config) => {
  const accessToken = getAccessToken();

  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  if (config.data) {
    const isFormData = config.data instanceof FormData;

    if (isFormData) {
      // We're using FormData only on login case. Therefore, we can hardcode the 'rememberMe' key.
      // If things change, please update this code as well.
      config.data.append('remember_me', config.data.get('rememberMe'));
      config.data.delete('rememberMe');
    } else {
      config.data = toSnakeCase(config.data);
    }
  }

  return config;
});

axiosInstance.interceptors.response.use(
  async (response) => {
    response.data = toCamelCase(response.data);
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    const {
      status,
      data: { detail },
    } = error.response;

    if (status === 401 && detail === 'Expired token') {
      let refreshResponse;

      try {
        refreshResponse = await axiosInstance.post('/authentication/refresh/');
      } catch (refreshError) {
        return Promise.reject(refreshError);
      }

      if (refreshResponse.status === 200) {
        originalRequest.headers.Authorization = getAccessToken();
        return axiosInstance.request(originalRequest);
      }
    }

    return new Promise((resolve, reject) => {
      error.response.data = toCamelCase(error.response.data);
      reject(error);
    });
  },
);

export default axiosInstance;
