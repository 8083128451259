import React, { useState, useContext, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { IconButton } from 'components/ui/buttons';
import { AddNewContext, AvailableEntitiesContext } from 'components/GoOut/state/contexts';
import {
  addSpecies,
  removeSpecies,
  setSpecies,
  setTotalQuantity,
} from 'components/GoOut/state/actions/addNew/others';
import { onFieldChange } from 'utility/utils';
import { isRequired } from 'utility/validation';
import PropTypes from 'prop-types';
import CanEditContext from 'contexts/CanEditContext';
import { useData } from 'components/GoOut/hooks';
import { init } from 'components/GoOut/state/actions/availableEntities/species';
import IconStats from 'components/ui/IconStats';
import Section from '../Section';
import CatchData from './CatchData';

const Results = (props) => {
  const { onChange } = props;
  const { id: goOutId } = useParams();
  const editable = useContext(CanEditContext);
  const [isAccordionIdOpened, setIsAccordionIdOpened] = useState(editable ? 0 : null);
  const [formData, formDataDispatch] = useContext(AddNewContext);
  const [{ species: availableSpecies }, availableEntitiesDispatch] =
    useContext(AvailableEntitiesContext);
  const {
    results: { totalQuantity, species },
  } = formData;
  const [isLoading, availableData, { load: loadData }] = useData('/species', availableSpecies, {
    formDataDispatch,
    availableEntitiesDispatch,
    onInit: init,
    onAdd: () => {},
    onSelect: () => {},
    onDeselect: () => {},
  });
  const dropdownData = useMemo(
    () => availableData.map((o) => ({ id: o.id, value: o.name })),
    [availableData],
  );

  useEffect(() => {
    const isValid =
      species.length === 0 ||
      species.every((item) => {
        return (
          Object.values(item).every((field) => field.isValid) &&
          Object.values(item).some((field) => field.isDirty)
        );
      });
    onChange(isValid);
  }, [species, onChange]);

  useEffect(() => {
    const total = species.reduce(
      (acc, item) =>
        acc + (Number.isNaN(item.totalWeight.value) ? 0 : Number(item.totalWeight.value)),
      0,
    );
    const setter = (newItem) => formDataDispatch(setTotalQuantity(newItem));
    onFieldChange(total, setter);
  }, [formDataDispatch, species]);

  useEffect(() => {
    if (goOutId && isLoading) {
      loadData();
    }
  }, [goOutId, isLoading, loadData]);

  const onSpeciesDropdownTriggerClick = (isOpened) => {
    if (isOpened && isLoading) {
      loadData();
    }
  };

  const addRow = () => {
    formDataDispatch(addSpecies());
  };

  const removeRow = (index) => {
    formDataDispatch(removeSpecies(index));
  };

  const onSelectSpecies = (selectedId, index, field) => {
    const setter = (newItem) => formDataDispatch(setSpecies(newItem, index, field));
    onFieldChange(selectedId, setter, isRequired);
  };

  const changeField = (value, index, field, validator) => {
    const setter = (newItem) => formDataDispatch(setSpecies(newItem, index, field));
    onFieldChange(value, setter, validator);
  };

  return (
    <Section title="Rezultate" className="flex flex-col">
      <div className="flex">
        <IconStats
          icon="scale"
          data={[
            {
              label: 'Cantitate totală',
              value: `${Number.parseInt(totalQuantity.value || '0', 10).toFixed(3)} kg`,
            },
          ]}
          rounded="small"
        />
      </div>
      {species.map((obj, index) => {
        const currentSpecies = availableData.find((item) => item.id === obj.species.value);
        const isOpened = isAccordionIdOpened === index;
        return (
          <CatchData
            key={obj.species.value}
            formData={obj}
            speciesDetails={currentSpecies}
            index={index}
            opened={isOpened}
            onAccordionToggle={() => setIsAccordionIdOpened(isOpened ? null : index)}
            editable={editable}
            removeRow={editable ? removeRow : null}
            onDropdownTriggerClick={onSpeciesDropdownTriggerClick}
            changeField={changeField}
            onSelect={onSelectSpecies}
            dropdownData={{
              isLoading,
              data: dropdownData,
            }}
          />
        );
      })}
      {editable && (
        <div className="col-span-3 justify-start">
          <IconButton
            variant="link"
            onClick={addRow}
            size="small"
            weight="medium"
            icon="plus-small"
          >
            Adaugă o {species.length > 1 ? 'nouă' : ''} specie
          </IconButton>
        </div>
      )}
    </Section>
  );
};

Results.propTypes = {
  onChange: PropTypes.func.isRequired,
};

Results.displayName = 'Results';

export default Results;
