import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/ui/Modal';
import Text from './Text';

const maxTruncateValue = 300;
const minTruncateValue = 100;

const Description = (props) => {
  const { imageSrc, description } = props;
  const [showModal, setShowModal] = useState(false);
  const [truncateValue, setTruncateValue] = useState(
    window.innerWidth < 768 ? minTruncateValue : maxTruncateValue,
  );
  const onViewMore = () => setShowModal(true);
  const onCloseModal = () => setShowModal(false);

  const onResize = () => {
    if (window.innerWidth < 768) {
      setTruncateValue(minTruncateValue);
    } else {
      setTruncateValue(maxTruncateValue);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  let textToShow;
  let isTrimmed = false;
  if (description.length > truncateValue) {
    textToShow = description.slice(0, truncateValue);
    isTrimmed = true;
  } else {
    textToShow = description;
  }

  return (
    <>
      <Text onViewMore={isTrimmed ? onViewMore : undefined}>{textToShow}</Text>
      {showModal && (
        <Modal title="Descriere" onClose={onCloseModal} cancelText="Închide">
          <div className="flex flex-col gap-6.4">
            <Text>{description}</Text>
            {imageSrc && (
              <div className="rounded-6.4">
                <img className="rounded-inherit w-full" src={imageSrc} alt="Cover" />
              </div>
            )}
          </div>
        </Modal>
      )}
    </>
  );
};

Description.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

Description.displayName = 'Description';

export default Description;
