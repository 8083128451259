import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { format, getMonth, addMonths, subMonths, isFuture } from 'date-fns';
import { ro } from 'date-fns/locale/ro';
import { IconButton, Button } from 'components/ui/buttons';
import { DAYS_OF_WEEK } from 'components/ui/Calendar/utility/constants';
import Day from 'components/ui/Calendar/Day';
import { getEachDayOfMonth } from 'components/ui/Calendar/utility/utils';

const Days = (props) => {
  const { selectedDate, onViewChange, onChange } = props;
  const [dateInView, setDateInView] = useState(new Date(selectedDate));
  const timeNow = useRef(new Date());
  const currentMonth = getMonth(dateInView);
  const days = getEachDayOfMonth(dateInView);
  const formattedMonth = format(dateInView, 'MMMM yyyy', { locale: ro });
  const weeksNo = days.length / 7;
  const onPrevMonth = () => setDateInView(subMonths(dateInView, 1));
  const onNextMonth = () => setDateInView(addMonths(dateInView, 1));

  return (
    <>
      <div data-testid="days-view-header" className="flex justify-between items-center gap-6.4">
        <IconButton variant="link" icon="left-chevron" onClick={onPrevMonth} />
        <Button dataTestId="days-view-month" variant="link" onClick={onViewChange}>
          {formattedMonth}
        </Button>
        <IconButton variant="link" icon="right-chevron" onClick={onNextMonth} />
      </div>
      <div
        data-testid="days-container"
        className="w-full grid grid-cols-7 justify-between gap-8 h-131.2"
      >
        {DAYS_OF_WEEK.map((day) => (
          <div
            key={day.identifier}
            className="w-12.8 text-gray font-medium leading-6.4 flex justify-center items-center mx-auto"
          >
            {day.label}
          </div>
        ))}
        {days.map((day) => (
          <Day
            key={format(day, 'yyyy-MM-dd')}
            month={getMonth(day)}
            referenceMonth={currentMonth}
            onClick={() => onChange(day)}
            value={day.getDate().toString()}
            isToday={format(day, 'yyyy-MM-dd') === format(timeNow.current, 'yyyy-MM-dd')}
            isSelected={format(day, 'yyyy-MM-dd') === format(selectedDate, 'yyyy-MM-dd')}
            disabled={isFuture(day)}
          />
        ))}
        {
          // Add empty cells to complete the last week
          Array.from({ length: 6 - weeksNo }).map((_, index) => (
            <div key={index} className="w-12.8 h-12.8" />
          ))
        }
      </div>
    </>
  );
};

Days.propTypes = {
  selectedDate: PropTypes.instanceOf(Date).isRequired,
  onViewChange: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Days;
