import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAPI from 'hooks/useAPI';

const getEmailAddress = (str) => {
  const regex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/;
  return str.match(regex)[0] || '';
};

const getMessage = (messageType, email) => {
  const body = ['expired', 'password-expired'].includes(messageType) ? (
    'Puteți solicita unul nou folosind butonul de mai jos.'
  ) : (
    <>
      Un e-mail a fost trimis pe adresa <span className="font-bold text-green-dark">{email}</span>.
      Te rugăm să urmărești indicațiile primite pe mail pentru a activa contul.
    </>
  );
  switch (messageType) {
    case 'success':
      return {
        title: 'Contul tău a fost creat cu succes.',
        body,
        icon: 'success',
      };
    case 'inactive':
      return {
        title: 'Contul nu a fost activat.',
        body,
        icon: 'close',
      };
    case 'expired':
      return {
        title: 'Link-ul de activare a expirat.',
        body,
        icon: 'warning',
      };
    case 'password-success':
      return {
        title: 'Cererea de resetare a parolei a fost trimisă cu succes.',
        body,
        icon: 'success',
      };
    case 'password-expired':
      return {
        title: 'Link-ul de resetare a parolei a expirat.',
        body,
        icon: 'warning',
      };
    default:
      return null;
  }
};

const useServerError = (data, action) => {
  const [messageType, setMessageType] = useState('success');
  const [email, setEmail] = useState(data.email);
  const navigate = useNavigate();
  const { apiCall } = useAPI();
  const messageData = useMemo(() => getMessage(messageType, email), [messageType, email]);
  let url = useMemo(() => `/authentication/${action}`, [action]);

  useEffect(() => {
    if (data) {
      switch (data.detail) {
        case 'Account is inactive':
          setMessageType('inactive');
          break;
        case 'Token has expired':
          if (action === 'reset-password') {
            setMessageType('password-expired');
          } else {
            setMessageType('expired');
          }
          break;
        case 'Reset password instructions sent':
          setMessageType('password-success');
          break;
        default:
          setMessageType('success');
          break;
      }
    }
  }, [action, data]);

  const onSubmit = async (e) => {
    e.preventDefault();

    const isResend = ['success', 'password-success'].includes(messageType);

    let body;
    if (isResend) {
      url += '/resend';
      body = { email };
    } else {
      url += '/refresh';
      body = {
        token: data.token,
        email,
      };
    }

    await apiCall(
      url,
      'post',
      { ...body },
      {
        getSuccessMessage: () => 'E-mail-ul a fost retrimis cu succes!',
        getErrorMessage: (errorData, statusCode) => {
          if (statusCode === 400) {
            switch (errorData.detail) {
              case 'Account is already active':
                return 'Contul tău a fost deja activat!';
              case 'Token has expired':
                return 'Link-ul de activare a expirat!';
              case 'Token is invalid':
                return 'Link-ul de activare a expirat!';
              default:
                return null;
            }
          }
          if (statusCode === 404) {
            return 'Contul nu a fost găsit!';
          }
          return null;
        },
        onErrorCallback: () => {
          navigate('/');
        },
        onSuccessCallback: (successData) => {
          if (!isResend) {
            setEmail(getEmailAddress(successData.message));
          }
          setMessageType(action === 'reset-password' ? 'password-success' : 'success');
        },
      },
    );
  };

  return {
    messageType,
    messageData,
    onSubmit,
  };
};

export default useServerError;
