import React from 'react';
import PropTypes from 'prop-types';

const Header = (props) => {
  const { title, description } = props;

  return (
    <div className="flex flex-col gap-y-1.6">
      <span className="text-green-dark text-inner-header font-semibold ">{title}</span>
      {description && <span className="leading-8 text-gray">{description}</span>}
    </div>
  );
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
};

Header.defaultProps = {
  description: '',
};

Header.displayName = 'Header';

export default Header;
