import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Accordion from 'components/ui/Accordion';
import Table from 'components/ui/table/Table';
import { Button } from 'components/ui/buttons';
import FeedingStrategyInput from 'components/GoOut/steps/Feeding/FeedingStrategyInput';
import { useSelection } from './hooks';
import { useAddNew, useTableRows, useTableData } from '../../hooks';
import { getOnSelectionChangeMethod } from '../../utility/utils';
import Section from '../Section';

const getAccordionExtras = (rows, onClick) => {
  const selectedNumber = rows.length;

  if (selectedNumber === 0) {
    return null;
  }

  let text;
  if (selectedNumber === 1) {
    text = 'Vezi 1 selecție';
  } else {
    text = `Vezi ${selectedNumber} selecții`;
  }

  return (
    <Button variant="link" size="small" onClick={onClick}>
      {text}
    </Button>
  );
};

const AccordionWithData = (props) => {
  const {
    title,
    actionText,
    strategyInputProps,
    endpoint,
    onToggle,
    opened,
    actions,
    state,
    dataMeta,
    AddNewModalComponent,
    onValidate,
  } = props;
  const { selectedIds } = state;
  const { formDataDispatch, onSelect, onDeselect, onStrategyChange } = actions;
  const [tableHeaders, objectColumns] = useTableData(dataMeta);
  const [isLoading, rows, selectedRows, { load: loadData, onAdd }] = useTableRows(
    endpoint,
    state,
    actions,
    objectColumns,
  );
  const onChange = getOnSelectionChangeMethod(selectedIds, formDataDispatch, onSelect, onDeselect);
  const [showAddNewModal, setShowAddNewModal, content] = useAddNew(AddNewModalComponent, onAdd);
  const [showSelectionModal, setShowSelectionModal, selectionModal] = useSelection(
    title,
    selectedRows,
    tableHeaders,
  );
  const accordionExtras = useMemo(
    () => getAccordionExtras(selectedRows, () => setShowSelectionModal(true)),
    [selectedRows, setShowSelectionModal],
  );

  useEffect(() => {
    if (onValidate) {
      onValidate(selectedRows.length > 0);
    }
  }, [onValidate, selectedRows]);

  const onAccordionToggle = () => {
    if (!opened) {
      loadData();
    }

    onToggle();
  };

  return (
    <Section className="flex flex-col gap-y-4.8">
      <Accordion
        onToggle={onAccordionToggle}
        title={title}
        opened={opened}
        extras={accordionExtras}
      >
        <Table
          header={tableHeaders}
          rows={rows}
          itemsPerPage={6}
          itemsCount={0}
          onPageChange={() => {}}
          onSelect={onChange}
          loading={isLoading}
        />
        <Button variant="blueish" onClick={() => setShowAddNewModal(true)}>
          {actionText}
        </Button>
        <FeedingStrategyInput {...strategyInputProps} onChange={onStrategyChange} />
        {showAddNewModal && content}
        {showSelectionModal && selectionModal}
      </Accordion>
    </Section>
  );
};

AccordionWithData.propTypes = {
  opened: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
  onValidate: PropTypes.func,
  title: PropTypes.node.isRequired,
  actionText: PropTypes.string.isRequired,
  strategyInputProps: PropTypes.object.isRequired,
  endpoint: PropTypes.string.isRequired,
  actions: PropTypes.shape({
    formDataDispatch: PropTypes.func.isRequired,
    availableEntitiesDispatch: PropTypes.func.isRequired,
    onInit: PropTypes.func.isRequired,
    onAdd: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    onDeselect: PropTypes.func.isRequired,
    onStrategyChange: PropTypes.func.isRequired,
  }).isRequired,
  state: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    all: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
    selectedIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  }).isRequired,
  dataMeta: PropTypes.object.isRequired,
  AddNewModalComponent: PropTypes.elementType.isRequired,
};

AccordionWithData.defaultProps = {
  opened: false,
  onValidate: null,
};

AccordionWithData.displayName = 'AccordionWithData';

export default AccordionWithData;
