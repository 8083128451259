import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { IconInput } from 'components/ui/inputs';
import Checkbox from 'components/ui/Checkbox';
import GenericDropdown from '../GenericDropdown';
import InputWrapper from './InputWrapper';
import HighlightedText from './HighlightedText';
import { getChipValues, getFilteredData, getOnSelectionChangeMethod } from './utils';

const CheckboxesDropdown = (props) => {
  const {
    searchable,
    data,
    triggerProps,
    action,
    singleSelection,
    selectedValues,
    onChange,
    variant,
    ...otherProps
  } = props;
  const [inputValue, setInputValue] = useState('');
  const isRadioVariant = variant === 'radio';
  const filteredData = useMemo(() => getFilteredData(data, inputValue), [data, inputValue]);
  const onSelectionChange = useCallback(
    getOnSelectionChangeMethod(selectedValues, singleSelection, isRadioVariant, onChange),
    [selectedValues, singleSelection, onChange, isRadioVariant],
  );

  const chipValues = useMemo(
    () => getChipValues(data, selectedValues, onSelectionChange),
    [data, selectedValues, onSelectionChange],
  );

  const onSearchChange = (event) => {
    setInputValue(event.target.value);
  };

  return (
    <GenericDropdown
      className="!w-full"
      triggerProps={{
        ...triggerProps,
        values: chipValues,
        singleSelection,
      }}
      triggerComponent={InputWrapper}
      {...otherProps}
    >
      <div className="rounded-[inherit] flex flex-col gap-6.4">
        {searchable && (
          <IconInput
            icon="search"
            onChange={onSearchChange}
            name=""
            id=""
            placeholder="Caută"
            value={inputValue}
            autoFocus
          />
        )}
        <div className="flex flex-col gap-4.8">
          {filteredData.length === 0 ? (
            <p className="text-center text-gray-darker italic">Nu există date.</p>
          ) : (
            <>
              <p className="text-gray-dark font-medium leading-8">Alege din listă</p>
              <div className="flex flex-col gap-3.2 max-h-56 overflow-auto">
                {filteredData.map((item, index) => (
                  <Checkbox
                    key={`${index}-${item.id}`}
                    label={<HighlightedText term={inputValue} value={item.value} />}
                    onChange={() => onSelectionChange(item.id)}
                    name={`selection-${index}`}
                    id={`selection-${index}`}
                    checked={selectedValues.includes(item.id)}
                    variant={variant}
                  />
                ))}
              </div>
            </>
          )}
          {action}
        </div>
      </div>
    </GenericDropdown>
  );
};

CheckboxesDropdown.propTypes = {
  searchable: PropTypes.bool,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  triggerProps: PropTypes.object,
  action: PropTypes.node,
  singleSelection: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  selectedValues: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  variant: PropTypes.oneOf(['checkbox', 'radio']),
};

CheckboxesDropdown.defaultProps = {
  searchable: false,
  triggerProps: {},
  action: null,
  singleSelection: false,
  selectedValues: [],
  variant: 'checkbox',
};

CheckboxesDropdown.displayName = 'CheckboxesDropdown';

export default CheckboxesDropdown;
