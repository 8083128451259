import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Button from 'components/ui/buttons/Button';
import Icon from 'components/ui/Icon';

const IconButton = forwardRef((props, ref) => {
  const {
    icon,
    variant,
    children,
    iconColorClassName,
    disabled,
    fullWidth,
    size,
    onClick,
    submit,
    iconClassName,
    iconOrientation,
    weight,
    scheme,
    ...otherProps
  } = props;
  const isPrimary = variant === 'primary';
  const isSecondary = variant === 'secondary';
  const isLink = variant === 'link';
  const isSocial = variant === 'social';
  const isBlueish = variant === 'blueish';
  const isSimple = variant === 'simple';
  const isSimpleDefault = isSimple && scheme === 'default';
  const isSimpleDanger = isSimple && scheme === 'danger';
  const isSimpleBlue = isSimple && scheme === 'blue';
  const isDanger = variant === 'danger';
  const isSmall = size === 'small';
  const isMedium = size === 'medium';
  const isLeftIcon = iconOrientation === 'left';

  const iconElement = (
    <Icon
      identifier={icon}
      className={iconClassName}
      fillClassName={cn(iconColorClassName, {
        'fill-gray-light-stroke': isSocial && disabled,
        'fill-blue group-hover:fill-blue-darker':
          (isBlueish || isLink || isSimpleBlue) && !disabled,
        'fill-gray': isBlueish && disabled,
        'fill-white': isPrimary || isDanger,
        'fill-gray-darker group-hover:fill-gray-800': (isSecondary || isSimpleDefault) && !disabled,
        'fill-red group-hover:fill-red-darker': isSimpleDanger && !disabled,
        'fill-gray-input-stroke': (isSecondary || isSimple) && disabled,
      })}
    />
  );

  const childrenElement = children && (
    <span
      className={cn({
        'ml-3.2': isLeftIcon,
        'mr-3.2': !isLeftIcon,
        'ml-4.8': isSocial && isLeftIcon,
        'mr-4.8': isSocial && !isLeftIcon,
        'ml-1.6': isLink && isLeftIcon,
        'mr-1.6': isLink && !isLeftIcon,
        'text-controls': isMedium,
        'leading-8': isSmall,
      })}
    >
      {children}
    </span>
  );

  return (
    <Button
      ref={ref}
      className={cn('group flex items-center justify-center', { '!px-0.8 !py-0.8': !children })}
      variant={variant}
      disabled={disabled}
      fullWidth={fullWidth}
      onClick={onClick}
      submit={submit}
      size={size}
      weight={weight}
      scheme={scheme}
      {...otherProps}
    >
      {isLeftIcon && iconElement}
      {childrenElement}
      {!isLeftIcon && iconElement}
    </Button>
  );
});

IconButton.propTypes = {
  icon: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['primary', 'secondary', 'link', 'simple', 'social', 'blueish']),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  iconColorClassName: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium']),
  onClick: PropTypes.func.isRequired,
  submit: PropTypes.bool,
  iconClassName: PropTypes.string,
  iconOrientation: PropTypes.oneOf(['left', 'right']),
  weight: PropTypes.oneOf(['medium', 'semibold']),
  scheme: PropTypes.oneOf(['default', 'danger', 'blue']),
};

IconButton.defaultProps = {
  children: null,
  variant: 'secondary',
  iconColorClassName: '',
  disabled: false,
  fullWidth: false,
  size: 'medium',
  submit: false,
  iconClassName: '',
  iconOrientation: 'left',
  weight: 'semibold',
  scheme: 'default',
};

IconButton.displayName = 'IconButton';

export default IconButton;
