import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { CalendarDropdown } from 'components/ui/dropdowns';
import IconStats from 'components/ui/IconStats';
import Context from 'components/GoOut/state/contexts/addNew';
import { setEndDate } from 'components/GoOut/state/actions/addNew/others';
import { onFieldChange } from 'utility/utils';
import { isRequired } from 'utility/validation';
import { format } from 'date-fns';
import { ro } from 'date-fns/locale/ro';

const EndDateInput = (props) => {
  const { editable } = props;
  const [state, dispatch] = useContext(Context);
  const {
    event: {
      endDate: {
        value: endDateValue,
        isValid: endDateIsValid,
        isDirty: endDateIsDirty,
        error: endDateError,
      },
    },
  } = state;

  const onChange = (date) => {
    const setter = (newItem) => dispatch(setEndDate({ ...newItem, value: date }));
    onFieldChange(format(date, 'd MMMM yyyy'), setter, isRequired);
  };

  const defaultProps = {
    id: 'endDate',
    name: 'endDate',
    placeholder: 'zz/ll/aaaa',
    label: 'Dată de final',
    type: 'text',
    required: true,
    valid: endDateIsDirty ? endDateIsValid : undefined,
    error: endDateError,
    readOnly: !editable,
  };

  if (editable) {
    return (
      <CalendarDropdown
        onChange={onChange}
        triggerProps={defaultProps}
        withCurrentDate
        date={endDateValue ? new Date(endDateValue) : null}
        readOnly={!editable}
      />
    );
  }

  const displayValue = endDateValue ? format(endDateValue, 'd MMMM yyyy', { locale: ro }) : '';
  return (
    <IconStats
      icon="calendar"
      data={[{ label: 'Dată de final', value: displayValue }]}
      rounded="small"
    />
  );
};

EndDateInput.propTypes = {
  editable: PropTypes.bool.isRequired,
};

export default EndDateInput;
