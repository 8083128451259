import React from 'react';
import PropTypes from 'prop-types';
import Section from 'components/GoOut/steps/Section';
import Upload from 'components/ui/Upload';
import { IconButton } from 'components/ui/buttons';

const Uploader = (props) => {
  const { media, files, coverImage, onDelete, selectedNo, uploadedMedia } = props;

  return (
    <>
      <Section>
        <Upload onUpload={files} />
      </Section>
      {coverImage && (
        <Section>
          {/* eslint-disable-next-line max-len */}
          <div className="flex flex-col border border-gray-light-stroke rounded-3.2 px-6.4 pt-8 pb-4.8 gap-y-6.4">
            <span className="text-green-dark text-controls font-semibold">Imagine de copertă</span>
            {coverImage}
          </div>
        </Section>
      )}
      <Section>
        {/* eslint-disable-next-line max-len */}
        <div className="flex flex-col border border-gray-light-stroke rounded-3.2 px-6.4 pt-8 pb-4.8 gap-y-6.4">
          <div className="flex justify-between items-center">
            <span className="text-green-dark text-controls font-semibold">Fișiere încarcate</span>
            <div className="p-6.4">
              <IconButton
                variant="simple"
                scheme="danger"
                icon="bin-small"
                onClick={onDelete}
                disabled={selectedNo === 0}
              >
                Fișiere selectate {selectedNo}
              </IconButton>
            </div>
          </div>
          {media.length > 0 && <div className="flex flex-col justify-center">{uploadedMedia}</div>}
        </div>
      </Section>
    </>
  );
};

Uploader.propTypes = {
  media: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      file: PropTypes.object,
    }),
  ).isRequired,
  files: PropTypes.func.isRequired,
  coverImage: PropTypes.node.isRequired,
  onDelete: PropTypes.func.isRequired,
  selectedNo: PropTypes.number.isRequired,
  uploadedMedia: PropTypes.node.isRequired,
};

Uploader.displayName = 'Uploader';

export default Uploader;
