import React from 'react';
import PropTypes from 'prop-types';

const TabList = (props) => {
  const { tabs } = props;
  return (
    <div role="tablist" className="flex gap-x-9.6">
      {tabs}
    </div>
  );
};

TabList.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.node).isRequired,
};

TabList.displayName = 'TabList';

export default TabList;
