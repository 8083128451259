import React, { useState } from 'react';

const useAddNew = (AddNewModalComponent, onAdd, onAddCallback, ...otherProps) => {
  const [isVisible, setIsVisible] = useState(false);

  const onSuccess = (item) =>
    onAdd(item, () => {
      setIsVisible(false);

      if (onAddCallback) {
        onAddCallback(item);
      }
    });

  const content = (
    <AddNewModalComponent
      onClose={() => setIsVisible(false)}
      onSuccess={onSuccess}
      {...otherProps}
    />
  );

  return [isVisible, setIsVisible, content];
};

export default useAddNew;
