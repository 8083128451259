import React, { useMemo, useReducer } from 'react';
import PropTypes from 'prop-types';
import PageHeader from 'components/Layout/PageHeader';
import Tabs from 'components/ui/tabs/Tabs';
import { Button } from 'components/ui/buttons';
import Table from 'components/ui/table/Table';
import { reducer, defaultState } from 'state/equipment/reducer';
import Context from '../../contexts/BrandsContext';
import useData from './hooks/useData';
import useModalActions from './hooks/useModalActions';
import useMeta from './hooks/useMeta';

const MyEquipment = (props) => {
  const { activeTab } = props;
  const [state, dispatch] = useReducer(reducer, defaultState);
  const contextValue = useMemo(() => [state, dispatch], [state, dispatch]);
  const [items, otherCount, rowsData, addItem, editItem, deleteItem] = useData(activeTab);
  const { tabs, header, callToActionText } = useMeta(activeTab, items.length, otherCount);
  const { modalElement, showModal, openCreateModal, openEditModal, openDeleteModal } =
    useModalActions(activeTab, items, addItem, editItem, deleteItem);

  return (
    <Context.Provider value={contextValue}>
      <PageHeader title="Echipament" />
      <Tabs
        tabs={tabs}
        selectedId={activeTab}
        action={<Button onClick={openCreateModal}>{callToActionText}</Button>}
      >
        <div className="-m-0.4 rounded-b-3.2">
          <Table
            header={header}
            rows={rowsData}
            itemsCount={rowsData.length}
            onEdit={openEditModal}
            itemsPerPage={6}
            onPageChange={() => {}}
            currentPage={1}
            onDelete={openDeleteModal}
          />
        </div>
      </Tabs>
      {showModal && modalElement}
    </Context.Provider>
  );
};

MyEquipment.propTypes = {
  activeTab: PropTypes.oneOf(['rods', 'reels']),
};

MyEquipment.defaultProps = {
  activeTab: 'rods',
};

MyEquipment.displayName = 'MyEquipment';

export default MyEquipment;
