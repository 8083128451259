import React from 'react'; // useState
import PropTypes from 'prop-types';
// import { IconButton } from 'components/ui/buttons';
// import MapModal from 'components/ui/Map/MapModal';
import Social from 'components/GoOuts/GoOut/Social';
import Meta from './Meta';
import Details from './Details';

const GoOut = (props) => {
  const { author, image, weather, stats, location, description, onView, onEdit } = props;
  return (
    <div className="flex flex-col bg-white p-8 rounded-3.2 gap-6.4">
      <Meta author={author} onEdit={onEdit} onView={onView} />
      <Details
        image={image}
        weather={weather}
        stats={stats}
        description={description}
        location={location}
      />
      <Social onLike={() => {}} onComment={() => {}} />
    </div>
  );
};
GoOut.propTypes = {
  author: PropTypes.shape({
    fullName: PropTypes.string.isRequired,
    avatar: PropTypes.string,
    createdAt: PropTypes.string.isRequired,
  }),
  image: PropTypes.shape({
    orientation: PropTypes.oneOf(['landscape', 'portrait']),
    src: PropTypes.string,
  }).isRequired,
  weather: PropTypes.shape({
    temp: PropTypes.number,
    skyCondition: PropTypes.string,
    windSpeed: PropTypes.number,
    windDirection: PropTypes.string,
    windGap: PropTypes.number,
    airPressure: PropTypes.number,
  }),
  stats: PropTypes.shape({
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    species: PropTypes.array.isRequired,
    quantity: PropTypes.number.isRequired,
    personalScore: PropTypes.number.isRequired,
  }),
  location: PropTypes.shape({
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
    street: PropTypes.string,
    city: PropTypes.string,
    county: PropTypes.string,
    formattedAddress: PropTypes.string,
    name: PropTypes.string,
  }),
  description: PropTypes.string.isRequired,
  onView: PropTypes.func.isRequired,
  onEdit: PropTypes.func,
};

GoOut.defaultProps = {
  author: undefined,
  weather: undefined,
  stats: undefined,
  location: undefined,
  onEdit: undefined,
};

GoOut.displayName = 'GoOut';

export default GoOut;
