import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { getMonth, addYears, subYears, getYear, isFuture } from 'date-fns';
import { Button, IconButton } from 'components/ui/buttons';
import { MONTHS } from '../utility/constants';

const Months = (props) => {
  const { selectedDate, onChange, onViewChange } = props;
  const [dateInView, setDateInView] = useState(new Date(selectedDate));
  const selectedYear = getYear(selectedDate);
  const selectedMonth = getMonth(selectedDate);
  const year = getYear(dateInView);
  const onPrevYear = () => setDateInView(subYears(dateInView, 1));
  const onNextYear = () => setDateInView(addYears(dateInView, 1));

  return (
    <>
      <div data-testid="months-view-header" className="flex justify-between items-center gap-6.4">
        <IconButton variant="link" icon="left-chevron" onClick={onPrevYear} />
        <Button variant="link" onClick={onViewChange}>
          {year}
        </Button>
        <IconButton variant="link" icon="right-chevron" onClick={onNextYear} />
      </div>
      <div
        data-testid="months-container"
        className="w-full grid grid-cols-4 justify-between gap-8 h-131.2"
      >
        {MONTHS.map((month) => {
          const isSelected = selectedMonth === month.identifier && selectedYear === year;
          const isDisabled = isFuture(new Date(year, month.identifier, 1));

          return (
            <div key={month.identifier} className="flex items-center justify-center mx-auto">
              {isDisabled ? (
                <div className="text-gray text-lg p-4">{month.label}</div>
              ) : (
                <button
                  className={cn(
                    'p-4 rounded-full text-gray-dark text-lg hover:bg-blue hover:text-white',
                    {
                      'bg-blue text-white': isSelected,
                      'bg-transparent': !isSelected,
                    },
                  )}
                  type="button"
                  onClick={() => onChange(getYear(dateInView), month.identifier)}
                >
                  {month.label}
                </button>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};

Months.propTypes = {
  selectedDate: PropTypes.instanceOf(Date).isRequired,
  onChange: PropTypes.func.isRequired,
  onViewChange: PropTypes.func.isRequired,
};

export default Months;
