import React, { useContext, useRef, useState } from 'react';
import { IconButton } from 'components/ui/buttons';
import MapModal from 'components/ui/Map/MapModal';
import Context from 'components/GoOut/state/contexts/addNew';
import { setLocationField } from 'components/GoOut/state/actions/addNew/others';
import LocationsDropdown from 'components/GoOut/steps/LocationsDropdown';
import { formatLocation } from 'components/GoOut/utility/utils';

const extractLocationDetails = (addressComponents, displayName, location, service) => {
  const locationDetails = {
    name: '',
    streetNo: '',
    street: '',
    city: '',
    county: '',
    country: '',
  };

  const placesService = service === 'places';
  const countyPosition = placesService ? 1 : 0;

  const { lat, lng } = location.toJSON();
  locationDetails.latitude = lat;
  locationDetails.longitude = lng;
  locationDetails.formattedAddress = displayName;

  addressComponents.forEach((component) => {
    switch (component.types[0]) {
      case 'natural_feature':
      case 'establishment':
      case 'point_of_interest':
        locationDetails.name = component.long_name || component.Gg;
        break;
      case 'street_number':
        locationDetails.streetNo = component.long_name || component.Gg;
        break;
      case 'route':
        locationDetails.street = component.long_name || component.Gg;
        break;
      case 'locality':
        locationDetails.city = component.long_name || component.Gg;
        break;
      case 'administrative_area_level_1':
        locationDetails.county = (component.long_name || component.Fg).split(' ')[countyPosition];
        break;
      case 'country':
        locationDetails.country = component.long_name || component.Fg;
        break;
      default:
        break;
    }
  });

  return locationDetails;
};

const LocationInput = () => {
  const [state, dispatch] = useContext(Context);
  const [mapModal, setMapModal] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const {
    event: {
      location: {
        longitude: { value: longitudeValue },
        latitude: { value: latitudeValue },
        name: { value: name },
        formattedAddress: { value: formattedAddress },
      },
    },
  } = state;

  const mapModalProps = useRef({
    onClose: () => setMapModal(false),
  });

  const locationCoordinates =
    latitudeValue && longitudeValue
      ? {
          lat: latitudeValue,
          lng: longitudeValue,
        }
      : null;

  const onModalSubmit = (data) => {
    const {
      address_components: addressComponents,
      formatted_address: displayName,
      geometry: { location },
    } = data;
    const locationDetails = extractLocationDetails(addressComponents, displayName, location);
    locationDetails.formattedAddress = formatLocation(locationDetails);
    dispatch(setLocationField(locationDetails));
    setMapModal(false);
  };

  const onClickMap = (e) => {
    e.stopPropagation();
    mapModalProps.current.viewOnly = true;
    mapModalProps.current.onSubmit = null;
    setMapModal(true);
  };

  const onClickPin = () => {
    mapModalProps.current.viewOnly = false;
    mapModalProps.current.onSubmit = onModalSubmit;
    setMapModal(true);
  };

  const onSearchChange = (event) => {
    let locationDetails = {
      longitude: '',
      latitude: '',
      name: '',
      formattedAddress: '',
      streetNo: '',
      street: '',
      city: '',
      county: '',
      country: '',
    };
    if (event) {
      const { addressComponents, formattedAddress: displayName, location } = event;
      locationDetails = extractLocationDetails(addressComponents, displayName, location, 'places');
    }

    dispatch(setLocationField(locationDetails));
    setSelectedLocation(event);
  };

  return (
    <div>
      <LocationsDropdown
        selectedValues={[selectedLocation?.displayName || name]}
        onChange={onSearchChange}
        triggerProps={{
          id: 'location',
          name: 'location',
          label: 'Locație partidă',
          placeholder: 'Selectare locație',
          icon: 'direction-small',
          value: formattedAddress,
          actions: formattedAddress ? (
            <IconButton
              variant="link"
              onClick={(e) => onClickMap(e)}
              icon="map-marker-small"
              weight="medium"
              size="small"
            >
              Hartă
            </IconButton>
          ) : undefined,
          required: true,
        }}
        action={
          <div className="flex items-start z-10">
            <IconButton
              variant="simple"
              onClick={onClickPin}
              weight="medium"
              icon="map-marker-small"
            >
              Alege pe hartă
            </IconButton>
          </div>
        }
      />
      {mapModal && (
        <MapModal
          mapId="go-out-location"
          coordinates={locationCoordinates}
          locationDetails={name || formattedAddress}
          {...mapModalProps.current}
        />
      )}
    </div>
  );
};

LocationInput.propTypes = {};

export default LocationInput;
