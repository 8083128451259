import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import ImageGallery from 'react-image-gallery';
// import { IconButton } from 'components/ui/buttons';

const Gallery = (props) => {
  const { media } = props;
  const [isGalleryVisible, setIsGalleryVisible] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const galleryRef = useRef(null);
  const images = media.map((item) => ({
    original: URL.createObjectURL(item.file),
    thumbnail: URL.createObjectURL(item.file),
  }));
  const onToggleGallery = (index) => {
    setSelectedIndex(index);
    setIsGalleryVisible((prevState) => !prevState);
  };

  useEffect(() => {
    if (isGalleryVisible) {
      galleryRef.current.toggleFullScreen();
    }
  }, [isGalleryVisible]);

  return (
    <>
      <div className="grid grid-cols-4 gap-2">
        {media.map((item, index) => {
          return (
            <button type="button" key={item.id} onClick={() => onToggleGallery(index)}>
              <img
                src={URL.createObjectURL(item.file)}
                alt=""
                key={item.file}
                className="object-cover w-full h-60 rounded-1.6"
              />
            </button>
          );
        })}
      </div>
      {isGalleryVisible && (
        <div className="fixed top-0 right-0 bottom-0 left-0">
          <ImageGallery
            ref={galleryRef}
            items={images}
            thumbnailPosition="left"
            showBullets
            startIndex={selectedIndex}
            onScreenChange={(isFullScreen) => {
              if (!isFullScreen) {
                setIsGalleryVisible(false);
                setSelectedIndex(null);
              }
            }}
          />
        </div>
      )}
    </>
  );
};

Gallery.propTypes = {
  media: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      file: PropTypes.object,
    }),
  ).isRequired,
};

Gallery.displayName = 'Gallery';

export default Gallery;
