import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import GenericDropdown from 'components/ui/dropdowns/GenericDropdown';
import InputWrapper from 'components/ui/dropdowns/CheckboxesDropdown/InputWrapper';
import { IconInput } from 'components/ui/inputs';
import Checkbox from 'components/ui/Checkbox';
import useGoogleAPI from 'hooks/useGoogleAPI';
import LoadingSpinner from 'components/ui/LoadingSpinner';

const debounce = (func, delay) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

const LocationsDropdown = (props) => {
  const { triggerProps, variant, action, onChange, selectedValues } = props;
  const [inputValue, setInputValue] = useState('');
  const [foundLocations, setFoundLocations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const googleInstance = useGoogleAPI();

  useEffect(() => {
    if (!inputValue) {
      setFoundLocations([]);
    }
  }, [inputValue]);

  const searchLocation = useCallback(
    async (location) => {
      if (googleInstance && location) {
        const { Place } = await googleInstance.maps.importLibrary('places');
        const request = {
          textQuery: location || '',
          fields: ['displayName', 'formattedAddress', 'location', 'addressComponents'],
          language: 'ro',
          region: 'ro',
        };
        const { places } = await Place.searchByText(request);
        setFoundLocations(places);
      }

      setIsLoading(false);
    },
    [googleInstance],
  );

  const debouncedSearchLocation = useMemo(() => debounce(searchLocation, 500), [searchLocation]);

  const onSearchInput = (event) => {
    const { value } = event.target;

    setIsLoading(true);
    setInputValue(value);
    debouncedSearchLocation(value);
  };
  const onSelectionChange = useCallback(
    (value) => {
      let newValues = [];

      if (selectedValues.includes(value.displayName)) {
        newValues = selectedValues.filter((item) => item !== value.displayName);
      } else {
        newValues = [value];
      }
      const isValidValue = variant === 'radio' ? newValues.length > 0 : true;
      if (!isValidValue) {
        return;
      }
      onChange(newValues.length ? newValues[0] : null);
    },
    [selectedValues, onChange, variant],
  );

  return (
    <GenericDropdown triggerProps={triggerProps} triggerComponent={InputWrapper}>
      <div className="rounded-[inherit] flex flex-col gap-6.4">
        <IconInput
          icon="search"
          onChange={onSearchInput}
          name=""
          id=""
          placeholder="Caută"
          value={inputValue}
          autoFocus
        />
        <div className="flex flex-col gap-4.8">
          <div className="flex flex-col gap-1.2">
            <p className="text-gray-dark font-medium leading-8">Alege din listă</p>
            {!isLoading && !inputValue && (
              <p className="text-gray-dark text-sm font-bold leading-8">
                Caută în câmpul de mai sus locația dorită.
              </p>
            )}
            {!isLoading && inputValue && foundLocations.length === 0 && (
              <p className="text-gray-dark text-sm font-bold leading-8">Nici o locație găsită.</p>
            )}
            {isLoading && (
              <div className="flex justify-start">
                <LoadingSpinner />
              </div>
            )}
          </div>
          <div className="flex flex-col gap-3.2 max-h-56 overflow-auto">
            {!isLoading &&
              foundLocations.map((item, index) => {
                return (
                  <Checkbox
                    key={item.id}
                    label={item.displayName}
                    onChange={() => onSelectionChange(item)}
                    name={`selection-${index}`}
                    id={`selection-${index}`}
                    variant={variant}
                    checked={selectedValues.includes(item.displayName)}
                  />
                );
              })}
          </div>
          {action}
        </div>
      </div>
    </GenericDropdown>
  );
};

LocationsDropdown.propTypes = {
  triggerProps: PropTypes.object,
  data: PropTypes.arrayOf(PropTypes.string),
  variant: PropTypes.oneOf(['checkbox', 'radio']),
  action: PropTypes.node,
  onChange: PropTypes.func.isRequired,
  selectedValues: PropTypes.arrayOf(PropTypes.string),
};

LocationsDropdown.defaultProps = {
  triggerProps: {},
  data: [],
  variant: 'checkbox',
  action: null,
  selectedValues: [],
};

export default LocationsDropdown;
