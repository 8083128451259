import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from 'components/ui/Icon';

const Option = (props) => {
  const { first, last, value, icon, selected, onClick } = props;

  return (
    <button
      data-testid="dropdown-option"
      className={cn('flex justify-between items-center gap-16 py-4.8 px-6.4 hover:bg-blueish', {
        'rounded-t-none rounded-b-inherit': last,
        'rounded-b-none rounded-t-inherit': first,
        'bg-blueish': selected,
        'bg-white': !selected,
      })}
      onClick={onClick}
      type="button"
      tabIndex={0}
    >
      <p className="text-controls">{value}</p>
      {icon && <Icon fillClassName="fill-blue" identifier={icon} />}
    </button>
  );
};

Option.propTypes = {
  first: PropTypes.bool,
  last: PropTypes.bool,
  value: PropTypes.string.isRequired,
  icon: PropTypes.string,
  selected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

Option.defaultProps = {
  first: false,
  last: false,
  icon: null,
  selected: false,
};

Option.displayName = 'Option';

export default Option;
