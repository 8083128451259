import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/ui/Modal';
import Input from 'components/ui/inputs/Input';
import { ACTIONS } from 'components/Equipment/utility/constants';
import useBrandsLoader from 'hooks/useBrandsLoader';
import { isIntegerNumber, isRequired } from 'utility/validation';
import { BrandInput, ModelInput } from '../inputs';
import useFormFields from '../hooks/useFormFields';
import useSubmit from '../hooks/useSubmit';

const ReelModal = (props) => {
  const { onClose, action, item, onSuccess } = props;
  const { size, ratio, recoverLength } = item || {};
  const [fields, setFields, individualSetters] = useFormFields(item, {
    size: {
      field: {
        value: size ? size.toString() : '',
        isValid: !!size,
      },
      required: true,
      validator: isIntegerNumber,
    },
    ratio: {
      field: {
        value: ratio || '',
        isValid: true,
      },
      required: false,
      validator: isRequired,
    },
    recoverLength: {
      field: {
        value: recoverLength ? recoverLength.toString() : '',
        isValid: recoverLength ? isIntegerNumber(recoverLength.toString()) : true,
      },
      required: false,
      validator: isIntegerNumber,
    },
  });
  const [isBrandsLoading, allBrands] = useBrandsLoader();
  const [canSubmit, onSubmit] = useSubmit('reels', item, fields, setFields, onSuccess);
  const actionText = ACTIONS[action];

  return (
    <Modal
      title={`${actionText} mulinetă`}
      onClose={onClose}
      onCancel={onClose}
      onSubmit={onSubmit}
      submitText={actionText}
      canSubmit={canSubmit}
      form
    >
      <BrandInput
        loading={isBrandsLoading}
        all={allBrands}
        onChange={individualSetters.brand}
        {...fields.brand}
      />
      <ModelInput onChange={individualSetters.model} {...fields.model} />
      <Input
        id="size"
        name="size"
        onChange={individualSetters.size}
        placeholder="Introdu dimensiunea"
        label="Dimensiune"
        value={fields.size.value}
        required
        inputMode="numeric"
        pattern="[0-9]*"
        valid={fields.size.isDirty ? fields.size.isValid : undefined}
        helperText={
          fields.size.isDirty && !fields.size.isValid
            ? fields.size.error || 'Introdu un număr întreg'
            : null
        }
      />
      <Input
        id="ratio"
        name="ratio"
        onChange={individualSetters.ratio}
        placeholder="Introdu raportul de recuperare"
        label="Raport de recuperare"
        value={fields.ratio.value.toString()}
      />
      <Input
        id="recoverLength"
        name="recoverLength"
        onChange={individualSetters.recoverLength}
        placeholder="Introdu lungimea de recuperare"
        label="Lungime de recuperare (cm)"
        value={fields.recoverLength.value}
        inputMode="numeric"
        pattern="[0-9]*"
        valid={fields.recoverLength.isDirty ? fields.recoverLength.isValid : undefined}
        helperText={
          fields.recoverLength.isDirty && !fields.recoverLength.isValid
            ? 'Introdu un număr întreg'
            : null
        }
      />
    </Modal>
  );
};

ReelModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  action: PropTypes.oneOf(['add', 'edit']),
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    brand: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    model: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
    ratio: PropTypes.string.isRequired,
    recoverLength: PropTypes.number.isRequired,
  }),
};

ReelModal.defaultProps = {
  action: 'add',
  item: null,
};

ReelModal.displayName = 'ReelModal';

export default ReelModal;
