import React, { useContext, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import AppContext from 'contexts/AppContext';

const VisitorOnlyRoute = () => {
  const { isAuthenticated } = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/');
    }
  }, [isAuthenticated, navigate]);

  return <Outlet />;
};

VisitorOnlyRoute.propTypes = {};

export default VisitorOnlyRoute;
