import React from 'react';
import { createRoutesFromElements, Route } from 'react-router-dom';
import { fetchGoOutData } from 'components/GoOut/loaders';
import { fetchReels, fetchRods } from 'components/Equipment/loaders';
import LandingPageWrapper from 'components/LandingPageWrapper';
import LandingPage from 'components/LandingPage';
import App, { userLoader } from '../components/app';
import GoOuts, { goOutsLoader } from '../components/GoOuts';
import IdentityProvider from '../components/authentication/IdentityProvider';
import Register from '../components/authentication/Register';
import ActivationDetails from '../components/authentication/ActivationDetails';
import PasswordResetRequest from '../components/authentication/PasswordResetRequest';
import ActivateAccount from '../components/authentication/ActivateAccount';
import MyEquipment from '../components/Equipment';
import GoOut from '../components/GoOut';
import PoliciesContainer from '../components/Policies';
import CookiesPolicy from '../components/Policies/CookiesPolicy';
import PrivacyPolicy from '../components/Policies/PrivacyPolicy';
import TermsAndConditions from '../components/Policies/TermsAndConditions';
import Error404 from '../components/Error404';
import ProtectedRoute from './ProtectedRoute';
import VisitorOnlyRoute from './VisitorOnlyRoute';

const getRoutes = (config) =>
  createRoutesFromElements(
    <Route element={<App {...config} />} loader={userLoader}>
      <Route element={<LandingPageWrapper />}>
        <Route path="/" element={<LandingPage />} loader={goOutsLoader} />

        {/* Routes requiring no authentication, but that are not available if authenticated */}
        <Route element={<VisitorOnlyRoute />}>
          <Route path="/autentificare/:provider" element={<IdentityProvider />} />
          <Route path="/inregistrare" element={<Register />} />
          <Route path="/detalii-activare-cont" element={<ActivationDetails />} />
          <Route path="/cerere-resetare-parola" element={<PasswordResetRequest />} />
          <Route path="/cont/:userId/activare/:token" element={<ActivateAccount />} />
        </Route>

        {/* Routes requiring authentication. */}
        <Route element={<ProtectedRoute />}>
          <Route
            path="/partidele-mele"
            element={<GoOuts title="Partidele mele" ownOnly />}
            loader={goOutsLoader}
          />
          <Route
            path="/echipament/lansete"
            element={<MyEquipment activeTab="rods" />}
            loader={fetchRods}
          />
          <Route
            path="/echipament/mulinete"
            element={<MyEquipment activeTab="reels" />}
            loader={fetchReels}
          />
          <Route path="/adauga-partida" element={<GoOut title="Crează partidă" editable />} />
          <Route
            path="/partida/:id"
            element={<GoOut title="Detalii partidă" withActions />}
            loader={fetchGoOutData}
          />
          <Route
            path="/partida/:id/modifica"
            element={<GoOut title="Modifică partidă" withActions editable />}
            loader={fetchGoOutData}
          />
        </Route>
      </Route>

      {/* Policies routes. */}
      <Route element={<PoliciesContainer />}>
        <Route path="/politica-cookies" element={<CookiesPolicy />} />
        <Route path="/politica-de-confidentialitate" element={<PrivacyPolicy />} />
        <Route path="/termeni-si-conditii" element={<TermsAndConditions />} />
      </Route>
      <Route path="*" element={<Error404 />} />
    </Route>,
  );

export default getRoutes;
