import React, { useState } from 'react';
import Header from 'components/authentication/Header';
import { NavLink } from 'components/ui/links';
import { Button } from 'components/ui/buttons';
import { EMPTY_DEFAULT_FIELD } from 'constants/forms';
import { isValidEmail } from 'utility/validation';
import { onFieldChange } from 'utility/utils';
import { useNavigate } from 'react-router-dom';
import EmailInput from 'components/authentication/inputs/EmailInput';
import useAPI from 'hooks/useAPI';

const PasswordResetRequest = () => {
  const [emailField, setEmailField] = useState(EMPTY_DEFAULT_FIELD);
  const onChangeEmailField = (e) => {
    onFieldChange(e.target.value, setEmailField, isValidEmail);
  };
  const navigate = useNavigate();
  const { apiCall } = useAPI();

  const onSubmit = async (event) => {
    event.preventDefault();
    await apiCall(
      '/authentication/password-reset/request',
      'post',
      { email: emailField.value },
      {
        onSuccessCallback: (responseData) => {
          navigate('/detalii-resetare-parola', {
            state: {
              email: emailField.value,
              detail: responseData.message,
            },
          });
        },
        getSuccessMessage: () => 'Un e-mail a fost trimis pentru resetarea parolei.',
        getErrorMessage: (responseData, statusCode) => {
          switch (statusCode) {
            case 404:
              return 'Nu există un cont cu adresa de e-mail respectivă.';
            case 400:
              return 'Contul nu a fost activat.';
            default:
              return null;
          }
        },
        onErrorCallback: (errorData, statusCode) => {
          switch (statusCode) {
            case 404:
              navigate('/');
              break;
            case 400:
              navigate('/detalii-activare-cont', {
                state: {
                  detail: errorData.detail,
                  email: emailField.value,
                },
              });
              break;
            default:
              break;
          }
        },
      },
    );
  };

  return (
    <div className="flex flex-col h-full justify-start gap-8 xl:gap-12 relative">
      <Header>Resetare parolă</Header>
      <form className="grid gap-8 xl:gap-9.6 w-full" onSubmit={onSubmit}>
        <EmailInput
          item={emailField}
          onChange={onChangeEmailField}
          /* eslint-disable-next-line max-len */
          label="Un e-mail care va conține indicațiile de resetare a parolei va fi trimis pe adresa specificată."
        />
        <div className="flex items-center justify-center">
          <Button variant="primary" submit>
            Resetează parolă
          </Button>
        </div>
      </form>
      <div className="flex justify-end relative lg:absolute bottom-0 right-0 mt-24 lg:mt-0">
        <NavLink to="/" variant="accent">
          Mergi la pagina de autentificare
        </NavLink>
      </div>
    </div>
  );
};

export default PasswordResetRequest;
