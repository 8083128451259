import { useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { useCallback, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import { DEFAULT_API_ERROR } from 'constants/forms';
import useAPI from '../../../hooks/useAPI';

const detailMessages = {
  reset: {
    invalid: 'Link-ul de resetare a parolei este invalid!',
    expired: 'Link-ul de resetare a parolei a expirat!',
    success: 'Parola a fost resetată cu succes!',
  },
  activate: {
    invalid: 'Link-ul de activare este invalid!',
    expired: 'Link-ul de activare a expirat!',
    success: 'Contul a fost activat cu succes!',
  },
};

const useTokenValidationResponse = (action) => {
  const navigate = useNavigate();
  const serverResponse = useLoaderData();
  const { token } = useParams();
  const detailMessage = useMemo(() => detailMessages[action], [action]);
  const redirectUrl = useMemo(
    () => (action === 'reset' ? '/detalii-resetare-parola' : '/detalii-activare-cont'),
    [action],
  );
  const { apiCall } = useAPI();

  const handleErrorNavigation = useCallback(
    (data) => {
      switch (data.detail) {
        case 'Token is invalid':
          navigate('/');
          break;
        case 'Token has expired':
          navigate(redirectUrl, {
            state: {
              detail: data.detail,
              token,
            },
          });
          break;
        default:
          break;
      }
    },
    [navigate, token, redirectUrl],
  );

  const getErrorDetail = useCallback(
    (data) => {
      switch (data.detail) {
        case 'Token is invalid':
          return detailMessage.invalid;
        case 'Token has expired':
          return detailMessage.expired;
        default:
          return DEFAULT_API_ERROR;
      }
    },
    [detailMessage.expired, detailMessage.invalid],
  );

  useEffect(() => {
    if (serverResponse) {
      const { data, status } = serverResponse;
      if (status === 200) {
        return;
      }

      if (status === 400) {
        const error = getErrorDetail(data);
        toast.error(error);
        handleErrorNavigation(data);
      } else {
        toast.error(DEFAULT_API_ERROR);
      }
    }
  }, [getErrorDetail, handleErrorNavigation, serverResponse]);

  const onSubmit = async (url, method, body, options = {}) => {
    const {
      getSuccessMessage: customGetSuccessMessage,
      onSuccessCallback: customOnSuccessCallback,
      getErrorMessage: customGetErrorMessage,
      onErrorCallback: customOnErrorCallback,
    } = options;
    await apiCall(url, method, body, {
      getSuccessMessage: () => {
        if (customGetSuccessMessage) {
          const customMessage = customGetSuccessMessage();
          if (customMessage !== null) {
            return customGetSuccessMessage();
          }
        }
        return detailMessage.success;
      },
      onSuccessCallback: () => {
        if (customOnSuccessCallback) {
          customOnSuccessCallback();
        }
        return navigate('/');
      },
      getErrorMessage: (errorData, statusCode) => {
        if (customGetErrorMessage) {
          const customMessage = customGetErrorMessage(errorData, statusCode);
          if (customMessage !== null) {
            return customMessage;
          }
        }
        if (statusCode === 400) {
          return getErrorDetail(errorData);
        }
        return null;
      },
      onErrorCallback: (errorData, statusCode) => {
        if (customOnErrorCallback) {
          customOnErrorCallback(errorData, statusCode);
        }
        if (statusCode === 400) {
          handleErrorNavigation(errorData);
        }
        return null;
      },
    });
  };

  return {
    onSubmit,
    token,
    getErrorDetail,
    handleErrorNavigation,
  };
};

export default useTokenValidationResponse;
