import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/ui/Modal';
import BrandInput from 'components/Equipment/inputs/BrandInput';
import ModelInput from 'components/Equipment/inputs/ModelInput';
import DiameterInput from 'components/Equipment/inputs/DiameterInput';
import Input from 'components/ui/inputs/Input';
import useFormFields from 'components/Equipment/hooks/useFormFields';
import { CheckboxesDropdown } from 'components/ui/dropdowns';
import {
  isDecimalNumberMaxThreeDecimals,
  isDecimalNumberMaxTwoDecimals,
  isRequired,
} from 'utility/validation';
import useBrandsLoader from 'hooks/useBrandsLoader';
import useSubmit from '../hooks/useSubmit';

const powers = ['gr', 'lbs'].map((o) => ({ id: o, value: o }));

const PowerGumModal = (props) => {
  const { onClose, item, onSuccess } = props;
  const { diameter, power, powerUnit, length } = item || {};

  const [fields, setFields, individualSetters] = useFormFields(item, {
    diameter: {
      field: {
        value: diameter || '',
        isValid: true,
      },
      required: false,
      validator: isDecimalNumberMaxThreeDecimals,
    },
    power: {
      field: {
        value: power ? power.toString() : '',
        isValid: !!power,
      },
      required: true,
      validator: isRequired,
    },
    powerUnit: {
      field: {
        value: powerUnit || 'lbs',
        isValid: true,
      },
      required: true,
      validator: isRequired,
    },
    length: {
      field: {
        value: length ? length.toString() : '',
        isValid: !!length,
      },
      required: true,
      validator: isDecimalNumberMaxTwoDecimals,
    },
  });

  const [isLoadingBrands, allBrands] = useBrandsLoader();
  const [canSubmit, onSubmit] = useSubmit('power-gums', item, fields, setFields, onSuccess);

  return (
    <Modal
      title="Adaugă power gum"
      onClose={onClose}
      onSubmit={onSubmit}
      canSubmit={canSubmit}
      form
    >
      <BrandInput
        loading={isLoadingBrands}
        all={allBrands}
        onChange={individualSetters.brand}
        {...fields.brand}
      />
      <ModelInput onChange={individualSetters.model} {...fields.model} />
      <DiameterInput
        onChange={individualSetters.diameter}
        helperText={
          fields.diameter.isDirty && !fields.diameter.isValid
            ? 'Introdu un număr cu maxim 3 zecimale'
            : null
        }
        {...fields.diameter}
      />
      <div className="flex gap-6.4 items-center justify-between">
        <div className="w-8/12">
          <Input
            id="diameter"
            name="diameter"
            onChange={individualSetters.power}
            placeholder="Introdu puterea"
            label="Putere"
            value={fields.power.value}
            inputMode="decimal"
            pattern="[0-9]*"
            required
            helperText={
              fields.power.isDirty && !fields.power.isValid
                ? 'Introdu un număr cu maxim 3 zecimale'
                : null
            }
            valid={fields.power.isDirty ? fields.power.isValid : undefined}
          />
        </div>
        <div className="w-4/12">
          <CheckboxesDropdown
            data={powers}
            onChange={individualSetters.powerUnit}
            singleSelection
            selectedValues={fields.powerUnit.value ? [fields.powerUnit.value] : undefined}
            triggerProps={{
              label: 'Unitate',
              valid: fields.powerUnit.isDirty ? fields.powerUnit.isValid : undefined,
            }}
            variant="radio"
          />
        </div>
      </div>
      <Input
        id="length"
        name="length"
        onChange={individualSetters.length}
        placeholder="Introdu lungimea"
        label="Lungime"
        value={fields.length.value}
        inputMode="numeric"
        pattern="[0-9]*"
        required
        valid={fields.length.isDirty ? fields.length.isValid : undefined}
        helperText={
          fields.length.isDirty && !fields.length.isValid ? 'Introdu un număr întreg' : null
        }
      />
    </Modal>
  );
};

PowerGumModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    brand: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    model: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    diameter: PropTypes.number.isRequired,
    length: PropTypes.number.isRequired,
  }),
};

PowerGumModal.defaultProps = {
  item: null,
};

PowerGumModal.displayName = 'PowerGumModal';

export default PowerGumModal;
