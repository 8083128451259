import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { IconButton } from 'components/ui/buttons';
import Pagination from 'components/ui/table/Pagination';
import Icon from 'components/ui/Icon';
import LoadingSpinner from 'components/ui/LoadingSpinner';

const Table = (props) => {
  const {
    header,
    rows,
    itemsPerPage,
    itemsCount,
    currentPage,
    onEdit,
    onDelete,
    onSelect,
    onPageChange,
    loading,
  } = props;
  const pagesCount = itemsPerPage && itemsPerPage > 0 ? Math.ceil(itemsCount / itemsPerPage) : 1;
  const hasNextPage = pagesCount > 1 && currentPage < pagesCount;
  const actions = onDelete || onEdit || onSelect;

  return (
    <table
      className="flex flex-col border border-gray-light-stroke rounded-inherit"
      data-testid="table"
    >
      <thead data-testid="table-header" className="bg-gray-50">
        <tr
          className="grid grid-flow-col auto-cols-fr items-center border-b border-gray-light-stroke"
          data-testid="table-header-row"
        >
          {header.map((item, index) => (
            <th
              key={`${index}-${item}`}
              /* eslint-disable-next-line max-len */
              className="col-span-1 px-9.6 py-4.8 text-start text-gray-darker font-medium text-sm leading-7.2"
              data-testid="table-header-cell"
            >
              {item}
            </th>
          ))}
          {actions && <th className="col-span-1" aria-label="empty-cell" />}
        </tr>
      </thead>
      <tbody data-testid="table-body" className="bg-white">
        {rows.map((row) => (
          <tr
            key={`${row.id}`}
            /* eslint-disable-next-line max-len */
            className={cn(
              'grid grid-flow-col auto-cols-fr items-center border-b border-gray-light-stroke',
              {
                'cursor-pointer hover:bg-blue-lighter': onSelect,
                'bg-blue-lighter': onSelect && row.selected,
              },
            )}
            data-testid="table-body-row"
            onClick={onSelect ? () => onSelect(row.id) : undefined}
          >
            {row.values.map((value, index) => (
              <td
                key={`${index}-${value}`}
                className={cn('col-span-1 px-9.6 py-6.4 leading-8 text-gray-darker', {
                  'text-green-dark font-medium': index === 0,
                })}
                data-testid="table-body-cell"
              >
                {value || '-'}
              </td>
            ))}
            {actions && (
              <td
                className="col-span-1 flex items-center justify-end px-6.4 py-2.4 gap-x-1.6"
                aria-label="controls"
              >
                {onDelete && (
                  <div className="group p-3.2">
                    <IconButton
                      icon="bin-small"
                      onClick={() => onDelete(row.id)}
                      variant="simple"
                      data-testid="delete-button"
                      scheme="danger"
                    />
                  </div>
                )}
                {onEdit && (
                  <div className="group p-3.2">
                    <IconButton
                      icon="edit-small"
                      onClick={() => onEdit(row.id)}
                      variant="simple"
                      data-testid="edit-button"
                      scheme="blue"
                    />
                  </div>
                )}
                {onSelect && (
                  <div className="group p-3.2">
                    {row.selected && <Icon fillClassName="fill-blue" identifier="check-small" />}
                  </div>
                )}
              </td>
            )}
          </tr>
        ))}
        {!rows.length && (
          <tr
            className="grid grid-cols-1 px-9.6 py-6.4 text-gray-darker text-center"
            data-testid="empty-row"
          >
            <td colSpan={header.length + (actions ? 1 : 0)}>
              {loading ? <LoadingSpinner /> : 'Nu există date de afișat'}
            </td>
          </tr>
        )}
      </tbody>
      {pagesCount > 1 && (
        <tfoot data-testid="table-footer" className="bg-white">
          <tr className="grid grid-cols-3 px-9.6 pt-4.8 pb-6.4" data-testid="table-footer-row">
            <td className="flex items-center justify-start">
              {currentPage > 1 && (
                <IconButton
                  data-testid="backwards-button"
                  onClick={() => onPageChange(currentPage - 1)}
                  icon="left-arrow"
                  size="small"
                  weight="medium"
                >
                  Înapoi
                </IconButton>
              )}
            </td>
            <td className="flex justify-center" aria-label="Pagination">
              <Pagination
                pagesCount={pagesCount}
                currentPage={currentPage}
                onChange={onPageChange}
              />
            </td>
            <td className="flex items-center justify-end">
              {hasNextPage && (
                <IconButton
                  data-testid="forward-button"
                  icon="right-arrow"
                  iconOrientation="right"
                  onClick={() => onPageChange(currentPage + 1)}
                  size="small"
                  weight="medium"
                >
                  Înainte
                </IconButton>
              )}
            </td>
          </tr>
        </tfoot>
      )}
    </table>
  );
};

Table.propTypes = {
  header: PropTypes.array.isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      values: PropTypes.array,
    }),
  ).isRequired,
  itemsPerPage: PropTypes.number,
  itemsCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onSelect: PropTypes.func,
  onPageChange: PropTypes.func,
  loading: PropTypes.bool,
};

Table.defaultProps = {
  currentPage: 1,
  onEdit: null,
  onDelete: null,
  onSelect: null,
  loading: false,
  itemsPerPage: 0,
  onPageChange: () => {},
};

Table.displayName = 'Table';

export default Table;
