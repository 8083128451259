import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from 'components/ui/buttons';

const PageHeader = (props) => {
  const { title, children, onClose, actions } = props;

  return (
    <div className="z-20 flex flex-col bg-whitey-gray pb-6.4 pt-6.4 sticky -mt-6.4 top-29.2 gap-y-3.2">
      <div className="flex md:items-center justify-between">
        <h1 className="text-2xl lg:text-4xl font-bold text-green-dark">{title}</h1>
        <div className="flex gap-x-3.2">
          {actions}
          {onClose && (
            <IconButton
              onClick={onClose}
              icon="close"
              variant="simple"
              iconClassName="w-9.6 h-9.6"
              iconColorClassName="stroke-gray group-hover:stroke-gray-darker"
            />
          )}
        </div>
      </div>
      {/* {actions && <div className="flex md:hidden items-center gap-x-3.2">{actions}</div>} */}
      {children && <div>{children}</div>}
    </div>
  );
};

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  onClose: PropTypes.func,
  actions: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

PageHeader.defaultProps = {
  children: null,
  onClose: null,
  actions: null,
};

export default PageHeader;
