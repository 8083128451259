import React, { useState, useReducer, useMemo, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate, useLoaderData } from 'react-router-dom';
import PageHeader from 'components/Layout/PageHeader';
import Stepper from 'components/ui/Stepper';
import {
  reducer as equipmentReducer,
  defaultState as equipmentDefaultState,
} from 'state/equipment/reducer';
import {
  reducer as resultsReducer,
  defaultState as resultsDefaultState,
} from 'state/results/reducer';
import { getGoOutState, getAvailableEntities } from 'components/GoOuts/utility/utils';
import { setGoOut } from 'components/GoOut/state/actions/addNew/others';
import { useModalActions } from 'components/GoOut/hooks';
import { Delete, Edit } from 'components/GoOut/controls';
import BrandsContext from '../../contexts/BrandsContext';
import ResultsContext from '../../contexts/ResultsContext';
import { Localization, Environment, Gear, Feeding, Details, Strategy, Media } from './steps';
import AppContext from '../../contexts/AppContext';
import { AddNewContext, AvailableEntitiesContext } from './state/contexts';
import {
  reducer as addNewReducer,
  defaultState as addNewDefaultState,
} from './state/reducers/addNew';
import {
  reducer as availableEntitiesReducer,
  defaultState as availableEntitiesDefaultState,
} from './state/reducers/availableEntities';
import { init as initAvailableEntities } from './state/actions/availableEntities/common';
import CanSubmitContext from '../../contexts/CanSubmitContext';
import CanEditContext from '../../contexts/CanEditContext';

const steps = [
  {
    title: 'Locație și dată',
    content: <Localization />,
  },
  {
    title: 'Condiții meteo și detalii apă',
    content: <Environment />,
  },
  {
    title: 'Echipament și strune',
    content: <Gear />,
  },
  {
    title: 'Feedere, nade și momeli',
    content: <Feeding />,
  },
  {
    title: 'Descriere & rezultate',
    content: <Details />,
  },
  {
    title: 'Strategii',
    content: <Strategy />,
  },
  {
    title: 'Poze și video',
    content: <Media />,
  },
];

const GoOut = (props) => {
  const { title, editable, withActions } = props; // TODO: Use 'withActions' and 'edit' accordingly.
  const [submit, setSubmit] = useState(false);
  const [hasActions, setHasActions] = useState(withActions);
  const location = useLocation();
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(addNewReducer, addNewDefaultState);
  const contextValue = useMemo(() => [state, dispatch], [state, dispatch]);
  const [brandsState, brandsDispatch] = useReducer(equipmentReducer, equipmentDefaultState);
  const brandsContextValue = useMemo(
    () => [brandsState, brandsDispatch],
    [brandsState, brandsDispatch],
  );
  const [availableEntitiesState, availableEntitiesDispatch] = useReducer(
    availableEntitiesReducer,
    availableEntitiesDefaultState,
  );
  const availableEntitiesContextValue = useMemo(
    () => [availableEntitiesState, availableEntitiesDispatch],
    [availableEntitiesState, availableEntitiesDispatch],
  );
  const [resultsState, resultsDispatch] = useReducer(resultsReducer, resultsDefaultState);
  const resultsContextValue = useMemo(
    () => [resultsState, resultsDispatch],
    [resultsState, resultsDispatch],
  );
  const canSubmitContext = useMemo(() => [submit, setSubmit], [submit, setSubmit]);
  const { goOut, errors } = useLoaderData() || {};
  const { modalElement, showModal, openCreateModal, openEditModal, openDeleteModal } =
    useModalActions();
  const { user } = useContext(AppContext);

  useEffect(() => {
    if ((errors || []).length > 0) {
      navigate('/');
    }
  }, [errors, navigate]);

  useEffect(() => {
    if (user.id === goOut?.user.id) {
      setHasActions(true);
    } else {
      setHasActions(false);
    }
  }, [user, goOut]);

  useEffect(() => {
    (async () => {
      if (goOut) {
        const goOutData = await getGoOutState(goOut);
        dispatch(setGoOut(goOutData));

        if (editable) {
          availableEntitiesDispatch(initAvailableEntities());
        } else {
          const availableEntities = getAvailableEntities(goOut);
          availableEntitiesDispatch(initAvailableEntities(availableEntities));
        }
      } else {
        dispatch(setGoOut(addNewDefaultState));
      }
    })();
  }, [goOut, editable]);

  const onClose = () => {
    let redirectUrl;
    const hasPrevious = !!location.state?.from;

    if (hasPrevious) {
      redirectUrl = location.state.from;
    } else {
      redirectUrl = '/';
    }
    navigate({
      pathname: redirectUrl,
    });
  };

  const onEditClick = () => {
    navigate(`/partida/${goOut.id}/modifica`);
  };

  const editAction =
    false && withActions && !editable ? <Edit key={2} onEdit={onEditClick} /> : null;
  const controls = hasActions && [<Delete key={1} onDelete={openDeleteModal} />, editAction];

  return (
    <div className="flex flex-col w-full">
      <BrandsContext.Provider value={brandsContextValue}>
        <ResultsContext.Provider value={resultsContextValue}>
          <AvailableEntitiesContext.Provider value={availableEntitiesContextValue}>
            <AddNewContext.Provider value={contextValue}>
              <CanSubmitContext.Provider value={canSubmitContext}>
                <CanEditContext.Provider value={editable}>
                  <PageHeader
                    title={title}
                    onClose={onClose}
                    actions={withActions ? controls : null}
                  />
                  <Stepper
                    onSubmit={goOut ? openEditModal : openCreateModal}
                    canSubmit={submit}
                    steps={steps}
                    editable={editable}
                    freeSelection={!!goOut}
                  />
                  {showModal && modalElement}
                </CanEditContext.Provider>
              </CanSubmitContext.Provider>
            </AddNewContext.Provider>
          </AvailableEntitiesContext.Provider>
        </ResultsContext.Provider>
      </BrandsContext.Provider>
    </div>
  );
};

GoOut.propTypes = {
  title: PropTypes.string.isRequired,
  withActions: PropTypes.bool,
  editable: PropTypes.bool,
};

GoOut.defaultProps = {
  // allows us to know if the actions should be rendered (edit and delete) - IF OWNER OF THE GO OUT!
  withActions: false,
  // allows us to know if we're in edit mode or view mode - in view mode fields are readonly.
  editable: false,
};

GoOut.displayName = 'GoOut';

export default GoOut;
