import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import Checkbox from 'components/ui/Checkbox';
import { Button } from 'components/ui/buttons';
import Context from 'components/GoOut/state/contexts/addNew';
import {
  removeMediaFile,
  setCoverImage,
  setMediaFile,
} from 'components/GoOut/state/actions/addNew/others';
import CanSubmitContext from 'contexts/CanSubmitContext';
import Icon from 'components/ui/Icon';
import CanEditContext from 'contexts/CanEditContext';
import PictureModal from './PictureModal';
import DeleteModal from './DeleteModal';
import Step from '../Step';
import Uploader from './Uploader';
import Gallery from './Gallery';

const title = 'Poze și video';
const getDescription = (editable) =>
  editable
    ? 'Încarcă poze și video din timpul partidei. Trebuie să selectezi o poză de copertă. Aceasta va fi afișată în lista de partide. Dacă formatul imaginilor nu corespunde cu cel al copertei, aveți posibilitatea de a redimensiona imaginea.'
    : 'Pozele și video-urile încărcate din timpul partidei. Bucură-te de amintirile frumoase!';

const getModal = (type, modalProps) => {
  let modal;
  switch (type) {
    case 'cover':
      modal = <PictureModal {...modalProps} />;
      break;
    case 'delete':
      modal = <DeleteModal {...modalProps} />;
      break;
    default:
      return null;
  }
  return modal;
};

const getFileNameMeta = (name) => {
  if (name.length > 20) {
    const fileName = name.slice(0, 20);
    return { value: `${fileName}...`, isChanged: true };
  }

  return { value: name, isChanged: false };
};

const Media = () => {
  const [selectedPictures, setSelectedPictures] = useState([]);
  const [whichModal, setWhichModal] = useState('');
  const [state, dispatch] = useContext(Context);
  const { media } = state;
  const [, setSubmit] = useContext(CanSubmitContext);
  const editable = useContext(CanEditContext);

  useEffect(() => {
    // const hasFiles = media.length > 0;
    //
    // if (hasFiles) {
    //   const allVideos = media.every((item) => item.file.type.startsWith('video/'));
    //   if (allVideos) {
    //     setSubmit(true);
    //   } else {
    //     const hasCover = media.some((item) => item.isCover);
    //     setSubmit(hasCover);
    //   }
    // } else {
    //   setSubmit(true);
    // }
    setSubmit(false); // hard coded for not working go outs.
  }, [media, setSubmit]);

  const closeModal = () => {
    setWhichModal('');
  };

  const modalProps = useRef({
    onClose: closeModal,
    onSubmit: () => {},
  });

  const onSubmit = (meta, index, file) => {
    dispatch(
      setCoverImage(index, file, {
        croppedArea: meta.croppedArea,
        cropData: meta.cropData,
      }),
    );
    closeModal();
  };

  const modal = useMemo(() => getModal(whichModal, modalProps.current), [whichModal]);

  const onSelectCover = (e, index) => {
    const selectedItemIndex = e.target.checked ? index : null;
    modalProps.current.picture = media[selectedItemIndex].file;
    modalProps.current.meta = undefined;
    modalProps.current.onSubmit = (meta) => onSubmit(meta, index, media[selectedItemIndex].file);
    setWhichModal('cover');
  };

  const onResize = (index, item) => {
    modalProps.current.picture = item.file;
    modalProps.current.meta = {
      ...item.metaData.cropData,
    };
    modalProps.current.onSubmit = (meta) => onSubmit(meta, index, item.file);
    setWhichModal('cover');
  };

  const selectPicture = (e, index) => {
    setSelectedPictures((prevState) => {
      const indexInSelected = prevState.indexOf(index);
      if (indexInSelected !== -1) {
        return prevState.filter((item) => item !== index);
      }
      return [...prevState, index];
    });
  };

  const onDelete = () => {
    modalProps.current.onSubmit = () => {
      dispatch(removeMediaFile(selectedPictures));
      setSelectedPictures([]);
      closeModal();
    };
    setWhichModal('delete');
  };

  const uploadFiles = (files) => {
    files.map((file) =>
      dispatch(
        setMediaFile({
          file,
          isCover: false,
        }),
      ),
    );
  };

  const uploadedMedia = () =>
    media.map((item, index) => {
      const { file, isCover } = item;
      const { value: fileName, isChanged } = getFileNameMeta(file.name);
      const isImage = file.type ? file.type.includes('image') : true;

      return (
        <div
          key={file.name}
          className={cn('flex justify-between items-center', {
            'border-b border-gray-light-stroke': index !== media.length - 1,
          })}
        >
          <div className="flex gap-x-4.8 p-4.8 items-center">
            <Checkbox
              onChange={(e) => selectPicture(e, index)}
              name={file.name}
              id={file.name}
              value={file.name}
              checked={selectedPictures.includes(index)}
              readOnly={!editable}
            />
            {isImage ? (
              <img
                src={URL.createObjectURL(file)}
                alt={file.name}
                className="w-16.8 h-16.8 rounded-3.2 object-cover object-center"
              />
            ) : (
              <Icon identifier="film" className="w-16.8 h-16.8" />
            )}
            <span
              className="text-green-dark leading-8 font-medium"
              title={isChanged ? file.name : undefined}
            >
              {fileName}
            </span>
          </div>
          <div className="flex items-center">
            <div className="py-4.8 px-6.4">
              {isImage && (
                <Checkbox
                  size="small"
                  onChange={(e) => onSelectCover(e, index)}
                  name="coverImg"
                  value={index.toString()}
                  id={`coverImg_${index}`}
                  label="Imagine de copertă"
                  variant="radio"
                  singleSelection
                  checked={isCover}
                  readOnly={!editable}
                />
              )}
            </div>
          </div>
        </div>
      );
    });

  const getCoverImage = () => {
    const coverImageIndex = media.findIndex((item) => item.isCover);
    const coverImage = media[coverImageIndex];

    if (coverImage) {
      return (
        <div className="flex justify-between">
          <div className="flex gap-x-4.8 p-4.8 items-center">
            <img
              src={URL.createObjectURL(coverImage.file)}
              alt="cover"
              className="w-16.8 h-16.8 rounded-3.2 object-center object-cover"
            />
            <span className="text-green-dark leading-8 font-medium">{coverImage.file.name}</span>
          </div>
          <div className="flex items-center">
            <Button onClick={() => onResize(coverImageIndex, coverImage)}>Modifică</Button>
          </div>
        </div>
      );
    }

    const hasImages = media.filter((obj) => obj.file.type.includes('image')).length > 0;
    if (hasImages) {
      return (
        <span className="text-red-darker text-controls font-semibold">
          Te rugăm să selectezi o imagine de copertă
        </span>
      );
    }

    return null;
  };

  return (
    <Step title={title} description={getDescription(editable)}>
      {editable ? (
        <Uploader
          files={uploadFiles}
          onDelete={() => onDelete(selectedPictures)}
          selectedNo={selectedPictures.length}
          coverImage={getCoverImage()}
          uploadedMedia={uploadedMedia()}
          media={media}
        />
      ) : (
        <Gallery media={media} />
      )}
      {whichModal && modal}
    </Step>
  );
};

Media.displayName = 'Media';

export default Media;
