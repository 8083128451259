import React from 'react';
import { Emphasize } from '../formatting';

const Email = () => {
  return (
    <Emphasize variant="link">
      <a href="mailto:contact@pescuiala.ro">contact@pescuiala.ro</a>
    </Emphasize>
  );
};

export default Email;
