import React from 'react';
import PropTypes from 'prop-types';
import {
  init,
  add,
  deselect,
  select,
  setStrategy,
} from 'components/GoOut/state/actions/availableEntities/baits';
import { BAITS_META } from 'components/GoOut/utility/constants';
import BaitModal from 'components/Equipment/entities/BaitModal';
import AccordionWithData from '../AccordionWithData';
import TableWithData from '../TableWithData';
import { useItems } from '../../../hooks';

const Baits = (props) => {
  const { opened, onToggle, onValidationChange, editable } = props;
  const [formData, formDataDispatch, availableEntitiesData, availableEntitiesDispatch] = useItems(
    'feeding.baits',
    'baits',
  );
  const data = { ...formData, ...availableEntitiesData };

  if (editable) {
    return (
      <AccordionWithData
        title="Momeli"
        actionText="Adaugă momeală nouă"
        opened={opened}
        onToggle={() => onToggle('baits')}
        onValidate={onValidationChange}
        strategyInputProps={{
          label: 'Strategie folosire momeală cârlig',
          placeholder: 'Descriere strategie folosire momeală cârlig',
          contextKey: 'baits',
        }}
        endpoint="/gear/baits"
        actions={{
          formDataDispatch,
          availableEntitiesDispatch,
          onInit: init,
          onAdd: add,
          onSelect: select,
          onDeselect: deselect,
          onStrategyChange: setStrategy,
        }}
        state={data}
        dataMeta={BAITS_META}
        AddNewModalComponent={BaitModal}
      />
    );
  }

  return (
    <TableWithData
      title="Momeli"
      meta={BAITS_META}
      all={availableEntitiesData.all}
      selectedIds={formData.selectedIds}
    />
  );
};

Baits.propTypes = {
  opened: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  onValidationChange: PropTypes.func.isRequired,
  editable: PropTypes.bool.isRequired,
};

Baits.displayName = 'Baits';

export default Baits;
