import React from 'react';
import PropTypes from 'prop-types';
import Header from './Header';

const Index = (props) => {
  const { children, title, description } = props;

  return (
    <div className="flex-1 flex flex-col w-full gap-y-16">
      <Header title={title} description={description} />
      <div className="flex flex-col gap-y-19.2">{children}</div>
    </div>
  );
};

Index.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
};

Index.defaultProps = {
  description: '',
};

Index.displayName = 'Index';

export default Index;
