import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Context from 'components/GoOut/state/contexts/addNew';
import { CheckboxesDropdown } from 'components/ui/dropdowns';
import { setWaterSpeed } from 'components/GoOut/state/actions/addNew/others';
import { onFieldChange } from 'utility/utils';
import IconStats from 'components/ui/IconStats';

const waterSpeeds = ['puternic', 'mediu', 'lin'].map((o) => ({ id: o, value: o }));

const WaterSpeedInput = (props) => {
  const { editable } = props;
  const [state, dispatch] = useContext(Context);
  const {
    conditions: {
      water: {
        speed: { value: speed, isValid: speedIsValid, isDirty: speedIsDirty, error: speedError },
      },
    },
  } = state;

  const onSelectSpeed = (value) => {
    const setter = (newItem) => dispatch(setWaterSpeed(newItem));
    onFieldChange(value, setter, undefined);
  };

  if (editable) {
    const triggerProps = {
      id: 'waterSpeed',
      name: 'waterSpeed',
      placeholder: 'Selectare curent',
      label: 'Curent',
      type: 'text',
      valid: speedIsDirty ? speedIsValid : undefined,
      helperText: speedIsDirty && !speedIsValid ? speedError : null,
      value: speed,
    };

    return (
      <CheckboxesDropdown
        data={waterSpeeds}
        id="waterSpeed"
        onChange={onSelectSpeed}
        triggerProps={triggerProps}
        singleSelection
        selectedValues={speed ? [speed] : []}
        disabled={!editable}
        readOnly={!editable}
      />
    );
  }

  return <IconStats icon="wave" data={[{ label: 'Curent', value: speed }]} rounded="small" />;
};

WaterSpeedInput.propTypes = {
  editable: PropTypes.bool.isRequired,
};

export default WaterSpeedInput;
