import React from 'react';
import PropTypes from 'prop-types';
import useScrollLock from 'hooks/useScrollLock';
import { Button, IconButton } from 'components/ui/buttons';
import Icon from 'components/ui/Icon';

const Modal = (props) => {
  const {
    title,
    description,
    icon,
    children,
    onClose,
    onSubmit,
    submitText,
    cancelText,
    danger,
    canSubmit,
    form,
  } = props;
  const hasSubmitButton = !!onSubmit || form;
  const ChildrenParentElementType = form ? 'form' : 'div';

  useScrollLock();

  return (
    <div
      data-testid="modal-container"
      /* eslint-disable-next-line max-len */
      className="fixed z-50 top-0 left-0 right-0 bottom-0 bg-green-dark bg-opacity-25 flex items-center justify-center sidebar-screen:pl-104"
    >
      <ChildrenParentElementType
        data-testid="modal"
        /* eslint-disable-next-line max-len */
        className="flex flex-col psc-modal rounded-3.2 bg-white shadow-modal w-full h-full lg:w-192 lg:h-auto"
        {...(form ? { onSubmit, noValidate: true } : {})}
      >
        <div data-testid="modal-header" className="flex flex-col gap-y-3.2">
          <div className="flex gap-y-3.2">
            <div
              data-testid="modal-title"
              className="flex items-center text-modal-title font-semibold text-green-dark"
            >
              {icon && <Icon identifier={icon} className="mr-4.8" />}
              {title}
            </div>
            {onClose && (
              <div data-testid="close-button" className="group ml-auto mb-1.6">
                <IconButton
                  onClick={onClose}
                  icon="close"
                  variant="simple"
                  iconClassName="w-9.6 h-9.6"
                  iconColorClassName="stroke-gray group-hover:stroke-gray-darker"
                />
              </div>
            )}
          </div>
          {description && <span className="text-gray leading-8">{description}</span>}
        </div>
        {children && (
          <div
            data-testid="modal-content"
            className="flex flex-col gap-y-8 -mx-9.6 px-9.6 mt-8 mb-8 overflow-auto md:max-h-screen-60"
          >
            {children}
          </div>
        )}
        {(!!onClose || hasSubmitButton) && (
          <div
            data-testid="modal-footer"
            className={`grid gap-x-3.2 ${
              !!onClose && hasSubmitButton ? 'grid-cols-2' : 'grid-cols-1'
            }`}
          >
            {onClose && (
              <Button data-testid="cancel-button" onClick={onClose} variant="secondary" fullWidth>
                {cancelText}
              </Button>
            )}
            {hasSubmitButton && (
              <Button
                data-testid="submit-button"
                onClick={onSubmit || undefined}
                variant={danger ? 'danger' : 'primary'}
                fullWidth
                disabled={!canSubmit}
                submit={form}
              >
                {submitText}
              </Button>
            )}
          </div>
        )}
      </ChildrenParentElementType>
    </div>
  );
};

Modal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  onClose: PropTypes.func,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  icon: PropTypes.string,
  submitText: PropTypes.string,
  cancelText: PropTypes.string,
  onSubmit: PropTypes.func,
  danger: PropTypes.bool,
  canSubmit: PropTypes.bool,
  form: PropTypes.bool,
};

Modal.defaultProps = {
  children: null,
  onClose: null,
  description: '',
  onSubmit: null,
  icon: '',
  submitText: 'Adaugă',
  cancelText: 'Renunță',
  danger: false,
  canSubmit: true,
  form: false,
};

Modal.displayName = 'Modal';

export default Modal;
