import React from 'react';
import { NavLink } from 'react-router-dom';
import { Emphasize } from '../formatting';

const AppLink = () => {
  return (
    <Emphasize variant="link">
      <NavLink to="/" tabIndex="_blank">
        pescuiala.ro
      </NavLink>
    </Emphasize>
  );
};

export default AppLink;
