import { useLoaderData } from 'react-router-dom';
import { /* useEffect, */ useMemo } from 'react';
/* import { toast } from 'react-toastify';
import { DEFAULT_API_ERROR } from 'constants/forms'; */
import { getFormattedDate } from 'utility/utils';

const useData = () => {
  const { data: rawGoOuts /* , error */ } = useLoaderData() || {};

  // useEffect(() => {
  //   if (error) {
  //     const { response: errorResponse } = error;
  //
  //     if (errorResponse) {
  //       const { status: statusCode, data: errorData } = errorResponse;
  //
  //       if (statusCode === 401 && errorData.detail === 'Not authenticated') {
  //         toast.error('Nu aveți permisiunea necesară pentru a accesa această pagină.');
  //       }
  //     } else {
  //       toast.error(DEFAULT_API_ERROR);
  //     }
  //   }
  // }, [error]);

  const serverGoOuts = useMemo(
    () =>
      (rawGoOuts || []).map((goOut) => {
        const {
          createdAt,
          startsAt,
          endsAt,
          user,
          catch: catchData,
          totalQuantity,
          selfRate,
          waterCondition,
        } = goOut || {};

        return {
          id: goOut?.id,
          stats: {
            startDate: getFormattedDate(startsAt, { withTime: true }),
            endDate: getFormattedDate(endsAt, { withTime: true }),
            species: catchData.map((item) => item.fishSpecies.name),
            quantity: totalQuantity,
            personalScore: selfRate,
            waterCondition,
          },
          author: {
            id: user.id,
            fullName: user.fullName,
            avatar: user.avatar,
            createdAt: getFormattedDate(createdAt),
          },
          ...goOut,
        };
      }),
    [rawGoOuts],
  );

  return {
    serverGoOuts,
  };
};

export default useData;
