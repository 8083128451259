import React, { useState } from 'react';
import SelectionModal from 'components/GoOut/steps/Feeding/SelectionModal';

const useSelection = (title, rows, tableHeader) => {
  const [isVisible, setIsVisible] = useState(false);
  const tableProps = {
    header: tableHeader,
    rows,
    itemsPerPage: 6,
    itemsCount: rows.length,
    onPageChange: () => {},
  };

  const content = (
    <SelectionModal
      title={`${title} selectate`}
      onClose={() => setIsVisible(false)}
      tableProps={tableProps}
    />
  );

  return [isVisible, setIsVisible, content];
};

export default useSelection;
