import React, { useState, useRef } from 'react';
import DeleteModal from '../entities/DeleteModal';
import RodModal from '../entities/RodModal';
import ReelModal from '../entities/ReelModal';

const getModal = (activeTab, modalProps) => {
  if (modalProps.action === 'delete') {
    return <DeleteModal {...modalProps} />;
  }

  switch (activeTab) {
    case 'rods':
      return <RodModal {...modalProps} />;
    case 'reels':
      return <ReelModal {...modalProps} />;
    default:
      throw new Error(`Equipment does not handle ${activeTab}`);
  }
};

const useModalActions = (activeTab, items, addItem, editItem, deleteItem) => {
  const [showModal, setShowModal] = useState(false);
  const modalProps = useRef({
    onClose: () => {
      setShowModal(false);
      delete modalProps.current.item;
      delete modalProps.current.entity;
    },
  });
  const modalElement = showModal ? getModal(activeTab, modalProps.current) : null;

  const cleanUpModalProps = () => {
    if (modalProps.current) {
      Object.keys(modalProps.current).forEach((key) => {
        if (key !== 'onClose') {
          delete modalProps.current[key];
        }
      });
    }
  };

  const openCreateModal = () => {
    modalProps.current.action = 'add';
    modalProps.current.onSuccess = (newItem) => {
      addItem(newItem);
      setShowModal(false);
      cleanUpModalProps();
    };
    setShowModal(true);
  };

  const openEditModal = (itemId) => {
    modalProps.current.action = 'edit';
    modalProps.current.item = items.find((item) => item.id === itemId);
    modalProps.current.onSuccess = (updatedItem) => {
      editItem(updatedItem);
      setShowModal(false);
      cleanUpModalProps();
    };
    setShowModal(true);
  };

  const openDeleteModal = (itemId) => {
    modalProps.current.action = 'delete';
    modalProps.current.item = items.find((item) => item.id === itemId);
    modalProps.current.entity = activeTab;
    modalProps.current.onSuccess = () => {
      deleteItem(itemId);
      setShowModal(false);
      cleanUpModalProps();
    };
    setShowModal(true);
  };

  return { modalElement, showModal, setShowModal, openCreateModal, openEditModal, openDeleteModal };
};

export default useModalActions;
