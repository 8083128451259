import React from 'react';
import PropTypes from 'prop-types';

const Title = (props) => {
  const { children } = props;

  return <h1 className="text-xl lg:text-2xl text-green-dark font-bold">{children}</h1>;
};

Title.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
};

Title.displayName = 'Title';

export default Title;
