import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Icon from 'components/ui/Icon';
import { Button } from 'components/ui/buttons';
import useAPI from '../../../hooks/useAPI';

const SuccessfulRegistration = (props) => {
  const { userId, email } = props;
  const navigate = useNavigate();
  const { apiCall } = useAPI();

  const onSubmit = async (e) => {
    e.preventDefault();

    await apiCall(
      `/authentication/${userId}/activate/resend`,
      'post',
      {},
      {
        getSuccessMessage: () => 'E-mail-ul a fost retrimis cu succes!',
        getErrorMessage: (statusCode) => {
          if (statusCode === 400) {
            return 'Contul tău a fost deja activat!';
          }
          if (statusCode === 404) {
            return 'Contul nu a fost găsit!';
          }

          return null;
        },
        onErrorCallback: () => {
          navigate('/');
        },
      },
    );
    return false;
  };

  return (
    <>
      <div className="flex justify-center lg:justify-start">
        <Icon identifier="success" />
        <p className="ml-4 lg:ml-4.8 flex-1">
          <span className="font-bold text-green-dark">Contul tău a fost creat cu succes.</span> Un
          e-mail a fost trimis pe adresa <span className="font-bold text-green-dark">{email}</span>.
          Te rugăm să urmărești indicațiile primite pe mail pentru a activa contul.
        </p>
      </div>
      <form className="grid gap-8 xl:gap-9.6 w-full" onSubmit={onSubmit}>
        <p>
          Dacă nu ai primit un e-mail cu indicații pentru validare, poți cere o retrimitere folosind
          butonul de mai jos. Din diverse motive, e-mailul ar putea ajunge în folderul de spam. Te
          rugăm să verifici și acest folder.
        </p>
        <Button variant="primary" submit>
          Retrimite e-mail
        </Button>
      </form>
    </>
  );
};

SuccessfulRegistration.propTypes = {
  userId: PropTypes.number.isRequired,
  email: PropTypes.string.isRequired,
};

SuccessfulRegistration.displayName = 'Success';

export default SuccessfulRegistration;
