import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/ui/Icon';

const Author = (props) => {
  const { author, location } = props;
  const { fullName, avatar, createdAt } = author;

  return (
    <div className="flex items-center gap-8 md:gap-16">
      <div className="flex items-center gap-4">
        <div className="w-16 h-16 flex items-center justify-center rounded-full">
          {avatar ? (
            <img className="w-full h-full rounded-inherit" src={avatar} alt="Author" />
          ) : (
            <span className="bg-green-dark text-white rounded-full w-16 h-16 flex items-center justify-center">
              <Icon identifier="water-fishing" fillClassName="fill-white" className="w-10 h-10" />
            </span>
          )}
        </div>
        <div className="flex flex-col">
          <p className="text-green-dark font-bold">{fullName}</p>
          <p className="text-gray text-sm">{createdAt}</p>
        </div>
      </div>
      {location}
    </div>
  );
};

Author.propTypes = {
  author: PropTypes.shape({
    fullName: PropTypes.string.isRequired,
    avatar: PropTypes.string,
    createdAt: PropTypes.string.isRequired,
  }),
  location: PropTypes.node,
};

Author.defaultProps = {
  author: undefined,
  location: null,
};

export default Author;
