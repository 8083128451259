import React, { useContext, useEffect } from 'react';
import Step from 'components/GoOut/steps/Step';
import LocationInput from 'components/GoOut/steps/inputs/LocationInput';
import StartDateInput from 'components/GoOut/steps/inputs/StartDateInput';
import EndDateInput from 'components/GoOut/steps/inputs/EndDateInput';
import StartTimeInput from 'components/GoOut/steps/inputs/StartTimeInput';
import EndTimeInput from 'components/GoOut/steps/inputs/EndTimeInput';
import Map from 'components/ui/Map';
import CanSubmitContext from 'contexts/CanSubmitContext';
import CanEditContext from 'contexts/CanEditContext';
import Section from './Section';
import Context from '../state/contexts/addNew';

const title = 'Locație și dată';
const getDescription = (editable) =>
  editable
    ? 'Adaugă detalii despre unde și când a avut loc partida de pescuit.'
    : 'Detalii despre unde și când a avut loc partida de pescuit.';

const Localization = () => {
  const [state] = useContext(Context);
  const editable = useContext(CanEditContext);
  const [, setSubmit] = useContext(CanSubmitContext);

  const {
    event: {
      startTime: { isValid: startTimeIsValid },
      endTime: { isValid: endTimeIsValid },
      startDate: { isValid: startDateIsValid },
      endDate: { isValid: endDateIsValid },
      location: {
        longitude: { value: longitude },
        latitude: { value: latitude },
      },
    },
  } = state;

  useEffect(() => {
    const canSubmit =
      startDateIsValid &&
      endDateIsValid &&
      startTimeIsValid &&
      endTimeIsValid &&
      Object.values(state.event.location).every((field) => field.isValid) &&
      Object.values(state.event.location).some((field) => field.isDirty);

    setSubmit(canSubmit);
  }, [
    startTimeIsValid,
    endTimeIsValid,
    startDateIsValid,
    endDateIsValid,
    state.event.location,
    setSubmit,
  ]);

  return (
    <Step title={title} description={getDescription(editable)}>
      <Section title="Dată și oră">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-9.6 gap-x-4.8">
          <StartDateInput editable={editable} />
          <EndDateInput editable={editable} />
          <StartTimeInput editable={editable} />
          <EndTimeInput editable={editable} />
        </div>
      </Section>
      <Section title="Locație">
        {editable ? (
          <LocationInput />
        ) : (
          <div className="flex-1 h-104 rounded-1.6">
            <Map
              mapId="go-out-location"
              viewOnly
              coordinates={{
                lat: latitude || 0,
                lng: longitude || 0,
              }}
            />
          </div>
        )}
      </Section>
    </Step>
  );
};

Localization.displayName = 'Localization';

export default Localization;
