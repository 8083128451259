import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/ui/Modal';
import { Input } from 'components/ui/inputs';
import { EMPTY_DEFAULT_FIELD } from 'constants/forms';
import { isRequired } from 'utility/validation';
import { onFieldChange } from 'utility/utils';
import useSubmit from '../hooks/useSubmit';

const BaitModal = (props) => {
  const { onClose, onSuccess } = props;
  const [name, setName] = useState(EMPTY_DEFAULT_FIELD);
  const onTypeFieldChange = (event) => onFieldChange(event.target.value, setName, isRequired);
  const [canSubmit, onSubmit] = useSubmit('baits', null, { name: { ...name } }, setName, onSuccess);

  return (
    <Modal title="Adaugă momeală" onClose={onClose} onSubmit={onSubmit} canSubmit={canSubmit} form>
      <Input
        label="Tip"
        placeholder="Introdu tipul"
        onChange={onTypeFieldChange}
        value={name.value || ''}
        valid={name.isDirty ? name.isValid : undefined}
        required
      />
    </Modal>
  );
};

BaitModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

BaitModal.displayName = 'BaitModal';

export default BaitModal;
