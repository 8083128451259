import { SET_GO_OUTS } from './types';

export const defaultState = {
  goOuts: {
    isLoaded: false,
    data: [],
  },
};

export const reducer = (state, action) => {
  switch (action.type) {
    case SET_GO_OUTS:
      return {
        ...state,
        goOuts: {
          isLoaded: true,
          data: action.payload,
        },
      };
    default:
      return state;
  }
};
