import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'components/ui/inputs';

const EmailInput = (props) => {
  const { onChange, item, label } = props;
  return (
    <Input
      id="email"
      name="email"
      onChange={onChange}
      placeholder="Introdu adresă e-mail"
      label={label}
      type="email"
      value={item.value}
      valid={item.value === '' || item.isValid}
      autoFocus
    />
  );
};

EmailInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  item: PropTypes.shape({
    value: PropTypes.string,
    isValid: PropTypes.bool,
  }).isRequired,
  label: PropTypes.string,
};

EmailInput.defaultProps = {
  label: 'Adresă e-mail cont',
};

export default EmailInput;
