import React, { useContext, useEffect, useState } from 'react';
import Weather from 'components/GoOut/steps/Environment/Weather';
import Water from 'components/GoOut/steps/Environment/Water';
import CanSubmitContext from 'contexts/CanSubmitContext';
import CanEditContext from 'contexts/CanEditContext';
import Step from '../Step';

const title = 'Condiții meteo și detalii apă';
const getDescription = (editable) =>
  editable
    ? 'Adaugă detalii despre condițiile meteo și condițiile apei în care ai pescuit.'
    : 'Detalii despre condițiile meteo și condițiile apei în care ai pescuit.';

const Environment = () => {
  const [weatherFormIsValid, setWeatherFormIsValid] = useState(false);
  const [waterFormIsValid, setWaterFormIsValid] = useState(false);
  const [, setSubmit] = useContext(CanSubmitContext);
  const editable = useContext(CanEditContext);

  useEffect(() => {
    setSubmit(waterFormIsValid && weatherFormIsValid);
  }, [setSubmit, waterFormIsValid, weatherFormIsValid]);

  const onWeatherFormChange = (value) => setWeatherFormIsValid(value);
  const onWaterFormChange = (value) => setWaterFormIsValid(value);

  return (
    <Step title={title} description={getDescription(editable)}>
      <Weather onChange={onWeatherFormChange} />
      <Water onChange={onWaterFormChange} />
    </Step>
  );
};

Environment.displayName = 'Environment';

export default Environment;
