import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/ui/Modal';
import Table from 'components/ui/table/Table';

const SelectionModal = (props) => {
  const { title, onClose, tableProps } = props;
  return (
    <Modal title={title} onClose={onClose}>
      <Table {...tableProps} />
    </Modal>
  );
};

SelectionModal.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  tableProps: PropTypes.object.isRequired,
};

SelectionModal.defaultProps = {
  title: 'Select items',
};

SelectionModal.displayName = 'SelectionModal';

export default SelectionModal;
