import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const PaginationNumber = (props) => {
  const { children, active, onSelect } = props;

  return (
    <button
      className={cn('p-4.8 font-medium rounded-3.2', {
        'text-blue bg-blue-lighter': active,
        'text-gray-dark bg-transparent hover:text-blue hover:bg-blue-lighter': !active,
      })}
      type="button"
      onClick={onSelect}
      disabled={active}
    >
      <div className="flex items-center justify-center w-6.4 h-6.4 leading-8">{children}</div>
    </button>
  );
};

PaginationNumber.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  active: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
};

PaginationNumber.defaultProps = {
  active: false,
};

PaginationNumber.displayName = 'PaginationNumber';

export default PaginationNumber;
