import React from 'react';
import PropTypes from 'prop-types';
import AddNewRigLineModal from 'components/Equipment/entities/RigLineModal';
import { getLineName } from '../../utility/utils';
import Dropdown from './Dropdown';
import {
  initRigLines,
  addRigLine,
  selectRigLine,
  deselectRigLine,
} from '../../state/actions/availableEntities/lines';

const RigLineSelector = (props) => {
  const { index, editable } = props;

  return (
    <Dropdown
      inputProps={{
        id: `rig-line-${index}`,
        name: 'rigLine',
        placeholder: 'Selectează struna',
        label: 'Strună',
      }}
      index={index}
      nameGetter={getLineName}
      resourceMeta={{
        apiResource: 'lines',
        entity: 'rigLines',
        formParentKey: 'rigs',
        formKey: 'line',
        queryParams: {
          type: 'rig',
        },
      }}
      actions={{
        onInit: initRigLines,
        onAdd: addRigLine,
        onSelect: selectRigLine,
        onDeselect: deselectRigLine,
      }}
      addNewProps={{
        component: AddNewRigLineModal,
        text: 'Adaugă strună',
      }}
      editable={editable}
    />
  );
};

RigLineSelector.propTypes = {
  index: PropTypes.number.isRequired,
  editable: PropTypes.bool.isRequired,
};

RigLineSelector.displayName = 'RigLineSelector';

export default RigLineSelector;
