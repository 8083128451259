import React from 'react';
import PropTypes from 'prop-types';
import {
  add,
  deselect,
  init,
  select,
  setStrategy,
} from 'components/GoOut/state/actions/availableEntities/groundBaits';
import GroundBaitModal from 'components/Equipment/entities/GroundBaitModal';
import { GROUND_BAITS_META } from 'components/GoOut/utility/constants';
import AccordionWithData from '../AccordionWithData';
import TableWithData from '../TableWithData';
import { useItems } from '../../../hooks';

const GroundBaits = (props) => {
  const { opened, onToggle, onValidationChange, editable } = props;
  const [formData, formDataDispatch, availableEntitiesData, availableEntitiesDispatch] = useItems(
    'feeding.groundBaits',
    'groundBaits',
  );
  const data = { ...formData, ...availableEntitiesData };

  if (editable) {
    return (
      <AccordionWithData
        title="Nade"
        actionText="Adaugă nadă nouă"
        opened={opened}
        onToggle={() => onToggle('groundBaits')}
        onValidate={onValidationChange}
        strategyInputProps={{
          label: 'Strategie de nădire',
          placeholder: 'Descriere strategie de nădire',
          contextKey: 'groundBaits',
        }}
        endpoint="/gear/ground-baits"
        actions={{
          formDataDispatch,
          availableEntitiesDispatch,
          onInit: init,
          onAdd: add,
          onSelect: select,
          onDeselect: deselect,
          onStrategyChange: setStrategy,
        }}
        state={data}
        dataMeta={GROUND_BAITS_META}
        AddNewModalComponent={GroundBaitModal}
      />
    );
  }

  return (
    <TableWithData
      title="Nade"
      meta={GROUND_BAITS_META}
      all={availableEntitiesData.all}
      selectedIds={formData.selectedIds}
    />
  );
};

GroundBaits.propTypes = {
  opened: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  onValidationChange: PropTypes.func.isRequired,
  editable: PropTypes.bool.isRequired,
};

GroundBaits.displayName = 'GroundBaits';

export default GroundBaits;
