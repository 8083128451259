import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from 'components/ui/buttons';

const Header = (props) => {
  const { onClose, triggerRef } = props;

  return (
    <div className="flex justify-between items-center">
      <div className="text-2xl text-green-dark font-bold">Filtre</div>
      <IconButton
        ref={triggerRef}
        onClick={onClose}
        icon="close"
        variant="simple"
        iconClassName="w-9.6 h-9.6"
      />
    </div>
  );
};

Header.propTypes = {
  onClose: PropTypes.func.isRequired,
  triggerRef: PropTypes.object.isRequired,
};

Header.displayName = 'Header';

export default Header;
