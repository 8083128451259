import React from 'react';
import PropTypes from 'prop-types';
import BrandInput from 'components/Equipment/inputs/BrandInput';
import Modal from 'components/ui/Modal';
import ModelInput from 'components/Equipment/inputs/ModelInput';
import useFormFields from 'components/Equipment/hooks/useFormFields';
import useSubmit from 'components/Equipment/hooks/useSubmit';
import useBrandsLoader from 'hooks/useBrandsLoader';

const GroundBaitModal = (props) => {
  const { onClose, item, onSuccess } = props;
  const [fields, setFields, individualSetters] = useFormFields(item, {});
  const [isLoadingBrands, allBrands] = useBrandsLoader();
  const [canSubmit, onSubmit] = useSubmit('ground-baits', item, fields, setFields, onSuccess);

  return (
    <Modal
      onClose={onClose}
      onSubmit={onSubmit}
      title="Adaugă ground bait"
      canSubmit={canSubmit}
      form
    >
      <BrandInput
        loading={isLoadingBrands}
        all={allBrands}
        onChange={individualSetters.brand}
        {...fields.brand}
      />
      <ModelInput onChange={individualSetters.model} {...fields.model} />
    </Modal>
  );
};

GroundBaitModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    brand: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    model: PropTypes.string.isRequired,
  }),
};

GroundBaitModal.defaultProps = {
  item: null,
};

GroundBaitModal.displayName = 'GroundBaitModal';

export default GroundBaitModal;
