import React from 'react';
import PropTypes from 'prop-types';
import { getTextWithoutDiacritics } from 'utility/utils';

const HighlightedText = (props) => {
  const { term, value } = props;
  const index = getTextWithoutDiacritics(value)
    .toLowerCase()
    .indexOf(getTextWithoutDiacritics(term).toLowerCase());
  const before = value.slice(0, index);
  const match = value.slice(index, index + term.length);
  const after = value.slice(index + term.length);

  return (
    <span data-testid="dropdown-highlighted-text" className="font-medium text-controls">
      {before}
      <span className="text-controls font-bold">{match}</span>
      {after}
    </span>
  );
};

HighlightedText.propTypes = {
  term: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

HighlightedText.displayName = 'HighlightedText';

export default HighlightedText;
