export const CLOUDY = {
  name: 'înnorat',
  identifier: 'overcast',
  title: 'Înnorat',
};

export const MOSTLY_CLOUDY = {
  name: 'predominant înnorat',
  identifier: 'cloudy-mostly',
  title: 'Predominant înnorat',
};

export const PARTLY_SUNNY = {
  name: 'parțial senin',
  identifier: 'cloudy-partly',
  title: 'Parțial senin',
};

export const SUNNY = {
  name: 'senin',
  identifier: 'clear',
  title: 'Senin',
};

export const MOSTLY_SUNNY = {
  name: 'predominant senin',
  identifier: 'clear-mostly',
  title: 'Predominant senin',
};

export const SKY_CONDITIONS = {
  ...Object.fromEntries(
    [CLOUDY, MOSTLY_CLOUDY, PARTLY_SUNNY, SUNNY, MOSTLY_SUNNY].map((item) => [item.name, item]),
  ),
};
