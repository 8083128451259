import React from 'react';
import { Section, Emphasize, List, Title } from './formatting';

const CookiesPolicy = () => {
  return (
    <div className="flex flex-col gap-9.6">
      <Title>POLITICĂ PRIVIND UTILIZAREA MODULELOR COOKIES</Title>
      <hr className="text-gray-input-stroke" />
      <Section title="I. Informaţii suplimentare despre fişierele cookie">
        <p>
          Scopul nostru este să vă oferim o experienţă online cât mai interactivă, iar în acest sens
          folosim module cookie sau tehnici similare. Este important să știți ce module cookie
          folosește aplicația și în ce scop, întrucât aceasta va ajuta la protejarea
          confidenţialităţii dumneavoastră, asigurând totodată uşurinţa de utilizare a aplicației
          noastre.
        </p>
      </Section>
      <Section title="II. Ce sunt modulele cookie?">
        <p>
          Modulele cookie sunt mici fişiere text care sunt stocate pe echipamentul dumneavoastră
          (computer sau dispozitiv mobil) atunci când accesați anumite site-uri web sau aplicații
          mobile.
        </p>
      </Section>
      <Section title="III. De ce se utilizează module cookie?">
        <p>Modulele cookie pot fi folosite în diferite scopuri:</p>
        <List variant="ordered">
          <li>
            pot fi necesare module cookie pentru a asigura funcţionarea corectă a aplicației. De
            exemplu, fără module cookie, poate fi dificil ca aplicația să reţină că v-ați conectat.
            Acestea sunt denumite <Emphasize>module cookie obligatorii</Emphasize>;
          </li>
          <li>
            pot fi folosite pentru a analiza cum este utilizată o aplicație, pentru a contoriza
            numărul de vizitatori şi pentru a afla cum poate fi îmbunătăţită aplicația. Nu asociem
            statisticile de utilizare a aplicației şi alte rapoarte cu persoane individuale. Aceste
            module cookie sunt denumite <Emphasize>module cookie analitice</Emphasize>;
          </li>
          <li>
            folosim module cookie pentru a permite publicitatea online, care poate fi personalizată
            pentru a afişa reclame mai relevante şi mai interesante pentru dumneavoastră, atât în
            aplicație, cât şi în afara acesteia. Realizăm acest lucru prin{' '}
            <Emphasize>module cookie de publicitate (direcţionate)</Emphasize>.
          </li>
          <li>
            folosim module cookie pentru a permite publicitatea online, care poate fi personalizată
            pentru a afişa reclame mai relevante şi mai interesante pentru dumneavoastră, atât pe
            site-ul nostru, cât şi în afara acestora. Realizăm acest lucru prin{' '}
            <Emphasize>module cookie de publicitate (direcţionate)</Emphasize>.
          </li>
        </List>
        <p>
          Astfel, informațiile generate de cookie-uri cu privire la modul în care este utilizată
          aplicația – informații standard de jurnal de utilizare a internetului (inclusiv adresa
          dumneavoastră IP) și informații privind comportamentul vizitatorului într-o formă anonimă
          – sunt transmise către Google și stocate de acesta, inclusiv pe servere din Statele Unite.
        </p>
        <p>Înainte de a fi transmisă către Google, adresa dumneavoastră IP este anonimizată.</p>
      </Section>
      <Section title="IV. Cum puteţi gestiona sau dezactiva modulele cookie din browser">
        <p>
          Trebuie să știți că vă puteți gestiona preferințele cookie din fereastra pop-up din
          site-ul atașat aplicației mobile sau din setările browserului, iar majoritatea browserelor
          vă permit să vedeți ce cookie-uri aveți și să le ștergeți în mod individual sau să blocați
          cookie-uri ale unor terțe părți, să blocați cookie-uri ale anumitor site-uri, să blocați
          setarea tuturor cookie-urilor, să ștergeți toate cookie-urile atunci când închideți
          browserul.
        </p>
        <p>
          În cazul în care dezactivați toate modulele (noastre) cookie din setările browserului,
          este posibil ca anumite secţiuni sau funcţii ale site-urilor noastre web să nu
          funcţioneze, deoarece browserul ne poate împiedica să setăm module cookie necesare pentru
          funcţionarea site-ului. Prin urmare, vă recomandăm să nu dezactivați toate modulele cookie
          din browserul web.
        </p>
      </Section>
    </div>
  );
};

export default CookiesPolicy;
