import React from 'react';
import { useLocation } from 'react-router-dom';
import Header from 'components/authentication/Header';
import { NavLink } from 'components/ui/links';
import useServerError from 'components/authentication/hooks/useServerError';
import { Button } from 'components/ui/buttons';
import Icon from 'components/ui/Icon';
import PropTypes from 'prop-types';

const Instructions = (props) => {
  const { actionType } = props;
  const location = useLocation();
  const {
    messageType,
    messageData: { title, body, icon } = {},
    onSubmit,
  } = useServerError(location.state, actionType);

  return (
    <div className="flex flex-col h-full justify-start gap-8 xl:gap-12 relative">
      <Header>Activare cont</Header>
      <form className="grid gap-8 xl:gap-9.6 w-full" onSubmit={onSubmit}>
        <div className="flex justify-center lg:justify-start">
          <Icon
            identifier={icon}
            fillClassName={
              messageType === 'inactive' ? 'stroke-error-500 fill-error-500' : undefined
            }
          />
          <p className="ml-4 lg:ml-4.8 flex-1">
            <span className="font-bold text-green-dark">{title}</span> {body}
          </p>
        </div>
        <p>
          Dacă nu ai primit un e-mail cu indicații pentru validare, poți cere o retrimitere folosind
          butonul de mai jos. Din diverse motive, e-mailul ar putea ajunge în folderul de spam. Te
          rugăm să verifici și acest folder.
        </p>
        <Button variant="primary" submit>
          Retrimite e-mail
        </Button>
      </form>
      <div className="flex justify-end relative lg:absolute bottom-0 right-0 mt-24 lg:mt-0">
        <NavLink to="/" variant="accent">
          Mergi la pagina de autentificare
        </NavLink>
      </div>
    </div>
  );
};

Instructions.propTypes = {
  actionType: PropTypes.oneOf(['activate', 'reset-password']),
};

Instructions.defaultProps = {
  actionType: 'activate',
};

Instructions.displayName = 'ActivationDetails';

export default Instructions;
